import { CRMlabelToValueDictionnary } from "@web/shared/dist/datas/CRMvalueToLabelDictionary";
import {
  ICommandeData,
  IStudyInformation,
} from "../../../interfaces/generalInterfaces";

export function formatEtudeLogement(
  studyInformation: IStudyInformation
): ICommandeData["etude_logement"] {
  return {
    surface_habitable: studyInformation.house_surface
      ? String(studyInformation.house_surface)
      : null,
    cover_type:
      CRMlabelToValueDictionnary(
        "natureDuSupport",
        studyInformation.roof_subtype || studyInformation.roof_type
      ) ?? null,
    type_occupation:
      CRMlabelToValueDictionnary(
        "occupationType",
        studyInformation?.occupation_type
      ) || 629950000,
    number_of_inhabitants: studyInformation.number_of_occupants ?? null,
    inclinaison_du_pan:
      CRMlabelToValueDictionnary(
        "roofInclinaison",
        studyInformation.roof_inclinaison?.toString()
      ) ?? null,
    electrical_installation_type:
      CRMlabelToValueDictionnary(
        "typeAlimentation",
        studyInformation.electric_installation_type
      ) || 629950000,
    year_of_construction:
      studyInformation?.annee_de_construction?.toString() ?? null,
    number_of_levels:
      CRMlabelToValueDictionnary(
        "floorNumber",
        (studyInformation.nombre_de_niveaux &&
          (studyInformation.nombre_de_niveaux > 3
            ? "3+"
            : studyInformation.nombre_de_niveaux.toString())) ||
          "1"
      ) ?? null,
    ceiling_height: studyInformation.hauteur_sous_plafond
      ? (studyInformation.hauteur_sous_plafond / 100).toString()
      : null,
    construction_square: studyInformation.surface_au_sol ?? null,
    wall_type:
      (studyInformation.type_de_murs &&
        CRMlabelToValueDictionnary(
          "wallType",
          studyInformation.type_de_murs[0]
        )) ??
      null,
    isolated_walls:
      studyInformation.annee_isolation_murs === "None" ? 629950001 : 629950000,
    number_of_rooms: studyInformation.nombre_de_pieces ?? null,
    attic_type:
      (studyInformation.type_de_combles &&
        CRMlabelToValueDictionnary(
          "atticType",
          studyInformation.type_de_combles[0]
        )) ??
      null,
    ventilation_type:
      (studyInformation.type_de_ventilation &&
        CRMlabelToValueDictionnary(
          "ventilationType",
          studyInformation.type_de_ventilation[0]
        )) ??
      null,
    basement_type:
      (studyInformation.type_de_sous_sol &&
        CRMlabelToValueDictionnary(
          "basementType",
          studyInformation.type_de_sous_sol[0]
        )) ??
      null,
    garageattenantalamaison:
      studyInformation.type_de_garage &&
      (studyInformation?.type_de_garage[0] === "interieur" ||
        studyInformation?.type_de_garage[0] === "independant")
        ? 0
        : 1,
    configurationgarage:
      (studyInformation.type_de_garage &&
        CRMlabelToValueDictionnary(
          "garageConfiguration",
          studyInformation.type_de_garage[0]
        )) ??
      null,
  };
}
