import { ISageProduct } from "../interfaces/generalInterfaces";
import { IDataProduct } from "../interfaces/renoglobaleInterface";

export function isWindowReference(sageRef: string | null) {
  if (!sageRef) return false;
  return sageRef.startsWith("FEN");
}

export function isFrenchWindowReference(sageRef: string | null) {
  if (!sageRef) return false;
  return sageRef.startsWith("PFEN");
}

export function getSageProductFromDataProduct(
  sageProducts: ISageProduct[],
  dataProduct: IDataProduct
): ISageProduct | undefined {
  const sageProduct = sageProducts.find(
    (sageProduct) => sageProduct.AR_Ref === dataProduct.sage_reference
  );
  return sageProduct;
}
