import "./TopSectionMainInformation.scss";

// images import
import pictoChart from "../../../assets/images/picto-chart.svg";
import pictoResteACharge from "../../../assets/images/picto-main-euros.svg";
import pictoProdGarantee from "../../../assets/images/picto-main-pouce-etoiles.svg";
import tirelire from "../../../assets/images/tirelire.svg";

// Interface import
import {
  IProfitability,
  IScenario,
} from "../../../interfaces/generalInterfaces";

// Services import
import { euroShortFormat } from "../../../services/euroFormatter";
import { calculatePVpathPricesFromEeroScenario } from "../../../services/PV/calculatePVpathPrices";

interface IProps {
  profitability: IProfitability | null;
  scenarioChosen: IScenario;
}

enum PriceTitle {
  DEFAULT = "PRIX TTC (hors aides)",
  TVA_OPTION = "PRIX TTC (hors aides, hors remboursement tva)",
}

export const TopSectionMainInformation = ({
  profitability,
  scenarioChosen,
}: IProps) => {
  const priceTitle = profitability?.tva_refund
    ? PriceTitle.TVA_OPTION
    : PriceTitle.DEFAULT;

  // tvaRefundable = real economies done by the client = tva refundable - price of the tva rfund option
  const { tvaRefundable } = calculatePVpathPricesFromEeroScenario({
    scenarioChosen,
  });
  return (
    <div className="TopSectionMainInfo">
      <div className="TopSectionMainInfo__SingleInfoBlock">
        <section className="imageContainer">
          <img
            src={pictoResteACharge}
            alt={"Reste à charge"}
            width={36}
            height={35}
          />
        </section>
        <section className="TopSectionMainInfo__TextContainer">
          <p className="TopSectionMainInfo__Title">{priceTitle}</p>
          <p className="TopSectionMainInfo__Value">
            {profitability?.total_net_cost &&
              euroShortFormat(profitability.total_net_cost)}
          </p>
        </section>
      </div>
      <div className="TopSectionMainInfo__SingleInfoBlock">
        <section className="imageContainer">
          <img
            src={pictoProdGarantee}
            alt="garantie de performance"
            width={43}
            height={39}
          />
        </section>
        <section className="TopSectionMainInfo__TextContainer">
          <p className="TopSectionMainInfo__Title">
            {"Total des aides".toUpperCase()}
          </p>
          <p className="TopSectionMainInfo__Value">
            {profitability?.total_help &&
              euroShortFormat(profitability.total_help)}
          </p>
        </section>
      </div>
      {profitability?.tva_refund && (
        <div className="TopSectionMainInfo__SingleInfoBlock">
          <section className="imageContainer">
            <img src={tirelire} alt="reboursement tva" width={43} height={39} />
          </section>
          <section className="TopSectionMainInfo__TextContainer">
            <p className="TopSectionMainInfo__Title">
              REMBOURSEMENT TVA (prix de l'option déduit)
            </p>
            <p className="TopSectionMainInfo__Value">
              {profitability?.total_help && euroShortFormat(tvaRefundable)}
            </p>
          </section>
        </div>
      )}

      <div className="TopSectionMainInfo__SingleInfoBlock">
        <section className="imageContainer">
          <img src={pictoChart} alt="gains cumulés" width={39} height={37} />
        </section>
        <section className="TopSectionMainInfo__TextContainer">
          <p className="TopSectionMainInfo__Title">
            {"rentabilité annuelle".toUpperCase()}
          </p>
          <p className="TopSectionMainInfo__Value">
            {profitability?.internal_rate_return &&
              new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
              }).format(profitability.internal_rate_return * 100) + " %"}
          </p>
        </section>
      </div>
    </div>
  );
};
