// inspired https://dev.to/faisalpathan/building-components-in-react-accordion-4ka5
import { PropsWithChildren } from "react";
import { CSSTransition } from "react-transition-group";
import "./Accordion.scss";

type AccordionProps = PropsWithChildren<{
  isOpen: boolean;
}>;

export default function Accordion({ children, isOpen }: AccordionProps) {
  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="accordion-wrapper__content"
      unmountOnExit
    >
      <section>{children}</section>
    </CSSTransition>
  );
}
