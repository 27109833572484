// Styles import
import "./saveModale.scss";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";

// Interface import
import {
  HelpsOptions,
  IOreeStudyType,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  study_type: IOreeStudyType;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  devisOptions: HelpsOptions;
  setDevisOptions: React.Dispatch<React.SetStateAction<HelpsOptions>>;
  saveFunction: () => Promise<void>;
  totalMPRHelp: number;
  totalCEEHelp: number;
}
export const SaveModale = ({
  study_type,
  isModalOpen,
  setIsModalOpen,
  devisOptions,
  setDevisOptions,
  saveFunction,
  totalCEEHelp,
  totalMPRHelp,
}: Props) => {
  return (
    <Modale
      isModaleOpen={isModalOpen}
      title="Enregistrer l'étude"
      setShowModal={setIsModalOpen}
    >
      <div className="SaveModale__PDFOptionsContainer">
        <h3>Option du devis</h3>
        <div className="SaveModale__Option">
          <Switch
            onClick={() =>
              setDevisOptions((state) => {
                return {
                  ...state,
                  isHelpsDisplayed: !state.isHelpsDisplayed,
                  isCEEHelpsDeducted: false,
                  isMPRHelpsDeducted: false,
                };
              })
            }
            value={devisOptions.isHelpsDisplayed}
            name="toggle1"
          />
          <p>Afficher les aides sur le devis et l'étude</p>
        </div>

        {/* If we are in a photovoltaique study it is not possible to deduct the helps as the helps are never deductible for this product*/}
        {devisOptions.isHelpsDisplayed &&
        study_type !== IOreeStudyType.PHOTOVOLTAIQUE ? (
          <>
            {totalCEEHelp ? (
              <div className="SaveModale__Option">
                <Switch
                  onClick={() =>
                    setDevisOptions((state) => {
                      return {
                        ...state,
                        isCEEHelpsDeducted: !state.isCEEHelpsDeducted,
                      };
                    })
                  }
                  value={devisOptions.isCEEHelpsDeducted}
                  name="toggle2"
                />
                <p>Déduire les aides CEE du reste à charge</p>
              </div>
            ) : null}

            {totalMPRHelp ? (
              <div className="SaveModale__Option">
                <Switch
                  onClick={() =>
                    setDevisOptions((state) => {
                      return {
                        ...state,
                        isMPRHelpsDeducted: !state.isMPRHelpsDeducted,
                      };
                    })
                  }
                  value={devisOptions.isMPRHelpsDeducted}
                  name="toggle3"
                />
                <p>Déduire les aides MPR du reste à charge</p>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className="SaveModale__ButtonContainer">
        <CTAButton
          name="Enregistrer"
          category="secondary"
          onClick={async () => {
            await saveFunction();
            setIsModalOpen(false);
          }}
        />
      </div>
    </Modale>
  );
};
