import { FinancementInfos } from "../../interfaces/generalInterfaces";
import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

type ResquestData = {
  quoteId: string;
  data: {
    commercialId: string | null;
    orderToCancelId: string | null;
    financementInfos: FinancementInfos;
  };
};

type ResponseData = {
  orderId: string | null;
  bDCFileName: string | null;
  bDCUrl: string | null;
  documentBase64: string | null;
};

function generateCrmBdc({
  studyId,
  data,
}: {
  studyId: string | null;
  data: ResquestData;
}): Promise<ResponseData> {
  return eeroApi
    .post(`/oree2/studies/${studyId}/crm/generateBdc`, data)
    .then((res) => res.data);
}

export function useGenerateCrmBdc() {
  const mutation = useAuthMutation({
    mutationFn: generateCrmBdc,
    retry: 1,
  });

  return mutation;
}
