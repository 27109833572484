import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

type Document = {
  base64: string;
  name: string;
};

type RequestBody = {
  commercialId: string | null;
  quoteId: string;
  documents: Document[];
};

type ResponseData = {
  message: string;
  fileUrls: string[];
};

async function uploadDocumentsToCrm({
  studyId,
  payload,
}: {
  studyId: string | null;
  payload: RequestBody;
}): Promise<ResponseData> {
  return eeroApi
    .post(`/oree2/studies/${studyId}/crm/uploadDocuments`, payload)
    .then((res) => res.data);
}

export function useUploadDocumentsToCrm() {
  const mutation = useAuthMutation({
    mutationFn: uploadDocumentsToCrm,
    retry: false,
  });

  return mutation;
}
