import { ISageProduct } from "../../interfaces/generalInterfaces";
import { ProductFormFilterOption } from "../../interfaces/renoglobaleInterface";

interface IFilterSAGEProducts {
  sageProducts: ISageProduct[];
  filterValues: ProductFormFilterOption[];
}

// FONCTION ONLY USED IN ITE PRODUCT PAGE (ITEPage.tsx).
// REMAINING WORK : GENERALISAION OF THE USE OF THE FONCTION IN THE OTHER PRODUCT PAGES.

/**
 * Filter a list of Sage products to keep only the ones whose keys (product characteristics)'s values match the form values.
 * Ex: keep only the Sage products with brand = "Solaredge"
 *
 * @param {ISageProduct[]} sageProducts - The initial array of Sage products we want to filter.
 * @param {keyof ISageProduct} formInputsvalues - The form inputs values that the Sage product should match.
 * @returns The filtered array of Sage products
 *
 */
export const filterSageProductsAccordingToFormValues = ({
  sageProducts,
  filterValues,
}: IFilterSAGEProducts) => {
  if (filterValues.length === 0) return sageProducts;
  return sageProducts.filter((sageProduct) => {
    return filterValues.every((state) =>
      state.value
        ? sageProduct[state.sageProductKey]?.toString() === state.value
        : true
    );
  });
};
