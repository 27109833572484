import { DSColors } from "@web/shared/src/styles/variables";
import "./formulePersonnalisableForm.scss";

// Libraries import
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SVG from "react-inlinesvg";

// components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Dualite } from "@web/shared/dist/components/DesignSystem/Boutons/Dualite/Dualite";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { PanelsDrawing } from "../../components/PanelsDrawing/PanelsDrawing";
import { ExtraChargesListItem } from "../ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProjectOverview } from "./ProjectOverview/ProjectOverview";

// services import
import { useGetPersonalizedScenario } from "../../api/hooks/useGetPersonalizedScenario";
import { useGetPersonalizedScenarios } from "../../api/hooks/useGetPersonalizedScenarios";
import {
  setIndexScenariosOptionChosenAction,
  setScenarioChosenAction,
} from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useGetScenario } from "../../services/OptionChosen/useGetScenario";
import { capitalize } from "../../utils/stringUtils";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import {
  IAvailablePower,
  InverterBrand,
} from "../../interfaces/generalInterfaces";
import { TExtraFee } from "../../interfaces/renoglobaleInterface";
import { INavigateData } from "../../services/useNavigation/useNavigation";

interface IProps {
  goNext?: (data?: INavigateData<any>) => void;
  userToken: string;
}

// Must match subtypes define at Data backend level
export enum PvOptionsSubType {
  POSITION = "position",
  COVERAGE = "coverage",
  STRUCTURE = "structure",
  TVA_REFUND = "tva_refund",
}
export const FormulePersonnalisableForm = ({ goNext, userToken }: IProps) => {
  // Redux states
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const scenarioChosen = useAppSelector((state) => state.scenarioChosen);
  const dispatch = useAppDispatch();

  //Local states
  const [panelsIndexes, setPanelsIndexes] = useState<number[]>([]);

  const [powerInkWc, setPowerInkWc] = useState<number | null>(
    scenarioChosen?.power_needed || null
  );
  const [numberOfPanels, setNumberOfPanels] = useState<number | null>(
    scenarioChosen?.number_of_panels || null
  );

  const [inverterBrand, setInverterBrand] = useState<string | null>(
    scenarioChosen?.inverter_brand || ""
  );

  const [isBattery, setIsBattery] = useState<boolean>(
    scenarioChosen?.is_storage_battery != null
      ? scenarioChosen?.is_storage_battery
      : false
  );
  const [batteryChoice, setBatteryChoice] = useState<string>("");

  const [isKitHomeManagement, setIsKitHomeManagement] = useState<boolean>(
    scenarioChosen?.kit_home_management != null
      ? scenarioChosen?.kit_home_management
      : false
  );
  const [position, setPosition] = useState<string | null>(
    scenarioChosen?.options?.find(
      (option) => option.sub_type === PvOptionsSubType.POSITION
    )?.sage_reference || null
  );
  const [coverage, setCoverage] = useState<string | null>(
    scenarioChosen?.options?.find(
      (option) => option.sub_type === PvOptionsSubType.COVERAGE
    )?.sage_reference || null
  );
  const [structure, setStructure] = useState<string | null>(
    scenarioChosen?.options?.find(
      (option) => option.sub_type === PvOptionsSubType.STRUCTURE
    )?.sage_reference || null
  );

  const [tvaRefund, setTvaRefund] = useState<string | null>(
    scenarioChosen?.options?.find(
      (option) => option.sub_type === PvOptionsSubType.TVA_REFUND
    )?.sage_reference || null
  );

  const [userMessage, setUserMessage] = useState<string>("");

  const [connectionType, _setConnectionType] = useState<string | undefined>(
    scenarioChosen.raccordement_type || "Autoconsommation avec vente du surplus"
  );

  const [extraFees, setExtraFees] = useState<TExtraFee[]>(
    scenarioChosen.extra_fees || []
  );

  const [isRecalculateNeeded, setIsRecalculNeeded] = useState<boolean>(false);

  // Custom hooks
  const {
    data: personalizedScenarios,
    isLoading: isFetchPersonalizedScenarioLoading,
  } = useGetPersonalizedScenarios({
    studyId: studyInformation?.study_id || null,
    buildingId: studyInformation?.building_id || null,
    householdId: studyInformation?.household_id || null,
  });

  const getAvailableBattery = () => {
    if (
      !personalizedScenarios?.available_calepinages ||
      !powerInkWc ||
      !inverterBrand
    ) {
      return [];
    }

    const batteryInformation =
      personalizedScenarios.available_calepinages[
        powerInkWc.toString() as IAvailablePower
      ]?.[inverterBrand as InverterBrand]?.battery_information;

    if (!batteryInformation) {
      return [];
    }

    const filteredBatteries = batteryInformation.filter(
      (battery) => battery.n_batteries !== 0
    );

    return filteredBatteries.map((battery) => {
      return {
        label: `${capitalize(inverterBrand)}  ${battery.n_batteries} x ${
          battery.single_battery_capacity_kWh
        }kWh`,
        value: `${capitalize(inverterBrand)}  ${battery.n_batteries} x ${
          battery.single_battery_capacity_kWh
        }kWh`,
        numberOfBattery: battery.n_batteries,
        brand: inverterBrand,
        singleBatteryCapacity: battery.single_battery_capacity_kWh,
      };
    });
  };

  const availableBattery = getAvailableBattery();

  const getAvailableKitHomeManagement = () => {
    const canGetAvailableKitHomeManagement =
      personalizedScenarios?.available_calepinages &&
      powerInkWc &&
      inverterBrand;
    if (!canGetAvailableKitHomeManagement) return [];

    const powerInkWcString = powerInkWc.toString() as IAvailablePower;
    const calepinageForGivenPower =
      personalizedScenarios.available_calepinages[powerInkWcString];
    if (!calepinageForGivenPower) return [];

    const inverterBrandString = inverterBrand as InverterBrand;
    const hasKitHomeManagamentArray =
      calepinageForGivenPower[inverterBrandString]?.kit_home_management;

    return hasKitHomeManagamentArray || [];
  };

  const availableKitHomeManagement = getAvailableKitHomeManagement();

  const isKitHomeManagementMandatory =
    availableKitHomeManagement.length === 1 &&
    availableKitHomeManagement[0] === true;

  const selectedOptions = [position, coverage, structure, tvaRefund].filter(
    (option) => option !== null
  ) as string[];

  const fetchPersonalizedScenarioPayload = {
    pathQueries: {
      studyId: studyInformation?.study_id || null,
    },
    paramsQueries: {
      buildingId: studyInformation?.building_id || null,
      householdId: studyInformation?.household_id || null,
      numberOfPanels: numberOfPanels,
      numberOfBatteries:
        availableBattery.find((option) => option.value === batteryChoice)
          ?.numberOfBattery || 0,
      kitHomeManagement: isKitHomeManagementMandatory
        ? true
        : isKitHomeManagement,
      inverter_brand: inverterBrand,
      single_battery_capacity:
        availableBattery.find((option) => option.value === batteryChoice)
          ?.singleBatteryCapacity || null, // We select the battery that matchs the form information
      panel_indices: panelsIndexes,
      totalExtraFees:
        extraFees?.reduce((total, extra_fee) => {
          return total + extra_fee.price_with_tax;
        }, 0) || 0,
      options: selectedOptions || null,
    },
    personalizedScenarios,
  };

  const {
    data: personalizedScenario,
    isFetching: fetchPersonalizedScenarioIsLoading,
    errorMessage: fetchPersonalizedScenarioErrorMessage,
    refetch: fetchPersonalizedScenario,
    isSuccess: isFetchPersonalizedScenarioSuccess,
  } = useGetPersonalizedScenario(fetchPersonalizedScenarioPayload);

  const { getScenario, isLoading: isGetScenarioLoading } = useGetScenario();

  // Functions
  const handleSetIsBattery = (answer: boolean) => {
    if (answer) {
      setBatteryChoice(() => availableBattery[0].value || "");
      setIsBattery(true);
      return;
    }
    setBatteryChoice("");
    setIsBattery(false);
    return;
  };

  const onClickEnSavoirPlus = async () => {
    if (personalizedScenario) {
      dispatch(
        setScenarioChosenAction({
          scenarioChosen: {
            scenario_id: studyInformation.scenario_id || null,
            number_of_panels:
              personalizedScenario?.products?.number_of_panels || null,
            power_needed:
              personalizedScenario?.products?.installed_power || null,
            is_storage_battery: isBattery ?? undefined,
            number_of_batteries:
              personalizedScenario?.products?.number_of_batteries || null,
            kit_home_management: isKitHomeManagement,
            raccordement_type: connectionType,
            recommended_calepinage:
              personalizedScenario?.recommended_calepinage,
            sage_reference_PV: personalizedScenario?.sage_reference_PV,
            selfconsumption_rate: personalizedScenario?.selfconsumption_rate,
            selfproduction_rate: personalizedScenario?.selfproduction_rate,
            total_cost_help_deducted: personalizedScenario?.total_cost,
            total_cost_without_help: personalizedScenario.total_cost,
            total_helps: personalizedScenario?.total_helps,
            pitch: personalizedScenario?.pitch,
            internal_rate_return: personalizedScenario?.internal_rate_return,
            formule_name: personalizedScenario?.formule_name,
            "25_years_economies": personalizedScenario?.["25_years_economies"],
            calepinage_image: personalizedScenario?.calepinage_image,
            tva: personalizedScenario?.tva,
            battery_pose_price_without_tax:
              personalizedScenario?.prices?.battery
                ?.battery_pose_price_without_tax,
            battery_pose_sage_reference:
              personalizedScenario?.prices?.battery
                ?.battery_pose_sage_reference,
            battery_price_without_tax:
              personalizedScenario?.prices?.battery?.battery_price_without_tax,
            battery_sage_reference:
              personalizedScenario?.prices?.battery?.battery_sage_reference,
            battery_alias: personalizedScenario?.prices?.battery?.battery_alias,
            kit_pose_price_without_tax:
              personalizedScenario?.prices?.kit_home_management
                ?.kit_pose_price_without_tax,
            kit_pose_sage_reference:
              personalizedScenario?.prices?.kit_home_management
                ?.kit_pose_sage_reference,
            kit_price_without_tax:
              personalizedScenario?.prices?.kit_home_management
                ?.kit_price_without_tax,
            kit_sage_reference:
              personalizedScenario?.prices?.kit_home_management
                ?.kit_sage_reference,
            kit_alias:
              personalizedScenario?.prices?.kit_home_management?.kit_alias,
            pv_pose_price_without_tax:
              personalizedScenario?.prices?.pv?.pose_price_without_tax,
            pv_pose_sage_reference:
              personalizedScenario?.prices?.pv?.pose_sage_reference,
            pv_price_without_tax:
              personalizedScenario?.prices?.pv?.pv_price_without_tax,
            pv_sage_reference:
              personalizedScenario?.prices?.pv?.pv_sage_reference,
            pv_alias: personalizedScenario?.prices?.pv?.pv_alias,
            inverter_brand: inverterBrand,
            single_battery_capacity:
              availableBattery.find((option) => option.label === batteryChoice)
                ?.singleBatteryCapacity || null,
            calepinage_chosen_indexes:
              personalizedScenario.calepinage_chosen_indexes,
            calepinage_chosen_ortho_coordinates:
              personalizedScenario.calepinage_chosen_ortho_coordinates,
            calepinage_chosen_solar_coordinates:
              personalizedScenario.calepinage_chosen_solar_coordinates,
            solar_potential_image: personalizedScenario.solar_potential_image,
            extra_fees: extraFees,
            is_personalized_scenario: true,
            options: personalizedScenario.options,
            tva_refund: personalizedScenario.tva_refund,
          },
        })
      );
      dispatch(
        setIndexScenariosOptionChosenAction({
          indexOptionChosen: null, // null => means it is a personalized scenario
        })
      );
      goNext && goNext();
    }
  };

  /**
   * Retrieves the stored scenario from the server.
   * Updates the local and redux state with the retrieved scenario.
   */
  const getStoredScenario = async (): Promise<void> => {
    const scenario = await getScenario({
      study_id: studyInformation?.study_id || "",
      userToken: userToken || "",
    });

    // If the scenario is not available, we can't initialize the local states with the stored scenario information
    // If the scenario doesn't have a calepinages indexes, it is not a personalized scenario so we don't want to initialize the local states
    // As the form doe not work if it is filled without calepinage_chosen_indexes (pannels indices) in a solar potential OK case
    if (!scenario || !scenario.calepinage_chosen_indexes) {
      return;
    }

    scenario.power_needed && setPowerInkWc(scenario.power_needed);
    scenario.inverter_brand && setInverterBrand(scenario.inverter_brand);
    scenario.number_of_panels && setNumberOfPanels(scenario.number_of_panels);
    scenario.is_storage_battery && setIsBattery(scenario.is_storage_battery);
    scenario.is_storage_battery &&
      scenario.inverter_brand &&
      setBatteryChoice(
        `${capitalize(scenario.inverter_brand)}  ${
          scenario.number_of_batteries
        } x ${scenario.single_battery_capacity}kWh`
      );
    scenario.kit_home_management &&
      setIsKitHomeManagement(scenario.kit_home_management);
    scenario.extra_fees && setExtraFees(scenario.extra_fees);
    // We set up the pannels indexes only if we are in a study with solar potential information
    if (
      studyInformation.solar_potential_ko === false &&
      scenario.calepinage_chosen_indexes
    ) {
      setPanelsIndexes(scenario.calepinage_chosen_indexes);
    }

    // We initialize the options whith the ones saved in the scenario table in eero DB.
    const positionOption = scenario.options?.find(
      (elem) => elem.sub_type === PvOptionsSubType.POSITION
    );
    positionOption && setPosition(positionOption.sage_reference);
    const structureOption = scenario.options?.find(
      (elem) => elem.sub_type === PvOptionsSubType.STRUCTURE
    );
    structureOption && setStructure(structureOption.sage_reference);
    const coverageOption = scenario.options?.find(
      (elem) => elem.sub_type === PvOptionsSubType.COVERAGE
    );
    coverageOption && setCoverage(coverageOption.sage_reference);
    const tvaRefundOption = scenario.options?.find(
      (elem) => elem.sub_type === PvOptionsSubType.TVA_REFUND
    );
    tvaRefundOption && setTvaRefund(tvaRefundOption.sage_reference);

    dispatch(setScenarioChosenAction({ scenarioChosen: scenario }));
  };

  const updatePanelsIndexes = ({ panel_index }: { panel_index: number }) => {
    const newPanelsIndexesState = [...panelsIndexes];
    panelsIndexes.includes(panel_index)
      ? newPanelsIndexesState.splice(panelsIndexes.indexOf(panel_index), 1)
      : newPanelsIndexesState.push(panel_index);

    // We check if the power selected exists in the list of available power received from the data (and based on the price list)
    const newPowerSelected =
      availablePowerInkWc.find(
        (power) =>
          power.value === newPanelsIndexesState.length * solarPanelPower
      )?.value || null;

    if (!newPowerSelected) {
      // If the power selected is not available we notice the user
      setUserMessage(
        "Puissance non disponible au catalogue, veuillez sélectionner une autre puissance"
      );
      setPowerInkWc(newPowerSelected);

      setPanelsIndexes(newPanelsIndexesState); // We need update the panels selected to be able to modify the number of panels
      setInverterBrand(null); // We reset the inverterbrand to force the user to select an available power.
      return;
    }

    // Case power selected is available in the price list
    setUserMessage("");
    setPowerInkWc(newPowerSelected);
    setPanelsIndexes(newPanelsIndexesState);
  };

  const onClickGetPersonalizedScenario = async () => {
    await fetchPersonalizedScenario();

    if (isFetchPersonalizedScenarioSuccess) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }

    setIsRecalculNeeded(false);
  };

  const resetFormOptionsValues = () => {
    setNumberOfPanels(null);
    setBatteryChoice("");
    setIsBattery(false);
    setIsKitHomeManagement(false);
    setPosition(null);
    setCoverage(null);
    setStructure(null);
    setTvaRefund(null);
  };

  const resetForm = () => {
    resetFormOptionsValues();
    setInverterBrand(null);
    setPowerInkWc(0);
    setPanelsIndexes([]);
  };

  // Actions to perform when the form is loaded
  useEffect(() => {
    getStoredScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Action to perform if some form values are updated
  useEffect(() => {
    setIsRecalculNeeded(true);
  }, [
    powerInkWc,
    numberOfPanels,
    isBattery,
    batteryChoice,
    isKitHomeManagement,
    inverterBrand,
    panelsIndexes.length,
    extraFees,
    position,
    coverage,
    structure,
    tvaRefund,
  ]);

  const solarPanelPower = 500; // 500Wc. Will be a mutable state updatable by the user later.

  const isCalepinageKo =
    personalizedScenarios?.calepinage_ko ||
    (personalizedScenarios?.ortho_panel_coordinates &&
      Object.keys(personalizedScenarios?.ortho_panel_coordinates)?.length ===
        0);

  // Form options initialization from the personalized scenarios received from the data backend
  const availablePowerInkWc = personalizedScenarios?.available_calepinages
    ? Object.keys(personalizedScenarios.available_calepinages)?.map((power) => {
        return {
          label: power,
          value: Number(power),
        };
      })
    : [];

  const availableBrand =
    personalizedScenarios?.available_calepinages && powerInkWc
      ? Object.keys(
          personalizedScenarios.available_calepinages?.[
            powerInkWc.toString() as IAvailablePower
          ]
        )?.map((brand) => {
          return {
            label: capitalize(brand),
            value: brand.toLowerCase(),
          };
        })
      : [];

  const availableNumberPanel =
    personalizedScenarios?.available_calepinages && powerInkWc
      ? personalizedScenarios.available_calepinages[
          powerInkWc.toString() as IAvailablePower
        ]?.[inverterBrand as InverterBrand]?.panel_information?.map((panel) => {
          return {
            label: panel.n_panels.toString(),
            value: panel.n_panels,
          };
        })
      : [];
  const positionOptions =
    personalizedScenarios?.available_calepinages && powerInkWc
      ? personalizedScenarios.available_calepinages[
          powerInkWc.toString() as IAvailablePower
        ]?.[inverterBrand as InverterBrand]?.options
          .filter((option) => option.sub_type === PvOptionsSubType.POSITION)
          ?.map((option) => {
            return {
              label: option.alias || option.name,
              value: option.sage_reference,
            };
          }) || []
      : [];
  const coverageOptions =
    personalizedScenarios?.available_calepinages && powerInkWc
      ? personalizedScenarios.available_calepinages[
          powerInkWc.toString() as IAvailablePower
        ]?.[inverterBrand as InverterBrand]?.options
          .filter((option) => option.sub_type === PvOptionsSubType.COVERAGE)
          ?.map((option) => {
            return {
              label: option.alias || option.name,
              value: option.sage_reference,
            };
          }) || []
      : [];
  const structureOptions =
    personalizedScenarios?.available_calepinages && powerInkWc
      ? personalizedScenarios.available_calepinages[
          powerInkWc.toString() as IAvailablePower
        ]?.[inverterBrand as InverterBrand]?.options
          .filter((option) => option.sub_type === PvOptionsSubType.STRUCTURE)
          ?.map((option) => {
            return {
              label: option.alias || option.name,
              value: option.sage_reference,
            };
          }) || []
      : [];
  const tvaRefundOption =
    personalizedScenarios?.available_calepinages && powerInkWc
      ? personalizedScenarios.available_calepinages[
          powerInkWc.toString() as IAvailablePower
        ]?.[inverterBrand as InverterBrand]?.options
          .filter((option) => option.sub_type === PvOptionsSubType.TVA_REFUND)
          ?.map((option) => {
            return {
              label: option.alias || option.name,
              value: option.sage_reference,
            };
          }) || []
      : [];

  const estimatedSolarProduction = panelsIndexes.reduce((acc, curr) => {
    return (
      acc +
      (personalizedScenarios?.solar_production?.[curr]
        ?.total_solar_production_kWh || 0)
    );
  }, 0);

  const isGetPersonalizedScenarioDisabled =
    !powerInkWc ||
    !numberOfPanels ||
    isBattery === null ||
    isKitHomeManagement === null ||
    !inverterBrand ||
    (isBattery === true && !batteryChoice);
  const isOnduleurInfoMessageDisplayed =
    studyInformation.electric_installation_type === "Triphase" &&
    powerInkWc &&
    powerInkWc >= 3000;

  useEffect(() => {
    if (isKitHomeManagementMandatory) {
      setIsKitHomeManagement(true);
    }
  }, [isKitHomeManagementMandatory]);

  // JSX
  if (isFetchPersonalizedScenarioLoading || isGetScenarioLoading) {
    return (
      <div className="FormulePersonnalisableForm__LoadinContainer">
        <LoadingComponent diameter={60} />
      </div>
    );
  }

  return (
    <div className="FormulePersonnalisableForm">
      <div className="FormulePersonnalisableForm__CanvasContainer">
        {!isCalepinageKo && personalizedScenarios?.ortho_panel_coordinates ? (
          <div className="FormulePersonnalisableForm__CanvasTitle">
            <p>
              Veuillez sélectionner la puissance et le calepinage de
              l'installation en cliquant sur la toiture
            </p>
          </div>
        ) : null}

        <div className="FormulePersonnalisableForm__CanvasContent">
          <div className="FormulePersonnalisableForm__CanvasDiv">
            <PanelsDrawing
              imageHeight={550}
              imageWidth={550}
              panelsCoordinatesOrtho={
                personalizedScenarios?.ortho_panel_coordinates
              }
              panelsCoordinatesSolarPotential={
                personalizedScenarios?.solar_panel_coordinates
              }
              imageOrthoBase64={
                "data:image/png;base64, " +
                (personalizedScenarios?.ortho_image || "")
              }
              imageSolarPotentialBase64={
                "data:image/png;base64, " +
                (personalizedScenario?.solar_potential_image || "")
              }
              calepinage={panelsIndexes}
              updatePanelsIndexes={updatePanelsIndexes}
              resetForm={resetForm}
              scale={1.5}
              isSolarPotential={true}
            />
          </div>
          <div className="FormulePersonnalisableForm__FormContainer">
            {/* If the calepinage is available, we don't display the power as is is already displayed above */}
            {isCalepinageKo ? (
              <div className="FormulePersonnalisableForm__InputContainer">
                <label>Puissance : </label>
                <div className="FormulePersonnalisableForm__DropDownContainer">
                  <div className="FormulePersonnalisableForm__DropDown">
                    <DropdownV2
                      placeholder={"Selectionner..."}
                      options={availablePowerInkWc}
                      values={[powerInkWc || 0]}
                      onSelect={(option) => {
                        if (option.value !== null) {
                          setPowerInkWc(Number(option.value));
                          setInverterBrand(null);
                          setNumberOfPanels(null);
                          setIsBattery(false);
                          setBatteryChoice("");
                        }
                      }}
                      isRequired={true}
                    />
                  </div>

                  <div className="FormulePersonnalisableForm__SVG">
                    {powerInkWc ? (
                      <SVG
                        src={Trash}
                        onClick={() => {
                          setNumberOfPanels(null);
                          setInverterBrand(null);
                          setPowerInkWc(null);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="FormulePersonnalisableForm__InputContainer">
              <label>Onduleur : </label>
              <div
                className="FormulePersonnalisableForm__DropDownContainer"
                title={
                  !powerInkWc
                    ? "Veuillez sélectionner un puissance minimum supérieure à 3 000 Wc avant de sélectionner un onduleur"
                    : ""
                }
              >
                <div className="FormulePersonnalisableForm__DropDown">
                  <DropdownV2
                    placeholder={
                      powerInkWc
                        ? "Selectionner..."
                        : "Puissance non disponible"
                    }
                    options={availableBrand}
                    values={[inverterBrand || ""]}
                    onSelect={(option) => {
                      if (option.value !== null) {
                        setInverterBrand(option.value?.toString() ?? null);
                        resetFormOptionsValues();
                        return;
                      }
                    }}
                    isRequired={true}
                    isDisabled={!powerInkWc}
                  />

                  {isOnduleurInfoMessageDisplayed ? (
                    <div className="FormulePersonnalisableForm__InfoMessage">
                      Vous êtes en <strong>alimentation triphasée</strong>.
                      Certains produits peuvent ne pas être disponible.
                    </div>
                  ) : null}
                </div>

                <div className="FormulePersonnalisableForm__SVG">
                  {inverterBrand ? (
                    <SVG
                      src={Trash}
                      onClick={() => {
                        setInverterBrand(null);
                        setNumberOfPanels(null);
                        setBatteryChoice("");
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="FormulePersonnalisableForm__InputContainer">
              <label>Nombre de modules : </label>
              <div
                className="FormulePersonnalisableForm__DropDownContainer"
                title={
                  !inverterBrand
                    ? "Veuillez sélectionner un onduleur avant de sélectionner un nombre de module"
                    : ""
                }
              >
                <div className="FormulePersonnalisableForm__DropDown">
                  <DropdownV2
                    placeholder={
                      inverterBrand
                        ? "Selectionner..."
                        : "Sélectionnez d'abord un onduleur"
                    }
                    options={availableNumberPanel}
                    values={numberOfPanels ? [numberOfPanels] : []}
                    onSelect={(option) => {
                      if (option.value !== null) {
                        setNumberOfPanels(Number(option.value));
                      }
                    }}
                    isRequired={true}
                    isDisabled={!inverterBrand}
                  />
                </div>
                <div className="FormulePersonnalisableForm__SVG">
                  {numberOfPanels ? (
                    <SVG
                      src={Trash}
                      onClick={() => {
                        setNumberOfPanels(null);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <h4>Options</h4>
            <div className="FormulePersonnalisableForm__InputContainer">
              <label>Batterie de stockage : </label>
              <Dualite
                binaryValue={isBattery}
                onSelect={(value) => handleSetIsBattery(value)}
                isRequired={true}
                isDisabled={availableBattery.length === 0}
                messageIfDisabled={
                  availableBattery.length === 0
                    ? "Pas de batterie disponible"
                    : ""
                }
              />
            </div>

            <div
              className={`FormulePersonnalisableForm__InputContainer${
                !isBattery ? "__hide" : ""
              }`}
            >
              <label>Batterie(s) : </label>

              <div className="FormulePersonnalisableForm__DropDown">
                <DropdownV2
                  placeholder="Selectionner..."
                  options={availableBattery}
                  values={[batteryChoice]}
                  onSelect={(option) => {
                    setBatteryChoice(option.value?.toString() || "");
                  }}
                  isRequired={true}
                />
              </div>
            </div>

            <div className="FormulePersonnalisableForm__InputContainer">
              <label>Kit de Home Management :</label>
              <Dualite
                binaryValue={
                  isKitHomeManagementMandatory
                    ? isKitHomeManagementMandatory
                    : isKitHomeManagement
                }
                onSelect={(value) => setIsKitHomeManagement(value)}
                isRequired={true}
                isDisabled={
                  availableKitHomeManagement.length === 1 ||
                  isKitHomeManagementMandatory
                }
                messageIfDisabled={
                  isKitHomeManagementMandatory
                    ? "Le kit de Home Management est obligatoire pour cette marque"
                    : availableKitHomeManagement.length === 1
                    ? "Pas de kit disponible"
                    : ""
                }
              />
            </div>

            {positionOptions.length > 0 ? (
              <div className="FormulePersonnalisableForm__InputContainer">
                <label>Format paysage :</label>
                <Dualite
                  binaryValue={!!position}
                  onSelect={(value) => {
                    if (value === true) {
                      setPosition(positionOptions[0].value); // For now there is only one option of position. That is why we use a switch button.
                      return;
                    }

                    setPosition(null);
                  }}
                  isRequired={true}
                  isDisabled={!inverterBrand}
                  messageIfDisabled={
                    "Vous devez sélectionner un onduleur avant de pouvoir selectionner l'option format paysage"
                  }
                />
              </div>
            ) : null}

            {structureOptions.length > 0 ? (
              <div className="FormulePersonnalisableForm__InputContainer">
                <label>Calepinage multi-champs :</label>
                <Dualite
                  binaryValue={!!structure}
                  onSelect={(value) => {
                    if (value === true) {
                      setStructure(structureOptions[0].value); // For now there is only one option of structure. That is why we use a switch button.
                      return;
                    }

                    setStructure(null);
                  }}
                  isRequired={true}
                  isDisabled={!inverterBrand}
                  messageIfDisabled={
                    "Vous devez sélectionner un onduleur avant de pouvoir selectionner l'option de multichamps"
                  }
                />
              </div>
            ) : null}

            {tvaRefundOption.length > 0 ? (
              <div className="FormulePersonnalisableForm__InputContainer">
                <label>Remboursement de la TVA :</label>
                <Dualite
                  binaryValue={!!tvaRefund}
                  onSelect={(value) => {
                    if (value === true) {
                      setTvaRefund(tvaRefundOption[0].value); // For now there is only one option of tva refund. That is why we use a switch button.
                      return;
                    }

                    setTvaRefund(null);
                  }}
                  isRequired={true}
                  isDisabled={!inverterBrand}
                  messageIfDisabled={
                    "Vous devez sélectionner un onduleur avant de pouvoir selectionner l'option de remboursement de TVA"
                  }
                />
              </div>
            ) : null}
            {coverageOptions.length > 0 ? (
              <div className="FormulePersonnalisableForm__InputContainer">
                <label>Extension de garantie onduleur</label>
                <Dualite
                  binaryValue={!!coverage}
                  onSelect={(value) => {
                    if (value === true) {
                      setCoverage(coverageOptions[0].value); // For now there is only one option of coverage. That is why we use a switch button.
                      return;
                    }

                    setCoverage(null);
                  }}
                  isRequired={true}
                  isDisabled={!inverterBrand}
                  messageIfDisabled={
                    "Vous devez sélectionner un onduleur avant de pouvoir selectionner l'option d'assurance"
                  }
                />
              </div>
            ) : null}

            <ExtraFees extraFees={extraFees} setExtraFees={setExtraFees} />

            <div className="FormulePersonnalisableForm__ButtonContainer">
              <CTAButton
                name="Valider"
                onClick={onClickGetPersonalizedScenario}
                isLoading={fetchPersonalizedScenarioIsLoading}
                isDisabled={isGetPersonalizedScenarioDisabled}
              />
            </div>
            {fetchPersonalizedScenarioErrorMessage && (
              <p>Pas de scenario disponible</p>
            )}
          </div>
        </div>
        <p className="FormulePersonnalisableForm__UserMessage">{userMessage}</p>
      </div>

      <section className="FormulePersonnalisableForm__FormSection">
        <div className="FormulePersonnalisableForm__InformationContainer">
          <div className="FormulePersonnalisableForm__InstallationInformation">
            <p>
              Puissance par panneau : <strong>{solarPanelPower + " Wc"}</strong>
            </p>
            <p>
              Nombre de panneau(x) :{" "}
              <strong>
                {panelsIndexes.length ||
                  (powerInkWc || 0) / solarPanelPower ||
                  0}
              </strong>
            </p>
            <p>
              Puissance totale :{" "}
              <strong>
                {(solarPanelPower * (panelsIndexes.length || 0) ||
                  powerInkWc ||
                  0) + " Wc"}
              </strong>
            </p>
            <p>
              Type de raccordement : <strong>{connectionType}</strong>
            </p>

            {!isCalepinageKo ? (
              <p>
                Production solaire annuelle estimée :{" "}
                <strong>
                  {new Intl.NumberFormat("fr-FR", {
                    maximumSignificantDigits: 4,
                  }).format(estimatedSolarProduction || 0) + " kWh"}
                </strong>
              </p>
            ) : null}
          </div>

          <ProjectOverview
            errorMessageEnSavoirPlusButton={
              fetchPersonalizedScenarioErrorMessage
            }
            nombreDeModules={numberOfPanels || ""}
            puissance={personalizedScenario?.products?.installed_power || ""}
            batterieDeStockages={
              !!personalizedScenario?.products?.number_of_batteries
            }
            nombreDeBatteries={
              personalizedScenario?.products?.number_of_batteries || ""
            }
            kitHomeManagement={
              personalizedScenario?.products?.kit_home_management
            }
            typeDeRaccordement={connectionType || ""}
            goNext={onClickEnSavoirPlus}
            isGetPersonalizedScenarioLoading={false}
            personalizedScenarioChosen={personalizedScenario || null}
            isRecalculateNeeded={isRecalculateNeeded}
            extraFees={extraFees}
          />
        </div>
      </section>
    </div>
  );
};

interface ExtraFeesProps {
  extraFees: TExtraFee[];
  setExtraFees: Dispatch<SetStateAction<TExtraFee[]>>;
}

function ExtraFees({ extraFees, setExtraFees }: ExtraFeesProps) {
  const [isModaleExtraFeesOpen, setIsModaleExtraFeesOpen] =
    useState<boolean>(false);

  return (
    <div className="FormulePersonnalisableForm__InputContainer">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleExtraFeesOpen}
        setFraisSupp={setExtraFees}
        setIsModaleFraisSuppOpen={setIsModaleExtraFeesOpen}
      />

      {extraFees.length > 0 ? (
        <section className="ISOComblesPage__ListFraisSuppContainer">
          {extraFees.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setExtraFees}
              />
            );
          })}
        </section>
      ) : null}

      <section className="ISOComblesPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleExtraFeesOpen(true)}
          />
        }
      </section>
    </div>
  );
}
