//Style import
import "./RecapProduit.scss";

// Interfaces import
import { ISageProduct, IScenario } from "../../../interfaces/generalInterfaces";

//Local interface
interface Props {
  image?: string;
  item?: ISageProduct;
  url?: string;
  scenarioChosen?: IScenario;
  productName?: string | null;
}

export const RecapProduit = ({
  image,
  item,
  url,
  scenarioChosen,
  productName,
}: Props) => {
  return (
    <div className="RecapProduit">
      {image ? (
        <div className="RecapProduit__Image">
          <img src={image} alt="photovoltaique" />
        </div>
      ) : null}
      <div className="RecapProduit__Infos">
        <div className="RecapProduit__Infos__Wrapper">
          <p className="titleProduct">
            {item?.NO_Qte && +item.NO_Qte + " x "}
            {scenarioChosen?.number_of_batteries &&
              scenarioChosen.number_of_batteries + " x "}
            {productName || item?.AR_Design}
          </p>
          <ul>
            {item?.Marque && (
              <li>
                Marque <strong>{item.Marque}</strong>
              </li>
            )}
            {item?.Gamme && (
              <li>Gamme {(item.NO_RefDet || "") + " " + item.Gamme}</li>
            )}
            {item?.["Capacité de la batterie"] && (
              <li>Capacité {item["Capacité de la batterie"]}</li>
            )}
            {item?.Dimensions && <li>Dimensions : {item.Dimensions}</li>}
            {item?.Alimentation && <li>Alimentation {item.Alimentation}</li>}
            {item?.["Norme et Certification"] && (
              <li>Norme et Certification : {item["Norme et Certification"]}</li>
            )}
            {item?.["Garantie Fabricant"] && (
              <li>Garantie Fabricant : {item["Garantie Fabricant"]}</li>
            )}
            {item?.["Classe energétique (Froid/Chaud"] && (
              <li>
                Classe energétique : {item["Classe energétique (Froid/Chaud"]}
              </li>
            )}
            {item?.["COP/SCOP"] && (
              <li>Classe energétique : {item["COP/SCOP"]}</li>
            )}
          </ul>
        </div>
        <div className="RecapProduit__Infos__Link">
          {url && (
            <a href={url} target="_blank" rel="noreferrer">
              Fiche technique
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
