import React, { useEffect, useState } from "react";
import "./MonInstallationScenarios.scss";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetMesScenarios } from "../../../services/MonInstallation/useGetMesScenarios";
import { useSaveLastPageVisited } from "../../../services/Oree2/useSaveLastPageVisited";
import { INavigateData } from "../../../services/useNavigation/useNavigation";

// Components import
import { LoadingScreen } from "../../../containers/LoadingScreen/LoadingScreen";
import { ScenariosListContainer } from "../../../containers/ScenariosListContainer/ScenariosListContainer";
import { SubHeader } from "../../../containers/SubHeader/SubHeader";
import { MonInstallationFormulePersonnalisable } from "../MonInstallationFormulePersonnalisable/MonInstallationFormulePersonnalisable";

// Interfaces import
import { IGoTo } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MonInstallationScenarios = ({ goNext }: IProps) => {
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const monInstallation = useAppSelector((state) => state.monInstallation);
  const scenarioChosen = useAppSelector((state) => state.scenarioChosen);
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const [isPageOpen, setIsPageOpen] = useState<boolean>(
    scenarioChosen?.is_personalized_scenario || false
  );
  const { getMesScenarios, errorMessage } = useGetMesScenarios();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  const [isLoading, setIsLoading] = useState(true);

  const fetchScenarios = async (userToken: string) => {
    const response = await getMesScenarios({
      building_id: studyInformation.building_id || "",
      household_id: studyInformation.household_id || "",
      userToken: userToken,
      study_id: studyInformation.study_id || "",
    });

    (!response || response.length === 0) && setIsPageOpen(true);
    setIsLoading(false);
  };

  useEffect(() => {
    userAuthInfo.userToken && fetchScenarios(userAuthInfo.userToken);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="MonInstallationScenarios">
      <SubHeader
        title="Mon installation"
        goNext={() => {
          saveLastPageVisited({
            last_page_visited: "my_installation",
            study_id: studyInformation?.study_id || null,
            userToken: userAuthInfo.userToken,
          });
          goNext();
        }}
        isLoading={isLoading}
        errorMessage={errorMessage}
        isNextButtonVisible={false}
      />
      {isLoading ? (
        <LoadingScreen
          sentenceOne="Nous sommes en train de calculer"
          sentenceTwo="les meilleurs scénarios pour votre toiture..."
        />
      ) : (
        <>
          {monInstallation.mesScenarios && (
            <ScenariosListContainer
              scenariosList={monInstallation.mesScenarios}
              goNext={goNext}
              aidesDeduitesVisible={monInstallation.mesScenarios.length > 0}
              company_id={userAuthInfo.companyId}
              scenario_id={studyInformation.scenario_id || null}
            />
          )}
          <MonInstallationFormulePersonnalisable
            goNext={goNext}
            setIsPageOpen={setIsPageOpen}
            isPageOpen={isPageOpen}
            building_id={studyInformation.building_id || ""}
            household_id={studyInformation.household_id || ""}
            userToken={userAuthInfo.userToken || ""}
            formulePersBtnVisible={monInstallation.mesScenarios.length > 0}
          />
        </>
      )}
    </div>
  );
};
