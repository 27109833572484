// Must match the values in the table permissions in Eero database
export const AUTHORIZATION_RIGHTS_LIST = {
  //Portal Admin
  portailAdmin_access: 1001,
  portailAdmin_swaggers_access: 1003,
  CRUD_coupon: 1004,
  prices_list_update: 1005,
  prices_list_read: 1006,
  prices_list_create: 1012,
  prices_list_delete: 1013,
  leads_management: 1007,
  adminportal_espacetech_access: 1008,
  role_create: 1009,
  permissions_read: 1010,
  role_read: 1011,
  role_update: 1022,
  role_delete: 1023,
  groups_read: 1017,
  groups_delete: 1018,
  groups_create: 1019,
  groups_update: 1020,
  groups_update_multiple: 1021,
  user_role_update: 1024,
  commercial_create: 1025,
  commercial_read: 1026,
  commercial_update: 1027,
  commercial_delete: 1028,
  user_create: 1030,
  user_read: 1031,
  user_update: 1032,
  user_delete: 1033,

  //Becqe
  oree2_access: 2001,
  oree2_renovationglobale: 2002,
  oree2_photovoltaique: 2003,

  //Vitee
  vitee_access: 3001,

  // Other
  super_user: 9999,
};
