import { WindowsInputFormData } from "../pages/MonLogementRG/MonLogementRG.types";
import {
  IDataProduct,
  IProjectKPI,
  TFraisSupp as TExtraFee,
} from "./renoglobaleInterface";

// Redux states interface
export interface IAppStates {
  userAuthInfo: IUserAuthInfo;
  commercialUserInformation: ICommercialUserInformation;
  monInstallation: IMonInstallation;
  SAGEItemsInfo: ISageProduct[];
  studyInformation: IStudyInformation;
  scenarioChosen: IScenario;
  cartProducts: IDataProduct[];
  RGProjectKPI: IProjectKPI | null;
  solarPotentialStepsStatus: ISolarPotentialStepsStatus;
  companySettings: CompanySettings;
}

export interface ISolarPotentialStepsStatus {
  isLoadingScreenDisplayed?: boolean;
  isSolarPotentialKOForm?: boolean | undefined;
  isCheckSolarPotentialAvailabilityLoading?: boolean;
  isGetSolarPotentialLoading?: boolean;
}

// Values are stored in DB as is, and should not be changed without a DB update
export enum IOreeStudyType {
  PHOTOVOLTAIQUE = "photovoltaique",
  RENOVATION_GLOBALE = "renovation-globale",
}

export const oreeStudyLabelDictionary = {
  [IOreeStudyType.PHOTOVOLTAIQUE]: "photovoltaïque",
  [IOreeStudyType.RENOVATION_GLOBALE]: "rénovation globale",
};

export interface IMonInstallation {
  mesScenarios: IDataScenarioPvExtended[];
  indexOptionChosen: number | null;
  performance: IPerformance | null;
  profitability: IProfitability | null;
  dimensionnement: IDimensionnement | null;
  impactEnvironnemental: IImpactEnvironnemental | null;
}

// User information interfaces
export interface IUserAuthInfo {
  userToken: string | null;
  userId: string | null;
  companyId: string | null;
  crm_user_id?: string | null;
  company_name?: string | null;
}

export interface ICommercialUserInformation {
  userFirstName: string | null;
  userLastName: string | null;
  userEmail: string | null;
  userPhoneNumber: string | null;
  userCompany: string | null;
  userRole: string | null;
  authorization_rights?: number[];
  company_address?: string | null;
  company_postal_code?: string | null;
  company_city?: string | null;
  company_service_client_email?: string | null;
  company_service_client_phone?: string | null;
  company_registre_du_commerce_numero?: string | null;
  company_tva_intracommunautaire_numero?: string | null;
  company_capital?: string | null;
  company_siret?: string | null;
  company_website_url?: string | null;
  company_id: string | null;
  company_logo_base_64?: string | null;
  company_raison_sociale?: string | null;
}

export type IStudyInformation = {
  study_id?: string | null;
  study_type?: IOreeStudyType | null;
  created_at?: string | null;
  commercial_id?: string | null;
  customer_id?: string | null;
  status?: number | null;
  comments?: string[] | null;
  last_page_visited?: string | null;
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  phonenumber?: string | null;
  membership_code?: string | null;
  how_did_you_know_us?: string | null;
  scenario_id?: string | null;
  total_cost_help_deducted?: number | null;
  total_cost_without_help?: number | null;
  total_helps?: number | null;
  scenario_rg_id?: string | null;
  type_of_payment?: string | null;
  loan_duration?: number | null;
  deposit?: number | null;
  interest_rate?: string | null;
  has_borrower_insurance?: boolean | null;
  is_deferral_option?: boolean;
  has_co_borrower?: boolean;
  monthlyPayment?: number;
  status_label?: string | null;
  loanSimulationResult?: LoanSimulationResult;
  last_bdc_id?: string | null;
  quote_id?: string | null;
  helps_options?: HelpsOptions;
} & IHousehold & {
    indexScenarioChosen?: number | null;
    solar_potential_ok?: boolean | null;
  };

export interface IHousehold {
  household_id?: string | null;
  user_id?: string | null;
  pdl?: string | null;
  id_source?: string | null;
  city?: string | null;
  address?: string | null;
  zipcode?: string | null;
  consent?: boolean | null;
  date_consent?: string | null;
  household_created_at?: string | null;
  tarification_option?: string | null;
  subscribed_power?: number | null;
  offpeak_hours?: IOffPeakHour[] | null;
  data_is_complete?: boolean | null;
  is_homeys_done?: boolean | null;
  homeys_datarecoverystatus?: "SUCCESS" | "FAILURE";
  roof_type?: string | null;
  roof_subtype?: string | null;
  roof_inclinaison?: number | null;
  roof_exposition?: string | null;
  roof_solar_mask?: number | null;
  annual_consumption_in_euros?: number | null;
  annual_consumption_in_kwh?: number | null;
  number_of_occupants?: number | null;
  house_surface?: number | null;
  electric_installation_type?: string | null;
  other_equipments?: string[] | null;
  is_electric_heater?: boolean | null;
  is_electric_water_heater?: boolean | null;
  becqe_firstname?: string | null;
  becqe_lastname?: string | null;
  becqe_email?: string | null;
  becqe_phonenumber?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  building_id?: string | null;
  roof_area_to_equip?: number | null;
  roof_sections?: TRoofSections[];
  solar_potential_image?: string | null;
  building_image?: string | null;
  source_energie?: (string | null)[];
  electricite_principaux_equipments?: (string | null)[];
  electricite_surface_chauffee?: number | null;
  electricite_temperature_confort?: number | null;
  electricite_type_chauffage?: (string | null)[];
  electricite_nombre_emetteur?: number | null;
  electricite_type_emetteur?: (string | null)[];
  electricite_type_ballon_eau_chaude?: (string | null)[];
  electricite_storage_capacity?: number | null;
  electricite_installation_year?: string | null;
  electricite_chauffage_euros?: number | null;
  electricite_ecs_euros?: number | null;
  electricite_autres_euros?: number | null;
  electricite_eclairage_euros?: number | null;
  electricite_piscine_euros?: number | null;
  electricite_borne_de_recharge_euros?: number | null;
  electricite_climatisation_euros?: number | null;
  electricity_price_per_kwh?: number | null;
  electricite_pac_aireau_installation_year?: number | null;
  electricite_pac_airair_installation_year?: number | null;
  gaz_consommation_euros?: number | null;
  gaz_consommation_kwh?: number | null;
  gaz_principaux_equipments?: (string | null)[];
  gaz_surface_chauffee?: number | null;
  gaz_temperature_confort?: number | null;
  gaz_type_chauffage?: (string | null)[];
  gaz_nombre_emetteur?: number | null;
  gaz_type_emetteur?: (string | null)[];
  gaz_storage_capacity?: number | null;
  gaz_chauffage_euros?: number | null;
  gaz_ecs_euros?: number | null;
  gaz_autres_euros?: number | null;
  gaz_price_per_kwh?: number | null;
  is_gaz_ecs_integrated_to_heating_system?: boolean | null;
  bois_consommation_euros?: number | null;
  bois_surface_chauffee?: number | null;
  bois_temperature_confort?: number | null;
  bois_type_chauffage?: (string | null)[];
  bois_nombre_emetteur?: number | null;
  bois_type_emetteur?: (string | null)[];
  gpl_consommation_euros?: number | null;
  gpl_principaux_equipments?: (string | null)[];
  gpl_surface_chauffee?: number | null;
  gpl_temperature_confort?: number | null;
  gpl_type_chauffage?: (string | null)[];
  gpl_nombre_emetteur?: number | null;
  gpl_type_emetteur?: (string | null)[];
  gpl_storage_capacity?: number | null;
  gpl_chauffage_euros?: number | null;
  gpl_ecs_euros?: number | null;
  fioul_consommation_euros?: number | null;
  fioul_principaux_equipments?: (string | null)[];
  fioul_surface_chauffee?: number | null;
  fioul_temperature_confort?: number | null;
  fioul_nombre_emetteur?: number | null;
  fioul_type_emetteur?: (string | null)[];
  fioul_storage_capacity?: number | null;
  fioul_chauffage_euros?: number | null;
  fioul_ecs_euros?: number | null;
  fioul_type_chauffage?: (string | null)[];
  solar_potential_ko?: boolean;
  number_of_modules?: number | null;
  is_storage_battery?: boolean | null;
  number_of_storage_batteries?: number | null;
  is_kit_home_management?: boolean | null;
  raccordement_type?: string | null;
  power_needed?: number | null;
  surface_habitable?: number | null;
  annee_de_construction?: number | null;
  nombre_de_niveaux?: number | null;
  nombre_de_pieces?: number | null;
  hauteur_sous_plafond?: number | null;
  surface_au_sol?: number | null;
  annee_isolation_murs?: string | null;
  annee_isolation_combles?: string | null;
  annee_isolation_sous_sol?: string | null;
  annee_isolation_garage?: string | null;
  type_de_murs?: (string | null)[];
  type_de_combles?: (string | null)[];
  type_de_ventilation?: (string | null)[];
  type_de_sous_sol?: (string | null)[];
  type_de_garage?: (string | null)[];
  fenetres?: WindowsInputFormData;
  portes_fenetres?: WindowsInputFormData;
  fiscally_french?: boolean | null;
  occupation_type?: string | null;
  income_color?: string | null;
}

export interface IGetStudiesResponse {
  study_id: string;
  study_type: IOreeStudyType | null;
  created_at: string | null;
  modified_at: string | null;
  commercial_id: string | null;
  customer_id: string | null;
  household_id: string | null;
  status: number | null;
  comments: string[] | null;
  email: string | null;
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
  address: string | null;
  zipcode: string | null;
  status_label: string | null;
  devis_count: number | null;
  bdc_count: number | null;
}

export interface IDataSendFromResetPasswordToLogin {
  message?: string;
}

export interface IUpdatePasswordBodyRequest {
  user_id: string;
  oldPassword: string;
  newPassword: string;
}

export interface IUpdatePasswordResponse {
  code: number;
  errors: string | null;
}

// Interface used for Login API request
export interface ILoginRequestResponse {
  token?: string;
  userId?: string;
  companyId?: string;
  crm_user_id?: string | null;
  error?: string | null;
  company_name?: string | null;
}

export interface ILoginUserBody {
  email: string;
  password: string;
  serviceAccessValue: number;
}

export interface IConsumptionUnit {
  datetime: string;
  electric_consumption: number;
}

export interface IConsumptionPeakData {
  start: string;
  end: string;
}
export interface ErrorResponse {
  error?: string;
}

export interface IConsumptionData {
  tarification_option: string;
  subscribed_power: number;
  annual_electric_consumption: number;
  monthly_electric_consumption: number;
  CO2_emissions_associated_to_consumptions: number;
  average_consumption_peak: IConsumptionPeakData[];
  hourly_consumption: IConsumptionUnit[];
}

export interface IMonPotentielSolaireAPIResponse {
  building_image: string;
  count_of_roof_sections: number;
  image_height: number;
  image_width: number;
  roof_material: string;
  roof_sections: TRoofSections[];
  roof_total_area_m2: number;
  solar_potential_image: string;
  solar_potential_ok: boolean;
}

export interface IPotentielSolaireKOInfo {
  roof_material: string | null;
  type_material?: string | null;
  inclinaison?: number | null;
  exposition?: string | null;
  impact_masque_solaire?: number | null;
  roof_area_to_equip?: number | null;
}

export type TRoofSections = {
  azimuth_degrees: number;
  centroid_pixel_coordinates: {
    x: number | null;
    y: number | null;
  };
  area_m2: number | null;
  orientation: string | null;
  slope_degrees: number | null;
};

export type IOrderedKPI = {
  label: string;
  value: number;
};

// PV personalized scenario received by the data backend
export type PvScenarioData = {
  calepinage_image: string | null;
  formule_name: string | null;
  pitch: string | null;
  selfproduction_rate: number | null;
  selfconsumption_rate: number | null;
  internal_rate_return: number | null;
  "25_years_economies": number | null;
  kit_home_management_price: number | null;
  battery_price: number | null;
  tva: number | null;
  recommended_calepinage: boolean | null;
  total_helps?: number | null;
  total_cost?: number | null;
  sage_reference_PV?: string | null;
  prices?: IPrices | null;
  products: {
    number_of_panels: number | null;
    installed_power: number | null;
    number_of_batteries: number | null;
    kit_home_management: boolean | null;
    single_battery_capacity: number | null;
    inverter_brand: string | null;
  };
  solar_potential_image: string | null;
  options: KitPvOption[];
  tva_refund: number | null;
};

// PV recommended scenario received by the data backend
export type PvScenarioDataRecommended = PvScenarioData & {
  ordered_kpis: IOrderedKPI[];
};

type ScenarioCalepinageInfo = {
  calepinage_chosen_ortho_coordinates?: {
    [key: string]: number[][];
  } | null;
  calepinage_chosen_solar_coordinates?: {
    [key: string]: number[][];
  } | null;
  calepinage_chosen_indexes?: number[];
};

// PV personalized scenario enriched with calepinage information
export type DataPersonalizedScenarioPvExtended = PvScenarioData &
  ScenarioCalepinageInfo;

// PV recommended scenario received by the data backend
export type IDataScenarioPvExtended = PvScenarioDataRecommended &
  ScenarioCalepinageInfo;

export interface IPrices {
  battery?: {
    battery_pose_price_without_tax?: number;
    battery_pose_sage_reference?: string;
    battery_price_without_tax?: number;
    battery_sage_reference?: string;
    battery_alias?: string;
  };
  kit_home_management?: {
    kit_pose_price_without_tax?: number;
    kit_pose_sage_reference?: string;
    kit_price_without_tax?: number;
    kit_sage_reference?: string;
    kit_alias?: string;
  };
  pv?: {
    pose_price_without_tax?: number;
    pose_sage_reference?: string;
    pv_price_without_tax?: number;
    pv_sage_reference?: string;
    pv_alias?: string;
  };
}

export interface IDimensionnement {
  calepinage_solar_potential_image?: string | null;
  installed_power?: number | null;
  number_of_panels?: number | null;
  available_area?: number | null;
  panels_area?: number | null;
  count_of_inverters?: number | null;
  count_of_optimizers?: number | null;
  kit_home_management?: boolean | null;
  panel_decomposition: number[][];
  indexScenarioChosen?: number | null;
}
export interface IOffPeakHour {
  debut: string;
  fin: string;
  provenance: string;
}

export interface IUserDataForBecqe {
  user_id: string;
  email?: string;
  firstname?: string | null;
  lastname?: string | null;
  phonenumber?: string | null;
  user_created_at?: string;
  how_did_you_know_us?: string;
  membership_code?: string | null;
}

export interface IBecqe_Customer {
  user_id: string;
  becqe_customer_created_at: string;
  company_id: string;
}

export type IFullDataCustomer = IUserDataForBecqe &
  IBecqe_Customer &
  IHousehold & {
    study_id?: string;
  };

export interface IHourlyConsumptionProductionData {
  [key: string]: {
    consumption: number;
    consumption_shifted: number;
    production: number;
  };
}

export interface IDailySelfConsumptionData {
  [key: string]: { network_consumption: number; solar_consumption: number };
}

export interface IDailySelfConsumptionDataFormated {
  date: string;
  network_consumption: number;
  solar_consumption: number;
}

export interface IHourlyConsumptionProductionSingleDataFormated {
  hour: number;
  consumption: number;
  consumption_shifted: number;
  production: number;
  autoconso: number;
}

export interface IPerformance {
  annual_electric_consumption_kWh: number;
  annual_electric_production_kWh: number;
  annual_electric_production_without_shadow_casting_kWh: number | null;
  daily_selfconsumption: IDailySelfConsumptionData;
  days_of_autosufficiency: number;
  hourly_consumption_production: IHourlyConsumptionProductionData;
  network_consumption: number;
  selfconsumption_rate: number;
  selfproduction_rate: number;
  solar_consumption: number;
  solar_production_consumed_rate: number;
  solar_production_stored_rate: number;
  indexScenarioChosen?: number | null;
}

export interface IProfitability {
  internal_rate_return: number;
  project_depreciation_period: number;
  economies: IYearlySavings;
  economies_on_bills: number;
  remaining_amount_to_pay: number;
  total_net_gain: number;
  total_net_cost: number | null;
  total_help: number | null;
  extra_electricity_sales: {
    total_sale_euros: number;
    yearly_sales: IYearlyExtraSales[];
  };
  tva_refund: number;
  indexScenarioChosen?: number | null;
}

export interface IFinancingInformation {
  type_of_payment: string | null;
  loan_duration: number | null;
  deposit: number | null;
  interest_rate: string | null;
  is_deferral_option: boolean;
  hasBorrowerInsurance: boolean;
  hasCoBorrower: boolean;
  helps_options: HelpsOptions;
}

export interface LoanSimulationResult {
  monthlyPayment: number;
  TAEG: number;
  tauxDebiteurFixe: number;
  loanAmount: number;
  deferralDurationMonth: number;
}

export interface SofincoLoanData {
  monthlyAmountWithInsuranceAndServices: number | null;
  TAEG: number | null;
  annualPercentageRate: number | null;
  loanAmount: number;
  deferralDurationMonth: number;
}

export interface LoanSimulationApiResponse {
  sofincoLoanData: SofincoLoanData;
  message: string;
}

export interface IYearlySavings {
  [key: number]: {
    economy: number;
    electricity_network_price: number;
    electricity_solar_price: number;
  };
}

export interface IYearlyExtraSales {
  energy: number;
  euros: number;
  kWh_resale_price: number;
}

export interface IImpactEnvironnemental {
  CO2_equivalents: {
    heating_consumption: {
      label: string;
      value: number;
    };
    km_car: {
      label: string;
      value: number;
    };
    CO2_savings_25years: number;
  };
  green_value: number;
  indexScenarioChosen?: number | null;
}

export interface IGoTo {
  newCurrentStepIndex: number;
  newCurrentSubStepIndex?: number;
  isGoforwardAllowed?: boolean;
}

export interface IStudy {
  study_id: string | null;
  commercial_id?: string | null;
  customer_id?: string | null;
  household_id?: string | null;
  created_at?: string | null;
  status?: number | null;
  comments?: string[] | null;
  modified_at?: string;
  study_type: IOreeStudyType | null;
}

export interface IUser {
  user_id: string;
  email: string;
  hash: string | null;
  salt: string | null;
  token: string | null;
  password_reset_token: string | null;
  password_reset_token_expiration: string | null;
  user_created_at: string;
  authorization_rights: number[] | null;
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
}

export interface ISageProduct {
  AR_Ref?: string | null;
  AR_Design?: string | null;
  NO_RefDet?: string | null;
  FA_CodeFamille?: string | null;
  Marque?: string | null;
  Gamme?: string | null;
  Puissance?: string | null;
  Dimensions?: string | null;
  Poid?: string | null;
  NO_Qte?: number | null;
  Alimentation?: string | null;
  "Garantie Fabricant"?: string | null;
  "Norme et Certification"?: string | null;
  "Classe energétique (Froid/Chaud"?: string | null;
  "COP/SCOP"?: string | null;
  "Nom du produit"?: string | null;
  "Puissance de la PAC"?: string | null;
  "Capacité (l)"?: string | null;
  Technologie?: string | null;
  Volume?: string | null;
  "Ref fluide frigorigène"?: string | null;
  "Profil de soutirage"?: string | null;
  "Référence fabricant"?: string | null;
  "Garantie main d'oeuvre"?: string | null;
  Etas?: string | null;
  Conditionnement?: string | null;
  Description?: string | null;
  Marque_pannel?: string | null;
  inverter_technology?: string | null;
  number_of_panels?: number | null;
  power?: number | null;
  "temps de chauffe"?: string | null;
  "Capacité de la batterie"?: string | null;
  "Lien fiche produit"?: string | null;
  "Image produit"?: string | null;
  "Résistance Thermique "?: string | null;
  "Type de produit"?: string | null;
  "type de vitrage "?: string | null;
  "Facteur solaire"?: string | null;
  "Coef de transmission surfacique"?: string | null;
}

interface IBatteryInformation {
  n_batteries: number;
  single_battery_capacity_kWh: number;
  battery_sage_reference?: string;
}

interface IPanelInformation {
  n_panels: number;
  single_panel_power_Wc: number;
}

export enum InverterBrand {
  ENPHASE = "enphase",
  HUAWEI = "huawei",
  SOLAREDGE = "solaredge",
}
export type IAvailablePower =
  | "3000"
  | "3500"
  | "4000"
  | "4500"
  | "5000"
  | "5500"
  | "6000"
  | "6500"
  | "7000"
  | "7500"
  | "8000"
  | "8500"
  | "9000"
  | "9500"
  | "10000"
  | "10500"
  | "11000"
  | "11500"
  | "12000"
  | "12500"
  | "13000"
  | "13500"
  | "14000"
  | "14500"
  | "15000"
  | "15500"
  | "16000"
  | "16500"
  | "17000"
  | "17500"
  | "18000"
  | "18500"
  | "19000";

export type KitPvOption = {
  alias: string;
  name: string;
  price_without_tax: number;
  sage_reference: string;
  tva: number;
  sub_type: string;
};

interface IAvailableBrandInfo {
  battery_information: IBatteryInformation[];
  kit_home_management: boolean[];
  panel_information: IPanelInformation[];
  options: KitPvOption[];
}

export type IAvailableCalepinage = {
  [key in InverterBrand]: IAvailableBrandInfo;
};

export type IAvailableCalepinages = {
  available_calepinages: { [key in IAvailablePower]: IAvailableCalepinage };
  ortho_image: string;
  ortho_panel_coordinates: {
    [key: string]: [number, number][];
  };
  solar_potential_image: string;

  solar_panel_coordinates: {
    [key: string]: [number, number][];
  };
  solar_production: {
    panel_index: number;
    total_solar_production_kWh: number;
  }[];
  calepinage_ko: boolean;
};

export interface IDocumentFromDB {
  document_id: string;
  study_id: string;
  document_blob_name: string;
  file_type: string;
  created_at: string;
  project_id: string;
  comments: string[];
  bdc_id: string;
  bdc_file_name: string;
  bdc_url: string;
}

export interface IDocumentDataUrl {
  dataUrl: string | null;
}

export interface FinancingData {
  isLoanOptionChosen: boolean;
  fundingPeriod: number;
  amount: number;
  personalContributionAmount: number;
  interestRate: string;
  isDeferralOption: boolean;
  hasCoBorrower: boolean;
  hasBorrowerInsurance: boolean;
  loanSimulationResult: LoanSimulationResult;
}

export interface FinancementInfos {
  typedeFinancement: number;
  organismedeFinancementId: string | null;
  amountLease: string | null;
  coEmprunteur: boolean | null;
  nombreEcheance: number | null;
  report: number | null;
  montantdesEcheances: string | null;
  acompte: string | null;
  tauxDebiteurFixe: string | null;
  taeg: string | null;
  assuranceEmprunteur: boolean | null;
}

export interface CodePromo {
  codePromotion: {
    label: string | null;
    value: number | null;
  };
  codeParrainage: string | null;
}

export interface ICommandeData {
  prospect: Prospect;
  etude_logement: EtudeLogement;
  potentiel_solaire: PotentielSolaire;
  vos_conso: VosConso;
  liste_produit: ProductList;
  code_promo?: CodePromo;
  idDevisBecqe: string;
  onlyquote: boolean;
  typeEtude: number;
  financementInfos: FinancementInfos;
  orderToCancelId: string | null;
}

export interface INoReferenceProduct {
  productName: string;
  tvaCode: number;
  quantity: number;
  montant_ht: string;
  discount?: number;
  description?: string;
}

export interface ProductList {
  centralPhotovoltaiques?: (ProductCommonBases & CentralPhotovoltaiques)[];
  chauffesEau?: (ProductCommonBases & Chauffe)[];
  pompesChaleurAirEau?: (ProductCommonBases & Chauffe)[];
  pompesChaleurAirAir?: (ProductCommonBases & IPacAirAir)[];
  isolations?: (ProductCommonBases & Isolation)[];
  relampingLEDs?: ProductCommonBases[];
  fenetres?: ProductCommonBases[];
  noReferencedProducts?: INoReferenceProduct[];
}

export interface ProductCommonBases {
  id_Sage: string | null | undefined;
  price: number | null | undefined;
  producttypecode?: number;
  tvaCode: number | undefined;
  quantity: number;
  subsituedProducts: SubsituedProduct[] | null;
  writableProperties: WritableProperty[] | null;
  poseProduct: PoseProduct;
  aides: Aides[] | null;
  description?: string | null;
}

export interface CrmProductWindow {
  discount: number | null;
}

export interface Chauffe {
  cop_scop: string | null;
  capacity: number | null;
  capacityOptValue: number | null;
  puissance: number | null;
  discount: number | null;
  tempsDeChauffeParHeure: string | null;
  ecsInteg: boolean | null;
}

export interface IPacAirAir {
  cop_scop: string | null;
  puissance: number | null;
  discount: number | null;
}

export interface Isolation {
  discount: number | null;
  epaisseur: number | null;
  conductivity: number | null;
  isolatedZones: IsolatedZone[];
  typeOperation: number | null;
}

export interface IsolatedZone {
  zoneName: string | null;
  surface: number | null;
  typedeParoi: number | null;
  typeOperation: (number | undefined)[];
}

export interface PoseProduct {
  id_Sage: string | null;
  tvaCode: number | undefined;
  quantity: number | null;
  price: number | null;
}

export interface CentralPhotovoltaiques {
  puissanceCentrale?: number | null;
}

interface SubsituedProduct {
  id_Sage?: string | null | undefined;
  tvaCode?: number;
  quantity?: number | null;
  price?: number | null;
  producttypecode?: number;
  poseProduct?: {
    id_Sage?: string | null | undefined;
    tvaCode?: number;
    quantity?: number | null;
    price?: number | null | undefined;
  };
  writableProperties?: WritableProperty[];
}

interface WritableProperty {
  value?: string | number | null;
  name?: string;
  valueString?: string | number | null;
}

interface Prospect {
  // Civility: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  mobilePhone: string | null | undefined;
  // Telephone: string | null | undefined;
  eMailAddress: string | null | undefined;
  street: string | null | undefined;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  lat: number | string | null | undefined;
  lng: number | string | null | undefined;
  commercialId: string | null | undefined;
}

interface EtudeLogement {
  type_occupation: number;
  number_of_inhabitants: string | number | null;
  surface_habitable: number | string | null;
  cover_type: number | null;
  inclinaison_du_pan: number | null;
  electrical_installation_type: number | null;
  year_of_construction: string | null;
  number_of_levels: number | null;
  number_of_rooms: number | null;
  ceiling_height: string | null;
  construction_square: number | null;
  wall_type: number | null;
  isolated_walls: number | null;
  attic_type: number | null;
  ventilation_type: number | null;
  basement_type: number | null;
  garageattenantalamaison: number | null;
  configurationgarage: number | null;
}

interface PotentielSolaire {
  potentiel_ensoleillement: number | null;
  exposure: number | null;
  solar_potential_area: number | null;
  impact_masque_solaire: number | null;
}

interface VosConso {
  chauffage: Chauffage;
  ecs: Ecs;
  homeysPathCompleted?: boolean | null | undefined;
}

interface Chauffage {
  sourceEnergieElec: boolean | undefined;
  sourceEnergie?: number;
  estimationFactureChauffage?: string;
  prixElectricite?: string;
  surfaceChauffee?: number;
  temperatureDeConfort?: string;
  typeChauffage?: number;
}

export interface Ecs {
  boilerEnergyTypeElec: boolean;
  boilerEnergyType?: number;
  boilerType?: number | null;
  anneeInstallation?: string;
  capaciteStockage?: number;
}

interface Aides {
  amount: number | null | undefined;
  aideId: string | null | undefined;
  isDeducted: boolean;
}
export interface IScenario {
  scenario_id?: string | null;
  study_id?: string | null;
  created_at?: string | null;
  modified_at?: string | null;
  modified_by?: string | null;
  power_needed?: number | null;
  kit_home_management?: boolean | null;
  number_of_batteries?: number | null;
  number_of_panels?: number | null;
  recommended_calepinage?: boolean | null;
  sage_reference_PV?: string | null;
  selfconsumption_rate?: number | null;
  selfproduction_rate?: number | null;
  total_cost_help_deducted?: number | null;
  total_cost_without_help?: number | null;
  total_helps?: number | null;
  pitch?: string | null;
  internal_rate_return?: number | null;
  formule_name?: string | null;
  "25_years_economies"?: number | null;
  calepinage_image?: string | null;
  raccordement_type?: string | null;
  is_storage_battery?: boolean | null;
  tva?: number | null;
  battery_price?: number | null;
  battery_pose_price_without_tax?: number | null;
  battery_pose_sage_reference?: string | null;
  battery_price_without_tax?: number | null;
  battery_sage_reference?: string | null;
  battery_alias?: string | null;
  kit_pose_price_without_tax?: number | null;
  kit_pose_sage_reference?: string | null;
  kit_price_without_tax?: number | null;
  kit_sage_reference?: string | null;
  kit_alias?: string | null;
  pv_pose_price_without_tax?: number | null;
  pv_pose_sage_reference?: string | null;
  pv_price_without_tax?: number | null;
  pv_sage_reference?: string | null;
  pv_alias?: string | null;
  inverter_brand?: string | null;
  single_battery_capacity?: number | null;
  calepinage_chosen_ortho_coordinates?: {
    [key: string]: number[][];
  } | null;
  calepinage_chosen_solar_coordinates?: {
    [key: string]: number[][];
  } | null;
  calepinage_chosen_indexes?: number[];
  solar_potential_image?: string | null;
  extra_fees?: TExtraFee[] | null;
  is_personalized_scenario?: boolean;
  options: KitPvOption[] | null;
  tva_refund: number | null; // Total amount the client can be refund if he chooses the tva refund option. The price of the option is not substracted from the amount.
}
export interface IDocument {
  document_id?: string | null;
  study_id?: string;
  document_blob_name?: string;
  file_type?: string;
  formule_selected?: string;
  battery_pose_price_without_tax?: number | null;
  battery_pose_sage_reference?: string | null;
  battery_price_without_tax?: number | null;
  battery_sage_reference?: string | null;
  kit_pose_price_without_tax?: number | null;
  kit_pose_sage_reference?: string | null;
  kit_price_without_tax?: number | null;
  kit_sage_reference?: string | null;
  pv_pose_price_without_tax?: number | null;
  pv_pose_sage_reference?: string | null;
  pv_price_without_tax?: number | null;
  pv_sage_reference?: string | null;
  number_of_batteries?: number | null;
  kit_home_management?: boolean | null;
  tva?: number | null;
  total_helps?: number | null;
  power_needed?: number | null;
  total_cost_without_help?: number | null;
  total_cost_help_deducted?: number | null;
  selfconsumption_rate?: number | null;
  project_id?: string;
  comments?: string[];
  bdc_id?: string | null;
}

export interface IChantier {
  chantierID: string;
  latitude: string;
  longitude: string;
  listProduit: string;
  kwhProduits: string | null;
}

export interface ISageDepot {
  DE_No: number;
  DE_Intitule: string;
  cbDE_Intitule: Buffer;
  DE_Adresse: string;
  DE_Complement: string;
  DE_CodePostal: string;
  DE_Ville: string;
  DE_Contact: string;
  DE_Principal: number;
  DE_CatCompta: number;
  DE_Region: string;
  DE_Pays: string;
  DE_EMail: string;
  DE_Code: null;
  cbDE_Code: Buffer;
  DE_Telephone: string;
  DE_Telecopie: string;
  DE_Replication: number;
  DP_NoDefaut: number;
  cbDP_NoDefaut: number;
  DE_Exclure: number;
  DE_Souche01: number;
  DE_Souche02: number;
  DE_Souche03: number;
  cbProt: number;
  cbMarq: number;
  cbCreateur: string;
  cbModification: Date;
  cbReplication: number;
  cbFlag: number;
  cbCreation: Date;
  cbCreationUser: string;
  latitude?: number;
  longitude?: number;
}

export interface HelpsOptions {
  isHelpsDisplayed: boolean;
  isCEEHelpsDeducted: boolean;
  isMPRHelpsDeducted: boolean;
}

export type CompanySettings = {
  uiSettings: CompanyUiSettings[] | [];
};

type CompanyUiSettings = {
  feature: UiFeatures;
  is_enabled: boolean;
};

// Values must match the enum values in the table ui_settings in the database
export enum UiFeatures {
  HOW_DID_YOU_KNOW_US = "how_did_you_know_us",
}
