import { DSColors } from "@web/shared/src/styles/variables";
import { useEffect, useState } from "react";
import "./MesEtudesDetailsEtude.scss";

// services import
import { useNavigate, useParams } from "react-router-dom";
import {
  IOreeStudyType,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";
import { useAppSelector } from "../../redux/store/hook";
import {
  IRequestDocumentInfo,
  useGetAllDocumentsFromAzureBlobStorage,
} from "../../services/AzureBlobStorage/useGetAllDocumentsFromAzureBlobStorage";
import { useGetSingleFileFromABS } from "../../services/AzureBlobStorage/useGetSingleFileFromABS";
import { useUpdateDocument } from "../../services/Devis/useUpdateDocument";
import { useGetStudy } from "../../services/Studies/useGetStudy";

// component import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// image import
import alertIcon from "@web/shared/dist/assets/images/DesignSystemIcons/Icon/Outline/alert-circle.svg";
import iconSucess from "@web/shared/dist/assets/images/DesignSystemIcons/Icon/Outline/checkmark-circle.svg";
import letterIcon from "@web/shared/dist/assets/images/DesignSystemIcons/Icon/Outline/email.svg";
import houseIcon from "@web/shared/dist/assets/images/DesignSystemIcons/Icon/Outline/home.svg";
import phoneIcon from "@web/shared/dist/assets/images/DesignSystemIcons/Icon/Outline/phone.svg";
import chevron from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-right.svg";
import goBackSimple from "../../assets/images/Navbar/goBackSimple.svg";
import commentaireLogo from "../../assets/images/commentaireLogo.svg";
import downloadPicto from "../../assets/images/download-picto.svg";
import eye from "../../assets/images/eye.svg";

export const MesEtudesDetailsEtude = () => {
  // Services
  const navigate = useNavigate();
  const { userAuthInfo } = useAppSelector((state) => state);

  // States
  const { study_id } = useParams();
  const [etudeData, setEtudeData] = useState<IStudyInformation | null>(null);
  const [devisList, setDevisList] = useState<IRequestDocumentInfo[]>([]);
  const [etudesList, setEtudesList] = useState<IRequestDocumentInfo[]>([]);

  const [isModalRepriseEtudeOpen, setIsModalRepriseEtudeOpen] =
    useState<boolean>(false);
  const [newCommentValue, setNewCommentValue] = useState<string>("");
  const [selectedStudy, setSelectedStudy] =
    useState<IRequestDocumentInfo | null>();
  const [selectedDevis, setSelectedDevis] = useState<IRequestDocumentInfo>();

  const [toggleCommentInput, setToggleCommentInput] = useState<boolean>(false);

  // Custom hooks
  const {
    getStudy,
    errorMessage: getStudyError,
    isLoading: getStudyIsLoading,
  } = useGetStudy();
  const {
    getAllDocumentsFromAzureBlobStorage,
    isLoading: areDocumentsLoading,
  } = useGetAllDocumentsFromAzureBlobStorage();
  const { getSingleFileFromABS } = useGetSingleFileFromABS();
  const { updateDocument, errorMessage } = useUpdateDocument();

  // functions
  const getStudyData = async () => {
    const response = await getStudy({
      study_id: study_id,
      userToken: userAuthInfo.userToken,
    });

    if (response) {
      setEtudeData(response);
    }
  };

  const getDevisData = async () => {
    const devisPromise = getAllDocumentsFromAzureBlobStorage({
      study_id: study_id || "",
      userToken: userAuthInfo.userToken || "",
      fileType: "devis",
    });

    const etudesPromise = getAllDocumentsFromAzureBlobStorage({
      study_id: study_id || "",
      userToken: userAuthInfo.userToken || "",
      fileType: "etude",
    });

    const [devisResult, etudesResult] = await Promise.allSettled([
      devisPromise,
      etudesPromise,
    ]);

    if (
      devisResult.status === "fulfilled" &&
      etudesResult.status === "fulfilled"
    ) {
      devisResult.value && setDevisList(devisResult.value);
      etudesResult.value && setEtudesList(etudesResult.value);

      if (devisResult.value && devisResult.value.length > 0) {
        setSelectedDevis(devisResult.value[0]);
        setSelectedStudy((state) => {
          return etudesResult.value?.find(
            (etude) => etude.project_id === devisResult.value?.[0].project_id
          );
        });
      }
    }
  };

  useEffect(() => {
    getStudyData();
    getDevisData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddCommentClick = async ({
    selectedDevis,
    newCommentValue,
  }: {
    selectedDevis?: IRequestDocumentInfo;
    newCommentValue: string;
  }) => {
    const newComments = [...(selectedDevis?.comments || []), newCommentValue];
    const response = await updateDocument({
      userToken: userAuthInfo.userToken,
      documentPayload: {
        document_id: selectedDevis?.document_id,
        comments: newComments,
      },
      study_id: study_id || "",
    });

    if (response && response.status === 200) {
      // If response is successfull we update the devis in the devis list with the new comments
      setDevisList((state) => {
        const newState = [...state];
        const index = newState.findIndex(
          (elem) => elem.document_id === selectedDevis?.document_id
        );
        newState[index].comments = newComments;
        return newState;
      });
      setNewCommentValue("");
    }
  };

  const viewDocument = ({
    documentURL,
    isBDC = false,
  }: {
    documentURL: string;
    isBDC?: boolean;
  }) => {
    if (isBDC) {
      const downloadLink = document.createElement("a");
      downloadLink.target = "_blank";
      downloadLink.href = documentURL;
      downloadLink.click();
      return;
    }

    const newWindow = window.open();
    // we open a new window containing the pdf
    // we're using an iframe as Chrome doesn't allow the opening of files using JS and base64
    newWindow?.document.write(
      `<iframe src="${documentURL}" frameborder="0" style="border:0; top:-8px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  const dowloadDocument = ({
    documentURL,
    documentName,
  }: {
    documentURL: string;
    documentName: string;
  }) => {
    const downloadLink = document.createElement("a");
    downloadLink.target = "_blank";
    downloadLink.href = documentURL;
    downloadLink.download = documentName;

    downloadLink.click();
  };

  const handleOnClickDocument = async (
    document_id: string,
    buttonType: "view" | "download",
    documentBlobName: string
  ) => {
    const fileDataUrl = await getSingleFileFromABS(
      userAuthInfo.userToken,
      document_id,
      study_id || ""
    );

    if (!fileDataUrl) {
      return;
    }

    if (buttonType === "view") {
      viewDocument({ documentURL: fileDataUrl });
    }
    if (buttonType === "download") {
      dowloadDocument({
        documentURL: fileDataUrl,
        documentName: documentBlobName.split("-")[0],
      });
    }
  };

  // DevisList is sorted by created_at DESC, so we can get the last bdc_id by getting the first row of the list
  const lastBdcId = devisList.find((devis) => devis.bdc_id)?.bdc_id;

  return (
    <div className="MesEtudesDetailsEtude">
      {getStudyIsLoading ? (
        <div className="MesEtudesDetailsEtude__LoadingContainer">
          <LoadingComponent diameter={60} />
        </div>
      ) : getStudyError ? (
        <>
          <section
            className="MesEtudesDetailsEtude__ReturnButton"
            onClick={() => navigate("/mes-etudes")}
          >
            <img src={goBackSimple} alt="return button" />
          </section>
          <ErrorMessage errorMessage={getStudyError} />
        </>
      ) : (
        etudeData && (
          <div className="MesEtudesDetailsEtude__Container">
            <div className="MesEtudesDetailsEtude__Container__Header">
              <div className="MesEtudesDetailsEtude__Container__Header__Left">
                <img
                  onClick={() => navigate("/mes-etudes")}
                  src={goBackSimple}
                  alt="return button"
                />
                <h1>
                  M. Mme{" "}
                  {etudeData.lastname &&
                    etudeData.lastname.charAt(0).toUpperCase() +
                      etudeData.lastname.slice(1)}{" "}
                  {etudeData.firstname &&
                    etudeData.firstname.charAt(0).toUpperCase() +
                      etudeData.firstname.slice(1)}
                </h1>
              </div>
              <Modale
                title="Reprendre le projet"
                text="Les données de votre projet vont être actualisés. 
                    Cette mise à jour peut impliquer des modifications par rapport à vos résultats précédents."
                textAlignMiddle={true}
                isModaleOpen={isModalRepriseEtudeOpen}
              >
                <div className="MesEtudesDetailsEtude__ModaleButtons">
                  <CTAButton
                    name="Annuler"
                    category={"secondary"}
                    onClick={() => setIsModalRepriseEtudeOpen(false)}
                  />
                  <CTAButton
                    name="Reprendre"
                    onClick={() => {
                      navigate(
                        `/etude/${
                          etudeData.study_type || IOreeStudyType.PHOTOVOLTAIQUE
                        }/${etudeData.study_id}`
                      );
                    }}
                  />
                </div>
              </Modale>
            </div>
            <div className="MesEtudesDetailsEtude__Container__SubHeader">
              <div className="contact_information">
                <div className="information phone">
                  <img src={houseIcon} alt="" />
                  <p>
                    {etudeData.address} {etudeData.zipcode}
                  </p>
                </div>
                <div className="information">
                  <img src={letterIcon} alt="" />
                  <p>{etudeData.email}</p>
                </div>
                <div className="information">
                  <img src={phoneIcon} alt="" />
                  <p>{etudeData.phonenumber}</p>
                </div>
              </div>
              {devisList.findIndex(
                (devis) => devis.document_id === selectedDevis?.document_id
              ) === 0 || devisList.length === 0 ? (
                // Button display only for the first selected row
                <div className="MesEtudesDetailsEtude__ButtonContainer">
                  <CTAButton
                    name="Reprendre l'étude"
                    onClick={() => setIsModalRepriseEtudeOpen(true)}
                  />
                </div>
              ) : null}
            </div>
            <div className="MesEtudesDetailsEtude__Container__Content">
              <div className="MesEtudesDetailsEtude__CustomerFilesAndComments">
                {areDocumentsLoading ? (
                  <section className="MesEtudesDetailsEtude__Loader">
                    <LoadingComponent diameter={60} />
                  </section>
                ) : (
                  <section className="MesEtudesDetailsEtude__CustomerFilesAndComments__DocumentSection">
                    {devisList.length === 0 && etudesList.length === 0 && (
                      <p className="centered">
                        Vous n’avez pas d’étude terminée pour le moment.
                      </p>
                    )}
                    <table className="MesEtudesDetailsEtude__TableStudies">
                      <thead>
                        <tr>
                          <th>
                            <p>Date de l'étude</p>
                          </th>
                          <th>
                            <p>Type d'étude</p>
                          </th>
                          <th>
                            <p>Devis</p>
                          </th>
                          <th>
                            <p>BDC</p>
                          </th>
                          <th>
                            <p>Montant</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {devisList.map((devis, index) => {
                          return (
                            <tr
                              key={devis.document_id + index}
                              onClick={() => {
                                setSelectedDevis(devis);
                                setSelectedStudy(() => {
                                  return etudesList.filter(
                                    (etude) =>
                                      etude.project_id === devis.project_id
                                  )[0];
                                });
                              }}
                              style={{
                                backgroundColor:
                                  devis.document_id ===
                                  selectedDevis?.document_id
                                    ? "#dbe9f8"
                                    : "white",
                              }}
                            >
                              <td>
                                <p>
                                  {new Intl.DateTimeFormat("fr-FR", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }).format(new Date(devis.created_at))}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {devis.study_type ? devis.study_type : "-"}
                                </p>
                              </td>
                              <td>
                                {devis.document_blob_name ? (
                                  <img
                                    src={iconSucess}
                                    alt=""
                                    style={{
                                      filter:
                                        "brightness(0) saturate(100%) invert(49%) sepia(48%) saturate(4683%) hue-rotate(125deg) brightness(90%) contrast(96%)",
                                    }}
                                  />
                                ) : (
                                  <p>"-"</p>
                                )}
                              </td>
                              <td>
                                {devis.bdc_id ? (
                                  <img
                                    src={
                                      devis.bdc_id === lastBdcId
                                        ? iconSucess
                                        : alertIcon
                                    } // If first row have a bdc_id we display the success icon for the first row only
                                    alt=""
                                    style={
                                      devis.bdc_id === lastBdcId
                                        ? {
                                            filter:
                                              "brightness(0) saturate(100%) invert(49%) sepia(48%) saturate(4683%) hue-rotate(125deg) brightness(90%) contrast(96%)", // Green
                                          }
                                        : {
                                            filter:
                                              "invert(61%) sepia(50%) saturate(4015%) hue-rotate(6deg) brightness(99%) contrast(101%)", // $ZealOrange: #ed8c00;
                                          }
                                    }
                                  />
                                ) : (
                                  <p>-</p>
                                )}
                              </td>
                              <td>
                                <p>
                                  {etudeData.total_cost_without_help
                                    ? new Intl.NumberFormat("fr-FR", {
                                        style: "currency",
                                        currency: "EUR",
                                      }).format(
                                        etudeData.total_cost_without_help
                                      )
                                    : "-"}
                                </p>
                              </td>
                              <td className="image_container">
                                <img src={chevron} alt="" />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                )}
              </div>
              <aside className="MesEtudesDetailsEtude__StudyInformation">
                {selectedDevis && (
                  <>
                    <div className="date">
                      <p>
                        {new Intl.DateTimeFormat("fr-FR", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(selectedDevis.created_at || 0))}
                      </p>
                    </div>
                    <div className="MesEtudesDetailsEtude__Documents">
                      {selectedDevis || selectedStudy
                        ? [selectedStudy, selectedDevis].map(
                            (document, index) => {
                              if (!document) return null;
                              return (
                                <div
                                  className="MesEtudesDetailsEtude__DocumentsContainer"
                                  key={document.document_id + index}
                                >
                                  <div
                                    className="MesEtudesDetailsEtude__SingleDoc"
                                    key={document.document_id + index}
                                  >
                                    <p>{document.document_blob_name}</p>
                                    <section className="buttonsContainer">
                                      <div
                                        className="button"
                                        onClick={() => {
                                          handleOnClickDocument(
                                            document.document_id,
                                            "view",
                                            document.document_blob_name
                                          );
                                        }}
                                      >
                                        <img
                                          src={eye}
                                          alt="Visualiser le fichier"
                                        />
                                      </div>
                                      <div
                                        className="button"
                                        onClick={() =>
                                          handleOnClickDocument(
                                            document.document_id,
                                            "download",
                                            document.document_blob_name
                                          )
                                        }
                                      >
                                        <img
                                          src={downloadPicto}
                                          alt="Télécharger le fichier"
                                        />
                                      </div>
                                    </section>
                                  </div>
                                  {document.bdc_id &&
                                  document.bdc_url &&
                                  document.bdc_file_name ? (
                                    <div
                                      className="MesEtudesDetailsEtude__SingleDoc"
                                      key={document.bdc_id + index}
                                    >
                                      <p>{document.bdc_file_name}</p>
                                      <section className="buttonsContainer">
                                        <div
                                          className="button"
                                          onClick={() => {
                                            viewDocument({
                                              documentURL:
                                                document.bdc_url || "",
                                              isBDC: true,
                                            });
                                          }}
                                        >
                                          <img
                                            src={eye}
                                            alt="Visualiser le fichier"
                                          />
                                        </div>
                                        <div
                                          className="button"
                                          onClick={() =>
                                            viewDocument({
                                              documentURL:
                                                document.bdc_url || "",
                                              isBDC: true,
                                            })
                                          }
                                        >
                                          <img
                                            src={downloadPicto}
                                            alt="Télécharger le fichier"
                                          />
                                        </div>
                                      </section>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            }
                          )
                        : "pas de document trouvé"}
                    </div>
                  </>
                )}

                <section className="MesEtudesDetailsEtude__CustomerFilesAndComments__CommentSection">
                  <div className="addComment__Title">
                    <img src={commentaireLogo} alt="commentaires" />
                    <p>Commentaires</p>
                  </div>
                  {(selectedDevis?.comments?.length || 0) > 0 && (
                    <div className="commentsList">
                      <ul>
                        {selectedDevis?.comments?.map((comment, index) => {
                          return (
                            <li key={index}>
                              <p className="commentsList__Title">{comment}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  {!toggleCommentInput && selectedDevis?.document_id && (
                    <BoutonPicto
                      text="Ajouter un commentaire"
                      onClick={() => setToggleCommentInput(true)}
                      color={DSColors.Mint}
                    />
                  )}
                  {toggleCommentInput && (
                    <>
                      <div className="addComment">
                        <textarea
                          className="textArea"
                          value={newCommentValue || ""}
                          onChange={(e) =>
                            setNewCommentValue(e.currentTarget.value)
                          }
                          placeholder="Commentaire sur le rendez-vous..."
                        />
                      </div>
                      <div className="buttonContainer">
                        <CTAButton
                          isDisabled={newCommentValue.length > 0 ? false : true}
                          family={"becqe"}
                          category="secondary"
                          name="Enregistrer mon commentaire"
                          onClick={() =>
                            onAddCommentClick({
                              selectedDevis,
                              newCommentValue,
                            })
                          }
                        />
                        {errorMessage && (
                          <ErrorMessage errorMessage={errorMessage} />
                        )}
                      </div>
                    </>
                  )}
                </section>
              </aside>
            </div>
          </div>
        )
      )}
    </div>
  );
};
