import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Interface import
import { IScenario } from "../../interfaces/generalInterfaces";

//Service import
import { setScenarioChosenAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

// Local interface declaration
interface IGetScenarioProps {
  userToken?: string | null;
  study_id?: string | null;
}

export const useGetScenario = () => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const getScenario = async ({ userToken, study_id }: IGetScenarioProps) => {
    const response = await axiosGetRequest<IScenario>(
      `${BACKEND_URL}/oree2/pv/studies/${study_id}/selectedScenario`,

      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(
        setScenarioChosenAction({
          scenarioChosen: response,
        })
      );

      return response;
    } else {
      setErrorMessage("Erreur lors de la récupération du scénario");
    }
  };
  return {
    getScenario,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
