import "./PacAirAirQuantiteContainer.scss";

// Components import
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";

interface Props {
  title: string;
  value?: number;
  isMonosplit?: boolean;
  onPlusMinusClick?: (value: number) => void;
}

export const PacAirAirQuantiteContainer = ({
  title,
  value,
  isMonosplit,
  onPlusMinusClick,
}: Props) => {
  return (
    <div className="PacAirAirQuantiteContainer">
      <section
        className={`PacAirAirQuantiteContainer__QuestionAnswerContainer`}
      >
        <p className={"PacAirAirQuantiteContainer__QuestionTitle"}>{title}</p>

        <Quantite
          value={value ?? 0}
          maxValue={isMonosplit ? 1 : Infinity}
          onPlusMinusClick={(value) => {
            onPlusMinusClick && onPlusMinusClick(value);
          }}
        />
      </section>
    </div>
  );
};
