import {
  FinancingData,
  ICommandeData,
} from "../../../interfaces/generalInterfaces";

export function formatFinancementInfos(
  financingData: FinancingData | undefined,
  restToPay: number
): ICommandeData["financementInfos"] {
  if (!financingData || !financingData.isLoanOptionChosen) {
    return {
      typedeFinancement: 629950000, // 629950000(=comptant)
      organismedeFinancementId: null,
      amountLease: restToPay.toString().replace(".", ","),
      coEmprunteur: null,
      nombreEcheance: null,
      report: null,
      montantdesEcheances: null,
      acompte: null,
      tauxDebiteurFixe: null,
      taeg: null,
      assuranceEmprunteur: null,
    };
  }

  return {
    typedeFinancement: 629950001, // 629950001(=partenaire financier)
    organismedeFinancementId: "27238673-27c5-ea11-a812-000d3abb516e", //= id de prod. Id de dev = "4b6e3471-9ddc-ea11-a813-000d3aafe55d"
    amountLease: (
      financingData.amount - financingData.personalContributionAmount
    )
      .toString()
      .replace(".", ","),
    coEmprunteur: financingData.hasCoBorrower,
    nombreEcheance: financingData.fundingPeriod * 12,
    report: financingData.loanSimulationResult.deferralDurationMonth,
    montantdesEcheances: financingData.loanSimulationResult.monthlyPayment
      .toString()
      .replace(".", ","),
    acompte: financingData.personalContributionAmount
      .toString()
      .replace(".", ","),
    tauxDebiteurFixe: financingData.loanSimulationResult.tauxDebiteurFixe
      .toString()
      .replace(".", ","),
    taeg: financingData.loanSimulationResult.TAEG.toString().replace(".", ","),
    assuranceEmprunteur: financingData.hasBorrowerInsurance,
  };
}
