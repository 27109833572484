// SVG import
import imageAutreProduit from "@web/shared/dist/assets/images/DesignSystemIllustrations/Autre produit.svg";
import imageCET from "@web/shared/dist/assets/images/DesignSystemIllustrations/CET-generique.svg";
import imageISOComble from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation-combles-perdus.svg";
import imageITE from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation-murs-exterieurs.svg";
import imageISOPlancher from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation-plancher-bas.svg";
import imageISORampants from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation_combles-amenages.svg";
import imageITI from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation_thermique_interieur.svg";
import imagePacAE from "@web/shared/dist/assets/images/DesignSystemIllustrations/PAC Air eau generique.svg";
import imagePV from "@web/shared/dist/assets/images/DesignSystemIllustrations/PV-generique.svg";
import imageVMC from "@web/shared/dist/assets/images/DesignSystemIllustrations/VMC.svg";

// PNG import (used in the pdfs that don't support svg)
import imageAutreProduitPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Autre produit.png";
import imageCETPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/CET-generique.png";
import ChaudiereAGranluePng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Chaudiere granules generique.png";
import imageISOComblePng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation-combles-perdus.png";
import imageITEPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation-murs-exterieurs.png";
import imageISOPlancherPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation-plancher-bas.png";
import imageISORampantsPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation_combles-amenages.png";
import imageITIPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/Isolation_thermique_interieur.png";
import LEDPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/LED-generique.png";
import PacAirAirPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/PAC Air Air generique.png";
import imagePacAEPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/PAC Air eau generique.png";
import imagePVPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/PV-generique.png";
import imageVMCPng from "@web/shared/dist/assets/images/DesignSystemIllustrations/VMC.png";
import WindowPng from "@web/shared/src/assets/images/DesignSystemIllustrations/window.png";

export const productsImagesDictionnary = ({
  product_type,
  image_type = "svg",
}: {
  product_type: string;
  image_type?: "svg" | "png";
}) => {
  if (image_type === "png") {
    switch (product_type) {
      case "PV":
        return imagePVPng;
      case "PACAIRO":
        return imagePacAEPng;
      case "CET":
        return imageCETPng;
      case "SYSTVENTIL":
        return imageVMCPng;
      case "ITE":
        return imageITEPng;
      case "ITI":
        return imageITIPng;
      case "ISO":
        return imageISOComblePng;
      case "ISOA":
        return imageISORampantsPng;
      case "ISOP":
        return imageISOPlancherPng;
      case "LED":
        return LEDPng;
      case "CHAUDIEREAGRANULES":
        return ChaudiereAGranluePng;
      case "PACAIRAIR":
        return PacAirAirPng;
      case "FENETRES":
        return WindowPng;
      case "AUTRE":
      default:
        return imageAutreProduitPng;
    }
  }
  switch (product_type) {
    case "PV":
      return imagePV;
    case "PACAIRO":
      return imagePacAE;
    case "PACAIRAIR":
      return PacAirAirPng;
    case "CET":
      return imageCET;
    case "VMC":
      return imageVMC;
    case "ITE":
      return imageITE;
    case "ITI":
      return imageITI;
    case "ISO":
      return imageISOComble;
    case "ISOA":
      return imageISORampants;
    case "ISOP":
      return imageISOPlancher;
    case "FENETRES":
      return WindowPng;
    case "AUTRE":
    default:
      return imageAutreProduit;
  }
};
