// Interfaces import
import { PvOptionsSubType } from "../../containers/FormulePersonnalisableForm/FormulePersonnalisableForm";
import { IScenario, PvScenarioData } from "../../interfaces/generalInterfaces";
import { TExtraFee } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IPvPathPricesFromEeroScenarioProps {
  scenarioChosen: IScenario | null;
  coupons?: { label: string; type: string; value: number }[];
}
interface IPvPathPricesFromDataScenarioProps {
  scenarioChosen: PvScenarioData | null;
  coupons?: { label: string; type: string; value: number }[];
  extraFees?: TExtraFee[];
}

/**
 * OPTIMIZATION SUGGESTION
 * Abandonned the use of the function calculatePVpathPricesFromEeroScenario to only use calculatePVpathPricesFromDataScenario
 * to manipulate only one scenario object format (PvScenarioData) instead of two (IScenario and PvScenarioData)
 * Imply to update the scenario object saved in Eero database to match the PvScenarioData interface
 * and to update the scenarioChosen object saved in redux to match the PvScenarioData interface
 */

/**
 * Calculate the prices of the PV path from the scenario chosen by the user
 * @param scenarioChosen: The scenario chosen by the user, formated as an IScenario object (scenario object saved in Eero database)
 */
export const calculatePVpathPricesFromEeroScenario = ({
  scenarioChosen,
  coupons = [],
}: IPvPathPricesFromEeroScenarioProps) => {
  const roudNumber = (number: number) => {
    return Math.round(number * 100) / 100;
  };
  const priceAllCoupons = () => {
    let total = 0;
    for (let i = 0; i < coupons.length; i++) {
      total += coupons[i].value;
    }
    return total;
  };

  // ------------ TVA && AIDE
  const tvaGlobale = scenarioChosen?.tva ? roudNumber(scenarioChosen.tva) : 0;

  const totalHelps = scenarioChosen?.total_helps
    ? roudNumber(scenarioChosen.total_helps)
    : 0;

  // ------------ PRIX PV
  const prixPVHTSansPose = scenarioChosen?.pv_price_without_tax
    ? roudNumber(scenarioChosen.pv_price_without_tax)
    : 0;

  const prixPVHTAvecPose =
    scenarioChosen?.pv_price_without_tax &&
    scenarioChosen.pv_pose_price_without_tax
      ? roudNumber(
          scenarioChosen.pv_price_without_tax +
            scenarioChosen.pv_pose_price_without_tax
        )
      : 0;

  const prixPVTTCAvecPose =
    prixPVHTAvecPose && tvaGlobale
      ? roudNumber(prixPVHTAvecPose * ((100 + tvaGlobale) / 100))
      : 0;

  const prixPVTTCSansPose =
    prixPVHTSansPose && tvaGlobale
      ? roudNumber(prixPVHTSansPose * ((100 + tvaGlobale) / 100))
      : 0;

  const forfaitInstallationHTPV = scenarioChosen?.pv_pose_price_without_tax
    ? roudNumber(scenarioChosen.pv_pose_price_without_tax)
    : 0;

  const forfaitInstallationTTCPV =
    forfaitInstallationHTPV &&
    tvaGlobale &&
    roudNumber(forfaitInstallationHTPV * ((100 + tvaGlobale) / 100));

  const totalTvaPv = prixPVTTCAvecPose - prixPVHTAvecPose;

  // ------------ PRIX KIT HOME MANAGEMENT
  const prixKitHomeManagementHTSansPose = scenarioChosen?.kit_price_without_tax
    ? roudNumber(scenarioChosen.kit_price_without_tax)
    : 0;

  const prixKitHomeManagmentHTAvecPose =
    scenarioChosen?.kit_price_without_tax &&
    scenarioChosen.kit_home_management &&
    scenarioChosen.kit_pose_price_without_tax
      ? roudNumber(
          scenarioChosen.kit_price_without_tax +
            scenarioChosen.kit_pose_price_without_tax
        )
      : 0;

  const prixKitHomeManagementTTCAvecPose =
    prixKitHomeManagmentHTAvecPose &&
    tvaGlobale &&
    scenarioChosen?.kit_home_management
      ? roudNumber(prixKitHomeManagmentHTAvecPose * ((100 + tvaGlobale) / 100))
      : 0;

  const prixKitHomeManagementTTCSansPose =
    prixKitHomeManagementHTSansPose &&
    tvaGlobale &&
    scenarioChosen?.kit_home_management
      ? roudNumber(prixKitHomeManagementHTSansPose * ((100 + tvaGlobale) / 100))
      : 0;

  const forfaitInstallationHTKitHomeManagement =
    scenarioChosen?.kit_pose_price_without_tax &&
    scenarioChosen.kit_home_management
      ? roudNumber(scenarioChosen.kit_pose_price_without_tax)
      : 0;

  const forfaitInstallationTTCKitHomeManagement =
    forfaitInstallationHTKitHomeManagement &&
    tvaGlobale &&
    scenarioChosen?.kit_home_management
      ? roudNumber(
          forfaitInstallationHTKitHomeManagement * ((100 + tvaGlobale) / 100)
        )
      : 0;

  const totalTvaKitHomeManagement =
    prixKitHomeManagementTTCAvecPose - prixKitHomeManagmentHTAvecPose;

  // ------------ PRIX BATTERY

  const batteryPriceWithoutInstallHT =
    scenarioChosen?.is_storage_battery &&
    scenarioChosen.battery_price_without_tax
      ? roudNumber(scenarioChosen.battery_price_without_tax)
      : 0;

  const batteryPriceWithInstallHT =
    scenarioChosen?.is_storage_battery &&
    scenarioChosen.battery_price_without_tax &&
    scenarioChosen.battery_pose_price_without_tax
      ? roudNumber(
          scenarioChosen.battery_price_without_tax +
            scenarioChosen.battery_pose_price_without_tax /
              (scenarioChosen?.number_of_batteries || 1) // price is devide by number of batteries because battery_pose_price_without_tax is calculated for all batteries
        )
      : 0;

  const allBatteriesPriceWhitInstallHT =
    batteryPriceWithInstallHT && scenarioChosen?.is_storage_battery
      ? roudNumber(
          batteryPriceWithInstallHT * (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitInstallTTC =
    batteryPriceWithInstallHT &&
    tvaGlobale &&
    scenarioChosen?.is_storage_battery
      ? roudNumber(
          batteryPriceWithInstallHT *
            ((100 + tvaGlobale) / 100) *
            (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitoutInstallTTC =
    batteryPriceWithoutInstallHT &&
    tvaGlobale &&
    scenarioChosen?.is_storage_battery
      ? roudNumber(
          batteryPriceWithoutInstallHT *
            ((100 + tvaGlobale) / 100) *
            (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitoutInstallHT =
    batteryPriceWithoutInstallHT &&
    tvaGlobale &&
    scenarioChosen?.is_storage_battery
      ? roudNumber(
          batteryPriceWithoutInstallHT *
            (scenarioChosen?.number_of_batteries || 1)
        )
      : 0;

  const batteryInstallPriceHT =
    scenarioChosen?.battery_pose_price_without_tax &&
    scenarioChosen.is_storage_battery
      ? roudNumber(scenarioChosen.battery_pose_price_without_tax)
      : 0;

  const batteryInstallPriceTTC =
    scenarioChosen?.is_storage_battery && batteryInstallPriceHT && tvaGlobale
      ? roudNumber(batteryInstallPriceHT * ((100 + tvaGlobale) / 100))
      : 0;

  const totalTvaBatteries =
    allBatteriesPriceWhitInstallTTC - allBatteriesPriceWhitInstallHT;

  // PRIX OTHER OPTIONS (Tva refund, position, structure, etc.)
  const optionsBilledByTuco = scenarioChosen?.options?.filter(
    (option) => option.sub_type !== PvOptionsSubType.TVA_REFUND
  ); // The tva option is not billed by Tuco. Its amount must not appear on tge quote.
  const totalOptionsPriceHT =
    optionsBilledByTuco?.reduce(
      (acc, option) => acc + option.price_without_tax,
      0
    ) || 0;
  const totalOptionsPriceTTC =
    optionsBilledByTuco?.reduce(
      (acc, option) =>
        acc + (option.price_without_tax * (100 + option.tva)) / 100,
      0
    ) || 0;

  const totalTvaOptions = totalOptionsPriceTTC - totalOptionsPriceHT;
  const tvaRefundableOption = scenarioChosen?.options?.find(
    (option) => option.sub_type === PvOptionsSubType.TVA_REFUND
  );
  const tvaRefundable = tvaRefundableOption
    ? (scenarioChosen?.tva_refund || 0) -
      (tvaRefundableOption.price_without_tax +
        (tvaRefundableOption.price_without_tax * tvaRefundableOption.tva) / 100)
    : 0; // = Amount tva refundable - price of the option TTC

  // ------------ PRIX FRAIS DE GESTION ---------

  const managementFeesTTC = 486;
  const managementFeesHT = 486 / (1 + tvaGlobale / 100);

  // ------------ PRIX FRAIS SUPPLEMENTAIRES ---------
  const totalPriceExtraFeesTTC = scenarioChosen?.extra_fees
    ? scenarioChosen.extra_fees.reduce(
        (acc, fee) => acc + fee.price_with_tax,
        0
      )
    : 0;

  const totalPriceExtraFeesHT = scenarioChosen?.extra_fees
    ? scenarioChosen.extra_fees.reduce(
        (acc, fee) => acc + fee.price_without_tax,
        0
      )
    : 0;

  const totalTVAExtraFees = totalPriceExtraFeesTTC - totalPriceExtraFeesHT;

  // ------------ TOTAL ----------
  const totalProjectCost = scenarioChosen?.total_cost_without_help || 0;
  const totalQuoteHT =
    prixKitHomeManagmentHTAvecPose +
    allBatteriesPriceWhitInstallHT +
    prixPVHTAvecPose +
    totalPriceExtraFeesHT +
    totalOptionsPriceHT;

  const totalCostTva =
    totalTvaPv +
    totalTvaKitHomeManagement +
    totalTvaBatteries +
    totalTVAExtraFees +
    totalTvaOptions;

  // For PV there is only one help (prime autoconsommation) wich is always refundable
  const totalRefundableHelps = totalHelps;
  const totalDeductibleHelps = 0;

  //  ------------ RESTE A CHARGE  ------------
  const totalResteACharge =
    allBatteriesPriceWhitInstallTTC +
    prixPVTTCAvecPose +
    totalPriceExtraFeesTTC +
    prixKitHomeManagementTTCAvecPose +
    totalOptionsPriceTTC -
    priceAllCoupons();

  const totalProjectCostHelpsDeducted = totalResteACharge - totalHelps;
  // If tva refund option is selected, we substract the tva refund amount from the total cost
  const totalProjectCostAllAvantagesDeducted =
    totalProjectCostHelpsDeducted - tvaRefundable;

  const resteAChargePhotovoltaique =
    prixPVHTSansPose +
    forfaitInstallationHTPV +
    (prixPVHTSansPose + forfaitInstallationHTPV) * (tvaGlobale / 100) -
    priceAllCoupons();

  return {
    tvaGlobale,
    totalHelps,
    managementFeesTTC,
    managementFeesHT,
    totalResteACharge,
    resteAChargePhotovoltaique,
    totalProjectCost,
    totalQuoteHT,
    totalRefundableHelps,
    totalDeductibleHelps,
    totalProjectCostHelpsDeducted,
    totalCostTva,
    extraFees: {
      totalPriceTTC: totalPriceExtraFeesTTC,
      totalPriceHT: totalPriceExtraFeesHT,
      totalTVA: totalTVAExtraFees,
    },
    totalProjectCostAllAvantagesDeducted,
    tvaRefundable,
    options: {
      totalHT: totalOptionsPriceHT,
      totalTTC: totalOptionsPriceTTC,
      totalTva: totalTvaOptions,
    },
    kitPv: {
      totalTva: totalTvaPv,
      totalHT: prixPVHTAvecPose,
      totalTTC: prixPVTTCAvecPose,
      totalHTWithoutInstallation: prixPVHTSansPose,
      totalTTCWithoutInstallation: prixPVTTCSansPose,
      installationHT: forfaitInstallationHTPV,
      installationTTC: forfaitInstallationTTCPV,
    },
    kitHomeManagmeent: {
      totalHT: prixKitHomeManagmentHTAvecPose,
      totalTTC: prixKitHomeManagementTTCAvecPose,
      totalTva: totalTvaKitHomeManagement,
      totalHTWithoutInstallation: prixKitHomeManagementHTSansPose,
      totalTTCWithoutInstallation: prixKitHomeManagementTTCSansPose,
      installationHT: forfaitInstallationHTKitHomeManagement,
      installationTTC: forfaitInstallationTTCKitHomeManagement,
    },
    batteries: {
      totalHT: allBatteriesPriceWhitInstallHT,
      totalTTC: allBatteriesPriceWhitInstallTTC,
      totalTva: totalTvaBatteries,
      totalHTWithoutInstallation: allBatteriesPriceWhitoutInstallHT,
      totalTTCWithoutInstallation: allBatteriesPriceWhitoutInstallTTC,
      installationHT: batteryInstallPriceHT,
      installationTTC: batteryInstallPriceTTC,
      unitPriceWithInstallationHT: batteryPriceWithInstallHT,
      unitPriceWithoutInstallationHT: batteryPriceWithoutInstallHT,
    },
  };
};

/**
 * Calculate the prices of the PV path from the scenario chosen by the user
 * @param scenarioChosen: The scenario chosen by the user, formated as an PvScenarioData object (object received by Data backen)
 */
export const calculatePVpathPricesFromDataScenario = ({
  scenarioChosen,
  coupons = [],
  extraFees = [],
}: IPvPathPricesFromDataScenarioProps) => {
  const roudNumber = (number: number) => {
    return Math.round(number * 100) / 100;
  };
  const priceAllCoupons = () => {
    let total = 0;
    for (let i = 0; i < coupons.length; i++) {
      total += coupons[i].value;
    }
    return total;
  };

  // ------------ TVA && AIDE
  const tvaGlobale = scenarioChosen?.tva ? roudNumber(scenarioChosen.tva) : 0;

  const totalHelps = scenarioChosen?.total_helps
    ? roudNumber(scenarioChosen.total_helps)
    : 0;

  // ------------ PRIX PV
  const prixPVHTSansPose = scenarioChosen?.prices?.pv?.pv_price_without_tax
    ? roudNumber(scenarioChosen?.prices?.pv?.pv_price_without_tax)
    : 0;

  const prixPVHTAvecPose =
    scenarioChosen?.prices?.pv?.pv_price_without_tax &&
    scenarioChosen?.prices?.pv?.pose_price_without_tax
      ? roudNumber(
          scenarioChosen?.prices?.pv?.pv_price_without_tax +
            scenarioChosen?.prices?.pv?.pose_price_without_tax
        )
      : 0;

  const prixPVTTCAvecPose =
    prixPVHTAvecPose && tvaGlobale
      ? roudNumber(prixPVHTAvecPose * ((100 + tvaGlobale) / 100))
      : 0;

  const prixPVTTCSansPose =
    prixPVHTSansPose && tvaGlobale
      ? roudNumber(prixPVHTSansPose * ((100 + tvaGlobale) / 100))
      : 0;

  const forfaitInstallationHTPV = scenarioChosen?.prices?.pv
    ?.pose_price_without_tax
    ? roudNumber(scenarioChosen?.prices?.pv?.pose_price_without_tax)
    : 0;

  const forfaitInstallationTTCPV =
    forfaitInstallationHTPV &&
    tvaGlobale &&
    roudNumber(forfaitInstallationHTPV * ((100 + tvaGlobale) / 100));

  const totalTvaPv = prixPVTTCAvecPose - prixPVHTAvecPose;

  // ------------ PRIX KIT HOME MANAGEMENT
  const prixKitHomeManagementHTSansPose = scenarioChosen?.prices
    ?.kit_home_management?.kit_price_without_tax
    ? roudNumber(
        scenarioChosen?.prices?.kit_home_management.kit_price_without_tax
      )
    : 0;

  const prixKitHomeManagmentHTAvecPose =
    scenarioChosen?.prices?.kit_home_management?.kit_price_without_tax &&
    scenarioChosen.products.kit_home_management &&
    scenarioChosen?.prices?.kit_home_management.kit_pose_price_without_tax
      ? roudNumber(
          scenarioChosen?.prices?.kit_home_management.kit_price_without_tax +
            scenarioChosen?.prices?.kit_home_management
              .kit_pose_price_without_tax
        )
      : 0;

  const prixKitHomeManagementTTCAvecPose =
    prixKitHomeManagmentHTAvecPose &&
    tvaGlobale &&
    scenarioChosen?.products.kit_home_management
      ? roudNumber(prixKitHomeManagmentHTAvecPose * ((100 + tvaGlobale) / 100))
      : 0;

  const prixKitHomeManagementTTCSansPose =
    prixKitHomeManagementHTSansPose &&
    tvaGlobale &&
    scenarioChosen?.products.kit_home_management
      ? roudNumber(prixKitHomeManagementHTSansPose * ((100 + tvaGlobale) / 100))
      : 0;

  const forfaitInstallationHTKitHomeManagement =
    scenarioChosen?.prices?.kit_home_management?.kit_pose_price_without_tax &&
    scenarioChosen.products.kit_home_management
      ? roudNumber(
          scenarioChosen?.prices?.kit_home_management.kit_pose_price_without_tax
        )
      : 0;

  const forfaitInstallationTTCKitHomeManagement =
    forfaitInstallationHTKitHomeManagement &&
    tvaGlobale &&
    scenarioChosen?.products.kit_home_management
      ? roudNumber(
          forfaitInstallationHTKitHomeManagement * ((100 + tvaGlobale) / 100)
        )
      : 0;

  const totalTvaKitHomeManagement =
    prixKitHomeManagementTTCAvecPose - prixKitHomeManagmentHTAvecPose;

  // ------------ PRIX BATTERY

  const batteryPriceWithoutInstallHT =
    scenarioChosen?.products.number_of_batteries &&
    scenarioChosen?.prices?.battery?.battery_price_without_tax
      ? roudNumber(scenarioChosen?.prices?.battery.battery_price_without_tax)
      : 0;

  const batteryPriceWithInstallHT =
    scenarioChosen?.products.number_of_batteries &&
    scenarioChosen?.prices?.battery?.battery_price_without_tax &&
    scenarioChosen?.prices?.battery.battery_pose_price_without_tax
      ? roudNumber(
          scenarioChosen?.prices?.battery.battery_price_without_tax +
            scenarioChosen?.prices?.battery.battery_pose_price_without_tax /
              (scenarioChosen?.products.number_of_batteries || 1) // price is devide by number of batteries because battery_pose_price_without_tax is calculated for all batteries
        )
      : 0;

  const allBatteriesPriceWhitInstallHT =
    batteryPriceWithInstallHT && scenarioChosen?.products.number_of_batteries
      ? roudNumber(
          batteryPriceWithInstallHT *
            (scenarioChosen?.products.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitInstallTTC =
    batteryPriceWithInstallHT &&
    tvaGlobale &&
    scenarioChosen?.products.number_of_batteries
      ? roudNumber(
          batteryPriceWithInstallHT *
            ((100 + tvaGlobale) / 100) *
            (scenarioChosen?.products.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitoutInstallTTC =
    batteryPriceWithoutInstallHT &&
    tvaGlobale &&
    scenarioChosen?.products.number_of_batteries
      ? roudNumber(
          batteryPriceWithoutInstallHT *
            ((100 + tvaGlobale) / 100) *
            (scenarioChosen?.products.number_of_batteries || 1)
        )
      : 0;

  const allBatteriesPriceWhitoutInstallHT =
    batteryPriceWithoutInstallHT &&
    tvaGlobale &&
    scenarioChosen?.products.number_of_batteries
      ? roudNumber(
          batteryPriceWithoutInstallHT *
            (scenarioChosen?.products.number_of_batteries || 1)
        )
      : 0;

  const batteryInstallPriceHT =
    scenarioChosen?.prices?.battery?.battery_pose_price_without_tax &&
    scenarioChosen?.products.number_of_batteries
      ? roudNumber(
          scenarioChosen?.prices?.battery.battery_pose_price_without_tax
        )
      : 0;

  const batteryInstallPriceTTC =
    scenarioChosen?.products.number_of_batteries &&
    batteryInstallPriceHT &&
    tvaGlobale
      ? roudNumber(batteryInstallPriceHT * ((100 + tvaGlobale) / 100))
      : 0;

  const totalTvaBatteries =
    allBatteriesPriceWhitInstallTTC - allBatteriesPriceWhitInstallHT;

  // ------------ PRIX FRAIS DE GESTION ---------

  const managementFeesTTC = 486;
  const managementFeesHT = 486 / (1 + tvaGlobale / 100);

  // ------------ PRIX FRAIS SUPPLEMENTAIRES ---------
  const totalPriceExtraFeesTTC = extraFees.reduce(
    (acc, fee) => acc + fee.price_with_tax,
    0
  );

  const totalPriceExtraFeesHT = extraFees.reduce(
    (acc, fee) => acc + fee.price_without_tax,
    0
  );
  const totalTVAExtraFees = totalPriceExtraFeesTTC - totalPriceExtraFeesHT;

  // PRIX OTHER OPTIONS (Tva refund, position, structure, etc.)

  const optionsBilledByTuco = scenarioChosen?.options?.filter(
    (option) => option.sub_type !== PvOptionsSubType.TVA_REFUND
  ); // The tva option is not billed by Tuco. Its amount must not appear on the quote.

  const totalOptionsPriceHT =
    optionsBilledByTuco?.reduce(
      (acc, option) => acc + option.price_without_tax,
      0
    ) || 0;
  const totalOptionsPriceTTC =
    optionsBilledByTuco?.reduce(
      (acc, option) =>
        acc + (option.price_without_tax * (100 + option.tva)) / 100,
      0
    ) || 0;

  const totalTvaOptions = totalOptionsPriceTTC - totalOptionsPriceHT;
  const tvaRefundableOption = scenarioChosen?.options?.find(
    (option) => option.sub_type === PvOptionsSubType.TVA_REFUND
  );
  const tvaRefundable = tvaRefundableOption
    ? (scenarioChosen?.tva_refund || 0) -
      (tvaRefundableOption.price_without_tax +
        (tvaRefundableOption.price_without_tax * tvaRefundableOption.tva) / 100)
    : 0; // = Amount tva refundable - price of the option TTC

  // ------------ TOTAL ----------
  const totalProjectCost = scenarioChosen?.total_cost || 0;
  const totalQuoteHT =
    prixKitHomeManagmentHTAvecPose +
    allBatteriesPriceWhitInstallHT +
    prixPVHTAvecPose +
    totalPriceExtraFeesHT +
    totalOptionsPriceHT;
  const totalCostTva =
    totalTvaPv +
    totalTvaKitHomeManagement +
    totalTvaBatteries +
    totalTVAExtraFees +
    totalTvaOptions;

  // For PV there is only one help (prime autoconsommation) wich is always refundable
  const totalRefundableHelps = totalHelps;
  const totalDeductibleHelps = 0;

  //  ------------ RESTE A CHARGE  ------------
  const totalResteACharge =
    allBatteriesPriceWhitInstallTTC +
    prixPVTTCAvecPose +
    totalPriceExtraFeesTTC +
    prixKitHomeManagementTTCAvecPose +
    totalOptionsPriceTTC -
    priceAllCoupons();

  const totalProjectCostHelpsDeducted = totalResteACharge - totalHelps;
  // If tva refund option is selected, we substract the tva refund amount from the total cost
  const totalProjectCostAllAvantagesDeducted =
    totalProjectCostHelpsDeducted - tvaRefundable;

  const resteAChargePhotovoltaique =
    prixPVHTSansPose +
    forfaitInstallationHTPV +
    (prixPVHTSansPose + forfaitInstallationHTPV) * (tvaGlobale / 100) -
    priceAllCoupons();

  return {
    tvaGlobale,
    totalHelps,
    managementFeesTTC,
    managementFeesHT,
    totalResteACharge,
    resteAChargePhotovoltaique,
    totalProjectCost,
    totalQuoteHT,
    totalRefundableHelps,
    totalDeductibleHelps,
    totalProjectCostHelpsDeducted,
    totalCostTva,
    extraFees: {
      totalPriceTTC: totalPriceExtraFeesTTC,
      totalPriceHT: totalPriceExtraFeesHT,
      totalTVA: totalTVAExtraFees,
    },
    totalProjectCostAllAvantagesDeducted,
    tvaRefundable,
    options: {
      totalHT: totalOptionsPriceHT,
      totalTTC: totalOptionsPriceTTC,
      totalTva: totalTvaOptions,
    },
    kitPv: {
      totalTva: totalTvaPv,
      totalHT: prixPVHTAvecPose,
      totalTTC: prixPVTTCAvecPose,
      totalHTWithoutInstallation: prixPVHTSansPose,
      totalTTCWithoutInstallation: prixPVTTCSansPose,
      installationHT: forfaitInstallationHTPV,
      installationTTC: forfaitInstallationTTCPV,
    },
    kitHomeManagement: {
      totalHT: prixKitHomeManagmentHTAvecPose,
      totalTTC: prixKitHomeManagementTTCAvecPose,
      totalTva: totalTvaKitHomeManagement,
      totalHTWithoutInstallation: prixKitHomeManagementHTSansPose,
      totalTTCWithoutInstallation: prixKitHomeManagementTTCSansPose,
      installationHT: forfaitInstallationHTKitHomeManagement,
      installationTTC: forfaitInstallationTTCKitHomeManagement,
    },
    batteries: {
      totalHT: allBatteriesPriceWhitInstallHT,
      totalTTC: allBatteriesPriceWhitInstallTTC,
      totalTva: totalTvaBatteries,
      totalHTWithoutInstallation: allBatteriesPriceWhitoutInstallHT,
      totalTTCWithoutInstallation: allBatteriesPriceWhitoutInstallTTC,
      installationHT: batteryInstallPriceHT,
      installationTTC: batteryInstallPriceTTC,
      unitPriceWithInstallationHT: batteryPriceWithInstallHT,
      unitPriceWithoutInstallationHT: batteryPriceWithoutInstallHT,
    },
  };
};
