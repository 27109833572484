import eeroApi from "../eeroApiAxios";
import { useAuthQuery } from "./useAuthQuery";

type FetchBdcStatusVariables = {
  bdcId?: string | null;
  studyId?: string | null;
  enabled?: boolean;
};
export type FetchBdcStatusResponse = {
  state?: string;
  statusReason?: string;
};
type FetchBdcStatusErrorResponse = {
  message: string;
};

async function fetchBdcStatus({ bdcId, studyId }: FetchBdcStatusVariables) {
  const response = await eeroApi.get<FetchBdcStatusResponse>(
    `/oree2/studies/${studyId}/bdc/${bdcId}/bdcStatus`
  );
  return response.data;
}

/**
 * Custom hook for fetching Sale Order status from CRM API, with eero API as middleware.
 */
export function useGetBdcStatus({
  bdcId,
  studyId,
  enabled = true,
}: FetchBdcStatusVariables) {
  const query = useAuthQuery<
    FetchBdcStatusResponse,
    FetchBdcStatusErrorResponse
  >({
    queryKey: ["getBdcStatus", bdcId],
    queryFn: () => fetchBdcStatus({ bdcId, studyId }),
    enabled,
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (query.isError) {
    const requestErrorMessage = query.error.response?.data?.message;

    errorMessage =
      requestErrorMessage ||
      query.genericErrorMessage ||
      "Impossible de récupérer le statut du bon de commande.";
  }

  return { ...query, errorMessage };
}
