import "./PacAirAirInputContainer.scss";

// Components import
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

interface Props {
  title: string;
  value?: string;
  areAnswersAlign?: boolean;
  type?: string;
  iconType?: "squaredMeters" | "meters" | "euros";
  placeholder?: string;
  inputLableText?: string;
  onChange?: (value: number) => void;
  iconHidden?: boolean;
}

export const PacAirAirInputContainer = ({
  title,
  areAnswersAlign = true,
  value,
  iconType,
  type,
  placeholder,
  inputLableText,
  onChange,
  iconHidden = false,
}: Props) => {
  return (
    <div className="PacAirAirInputContainer">
      <section
        className={`PacAirAirInputContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <p className={"PacAirAirInputContainer__QuestionTitle"}>{title}</p>

        <div className={`PacAirAirInputContainer__FieldContainer`}>
          <TextInput
            iconHidden={iconHidden}
            value={value ?? undefined}
            onChange={(e) =>
              onChange && onChange(e.currentTarget.valueAsNumber)
            }
            iconType={iconType}
            type={type}
            placeholder={placeholder}
            inputLableHidden={true}
            inputDescriptionHidden={true}
            inputLableText={inputLableText}
          />
        </div>
      </section>
    </div>
  );
};
