import { AxiosError } from "axios";
import { useState } from "react";
import Accordion from "../../Accordion/Accordion";
import "./ErrorEeroAlert.scss";

type ZodError = {
  code: string;
  expected: string;
  received: string;
  path: (string | number)[];
  message: string;
};

type Props = {
  title?: string;
  error: AxiosError | null;
};

export function ErrorEeroAlert({ title = "Erreur", error }: Props) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  if (!error) {
    return null;
  }

  if (!error.response) {
    return (
      <div className="ErrorEeroAlert">
        <h3>{title}</h3>
        <p>Impossible de communiquer avec le serveur.</p>
      </div>
    );
  }

  const response = error.response;

  const zodError = response.data?.error as ZodError[];
  if (zodError && Array.isArray(zodError) && response.status === 400) {
    return (
      <div className="ErrorEeroAlert">
        <h3>{title}</h3>
        <p>
          Erreur 400 - Une erreur validation s'est glissée dans les données
          envoyées au CRM.
        </p>

        <h4 onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
          Afficher les details
        </h4>
        <Accordion isOpen={isDetailsOpen}>
          <ul>
            {zodError.map((error, index) => (
              <li key={index}>
                {JSON.stringify(error.path)} - Received {error.received} -
                Expect {error.expected}
              </li>
            ))}
          </ul>
        </Accordion>
      </div>
    );
  }

  if (response && response.status === 422) {
    const errors = response.data?.error?.errors;

    return (
      <div className="ErrorEeroAlert">
        <h3>{title}</h3>
        <p>Erreur 422 - Un problème est survenu au niveau du CRM</p>

        {errors && (
          <ul>
            {errors.map((error: any, index: number) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className="ErrorEeroAlert">
      <h3>{title}</h3>
      <p>Erreur de communication API.</p>
      {response.data && <p>{JSON.stringify(response.data)}</p>}
    </div>
  );
}
