import {
  ICommandeData,
  IStudyInformation,
  IUserAuthInfo,
} from "../../../interfaces/generalInterfaces";
import { formatPhoneNumber } from "../../../utils/utilsFunctions";

export function formatPropect({
  studyInformation,
  userAuthInfo,
}: {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
}): ICommandeData["prospect"] {
  const addressArray = studyInformation.address?.split(", ");

  return {
    firstName: studyInformation.firstname,
    lastName: studyInformation.lastname,
    eMailAddress: studyInformation.email,
    mobilePhone: formatPhoneNumber(studyInformation.phonenumber),
    postalCode: studyInformation.zipcode,
    street: addressArray && addressArray[0],
    city: addressArray && addressArray[1],
    commercialId: userAuthInfo.crm_user_id, // id to use for test => "5CC1097C-590D-EB11-A814-000D3AAFE41C"
    lng: studyInformation.longitude ? String(studyInformation.longitude) : null,
    lat: studyInformation.latitude ? String(studyInformation.latitude) : null,
  };
}
