import "./LoadStudyDataHOC.scss";

// Libraries import
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Component import
import { LoadingScreen } from "../../containers/LoadingScreen/LoadingScreen";

// Services import
import {
  resetAllStudyInformationAction,
  setCompanySettingsAction,
} from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useGetStudy } from "../../services/Studies/useGetStudy";

// Interfaces import
import { useGetCompanySettings } from "../../api/hooks/useGetCompanySettings";
import {
  IStudyInformation,
  IUserAuthInfo,
} from "../../interfaces/generalInterfaces";
import { useGetCommercialInformation } from "../../services/useGetCommercialInformation";

// Local interface declaration
interface Props {
  children: JSX.Element;
  goToLastPageVisited: ({
    last_page_visited,
  }: {
    last_page_visited?: string | null;
    studyInformation?: IStudyInformation;
    userAuthInfo: IUserAuthInfo;
  }) => void;
}

export const LoadStudyDataHOC = ({ children, goToLastPageVisited }: Props) => {
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const commercialUserInformation = useAppSelector(
    (state) => state.commercialUserInformation
  );
  const dispatch = useAppDispatch();
  const { study_id } = useParams();
  const { getStudy } = useGetStudy();

  // Custom hook to get company settings.
  // Has been put here to not create a new HOC at the root of the app only for fetching the company settings.
  // But it would make sense to fetch the companing settings at the root of the app, and not at the root of the study.
  const { isFetching: isFetchingGetCompanySettings, data: companySettings } =
    useGetCompanySettings({
      enabled: !!userAuthInfo.companyId,
      companyId: userAuthInfo.companyId,
    });

  // Custom hook to get the commercial information.
  // Has been put here to not create a new HOC at the root of the app only for fetching the company settings & commercial information.
  // But it would make sense to fetch them at the root of the app, and not at the root of the study.
  const {
    getCommercialInformation,
    isLoading: isLoadingGetCommercialInformation,
  } = useGetCommercialInformation();

  const [isGetStudyLoading, setIsGetStudyLoading] = useState(false);

  // Fetch all data from the study and stored them in the studyInformation redux state
  const fetchData = async () => {
    setIsGetStudyLoading(true);
    const studyInformation = await getStudy({
      userToken: userAuthInfo.userToken,
      study_id,
    });

    if (!studyInformation) return;

    studyInformation.last_page_visited !== "my_scenario" &&
      (await new Promise((resolve) => setTimeout(resolve, 2000)));

    setIsGetStudyLoading(false);

    goToLastPageVisited({
      userAuthInfo,
      studyInformation: studyInformation,
    });
  };

  // When we get the company settings we store them in the global redux state
  useEffect(() => {
    if (companySettings) {
      dispatch(
        setCompanySettingsAction({
          companySettings,
        })
      );
    }
  }, [companySettings, dispatch]);

  useEffect(() => {
    // When we start a study we reset all cutomer data from redux
    dispatch(resetAllStudyInformationAction(null));

    // We get the commercial information if we don't have it yet
    if (
      !commercialUserInformation.userCompany &&
      userAuthInfo.userToken &&
      userAuthInfo.userId
    ) {
      getCommercialInformation({
        userId: userAuthInfo.userId,
        userToken: userAuthInfo.userToken,
      });
    }

    // We get the study data if it is not a new study
    study_id && study_id !== "new" && fetchData(); // If study id=new it means it is the creation of a new study -> we don't have yet study_id neither study information to get from backend
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isGetStudyLoading ||
      isFetchingGetCompanySettings ||
      isLoadingGetCommercialInformation ? (
        <div className="LoadStudyDataHoc__LoadingContainer">
          <LoadingScreen sentenceOne="Chargement des données de l'étude ..." />
        </div>
      ) : (
        children
      )}
    </>
  );
};
