import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Interface import
import { IProfitability } from "../../interfaces/generalInterfaces";

//Service import
import { TExtraFee } from "../../interfaces/renoglobaleInterface";
import { setProfitabilityAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

// Local interface declaration
interface IGetProfitabilityProps {
  userToken: string | null;
  number_of_panels: number | null;
  building_id?: string | null;
  household_id: string | null | undefined;
  kit_home_management: boolean | null;
  number_of_batteries: number | null;
  indexScenarioChosen: number | null;
  inverter_brand: string | null;
  study_id: string;
  single_battery_capacity: number | null;
  panel_indices?: number[] | null;
  extra_fees?: TExtraFee[] | null;
  isPersonalizedScenario: boolean;
  options: string[] | null;
}

export const useGetProfitability = () => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const getProfitability = async ({
    userToken,
    number_of_panels,
    building_id,
    household_id,
    kit_home_management,
    number_of_batteries,
    indexScenarioChosen,
    inverter_brand,
    study_id,
    single_battery_capacity,
    panel_indices,
    extra_fees,
    isPersonalizedScenario,
    options,
  }: IGetProfitabilityProps) => {
    const queryParams = new URLSearchParams();
    queryParams.append("building_id", String(building_id));
    queryParams.append("household_id", String(household_id));
    queryParams.append("number_of_panels", String(number_of_panels));
    queryParams.append("kit_home_management", String(kit_home_management));
    queryParams.append("number_of_batteries", String(number_of_batteries));
    queryParams.append("inverter_brand", String(inverter_brand));
    queryParams.append(
      "is_personalized_scenario",
      String(isPersonalizedScenario)
    );
    queryParams.append(
      "single_battery_capacity",
      String(single_battery_capacity)
    );
    queryParams.append(
      "panel_indices",
      (panel_indices?.length || 0) > 0 ? String(panel_indices) : ""
    );

    const total_extra_fees_with_tax =
      extra_fees?.reduce((acc, fee) => acc + fee.price_with_tax, 0) || 0;
    queryParams.append(
      "total_extra_fees_with_tax",
      JSON.stringify(total_extra_fees_with_tax)
    );
    queryParams.append(
      "options",
      options?.length != null
        ? "[" + options.map((option) => '"' + option + '"') + "]"
        : "" // Format the array to be python compatible
    );

    const response = await axiosGetRequest<IProfitability>(
      `${BACKEND_URL}/oree2/pv/studies/${study_id}/profitability?${queryParams}`,

      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(
        setProfitabilityAction({
          profitability: { ...response, indexScenarioChosen },
        })
      );
    } else {
      setErrorMessage("Erreur lors de la récupération de la rentabilité");
    }
  };
  return {
    getProfitability,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
