import { IAvailableCalepinages } from "../../interfaces/generalInterfaces";
import eeroApi from "../eeroApiAxios";
import { useAuthQuery } from "./useAuthQuery";

type UseGetScenariosVariables = {
  householdId: string | null;
  studyId: string | null;
  buildingId: string | null;
};

type FetchPersonalizedScenariosErrorResponse = {
  message: string;
  error?: string;
};

async function fetchPersonalizedScenarios(props: UseGetScenariosVariables) {
  const { studyId, householdId, buildingId } = props;
  const response = await eeroApi.get<IAvailableCalepinages>(
    `/oree2/pv/studies/${studyId}/personalizedScenarios`,
    {
      params: {
        household_id: householdId,
        building_id: buildingId,
      },
    }
  );
  return response.data;
}

/**
 * Custom hook for fetching personalized scenarios from eero, with eeroApi as middleware.
 * @param studyId The study id
 * @param buildingId The building id
 * @param householdId The household id
 */
export function useGetPersonalizedScenarios(props: UseGetScenariosVariables) {
  const query = useAuthQuery<
    IAvailableCalepinages,
    FetchPersonalizedScenariosErrorResponse
  >({
    queryKey: ["getPersonalizedScenarios", props], // Allow to cache the query if the queryKeys are the same
    queryFn: () => fetchPersonalizedScenarios(props),
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (query.isError) {
    const requestErrorMessage =
      query.error.response?.data?.message || query.error.response?.data?.error;

    errorMessage =
      requestErrorMessage ||
      query.genericErrorMessage ||
      "Impossible de récupérer les scénarios personnalisés disponibles.";
  }

  return { ...query, errorMessage };
}
