//Styles import
import { DSColors } from "@web/shared/src/styles/variables";
import "./PACAirAirPage.scss";

// Libraries import
import find from "lodash.find";
import { useState } from "react";
import SVG from "react-inlinesvg";

// Components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { InfoAlert } from "../../../components/alerts/InfoAlert/InfoAlert";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";
import { ProductFilterContainer } from "../filterComponent/ProductFilterContainer";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IRoomPacAirAir,
  TFraisSupp,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { PacAirAirQuantiteContainer } from "../../../containers/PacAirAirQuantiteContainer/PacAirAirQuantiteContainer";
import { PacAirAirRoom } from "../../../containers/PacAirAirRoom/PacAirAirRoom";
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { getDataProductBySageRef } from "../../../services/ProductsPageRG/PACAirAirPage/pacAirAirPage.helper";
import { formatDropdownOptionsFromSageProducts } from "../../../services/RenovationGlobale/formatDropdownOptionsFromSageProducts";
import { formatSageProductsForDropdown } from "../../../services/RenovationGlobale/formatProductSelectedForDropdown";

// Local interfaces declaration
interface IFilterSAGEProducts {
  sageProduct: ISageProduct[];
  sageProductCharacteristic?: keyof ISageProduct;
}

interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay: IDataProduct[];
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const PACAirAirPage = ({
  dataProductsFromCategory: dataProducts,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  const dispatch = useAppDispatch();
  const productsFromSage = useAppSelector((state) => state.SAGEItemsInfo);

  // We filter the SAGE products to only keep the SAGE PACAIRAIR products that are in the dataProducts products list from data backend (= products that are elligible for the house)
  const productsCharacteristicsListForPACFromSAGE = productsFromSage.filter(
    (itemInfo) =>
      dataProducts.some(
        (dataProduct) => dataProduct.sage_reference === itemInfo.AR_Ref
      )
  ); // Contains exterior units, interior units and installation

  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);
  const selectedProduct =
    selectedProductIndex != null
      ? cartProductsToDisplay[selectedProductIndex]
      : null;

  // States
  const [productSelected, setProductSelected] = useState<IOption[]>(
    selectedProduct && cartProductsToDisplay
      ? [
          {
            label:
              selectedProduct.product_alias || selectedProduct.product_name,
            value: selectedProduct.sage_reference,
          },
        ]
      : []
  );

  const productSelectedSageRef = productSelected?.[0]?.value;

  const dataProductSelected = getDataProductBySageRef(
    dataProducts,
    productSelectedSageRef
  );

  const isProductSelectedMonosplit =
    dataProductSelected?.product_characteristics?.number_of_units === 1;

  const [brand, setBrand] = useState<IOption[]>([]);
  const [gamme, setGamme] = useState<IOption[]>([]);
  const [surfaceToHeat, setSurfaceToHeat] = useState<number | null>(
    selectedProduct && cartProductsToDisplay
      ? Math.round(
          selectedProduct.selected_characteristics.surface_to_heat || 0
        )
      : 0
  );
  const [rooms, setRooms] = useState<IRoomPacAirAir[]>(
    selectedProduct && cartProductsToDisplay
      ? selectedProduct.selected_characteristics.rooms_information || []
      : []
  );
  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    selectedProduct && cartProductsToDisplay
      ? selectedProduct.extra_charges_added || []
      : []
  );
  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);
  const [isBlocksOpen, setIsBlocksOpen] = useState<boolean[]>(
    rooms.map(() => false) || [false]
  );
  const [blocksHeight, setBlocksHeight] = useState<number[]>(
    rooms.map(() => 0) || [0]
  );

  // Constants
  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProducts
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.find((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  // IIFE that re-build the product object to add to the cart each time a form value is updated
  const productToAdd = (() => {
    const product = dataProducts.find(
      (product) => product.sage_reference === productSelected?.[0]?.value
    );
    if (!product) {
      return undefined;
    }
    product.extra_charges_added = fraisSupp;

    // We add the form value selected by the user
    product.selected_characteristics.surface_to_heat =
      surfaceToHeat || undefined;

    product.selected_characteristics.rooms_information = rooms;
    return product;
  })();

  const listOfExteriorUnitsAvailables =
    productsCharacteristicsListForPACFromSAGE.filter((product) =>
      product.AR_Design?.includes("extérieur")
    );

  const listOfInteriorUnitsAvailables =
    productsCharacteristicsListForPACFromSAGE.filter((product) =>
      product.AR_Design?.includes("intérieur")
    );

  const listOfInteriorUnitsAvailableForSelectedExteriorUnit =
    listOfInteriorUnitsAvailables.filter((sageProduct) => {
      if (!dataProductSelected) return false;
      if (!isProductSelectedMonosplit) return true; // If the product is not a monosplit we don't need to filter the interior units

      const exteriorUnitPower =
        dataProductSelected.product_characteristics?.power;

      const dataProduct = getDataProductBySageRef(
        dataProducts,
        sageProduct.AR_Ref ?? null
      );

      if (dataProduct?.product_characteristics?.power === exteriorUnitPower) {
        return true;
      }

      return false;
    });

  const interior_unit_installation_sage_reference =
    productsCharacteristicsListForPACFromSAGE.find((product) =>
      product.AR_Design?.toLocaleLowerCase()?.includes("installation")
    )?.AR_Ref; // For now there is only one PAC air/air installation product in Sage that applies to interior units only (cf. Xavier Birraux)

  // Form functions
  const onClickAddRoom = ({
    value,
    exterior_unit_sage_reference,
  }: {
    value: number;
    exterior_unit_sage_reference: string | null;
  }) => {
    if (value > rooms.length) {
      setRooms((state) => {
        return [
          ...state,
          {
            room_id: "roomId_" + value + "_" + Date.now(),
            exterior_unit_sage_reference,
            interior_units: [],
          },
        ];
      });
      setIsBlocksOpen((state) => {
        return [...state, false];
      });
      return;
    }
    setRooms((state) => {
      return state.slice(0, value);
    });
    return;
  };

  const onClickDeleteRoom = ({ roomIndex }: { roomIndex: number }) => {
    setRooms((state) => {
      return state.filter((room, index) => index !== roomIndex);
    });
    setIsBlocksOpen((state) => {
      return state.filter((block, index) => index !== roomIndex);
    });
  };

  // For each product characteristics if there is a dropdown value in the local state we filter the product list to keep only
  // the products that have the dropdown value in their product characteristics
  // The dropdown value should not filter its own product_characteristics to be able to select another product_characteristics after selecting a first one
  const filterProductsCharacteristicsListAccordingToDropdownsValue = ({
    sageProduct,
    sageProductCharacteristic,
  }: IFilterSAGEProducts) => {
    return sageProduct
      .filter((product) => {
        if (brand.length === 0) return true;
        return (
          sageProductCharacteristic === "Marque" ||
          product.Marque?.toString()?.toLocaleLowerCase().trim() ===
            (brand?.[0]?.value?.toLocaleLowerCase().trim() ??
              product.Marque?.toString()?.toLocaleLowerCase().trim())
        );
      })
      .filter((product) => {
        if (gamme.length === 0) return true;
        return (
          sageProductCharacteristic === "Gamme" ||
          product.Gamme?.toString()?.toLocaleLowerCase().trim() ===
            (gamme?.[0]?.value?.toLocaleLowerCase().trim() ??
              product.Gamme?.toString()?.toLocaleLowerCase().trim())
        );
      });
  };

  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product find we can not add it to the selection
    }

    productToAdd.isProductRecommended =
      find(cartProductsToDisplay, productToAdd)?.isProductRecommended || false;
    productToAdd.isSelected = true;

    //If we have an selectedProductIndex it means the product is already in the recommended products : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProductsToDisplay];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProductsToDisplay, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: filterProductsCharacteristicsListAccordingToDropdownsValue({
      sageProduct: listOfExteriorUnitsAvailables,
    }),
    dataProducts: dataProducts,
  });

  const isFormValid =
    productToAdd &&
    surfaceToHeat &&
    rooms.length > 0 &&
    rooms.every(
      (room) =>
        room.room_height &&
        room.interior_units.length > 0 &&
        room.interior_units.every(
          (interiorUnit) => interiorUnit.name && interiorUnit.surface_to_heat
        )
    );

  if (productsCharacteristicsListForPACFromSAGE.length === 0) {
    return <div>Pas de produits disponible</div>;
  }

  return (
    <div className="PACAirAirPage">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />

      {isProductSelectedMonosplit && (
        <>
          <InfoAlert>
            <p>Vous avez sélectionné une unité extérieure monosplit.</p>
            <p>
              Vous pouvez uniquement équiper une pièce avec une unité
              intérieure.
            </p>
          </InfoAlert>
        </>
      )}

      <section className="PACAirAirPage__FormContainer">
        <div className="PACAirAirPage__SubTitle">
          <h3>Produit : </h3>
        </div>
        <div className="PACAirAirPage__ProductContent">
          <div className="PACAirAirPage__Question">
            <div className="PACAirAirPage__Dropdown">
              <Dropdown
                options={sageProductFormatedForDropdown}
                optionsSelected={productSelected}
                onSelect={(option) => {
                  setRooms([]);
                  manageDropdownSingleSelection(option, setProductSelected);
                }}
                multipleChoices={false}
                tags={false}
                placeholder="Choisir pompe à chaleur"
                maxHeight="32vh"
                searchable={true}
              />
            </div>
            <div className="PACAirAirPage__SVG">
              {productSelected?.[0]?.value ? (
                <SVG
                  src={Trash}
                  onClick={() => {
                    setRooms([]);
                    setProductSelected([]);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="PACAirAirPage__SubTitle">
          <h3>Surface totale chauffée (m²):</h3>
        </div>
        <div>
          <div className="PACAirAirPage__Question">
            <div className="PACAirAirPage__Textinput">
              <TextInput
                inputLableHidden
                type="number"
                inputDescriptionHidden
                iconType="m²"
                iconHidden={false}
                placeholder="Surface à chauffer"
                value={surfaceToHeat?.toString()}
                onChange={(e) => {
                  setSurfaceToHeat(e.target.valueAsNumber);
                }}
              />
            </div>
            <div className="PACAirAirPage__SVG">
              {surfaceToHeat ? (
                <SVG src={Trash} onClick={() => setSurfaceToHeat(null)} />
              ) : null}
            </div>
          </div>
        </div>
        <ProductFilterContainer>
          <div className="PACAirAirPage__FiltersContent">
            <div className="PACAirAirPage__QuestionContainer">
              <div className="PACAirAirPage__Question">
                <div className="PACAirAirPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Marque",
                          }
                        ),
                      productCharacteristic: "Marque",
                    })}
                    optionsSelected={brand}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setBrand);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Marque"
                  />
                </div>
                <div className="PACAirAirPage__SVG">
                  {brand?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setBrand([])} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="PACAirAirPage__QuestionContainer">
              <div className="PACAirAirPage__Question">
                <div className="PACAirAirPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts:
                        filterProductsCharacteristicsListAccordingToDropdownsValue(
                          {
                            sageProduct:
                              productsCharacteristicsListForPACFromSAGE,
                            sageProductCharacteristic: "Gamme",
                          }
                        ),
                      productCharacteristic: "Gamme",
                    })}
                    optionsSelected={gamme}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setGamme);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Gamme"
                  />
                </div>
                <div className="PACAirAirPage__SVG">
                  {gamme?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setGamme([])} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ProductFilterContainer>
      </section>

      <section>
        {productSelected?.[0]?.value ? (
          <div className="PACAirAirPage__Question">
            <PacAirAirQuantiteContainer
              title="Pièces à équiper :"
              value={rooms.length}
              isMonosplit={isProductSelectedMonosplit}
              onPlusMinusClick={(value) => {
                onClickAddRoom({
                  value,
                  exterior_unit_sage_reference: productSelected[0].value,
                });
              }}
            />
          </div>
        ) : null}
        <div className="PACAirAirPage__Rooms">
          {rooms.map((room, index) => {
            return (
              <PacAirAirRoom
                room={room}
                blockHeight={blocksHeight[index]}
                isBlockOpen={isBlocksOpen[index]}
                roomIndex={index}
                setBlocksHeight={setBlocksHeight}
                setIsBlocksOpen={setIsBlocksOpen}
                key={index}
                onClickDeleteRoom={onClickDeleteRoom}
                setRooms={setRooms}
                listOfInteriorUnitsAvailables={
                  listOfInteriorUnitsAvailableForSelectedExteriorUnit
                }
                interior_unit_installation_sage_reference={
                  interior_unit_installation_sage_reference
                }
                products_list={dataProducts}
                isMonosplit={isProductSelectedMonosplit}
              />
            );
          })}
        </div>
      </section>

      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}

      {fraisSupp.length > 0 ? (
        <section className="PACAirAirPage__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}

      <section className="PACAirAirPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {isFormValid ? (
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      ) : null}
    </div>
  );
};
