import { CRMlabelToValueDictionnary } from "@web/shared/dist/datas/CRMvalueToLabelDictionary";
import { energiePriceNov2023Data } from "@web/shared/dist/datas/dataHypotethis";
import {
  ICommandeData,
  IOreeStudyType,
  IStudyInformation,
} from "../../../interfaces/generalInterfaces";

export function formatVosConso(
  studyInformation: IStudyInformation
): ICommandeData["vos_conso"] {
  if (studyInformation.study_type === IOreeStudyType.RENOVATION_GLOBALE) {
    return formatConsoRG(studyInformation);
  } else {
    return formatConsoPV(studyInformation);
  }
}

function formatConsoPV(studyInformation: IStudyInformation) {
  return {
    chauffage: {
      sourceEnergieElec: studyInformation.is_electric_heater === true,
    },
    ecs: {
      boilerEnergyTypeElec: studyInformation.is_electric_water_heater === true,
    },
  };
}

function formatConsoRG(studyInformation: IStudyInformation) {
  const chauffage = [
    {
      source: "Electricité",
      factureEuro: studyInformation.electricite_chauffage_euros ?? 0,
      temperature: studyInformation.electricite_temperature_confort,
      typeChauffage: studyInformation.electricite_type_chauffage,
      surface: studyInformation.electricite_surface_chauffee ?? 0,
    },
    {
      source: "Gaz Naturel",
      factureEuro: studyInformation.gaz_chauffage_euros ?? 0,
      temperature: studyInformation.gaz_temperature_confort,
      typeChauffage: studyInformation.gaz_type_chauffage,
      surface: studyInformation.gaz_surface_chauffee ?? 0,
    },
    {
      source: "GPL",
      factureEuro: studyInformation.gpl_chauffage_euros ?? 0,
      temperature: studyInformation.gpl_temperature_confort,
      typeChauffage: studyInformation.gpl_type_chauffage,
      surface: studyInformation.gpl_surface_chauffee ?? 0,
    },
    {
      source: "Fioul",
      factureEuro: studyInformation.fioul_chauffage_euros ?? 0,
      temperature: studyInformation.fioul_temperature_confort,
      typeChauffage: studyInformation.fioul_type_chauffage,
      surface: studyInformation.fioul_surface_chauffee ?? 0,
    },
    {
      source: "Bois",
      factureEuro: studyInformation.bois_consommation_euros ?? 0,
      temperature: studyInformation.bois_temperature_confort,
      typeChauffage: studyInformation.bois_type_chauffage,
      surface: studyInformation.bois_surface_chauffee ?? 0,
    },
  ];

  const ecs = [
    {
      source: "Electricité",
      factureEuro: studyInformation.electricite_ecs_euros ?? 0,
      boilerType:
        studyInformation.electricite_type_ballon_eau_chaude &&
        studyInformation.electricite_type_ballon_eau_chaude[0],
      installYear: studyInformation.electricite_installation_year || undefined,
      storage: studyInformation.electricite_storage_capacity ?? 0,
    },
    {
      source: "Gaz Naturel",
      factureEuro: studyInformation.gaz_ecs_euros ?? 0,
      boilerType: null,
      installYear: undefined,
      storage: studyInformation.gaz_storage_capacity ?? 0,
    },
    {
      source: "Fioul",
      factureEuro: studyInformation.fioul_ecs_euros ?? 0,
      boilerType: null,
      installYear: undefined,
      storage: studyInformation.fioul_storage_capacity ?? 0,
    },
    {
      source: "GPL",
      factureEuro: studyInformation.gpl_ecs_euros ?? 0,
      boilerType: null,
      installYear: undefined,
      storage: studyInformation.gpl_storage_capacity ?? 0,
    },
  ];

  const bestSourceChauffage = chauffage.reduce((prev, current) => {
    return prev.factureEuro > current.factureEuro ? prev : current;
  });

  const bestECS = ecs.reduce((prev, current) => {
    return prev.factureEuro > current.factureEuro ? prev : current;
  });

  return {
    chauffage: {
      sourceEnergieElec:
        studyInformation.electricite_principaux_equipments?.includes(
          "electricite"
        ),
      sourceEnergie: CRMlabelToValueDictionnary(
        "sourceEnergie",
        bestSourceChauffage.source
      ),
      estimationFactureChauffage: chauffage
        .reduce((acc, obj) => {
          return acc + obj.factureEuro;
        }, 0)
        .toString(),
      prixElectricite: energiePriceNov2023Data.Electricité.toString(),
      surfaceChauffee: bestSourceChauffage.surface,
      temperatureDeConfort: bestSourceChauffage.temperature?.toString(),
      typeChauffage:
        bestSourceChauffage.typeChauffage &&
        CRMlabelToValueDictionnary(
          "typeChauffage",
          bestSourceChauffage.typeChauffage[0]
        ),
    },
    ecs: {
      boilerEnergyTypeElec:
        studyInformation.electricite_principaux_equipments?.includes(
          "eauChaudeSanitaire"
        ) || false,
      boilerEnergyType: CRMlabelToValueDictionnary(
        "sourceEnergie",
        bestECS.source
      ),
      boilerType: CRMlabelToValueDictionnary("boilertype", bestECS.boilerType),
      anneeInstallation: bestECS.installYear?.toString(),
      capaciteStockage: CRMlabelToValueDictionnary(
        "stockageCapacity",
        bestECS.storage.toString()
      ),
    },
  };
}
