// Service import

interface IDictionnary {
  [key: string]: number;
}

interface IDictionaryNested {
  [key: string]: { [key: string]: number };
}

interface IProductsPrices {
  [key: string]: {
    produtPrice: number;
    installationPrice: number;
    TVAProduct: number;
    TVAInstallation: number;
  };
}

// KWh electricity price (euros)
export const ElectricityPrice = 0.23;

// Electricity inflation
export const ElectricityInflation = 1.05;

// Montant CEE (€/MWhc)
export const CEEValuation = 6.4;
export const CEEValuationForBlueHouseholds = 6.6; // Different CEE valuation for modest households

// Average PAC Air/Air energie saving (%)
export const PACAirAirEnergieSaving = 0.5;

// Average carbon emission of a Paris New York flight (tCO2e/passager, source : https://eco-calculateur.dta.aviation-civile.gouv.fr/)
export const ParisNewYorkCarbonEmission = 0.504;

// Energie price per kWh (nov. 2023, in euros).
export const energiePriceNov2023Data: IDictionnary = {
  Bois: 0.05,
  Electricité: 0.23,
  Fioul: 0.1739,
  Gaz: 0.1392,
};

// Produire Mon Eau Chaude average energie saving according to energie source
export const ProduireMonEauChaudeEnergieSavingsInPercentage: IDictionnary = {
  Electricité: 73.82,
  Fioul: 67.92,
  Gaz: 66.21,
  Bois: 40.0,
};

// Carbon CO2 emission per kWh (gCO2/kWh)
export const carbonEmmisionPerKWhData: IDictionnary = {
  Bois: 30.0,
  Electricité: 79.0,
  Fioul: 324.0,
  Gaz: 272.0,
};

// Average carbon emission saving after a PAC Air/Eau installation, according to energie source
export const averageCarbonEmissionSavingPacAirEauData: IDictionnary = {
  Fioul: 7.35,
  Gaz: 8.69,
};

// Average carbon emission saving after a PAC Air/Eau installation, according to energie source
export const averageCarbonEmissionSavingPacAirAirData: IDictionnary = {
  Bois: 4.0,
  Electricité: 4.0,
};

// Data for carbon emission saving after a Chaudière à granulés installation, according to energie source
export const averageCarbonEmissionSavingChaudiereAGranulesData: IDictionnary = {
  FioulAbonnement: 0,
  FioulPrix: 1.543,
  FioulPCIinit: 9.76,
  FEFioul: 324,

  GazAbonnement: 250,
  GazPrix: 1,
  GazPCIinit: 9.9,
  FEGAZ: 227,

  GranulesPrix: 340,
  GranulesPCIinit: 4600,
  FEGranules: 30,

  CoeffInflation: (Math.pow(1.05, 5) - 1) / 0.05,
};

// Average carbon emission saving after a PAC Air/Eau installation, according to energie source
export const averageCarbonEmissionSavingChaufferMonEauChaudeData: IDictionnary =
  {
    Bois: 0,
    Electricité: 0.19,
    Fioul: 1.92,
    Gaz: 1.55,
  };

// Initial isolation coefficient according to house construction year
export const initialIsolationCoefficientData = [
  [0.35, 2012],
  [0.65, 2005],
  [1.0, 2000],
  [1.3, 1980],
  [1.6, 0],
];

// Project isolation coefficient according to initial isolation coefficient and isolation products installed
export const projectIsolationCoefficientData: IDictionaryNested = {
  "1.6": {
    ITE: 1.29,
    Combles: 1.23,
    PlancherBas: 1.51,
    ITECombles: 0.91,
    ITEPlancherBas: 1.2,
    ComblesPlanchersBas: 1.14,
    ITEComblesPlanchers: 0.83,
  },
  "1.3": {
    ITE: 1.06,
    Combles: 1.02,
    PlancherBas: 1.23,
    ITECombles: 0.78,
    ITEPlancherBas: 0.99,
    ComblesPlanchersBas: 0.95,
    ITEComblesPlanchers: 0.71,
  },
  "1.0": {
    ITE: 0.84,
    Combles: 0.81,
    PlancherBas: 0.95,
    ITECombles: 0.65,
    ITEPlancherBas: 0.79,
    ComblesPlanchersBas: 0.76,
    ITEComblesPlanchers: 0.6,
  },
  "0.65": {
    ITE: 0.58,
    Combles: 0.56,
    PlancherBas: 0.62,
    ITECombles: 0.49,
    ITEPlancherBas: 0.55,
    ComblesPlanchersBas: 0.53,
    ITEComblesPlanchers: 0.46,
  },
  "0.35": {
    ITE: 0.35,
    Combles: 0.35,
    PlancherBas: 0.35,
    ITECombles: 0.35,
    ITEPlancherBas: 0.35,
    ComblesPlanchersBas: 0.35,
    ITEComblesPlanchers: 0.35,
  },
};

// Three different PAC (power in kW)
export const PACPowerData = [11000, 14000, 16000];

// Number of CEE according to climatique zone for the PAC Air/Eau
export const CEEValuationPACAirEauData: IDictionnary = {
  H1: 79900,
  H2: 65400,
  H3: 43600,
};

// Number of CEE according to climatique zone for the PAC Air/Air
export const CEEValuationPACAirAirData: IDictionnary = {
  H1: 80200,
  H2: 65600,
  H3: 43700,
};

// Number of CEE according to climatique zone for the Chaudière à granulés
export const CEEValuationChaudiereAGranules: IDictionnary = {
  H1: 41300,
  H2: 33800,
  H3: 26300,
};

// PAC Air/Eau CEE coefficient according to the heating surface (in m²)
export const CEESurfaceChauffeePacAirEauCoeff: IDictionnary = {
  "<70": 0.5,
  "<90": 0.7,
  "<110": 1.0,
  "<130": 1.1,
  ">130": 1.6,
};

// PAC Air/Air CEE coefficient according to the heating surface (in m²)
export const CEESurfaceChauffeePacAirAirCoeff: IDictionnary = {
  "<35": 0.3,
  "<60": 0.5,
  "<70": 0.6,
  "<90": 0.7,
  "<110": 1.0,
  "<130": 1.1,
  ">130": 1.6,
};

// Number of CEE for a Chauffe Eau Thermodynamique
export const CEEValuationChauffeEauThermodynamique = 15600;

// Number of CEE according to climatique zone for an Isolation par l'exterieur (€/m²)
export const CEEValuationIsolationITE: IDictionnary = {
  H1: 1600,
  H2: 1300,
  H3: 880,
};

// Number of CEE according to climatique zone for an Isolation des combles (€/m²)
export const CEEValuationIsolationCombles: IDictionnary = {
  H1: 1700,
  H2: 1400,
  H3: 920,
};
// Number of CEE according to climatique zone for an Isolation des planchers bas (€/m²)
export const CEEValuationIsolationPlanchersBas: IDictionnary = {
  H1: 1100,
  H2: 890,
  H3: 590,
};

// Number of CEE according to climatique zone for isolation
export const CEEValuationIsolation: IDictionaryNested = {
  ITE: CEEValuationIsolationITE,
  Combles: CEEValuationIsolationCombles,
  PlancherBas: CEEValuationIsolationPlanchersBas,
};

// Price PAC Air/Eau according to power (in euros TVA not included)
export const PACAirEauPrices: IProductsPrices = {
  "11000": {
    produtPrice: 7573.46,
    installationPrice: 3791.47,
    TVAProduct: 5.5,
    TVAInstallation: 5.5,
  },
  "14000": {
    produtPrice: 7573.46,
    installationPrice: 3791.47,
    TVAProduct: 5.5,
    TVAInstallation: 5.5,
  },
  "16000": {
    produtPrice: 7573.46,
    installationPrice: 3791.47,
    TVAProduct: 5.5,
    TVAInstallation: 5.5,
  },
};

// Price of one PAC Air/Air unit (in euros TVA not included)
export const PACAirAirUnitPrice = {
  produtPrice: 1990,
  installationPrice: 818.18,
  TVAProduct: 20,
  TVAInstallation: 10,
};

// Price of one chaudière à granulés unit (in euros TVA not included)
export const ChaudiereAGranulesUnitPrice = {
  produtPrice: 11601.9,
  installationPrice: 4502.37,
  TVAProduct: 5.5,
  TVAInstallation: 5.5,
};

// Price of CET (in euros TVA not included) according to its volume(in Liters)
export const ChauffeEauThermodynamiquePrice: IProductsPrices = {
  "200": {
    produtPrice: 2190,
    installationPrice: 938.39,
    TVAProduct: 5.5,
    TVAInstallation: 5.5,
  },
  "270": {
    produtPrice: 2990,
    installationPrice: 938.39,
    TVAProduct: 5.5,
    TVAInstallation: 5.5,
  },
};

// Price isolation (in euros/m²)
export const isolationPrices: IDictionnary = {
  ITE: 150,
  Combles: 50,
  PlancherBas: 35,
};

//Period of validity for the various premiums
export const validityPeriodePrime = {
  startDate: "01/02/2023",
  endDate: "30/04/2023",
};

// Autoconsomation prime (in euros) per kWc according to the solar pannel power (last modified : august 2024)
export const primeAutoconsoParkWc = {
  "<3kWc": 260,
  "3-9kWc": 190,
  "9-36kWc": 190,
  "36-100kWc": 100,
  ">100kWc": 0,
};

// Autoconsomation with sale  prime (in euros) per kWc according to the solar pannel power (last modified : august 2024)
export const prixAutoconsoAvecReventeParkWc = {
  "<3kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1276),
  "3-9kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1276),
  "9-36kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.0765),
  "36-100kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
  }).format(0.0765),
  ">100kWc": 0,
};

// Total sale  prime (in euros) per kWc according to the solar pannel power
export const primeVenteTotaleParkWc = {
  "<3kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1205),
  "3-9kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1024),
  "9-36kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1318),
  "36-100kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1146),
  ">500kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.1003),
  ">1100kWc": Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 4,
  }).format(0.04),
};

// Autoconsomation prime (in euros) according to the solar pannel power
export const primeAutoconsommation = {
  "3kWc": primeAutoconsoParkWc["<3kWc"] * 3, // 3x390€
  "6kWc": primeAutoconsoParkWc["3-9kWc"] * 6, // 6x290€
  "9kWc": primeAutoconsoParkWc["3-9kWc"] * 9, // 9x290€
};

// Solar pannel project price according to the solar pannel installation power (include equipement + installation + TVA prices)
export const solarPannelProjectCost = {
  "3kWc": 8990,
  "6kWc": 16990,
  "9kWc": 22990,
};

export const coupDePouceChauffagePACAEHypothesis = {
  revenusTresModestes: 4500,
  revenusModestes: 4000,
  revenusIntermediaires: 2500,
  revenusSuperieurs: 2500,
};

export const coupDePouceChauffageCAGHypothesis = {
  revenusTresModestes: 4500,
  revenusModestes: 4000,
  revenusIntermediaires: 2500,
  revenusSuperieurs: 0,
};

export const maPrimeRenovPACHypothesis = {
  revenusTresModestes: 5000,
  revenusModestes: 3000,
  revenusIntermediaires: 2500,
  revenusSuperieurs: 0,
};

export const maPrimeRenovChaudiereAGranules = {
  revenusTresModestes: 10000,
  revenusModestes: 8000,
  revenusIntermediaires: 4000,
  revenusSuperieurs: 0,
};

export const maPrimeRenovProduireMonEauChaudeHypothesis = {
  revenusTresModestes: 1200,
  revenusModestes: 800,
  revenusIntermediaires: 400,
  revenusSuperieurs: 0,
};

export const maPrimeRenovIsolation = {
  ITE: {
    revenusTresModestes: 75,
    revenusModestes: 60,
    revenusIntermediaires: 40,
    revenusSuperieurs: 0,
  },
  rampants: {
    revenusTresModestes: 25,
    revenusModestes: 20,
    revenusIntermediaires: 15,
    revenusSuperieurs: 7,
  },
};

export const prixDAppelProducts = {
  solarPannel: 6110,
  chauffeEauThermodynamique: 2190,
  PACAirEau: 2990,
  PACAirAir: 3990,
  IsolationMursExterieurs: 85,
  IsolationDesCombles: 35,
  IsolationDesPlanchersBas: 40,
  Batterie: 9990,
};

export const standarInterestRate = 6.98;

export const interestRateOptions = [
  "AHAB",
  "AHC49",
  "AHC39",
  "AHC29",
  "AHC19",
  "AHC09",
  "AHAV",
];
