import {
  IDailySelfConsumptionData,
  IHourlyConsumptionProductionData,
  ISageProduct,
} from "./generalInterfaces";

export type ILabel = "A" | "B" | "C" | "D" | "E" | "F" | "G";

export interface IEnergyLabel {
  energy_label: ILabel;
  energy_value: number;
  ges_label?: ILabel;
  ges_value?: number;
  greenhouse_gaz_emissions: number;
  primary_energy_consumption: number;
}

export interface IPretictedBills {
  heating: number[];
  ecs: number[];
  other: number[];
}

export interface IHeatLoss {
  name: "windows" | "garage" | "walls" | "roof" | "floor";
  value: number;
}

export type TSingleHelp = {
  name: string;
  value: number;
  is_eligible: boolean;
  help_type?: "cee" | "mpr" | "other";
};

export type TFraisSupp = {
  product_name: string;
  sage_reference: string;
  price_without_tax: number;
  price_with_tax: number;
  TVA_percentage: number;
  description?: string;
};

// Duplicate this type instead of replacing it because it is used in a lot of files.
// And we don't want to break the code.
export type TExtraFee = TFraisSupp;

export interface IDataProduct {
  product_type: string;
  product_name: string;
  product_price_without_tax: number;
  product_price_with_tax: number;
  product_price_with_helps_deducted: number;
  total_extra_charges?: number;
  TVA_percentage: number;
  help: TSingleHelp[];
  help_breakdown?: string;
  recommendation_index: number;
  product_characteristics: ProductCharacteristics;
  sage_reference: string;
  installation_price_with_tax: number;
  installation_price_without_tax: number;
  installation_tva_percentage: number;
  sage_reference_installation: string;
  potential_products_to_add: TFraisSupp[];
  selected_characteristics: SelectedCharacteristics;
  isProductRecommended?: boolean;
  isSelected?: boolean;
  extra_charges_added?: TFraisSupp[];
  product_alias: string | null;
}

export interface ProductCharacteristics {
  available_batteries?: AvailableBattery[];
  kit_home_management?: KitHomeManagement;
  power?: number | null;
  ecs_storage_volume?: number | null;
  etas?: number | null;
  scop?: number | null;
  used_for_ecs?: boolean;
  inverter_brand?: string;
  brand?: string;
  number_of_units?: number;
  pacairair_type?: string;
}

export interface SelectedCharacteristics {
  kit_home_management?: boolean;
  n_batteries?: number;
  battery_sage_reference?: string;
  battery_installation_sage_reference?: string;
  single_battery_capacity_kWh?: number;
  config_type?: string;
  surface_to_isolate?: number;
  buidingVolume?: number;
  free_product_description?: string;
  surface_to_heat?: number; // Used for the PAC Air/Air
  rooms_information?: IRoomPacAirAir[]; // Used for the PAC Air/Air
  windows?: WindowProduct[];
  productQuantity?: number; // Quantity of the main product selected by the user
}

interface AvailableBattery {
  sage_reference: string;
  installation_sage_reference: string;
  price_with_tax: number;
  price_without_tax: number;
  installation_price_with_tax: number;
  installation_price_without_tax: number;
  tva_percentage: number;
  installation_tva_percentage: number;
  capacity_kWh: number;
  alias?: string | null;
}
interface KitHomeManagement {
  tva_percentage: number;
  installation_tva_percentage: number;
  installation_price_with_tax: number;
  installation_price_without_tax: number;
  price_with_tax: number;
  price_without_tax: number;
  sage_reference: string;
  installation_sage_reference: string;
  alias?: string | null;
}

export interface IAvailableProducts {
  available_products: IDataProduct[];
}

export type TProjectPages =
  | "product_page_from_catalogue"
  | "product_page_from_project_page"
  | "product_catalogue"
  | "project";

export interface IPerformanceRG {
  annual_electric_consumption_kWh: number;
  annual_electric_production_kWh: number;
  annual_electric_production_without_shadow_casting_kWh: number;
  daily_selfconsumption: IDailySelfConsumptionData;
  days_of_autosufficiency: number;
  energy_gain: number;
  hourly_consumption_production: IHourlyConsumptionProductionData;
  network_consumption: number;
  new_energy_label: string;
  new_energy_value: number;
  new_ges_emissions: number;
  new_ges_label: string;
  new_label: string;
  old_energy_label: string;
  old_energy_value: number;
  old_ges_emissions: number;
  old_ges_label: string;
  old_label: string;
  selfconsumption_rate: number;
  selfproduction_rate: number;
  solar_consumption: number;
  solar_production_consumed_rate: number;
  solar_production_stored_rate: number;
}

export interface IProfitabilityRG {
  energy_prices: {
    electricite: number[];
    gaz: number[];
    fioul: number[];
    solaire: number[];
  };
  savings: {
    heating: number[];
    ecs: number[];
    autoconsumption: number[];
    resale: number[];
  };
  remaining_amount_to_pay: 1546;
  cumulated_gain: 18000;
}

export interface IProjectKPI {
  energy_gain: number;
  is_eligible: boolean;
  label_gain: number;
  new_label: ILabel | string;
  product_helps: TSingleHelp[];
  remainder: number;
  reno_globale_helps: TSingleHelp[];
  rg_energy_gain: number;
  savings_on_bill: number;
  total_help: number;
  updated_products?: IDataProduct[];
}

export type IRenoGlobaleProductCategory =
  | "PV"
  | "PACAIRAIR"
  | "PACAIRO"
  | "ITE"
  | "ITI"
  | "LED"
  | "OTHER"
  | "CET"
  | "ISO"
  | "ISOP"
  | "ISOA"
  | "VMC";

export interface IRoomPacAirAir {
  room_id: string;
  exterior_unit_sage_reference: string | null;
  interior_units: InteriorUnitsPacAirAir[];
  room_height?: number;
}
export interface WindowProduct {
  id: string;
  sageReference: string | null;
  height?: number;
  width?: number;
  type?: string | null;
  isOpen: boolean;
  comment?: string;
  category: WindowType;
}

// The values of the keys of this object are used in the CRM in the description section of the window product.
export enum WindowType {
  FENETRE = "fenêtre",
  PORTE_FENETRE = "porte-fenêtre",
}

export interface InteriorUnitsPacAirAir {
  room_id: string;
  interior_unit_id: string;
  interior_unit_sage_reference?: string | null;
  interior_unit_installation_sage_reference?: string | null;
  interior_unit_TVA_percentage?: number | null;
  interior_unit_installation_TVA_percentage?: number | null;
  interior_unit_installation_price_with_tax?: number | null;
  interior_unit_installation_price_without_tax?: number | null;
  interior_unit_price_with_tax?: number | null;
  interior_unit_price_without_tax?: number | null;
  name?: string;
  surface_to_heat?: number;
  alias?: string | null;
}

export type Option = {
  label: string | null;
  value: string | null;
};

export type ImageOption = Option & {
  imgSrc?: string;
  imgAltTxt?: string;
};

export type ProductFormFilterOption = Option & {
  sageProductKey: keyof ISageProduct;
};
