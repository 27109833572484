//App reducer actions types
export const SET_USER_AUTH_INFO = "SET_USER_AUTH_INFO";
export const SET_COMMERCIAL_USER_INFORMATION =
  "SET_COMMERCIAL_USER_INFORMATION";
export const SET_USER_PROFILE_INFO = "SET_USER_PROFILE_INFO";
export const SET_BECQE_CUSTOMER_INFO = "SET_BECQE_CUSTOMER_INFO";
export const SET_INITAL_STATE = "SET_INITAL_STATE";
export const SET_MON_POTENTIEL_SOLAIRE_INFO = "SET_MON_POTENTIEL_SOLAIRE_INFO";
export const SET_MES_SCENARIOS_INFO = "SET_MES_SCENARIOS_INFO";
export const SET_MON_POTENTIEL_SOLAIRE_KO_INFO =
  "SET_MON_POTENTIEL_SOLAIRE_KO_INFO";
export const SET_INDEX_SCENARIOS_OPTION_CHOSEN =
  "SET_INDEX_SCENARIOS_OPTION_CHOSEN";
export const SET_PERSONALIZED_SCENARIO = "SET_PERSONALIZED_SCENARIO";
export const SET_DIMENSIONNEMENT = "SET_DIMENSIONNEMENT";
export const SET_PERFORMANCE = "SET_PERFORMANCE";
export const SET_PROFITABILITY = "SET_PROFITABILITY";
export const SET_IMPACTENVIRONNEMENTAL = "SET_IMPACTENVIRONNEMENTAL";
export const SET_ACTIVE_STUDY = "SET_ACTIVE_STUDY";
export const RESET_ALL_STUDY_INFO = "RESET_ALL_STUDY_INFO";
export const SET_STUDY_INFO = "SET_STUDY_INFO";
export const SET_SAGE_ITEMS_INFO = "SET_SAGE_ITEMS_INFO";
export const SET_SCENARIO_CHOSEN = "SET_SCENARIO_CHOSEN";
export const SET_CART_PRODUCTS = "SET_CART_PRODUCTS";
export const SET_RG_PROJECT_KPI = "SET_RG_PROJECT_KPI";
export const SET_SOLAR_POTENTIAL_STEPS_STATUS =
  "SET_SOLAR_POTENTIAL_STEPS_STATUS";
export const SET_COMPANY_SETTINGS = "SET_COMPANY_SETTINGS";
