// Services import
import { productPrices } from "./calculateProductPrice";

// Interfaces import
import { HelpsOptions } from "../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IProjectKPI,
} from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  finalCartProducts: IDataProduct[];
  coupons?: {
    label: string;
    type: string;
    value: number;
  }[];
  projectKPI: IProjectKPI | null;
  devisOptions?: HelpsOptions;
}

export const calculateRGDevisPrices = ({
  finalCartProducts,
  coupons,
  projectKPI,
  devisOptions,
}: IProps) => {
  // ------ Coupons ---------
  const totalValueCoupon =
    coupons
      ?.filter((coupon) => coupon.type !== "percentage")
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      ) || 0;

  const {
    totalDevisWithoutTax,
    totalDevisWithTaxes,
    totalHelp: sumOfTheHelpsPerProduct,
    restToPay: sumOfTheRestToPayPerProduct,
  } = finalCartProducts.reduce(
    (accumulator, product) => {
      const {
        totalPriceWhithoutTax,
        totalPriceWithTax,
        totalResteACharge,
        totalHelp,
        totalDeductibleHelps,
        totalRefundableHelps,
        totalHelpCEE,
        totalHelpMPR,
        totalHelpOther,
      } = productPrices({
        product,
        isEligibleRenoGlobal: projectKPI?.is_eligible ?? false,
        devisOptions,
      });
      accumulator.totalDevisWithoutTax =
        accumulator.totalDevisWithoutTax + totalPriceWhithoutTax;
      accumulator.totalDevisWithTaxes =
        accumulator.totalDevisWithTaxes + totalPriceWithTax;
      accumulator.restToPay = accumulator.restToPay + totalResteACharge;
      accumulator.totalHelp = accumulator.totalHelp + totalHelp;
      accumulator.totalProductsDeductibleHelps =
        accumulator.totalProductsDeductibleHelps + totalDeductibleHelps;
      accumulator.totalProductsRefundableHelps =
        accumulator.totalProductsRefundableHelps + totalRefundableHelps;
      accumulator.totalProductsCEEHelps =
        accumulator.totalProductsCEEHelps + totalHelpCEE;
      accumulator.totalProductsMPRHelps =
        accumulator.totalProductsMPRHelps + totalHelpMPR;
      accumulator.totalProductsOtherHelps =
        accumulator.totalProductsRefundableHelps + totalHelpOther;
      return accumulator;
    },
    {
      totalDevisWithoutTax: 0,
      totalDevisWithTaxes: 0,
      restToPay: 0,
      totalHelp: 0,
      totalProductsDeductibleHelps: 0,
      totalProductsRefundableHelps: 0,
      totalProductsCEEHelps: 0,
      totalProductsMPRHelps: 0,
      totalProductsOtherHelps: 0,
    }
  );

  const totalTVADevis = totalDevisWithTaxes - totalDevisWithoutTax;

  // If the cart is eligible to to MPR rénovation d'ampleur, the helps are not calculated per product but for the whole cart
  const renoDAmpleurMPRHelp = projectKPI?.is_eligible
    ? projectKPI?.reno_globale_helps.reduce((acc, help) => acc + help.value, 0)
    : 0;
  const totalMPRHelp = projectKPI?.is_eligible
    ? projectKPI?.reno_globale_helps
        .filter((help) => help.help_type === "mpr")
        .reduce((acc, help) => acc + help.value, 0) || 0
    : projectKPI?.product_helps
        .filter((help) => help.is_eligible && help.help_type === "mpr")
        .reduce((acc, help) => acc + help.value, 0) || 0;

  // If a project is eligible to a renovation d'ampleur, the CEE are not applicable anymore
  const totalCEEHelp = projectKPI?.is_eligible
    ? 0
    : projectKPI?.product_helps
        .filter((help) => help.is_eligible && help.help_type === "cee")
        .reduce((acc, help) => acc + help.value, 0) || 0;

  const totalOtherHelp =
    projectKPI?.product_helps
      .filter(
        (help) =>
          help.is_eligible &&
          help.help_type !== "mpr" &&
          help.help_type !== "cee"
      )
      .reduce((acc, help) => acc + help.value, 0) || 0;

  // Only CEE and MPR helps can be deductible for now
  const totalDeductibleHelps =
    (devisOptions?.isCEEHelpsDeducted ? totalCEEHelp : 0) +
    (devisOptions?.isMPRHelpsDeducted ? totalMPRHelp : 0);

  // You can not add MPR and CEE helps with a Reno d'Ampleur help. Otherwise you can add the other type of helps.
  const totalRefundableHelps =
    totalOtherHelp +
    (!devisOptions?.isCEEHelpsDeducted ? totalCEEHelp : 0) +
    (!devisOptions?.isMPRHelpsDeducted ? totalMPRHelp : 0);

  const totalHelp = projectKPI?.is_eligible
    ? renoDAmpleurMPRHelp + totalOtherHelp
    : sumOfTheHelpsPerProduct;

  // If MPR helps are deductible, we substract the renoDAmpleurMPRHelp from the rest to pay (not the other MPR help as they are already substracted from the product price in the productPrices function).
  const restToPay =
    sumOfTheRestToPayPerProduct -
    totalValueCoupon -
    (devisOptions?.isMPRHelpsDeducted ? renoDAmpleurMPRHelp : 0);

  const totalProjectCostHelpsDeducted =
    totalDevisWithTaxes - totalHelp - totalValueCoupon;

  return {
    totalDevisWithoutTax: Math.round(totalDevisWithoutTax * 100) / 100,
    totalDevisWithTaxes: Math.round(totalDevisWithTaxes * 100) / 100,
    totalTVADevis: Math.round(totalTVADevis * 100) / 100,
    totalHelp: Math.round(totalHelp * 100) / 100,
    totalValueCoupon: Math.round(totalValueCoupon * 100) / 100,
    restToPay: Math.round(restToPay * 100) / 100,
    totalMPRHelp: Math.round(totalMPRHelp * 100) / 100,
    totalCEEHelp: Math.round(totalCEEHelp * 100) / 100,
    totalDeductibleHelps: Math.round(totalDeductibleHelps * 100) / 100,
    totalRefundableHelps: Math.round(totalRefundableHelps * 100) / 100,
    totalOtherHelp: Math.round(totalOtherHelp * 100) / 100,
    totalProjectCostHelpsDeducted:
      Math.round(totalProjectCostHelpsDeducted * 100) / 100,
  };
};
