import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment"; // require

// Components
import { PdfCaracteristiqueToit } from "../../components/Pdf/Pdf-Etude/PdfCaracteristiqueToit/PdfCaracteristiqueToit";
import { PdfCaracteristiqueToitOneImage } from "../../components/Pdf/Pdf-Etude/PdfCaracteristiqueToitOneImage/PdfCaracteristiqueToitOneImage";
import { PdfConsommationsElectriques } from "../../components/Pdf/Pdf-Etude/PdfConsommationsElectriques/PdfConsommationsElectriques";
import { PdfContainerGraphRentabilite } from "../../components/Pdf/Pdf-Etude/PdfContainerGraphRentabilite/PdfContainerGraphRentabilite";
import { PdfContentFormuleP1 } from "../../components/Pdf/Pdf-Etude/PdfContentFormuleP1/PdfContentFormuleP1";
import { PdfDoubleCard } from "../../components/Pdf/Pdf-Etude/PdfDoubleCard/PdfDoubleCard";
import { PdfEncadreAvecLogo } from "../../components/Pdf/Pdf-Etude/PdfEncadreAvecLogo/PdfEncadreAvecLogo";
import { PdfEncadreBlancText } from "../../components/Pdf/Pdf-Etude/PdfEncadreBlancText/PdfEncadreBlancText";
import { PdfEtape } from "../../components/Pdf/Pdf-Etude/PdfEtape/PdfEtape";
import { PdfEvolutionPrix } from "../../components/Pdf/Pdf-Etude/PdfEvolutionPrix/PdfEvolutionPrix";
import { PdfHeader } from "../../components/Pdf/Pdf-Etude/PdfHeader/PdfHeader";
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfOnePan } from "../../components/Pdf/Pdf-Etude/PdfOnePan/PdfOnePan";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";
import { PdfPerson } from "../../components/Pdf/Pdf-Etude/PdfPerson/PdfPerson";
import { PdfReference } from "../../components/Pdf/Pdf-Etude/PdfReference/PdfReference";
import { PdfSecondTitle } from "../../components/Pdf/Pdf-Etude/PdfSecondTitle/PdfSecondTitle";
import { PdfText } from "../../components/Pdf/Pdf-Etude/PdfText/PdfText";
import { PdfTextItalic } from "../../components/Pdf/Pdf-Etude/PdfTextItalic/PdfTextItalic";
import { PdfThirdTitle } from "../../components/Pdf/Pdf-Etude/PdfThirdTitle/PdfThirdTitle";
import { PdfTitreDetail } from "../../components/Pdf/Pdf-Etude/PdfTitreDetail/PdfTitreDetail";
import { PdfTitreGris } from "../../components/Pdf/Pdf-Etude/PdfTitreGris/PdfTitreGris";

// Services import
import { calculatePrixMoyenKwHReseau } from "../../services/MonInstallation/calculatePrixMoyenKwHReseau";

// Data import
import { ElectricityPrice } from "@web/shared/dist/datas/dataHypotethis";
import { DSColors } from "@web/shared/src/styles/variables";

// Interfaces import
import {
  HelpsOptions,
  ICommercialUserInformation,
  IMonInstallation,
  IOreeStudyType,
  ISageProduct,
  IScenario,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";

// Fonts
import DMSansBold from "../../assets/pdf/fonts/DMSansBold.ttf";
import DMSansItalic from "../../assets/pdf/fonts/DMSansItalic.ttf";
import DMSansMedium from "../../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../../assets/pdf/fonts/DMSansRegular.ttf";

// Images
import co2 from "../../assets/pdf/images/co2.png";
import couvertureBas from "../../assets/pdf/images/couvertureBas.png";
import couvertureHaut from "../../assets/pdf/images/couvertureHaut.png";
import economieFacture from "../../assets/pdf/images/economieFacture.png";
import encartParrainage from "../../assets/pdf/images/encart-parrainage.png";
import espaceClient from "../../assets/pdf/images/espace-client.png";
import evolutionPrix from "../../assets/pdf/images/evolutionPrix.png";
import impactEnvironnemental from "../../assets/pdf/images/impactEnvironnemental.png";
import impactEnvironnementalBlue from "../../assets/pdf/images/impactEnvironnementalBlue.png";
import independance from "../../assets/pdf/images/independance.png";
import montagne1 from "../../assets/pdf/images/montagne1.png";
import performanceBlue from "../../assets/pdf/images/performanceBlue.png";
import performanceHomeManagement from "../../assets/pdf/images/performanceHomeManagement.png";
import rentabilite from "../../assets/pdf/images/rentabilite.png";
import toit3 from "../../assets/pdf/images/toit3.png";
import vousEtesIci from "../../assets/pdf/images/vous-etes-ici.png";
import { IProjectKPI } from "../../interfaces/renoglobaleInterface";
import { PdfEtudePresentationMisterMenuiserie } from "../PdfEtudeRG/PdfEtudePresentationMisterMenuiserie";
import { PdfEtudePresentationTuco } from "../PdfEtudeRG/PdfEtudePresentationTuco";
import { PdfEtudeHelpsPage } from "./PdfEtudePageAides";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});
Font.register({
  family: "DMSansItalic",
  src: DMSansItalic,
  format: "truetype",
});

// Create styles
export const pdfEtudePvStyles = StyleSheet.create({
  page: {
    color: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    fontFamily: "DMSansRegular",
  },
  pagePadding: {
    color: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    fontFamily: "DMSansRegular",
    paddingBottom: 50,
  },
  pagePaddingZero: {
    color: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    fontFamily: "DMSansRegular",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  containerTitles: {
    marginTop: 50,
    marginBottom: 50,
  },
  titlePdf: {
    fontSize: 38,
    fontFamily: "DMSansBold",
    color: `${DSColors.OffBlack}`,
  },
  subtitlePdf: {
    fontSize: 28,
    fontFamily: "DMSansMedium",
    color: `${DSColors.PlanetGreen}`,
    lineHeight: 1,
  },
  imageCouvertureBas: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  containerInfos: {},
  containerPoseurs: {
    display: "flex",
    flexDirection: "row",
    marginTop: 120,
    alignItems: "center",
  },
  containerPoseurs__left: {
    width: "50%",
  },
  containerPoseurs__right: {
    width: "50%",
    position: "absolute",
    right: -58,
  },

  containerPoseurs__left__titles: {
    marginBottom: 20,
  },
  containerPoseurs__left__titles__title: {
    fontSize: 28,
    color: `${DSColors.OffBlack}`,
    fontFamily: "DMSansBold",
  },
  containerPoseurs__left__titles__subtitle: {
    fontSize: 20,
    color: `${DSColors.PlanetGreen}`,
    fontFamily: "DMSansBold",
  },
  containerPoseurs__left__descriptions: {
    fontSize: 9,
  },
  containerPoseurs__left__descriptions__description: {
    marginBottom: 5,
  },
  containerStatistiques: {
    backgroundColor: "#e0e7fa",
    padding: "20 68",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 60,
  },
  containerStatistiques__element: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 9,
    width: "47%",
    height: "auto",
    padding: 10,
  },
  containerStatistiques__element__image: {
    width: 40,
    height: 40,
    objectFit: "contain",
    marginRight: 8,
  },
  containerStatistiques__element__text: {
    display: "flex",
    width: "100%",
  },
  certifications: {
    marginTop: 20,
  },
  certifications__title: {
    fontSize: 12,
    color: "#50545f",
    fontFamily: "DMSansBold",
    marginBottom: 10,
  },
  certifications__containerImages: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  certifications__containerImages__image: {
    width: "25%",
    maxHeight: "80",
    objectFit: "contain",
  },
  containerVotreEtude: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  arrowNext: {
    marginTop: 30,
    width: 40,
  },
  prochainesEtapes: {
    marginTop: 70,
    display: "flex",
  },
  prochainesEtapes__titles: {
    marginBottom: 10,
  },
  prochainesEtapes__description: {
    fontSize: 9,
    marginBottom: 20,
  },
  prochainesEtapes__premiereEtape: {
    marginLeft: 40,
  },
  prochainesEtapes__containerCode: {
    marginTop: 20,
  },
  prochainesEtapes__containerCode__text: {
    position: "absolute",
    fontSize: 9,
    color: "#08B77F",
    right: 48,
    top: 70,
  },
  prochainesEtapes__premiereEtape__Image: {
    position: "absolute",
    width: 100,
    right: 25,
    top: "50%",
    transform: "translate(0%, -50%)",
  },
  prochainesEtapes__premiereEtape__Arrow: {
    position: "absolute",
    right: 165,
    top: 12,
    width: 70,
  },

  //ELEMENT FOR TABLE
  table: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    width: "33.33%",
    padding: 5,
  },
});

interface IProps {
  studyInformation: IStudyInformation;
  emailInputValue: string;
  firstNameInputValue: string;
  lastNameInputValue: string;
  phoneNumberInputValue: string;
  imagePotentielSolaire1: string;
  imagePotentielSolaire2: string;
  imagePotentielSolaire3: string;
  imageTotalGain: string;
  imagePerformance: string;
  imagePerformanceIndependance: string;
  imagePerformanceIndependance2: string;
  commercialUserInformation: ICommercialUserInformation;
  monInstallation: IMonInstallation;
  co2Emission: number | undefined;
  scenarioChosen: IScenario;
  documentId: string;
  SAGEItemsInfo: ISageProduct[];
  isDemoVersion?: boolean;
  devisOptions: HelpsOptions;
  RGProjectKPI: IProjectKPI | null;
}

export const PdfEtude = ({
  studyInformation,
  emailInputValue,
  firstNameInputValue,
  lastNameInputValue,
  phoneNumberInputValue,
  imagePotentielSolaire1,
  imagePotentielSolaire2,
  imagePotentielSolaire3,
  imageTotalGain,
  imagePerformance,
  imagePerformanceIndependance,
  imagePerformanceIndependance2,
  commercialUserInformation,
  monInstallation,
  co2Emission,
  scenarioChosen,
  documentId,
  SAGEItemsInfo,
  isDemoVersion = false,
  devisOptions,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const dataForIndependenceLevelComponent = {
    selfproductionRate:
      monInstallation?.performance?.selfproduction_rate || null,
    solarProductionConsumed:
      monInstallation?.performance?.solar_production_consumed_rate || null,
    solarProductionStored:
      monInstallation?.performance?.solar_production_stored_rate || null,
  };

  const dataForSelfSufficiencyComponent = {
    dataChart: monInstallation?.performance?.daily_selfconsumption || null,
    daysOfAutosufficiency:
      monInstallation?.performance?.days_of_autosufficiency || null,
  };

  const prixMoyenKwHReseau = calculatePrixMoyenKwHReseau({
    economies: monInstallation.profitability?.economies,
    number_of_years: 25,
  });

  return (
    <Document>
      {/* FIRST PAGE */}
      <Page size="A4" style={pdfEtudePvStyles.page}>
        <Image src={couvertureHaut} />
        <PdfLayoutEtude isDemoVersion={isDemoVersion}>
          <View>
            <View style={pdfEtudePvStyles.containerTitles}>
              <Text style={pdfEtudePvStyles.titlePdf}>
                Étude photovoltaïque
              </Text>
              <Text style={pdfEtudePvStyles.subtitlePdf}>
                Produisez et consommez
              </Text>
              <Text style={pdfEtudePvStyles.subtitlePdf}>
                votre propre électricité
              </Text>
            </View>
            <View style={pdfEtudePvStyles.containerInfos}>
              <PdfReference reference={documentId} />
              <PdfPerson
                title={`${capitalize(firstNameInputValue)} ${capitalize(
                  lastNameInputValue
                )}`}
                data={[
                  <>
                    {studyInformation.address}, {studyInformation.zipcode}
                  </>,
                  <>{emailInputValue.toLowerCase()}</>,
                  <>{phoneNumberInputValue}</>,
                ]}
              />
              <PdfPerson
                title={"Votre expert énergétique :"}
                data={[
                  <>
                    {capitalize(commercialUserInformation.userFirstName)}{" "}
                    {capitalize(commercialUserInformation.userLastName)}
                  </>,
                  <>{commercialUserInformation.userEmail?.toLowerCase()}</>,
                  <>{commercialUserInformation.userPhoneNumber}</>,
                ]}
              />
            </View>
          </View>
        </PdfLayoutEtude>
        <Image
          style={pdfEtudePvStyles.imageCouvertureBas}
          src={couvertureBas}
        />
      </Page>
      {/* PRESNTATION DE TUCO */}
      {/* Following page is specific to Tuco and should not be displayed for other companies */}
      {commercialUserInformation.userCompany?.toLocaleLowerCase() === "tuco" ? (
        <PdfEtudePresentationTuco
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          commercialUserInformation={commercialUserInformation}
          documentId={documentId}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
      {/* PRESNTATION DE MISTER MENUISERIE */}
      {/* Following page is specific to Mister Menuiserie and should not be displayed for other companies */}
      {commercialUserInformation.userCompany
        ?.toLocaleLowerCase()
        .includes("menuiserie") ? (
        <PdfEtudePresentationMisterMenuiserie
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          isDemoVersion={isDemoVersion}
        />
      ) : null}

      {/* ETUDE PERSONNALISEE */}
      <Page size="A4" style={pdfEtudePvStyles.page}>
        <PdfHeader />
        <PdfLayoutEtude isDemoVersion={isDemoVersion}>
          <View style={pdfEtudePvStyles.containerVotreEtude}>
            <View style={pdfEtudePvStyles.containerPoseurs__left__titles}>
              <Text
                style={pdfEtudePvStyles.containerPoseurs__left__titles__title}
              >
                Votre étude
              </Text>
              <Text
                style={
                  pdfEtudePvStyles.containerPoseurs__left__titles__subtitle
                }
              >
                solaire personnalisée
              </Text>
            </View>
            <View style={pdfEtudePvStyles.containerPoseurs__left__descriptions}>
              <Text
                style={
                  pdfEtudePvStyles.containerPoseurs__left__descriptions__description
                }
              >
                Merci d’avoir réalisé une étude énergétique avec{" "}
                {commercialUserInformation.userCompany?.toLocaleUpperCase()} !
              </Text>
              <Text
                style={
                  pdfEtudePvStyles.containerPoseurs__left__descriptions__description
                }
              >
                Retrouvez dans ce document votre étude complète personnalisée
                comprenant :
              </Text>
              <View style={{ marginBottom: 8 }}>
                <Text>- l'analyse de votre potentiel solaire,</Text>
                <Text>- l'analyse de vos consommations électriques,</Text>
                <Text>- le détail de votre projet solaire.</Text>
              </View>
              <Text
                style={[
                  pdfEtudePvStyles.containerPoseurs__left__descriptions__description,
                  { fontFamily: "DMSansBold", marginBottom: 8 },
                ]}
              >
                Un devis détaillé de votre projet accompagne ce document.
              </Text>
              <Text
                style={
                  pdfEtudePvStyles.containerPoseurs__left__descriptions__description
                }
              >
                N’hésitez à recontacter votre expert énergétique pour plus
                d’informations.
              </Text>
              {/* <Image style={styles.arrowNext} src={fleche} /> */}
            </View>
          </View>
        </PdfLayoutEtude>
        <Image style={pdfEtudePvStyles.imageCouvertureBas} src={montagne1} />
        <PdfPagination
          isWhite
          date={`${moment().format("DD/MM/YYYY")}`}
          prenom={capitalize(firstNameInputValue)}
          nom={capitalize(lastNameInputValue)}
          reference={documentId}
        />
      </Page>

      {/* POTENTIEL SOLAIRE */}
      {studyInformation.solar_potential_image ? (
        <Page size="A4" style={pdfEtudePvStyles.pagePadding}>
          <PdfHeader isMargeBottom />
          <PdfLayoutEtude isDemoVersion={isDemoVersion}>
            {studyInformation.solar_potential_image &&
              studyInformation.roof_sections && (
                <>
                  <PdfTitreDetail
                    titleData={[<>Potentiel solaire</>, <>de votre toiture</>]}
                  />
                  <PdfTitreGris title="Caractéristiques du toit" />
                  <PdfCaracteristiqueToit
                    image1={imagePotentielSolaire3}
                    image2={imagePotentielSolaire1}
                    image3={toit3}
                    pans={studyInformation?.roof_sections?.length ?? 0}
                    surface={studyInformation.roof_area_to_equip}
                    material={studyInformation.roof_type}
                  />
                  <PdfTitreGris title="Potentiel par pan" />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {studyInformation?.roof_sections?.map(
                      (pan, index, _key) => {
                        return (
                          <PdfOnePan
                            number={(index + 1).toString()}
                            key={index}
                            orientation={pan.orientation ?? null}
                            surface={pan.area_m2 ?? null}
                            pente={pan.slope_degrees ?? null}
                          />
                        );
                      }
                    )}
                  </View>
                  <PdfTextItalic
                    text="L’analyse de votre toiture a été réalisée à partir de notre outil d’étude énergétique. Les données de votre toiture sont des estimations que vous
  avez confirmées. La géolocalisation de votre habitat couplée à la technologie de l’outil d’estimation de production photovoltaïque PVGIS a permis
  d’estimer le potentiel solaire de votre habitat en vue d’une installation photovoltaïque. "
                  />
                  <View wrap={false}>
                    <PdfTitreDetail
                      titleData={[<>Vos consommations</>, <>électriques</>]}
                    />
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      {monInstallation.performance
                        ?.annual_electric_consumption_kWh && (
                        <PdfEncadreAvecLogo
                          logo={economieFacture}
                          title={"CONSOMMATION ÉLECTRIQUE ANNUELLE :"}
                          text={`${monInstallation.performance?.annual_electric_consumption_kWh} kWh`}
                        />
                      )}
                      {co2Emission && (
                        <PdfEncadreAvecLogo
                          logo={co2}
                          title={"ÉMISSIONS CARBONE LIÉES"}
                          text={`${co2Emission} CO2/kWh`}
                        />
                      )}
                    </View>
                    <PdfText
                      text={`Ces données de consommation électriques que nous utilisons afin d’établir nos recommandations sont des données déclaratives du propriétaire du logement`}
                    />
                  </View>
                </>
              )}

            {!studyInformation.solar_potential_image &&
              studyInformation.roof_sections && (
                <>
                  <View wrap={false}>
                    <PdfTitreDetail
                      titleData={[
                        <>Potentiel solaire</>,
                        <>de votre toiture</>,
                      ]}
                    />
                    <PdfTitreGris title="Caractéristiques du toit" />
                    <PdfCaracteristiqueToitOneImage
                      couverture={studyInformation.roof_type}
                      inclinaison={studyInformation.roof_inclinaison}
                      exposition={studyInformation.roof_exposition}
                      impactMasqueSolaire={studyInformation.roof_solar_mask}
                      image1={imagePotentielSolaire3}
                    />
                    <PdfTextItalic text="L’analyse de votre toiture a été réalisée à partir de notre outil d’étude énergétique. Les données de votre toiture sont des estimations que vous avez confirmées. La géolocalisation de votre habitat couplée à la technologie de l’outil d’estimation de production photovoltaïque PVGIS a permis d’estimer le potentiel solaire de votre habitat en vue d’une installation photovoltaïque. " />
                  </View>

                  {monInstallation.performance
                    ?.annual_electric_consumption_kWh &&
                    studyInformation.number_of_occupants && (
                      <View wrap={false}>
                        <PdfTitreDetail
                          titleData={[<>Vos consommations</>, <>électriques</>]}
                        />
                        {monInstallation.performance
                          ?.annual_electric_consumption_kWh && (
                          <PdfEncadreAvecLogo
                            logo={economieFacture}
                            title={"CONSOMMATION ÉLECTRIQUE ANNUELLE :"}
                            text={`${monInstallation.performance?.annual_electric_consumption_kWh} kWh`}
                          />
                        )}
                        <PdfConsommationsElectriques
                          occupants={studyInformation.number_of_occupants}
                          surface={studyInformation.house_surface}
                          chauffeEauElectrique={
                            studyInformation.is_electric_water_heater
                              ? "Oui"
                              : "Non"
                          }
                          chauffageElectrique={
                            studyInformation.is_electric_heater ? "Oui" : "Non"
                          }
                          autresEquipements={[
                            <>Climatisation</>,
                            <>Piscine</>,
                            <>Voiture électrique</>,
                          ]}
                        />
                        <PdfTextItalic
                          text="Ces données de consommation électriques que nous utilisons afin d’établir nos recommandations sont des données déclaratives du propriétaire
                du logement"
                        />
                      </View>
                    )}
                </>
              )}
          </PdfLayoutEtude>
          <PdfPagination
            date={`${moment().format("DD/MM/YYYY")}`}
            prenom={capitalize(firstNameInputValue)}
            nom={capitalize(lastNameInputValue)}
            reference={documentId}
          />
        </Page>
      ) : null}

      {/* FORMULE CHOISIE */}
      <Page size="A4" style={pdfEtudePvStyles.pagePadding}>
        <PdfHeader isMargeTop isMargeBottom />
        <PdfLayoutEtude isDemoVersion={isDemoVersion}>
          <PdfTitreDetail
            titleData={[
              <>Vous avez choisi la</>,
              <>
                formule{" "}
                <Text style={{ fontFamily: "DMSansBold" }}>
                  {scenarioChosen.power_needed &&
                    (scenarioChosen.formule_name || "personalisable") +
                      " " +
                      scenarioChosen.power_needed / 1000 +
                      " kWc"}
                </Text>
              </>,
            ]}
          />
          <PdfSecondTitle text={"Récapitulatif de la formule"} />
          <PdfContentFormuleP1
            panneaux={scenarioChosen.number_of_panels}
            pitch={scenarioChosen.pitch}
            inverter_brand={scenarioChosen.inverter_brand}
            puissance={
              scenarioChosen.power_needed && scenarioChosen.power_needed / 1000
            }
            kitHome={scenarioChosen.kit_home_management ? 1 : null}
            batterie={
              scenarioChosen.number_of_batteries
                ? scenarioChosen.number_of_batteries
                : null
            }
            image1={imagePotentielSolaire2}
            nameKitHome={SAGEItemsInfo.filter(
              (item) => item.AR_Ref === scenarioChosen.kit_home_management
            ).map((item) => item.AR_Design)}
            nameBatterie={SAGEItemsInfo.filter(
              (item) => item.FA_CodeFamille === "BATTERIE"
            ).map((item) => item.Marque)}
            SAGEItemsInfo={SAGEItemsInfo}
            options={scenarioChosen.options || undefined}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
              gap: 7.5,
            }}
          >
            {scenarioChosen.formule_name !== "Sérénité" ? (
              <PdfEncadreAvecLogo
                logo={independance}
                title={"TAUX D'AUTOPRODUCTION"}
                text={
                  dataForIndependenceLevelComponent.selfproductionRate === 1 &&
                  dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                  dataForSelfSufficiencyComponent.daysOfAutosufficiency < 365
                    ? "99 %"
                    : dataForIndependenceLevelComponent.selfproductionRate &&
                      (
                        dataForIndependenceLevelComponent.selfproductionRate *
                        100
                      )
                        .toFixed(0)
                        .toString() + " %"
                }
              />
            ) : (
              <PdfEncadreAvecLogo
                logo={independance}
                title={"TAUX D'AUTOCONSOMMATION"}
                text={
                  scenarioChosen.selfconsumption_rate &&
                  (scenarioChosen.selfconsumption_rate * 100)
                    .toFixed()
                    .toString() + " %"
                }
              />
            )}
            <PdfEncadreAvecLogo
              logo={economieFacture}
              title={"ÉCONOMIES SUR 25 ANS"}
              text={scenarioChosen["25_years_economies"] + " €"}
            />
            {scenarioChosen.formule_name !== "Liberté+" &&
              scenarioChosen.formule_name !== "Liberté" &&
              scenarioChosen.formule_name !== "Sérénité" && (
                <PdfEncadreAvecLogo
                  logo={economieFacture}
                  title={"AUTOCONSOMMATION"}
                  text={
                    scenarioChosen.selfconsumption_rate &&
                    (scenarioChosen.selfconsumption_rate * 100)
                      .toFixed()
                      .toString() + " %"
                  }
                />
              )}
          </View>
          <PdfTextItalic
            text="Notre recommandation est établie à partir des éléments fournis et confirmés par le propriétaire du logement lors de son étude énergétique et en fonction des performances énergétiques communiquées par les fabricants sur leurs produits. Ces estimations sont données à titre indicatif et ne sont pas contractuelles. Les formules comprenant un home management prennent également en compte des modifications de comportement de consommation électrique du propriétaire du logement. Une visite technique sera réalisée avant l’installation pour confirmer la faisabilité technique de ce projet."
            noMargin
          />
        </PdfLayoutEtude>
        <PdfPagination
          date={`${moment().format("DD/MM/YYYY")}`}
          prenom={capitalize(firstNameInputValue)}
          nom={capitalize(lastNameInputValue)}
          reference={documentId}
        />
      </Page>

      {/* FORMULE EN DETAIL */}
      <Page size="A4" style={pdfEtudePvStyles.pagePadding}>
        <PdfHeader isMargeBottom />
        <PdfThirdTitle logo={performanceBlue} text={"Performance"} />
        <PdfLayoutEtude isDemoVersion={isDemoVersion}>
          <PdfTitreGris title="Le photovoltaïque, comment ça marche ?" />
          <PdfText text="Grâce à votre installation solaire, vous produisez et consommez votre propre énergie tout au long de la journée." />
          <View style={{ position: "relative" }}>
            <View
              style={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <Image
                style={{
                  width: 320,
                  height: 200,
                  objectFit: "contain",
                  padding: "20 0 0 5",
                }}
                src={imagePerformance}
              />
            </View>

            <Image
              style={{ zIndex: "-1", position: "relative" }}
              src={performanceHomeManagement}
            />
          </View>
          <View style={{ marginTop: 20 }}>
            <PdfText text="Découvrez les perfomances de votre projet." />
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {scenarioChosen.formule_name === "Liberté+" ? (
                <Image
                  style={{
                    objectFit: "contain",
                    width: 200,
                  }}
                  src={imagePerformanceIndependance}
                />
              ) : scenarioChosen.formule_name === "Liberté" ? (
                <Image
                  style={{
                    objectFit: "contain",
                    width: 200,
                  }}
                  src={imagePerformanceIndependance}
                />
              ) : scenarioChosen.formule_name === "Sérénité" ? (
                <Image
                  style={{
                    objectFit: "contain",
                    width: 200,
                  }}
                  src={imagePerformanceIndependance2}
                />
              ) : (
                <>
                  <Image
                    style={{
                      objectFit: "contain",
                      width: 200,
                    }}
                    src={imagePerformanceIndependance}
                  />
                  <Image
                    style={{
                      objectFit: "contain",
                      width: 200,
                    }}
                    src={imagePerformanceIndependance2}
                  />
                </>
              )}

              {scenarioChosen.formule_name === "Sérénité" ||
              scenarioChosen.formule_name === "Liberté" ||
              scenarioChosen.formule_name === "Liberté+" ? (
                <View style={{ display: "flex", width: "48.5%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: 8,
                      backgroundColor: "white",
                      border: "2px solid #e9eaeb",
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        fontSize: 9,
                      }}
                    >
                      {monInstallation.performance
                        ?.annual_electric_production_kWh && (
                        <View
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 0,
                            }}
                          >
                            <Text>PRODUCTION SOLAIRE ANNUELLE</Text>
                            <Text
                              style={{
                                fontSize: 11,
                                fontFamily: "DMSansMedium",
                              }}
                            >
                              {
                                monInstallation.performance
                                  ?.annual_electric_production_kWh
                              }{" "}
                              kWh
                            </Text>
                          </View>
                          <PdfTextItalic text="Électricité produite annuellement grâce à votre installation" />
                        </View>
                      )}

                      {scenarioChosen.formule_name === "Sérénité" &&
                        monInstallation.performance?.solar_consumption && (
                          <View>
                            <View
                              style={{
                                marginBottom: 5,
                              }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: 0,
                                }}
                              >
                                <Text>AUTOCONSOMMATION</Text>
                                <Text
                                  style={{
                                    fontSize: 11,
                                    fontFamily: "DMSansMedium",
                                  }}
                                >
                                  {
                                    monInstallation.performance
                                      ?.solar_consumption
                                  }
                                  kWh
                                </Text>
                              </View>
                              <PdfTextItalic
                                noMargin
                                text="Production solaire utilisée pour votre propre consommation électrique."
                              />
                            </View>
                            <View
                              style={{
                                marginBottom: 5,
                              }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: 0,
                                }}
                              >
                                <Text>REVENTE</Text>
                                <Text
                                  style={{
                                    fontSize: 11,
                                    fontFamily: "DMSansMedium",
                                  }}
                                >
                                  {monInstallation.performance
                                    ?.annual_electric_production_kWh -
                                    monInstallation.performance
                                      ?.solar_consumption}
                                  kWh
                                </Text>
                              </View>
                              <PdfTextItalic
                                noMargin
                                text="Production solaire revendue à EDF."
                              />
                            </View>
                          </View>
                        )}
                      {scenarioChosen.formule_name !== "Sérénité" &&
                        monInstallation.performance
                          ?.annual_electric_consumption_kWh && (
                          <View
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 0,
                              }}
                            >
                              <Text>CONSOMMATION SOLAIRE</Text>
                              <Text
                                style={{
                                  fontSize: 11,
                                  fontFamily: "DMSansMedium",
                                }}
                              >
                                {monInstallation.performance?.solar_consumption}
                                kWh
                              </Text>
                            </View>
                            <PdfTextItalic
                              noMargin
                              text="Production solaire utilisée pour votre propre consommation électrique."
                            />
                          </View>
                        )}
                      {/* {monInstallation.performance
                        ?.annual_electric_production_kWh &&
                        monInstallation.performance
                          ?.annual_electric_consumption_kWh && (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Text>REVENTE PAS ENCORE PAR LA DATA</Text>
                            <Text
                              style={{
                                fontSize: 11,
                                fontFamily: "DMSansMedium",
                              }}
                            >
                              {(
                                (monInstallation.performance
                                  ?.annual_electric_production_kWh -
                                  monInstallation.performance
                                    ?.annual_electric_consumption_kWh) /
                                100
                              ).toFixed(2)}{" "}
                              kWh
                            </Text>
                          </View>
                        )} */}
                    </View>
                  </View>
                  {scenarioChosen.formule_name === "Sérénité" ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 8,
                        backgroundColor: "white",
                        border: "2px solid #e9eaeb",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      {scenarioChosen.selfproduction_rate && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: 9,
                          }}
                        >
                          <Text>Taux d'autoproduction</Text>
                          <Text
                            style={{
                              fontFamily: "DMSansBold",
                              fontSize: 14,
                              margin: "0 0",
                            }}
                          >
                            {scenarioChosen.selfproduction_rate &&
                              (
                                scenarioChosen.selfproduction_rate * 100
                              ).toFixed(0)}{" "}
                            %
                          </Text>
                          {scenarioChosen.selfproduction_rate && (
                            <Text>
                              de votre consommation électrique est couverte par
                              votre production solaire. Le reste de votre
                              consommation (
                              {(
                                100 -
                                scenarioChosen.selfproduction_rate * 100
                              ).toFixed(0)}
                              %) utilise le réseau électrique.
                            </Text>
                          )}
                        </View>
                      )}
                    </View>
                  ) : scenarioChosen.formule_name === "Liberté" ||
                    scenarioChosen.formule_name === "Liberté+" ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 8,
                        backgroundColor: "white",
                        border: "2px solid #e9eaeb",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      {scenarioChosen.selfproduction_rate && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: 9,
                          }}
                        >
                          <Text>Taux d’autoconsommation</Text>
                          <Text
                            style={{
                              fontFamily: "DMSansMedium",
                              fontSize: 12,
                              margin: "0 0",
                            }}
                          >
                            {scenarioChosen.selfconsumption_rate &&
                              (
                                scenarioChosen.selfconsumption_rate * 100
                              ).toFixed(0)}{" "}
                            %
                          </Text>
                          {scenarioChosen.selfconsumption_rate && (
                            <Text>
                              de votre production solaire est utilisé pour votre
                              propre consommation électrique. Le reste de votre
                              production (
                              {(
                                100 -
                                scenarioChosen.selfconsumption_rate * 100
                              ).toFixed(0)}
                              %) est injecté dans le réseau électrique.
                            </Text>
                          )}
                        </View>
                      )}
                    </View>
                  ) : null}

                  {monInstallation.performance?.days_of_autosufficiency && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 8,
                        backgroundColor: "white",
                        border: "2px solid #e9eaeb",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Text style={{ fontSize: 9 }}>
                        {monInstallation.performance?.days_of_autosufficiency}{" "}
                        jours d'autosuffisance, sans recourir au réseau
                        électrique
                      </Text>
                    </View>
                  )}
                </View>
              ) : null}
            </View>
            {scenarioChosen.formule_name !== "Liberté" &&
              scenarioChosen.formule_name !== "Liberté+" &&
              scenarioChosen.formule_name !== "Sérénité" && (
                <View break style={{ display: "flex", width: "100%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: 8,
                      backgroundColor: "white",
                      border: "2px solid #e9eaeb",
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontSize: 9,
                        gap: 15,
                      }}
                    >
                      {monInstallation.performance
                        ?.annual_electric_production_kWh && (
                        <View
                          style={{
                            marginBottom: 0,
                            width: "50%",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 0,
                            }}
                          >
                            <Text>PRODUCTION SOLAIRE ANNUELLE</Text>
                            <Text
                              style={{
                                fontSize: 11,
                                fontFamily: "DMSansMedium",
                              }}
                            >
                              {
                                monInstallation.performance
                                  ?.annual_electric_production_kWh
                              }{" "}
                              kWh
                            </Text>
                          </View>
                          <PdfTextItalic text="Électricité produite annuellement grâce à votre installation" />
                        </View>
                      )}

                      <View
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 0,
                          }}
                        >
                          <Text>CONSOMMATION SOLAIRE</Text>
                          <Text
                            style={{
                              fontSize: 11,
                              fontFamily: "DMSansMedium",
                            }}
                          >
                            {monInstallation.performance?.solar_consumption}
                            kWh
                          </Text>
                        </View>
                        <PdfTextItalic
                          noMargin
                          text="Production solaire utilisée pour votre propre consommation électrique."
                        />
                      </View>
                    </View>
                  </View>
                  {scenarioChosen.formule_name === "Sérénité" ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 8,
                        backgroundColor: "white",
                        border: "2px solid #e9eaeb",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      {scenarioChosen.selfproduction_rate && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: 9,
                          }}
                        >
                          <Text>Taux d'autoproduction</Text>
                          <Text
                            style={{
                              fontFamily: "DMSansMedium",
                              fontSize: 12,
                              margin: "0 0",
                            }}
                          >
                            {scenarioChosen.selfproduction_rate &&
                              (
                                scenarioChosen.selfproduction_rate * 100
                              ).toFixed(0)}{" "}
                            %
                          </Text>
                          {scenarioChosen.selfproduction_rate && (
                            <Text>
                              de votre consommation est couverte par votre
                              installation. Le reste de votre consommation (
                              {(
                                100 -
                                scenarioChosen.selfproduction_rate * 100
                              ).toFixed(0)}
                              %) utilise l’électricité du réseau électrique.
                            </Text>
                          )}
                        </View>
                      )}
                    </View>
                  ) : scenarioChosen.formule_name !== "Essentielle" ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 8,
                        backgroundColor: "white",
                        border: "2px solid #e9eaeb",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      {scenarioChosen.selfproduction_rate && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: 9,
                          }}
                        >
                          <Text>Taux d’autoconsommation</Text>
                          <Text
                            style={{
                              fontFamily: "DMSansMedium",
                              fontSize: 12,
                              margin: "0 0",
                            }}
                          >
                            {scenarioChosen.selfconsumption_rate &&
                              (
                                scenarioChosen.selfconsumption_rate * 100
                              ).toFixed(0)}{" "}
                            %
                          </Text>
                          {scenarioChosen.selfconsumption_rate && (
                            <Text>
                              de votre production solaire est utilisé pour votre
                              propre consommation électrique. Le reste de votre
                              production (
                              {(
                                100 -
                                scenarioChosen.selfconsumption_rate * 100
                              ).toFixed(0)}
                              %) est injecté dans le réseau électrique.
                            </Text>
                          )}
                        </View>
                      )}
                    </View>
                  ) : null}
                  {monInstallation.performance?.days_of_autosufficiency && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 8,
                        backgroundColor: "white",
                        border: "2px solid #e9eaeb",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Text style={{ fontSize: 9 }}>
                        {monInstallation.performance?.days_of_autosufficiency}{" "}
                        jours d'autosuffisance, sans recourir au réseau
                        électrique
                      </Text>
                    </View>
                  )}
                </View>
              )}
          </View>
        </PdfLayoutEtude>
        <PdfPagination
          date={`${moment().format("DD/MM/YYYY")}`}
          prenom={capitalize(firstNameInputValue)}
          nom={capitalize(lastNameInputValue)}
          reference={documentId}
        />
      </Page>
      {/* </>
          ))} */}

      {/* RENTABILITE */}
      <Page size="A4" style={pdfEtudePvStyles.pagePadding}>
        <PdfHeader isMargeBottom />
        <PdfThirdTitle logo={rentabilite} text={"Rentabilité"} />
        <PdfLayoutEtude isDemoVersion={isDemoVersion}>
          <PdfTitreGris title="Gains cumulés" />
          <PdfText text="Investir dans le solaire est un projet écoresponsable mais aussi rentable ! Vous trouverez ci-dessous une simulation de vos gains basés sur 25 ans (garantie de votre production solaire optimale, 80% minimum)." />

          <PdfContainerGraphRentabilite image={imageTotalGain} />
          <PdfDoubleCard />
          <PdfTextItalic
            text="Les estimations chiffrées de votre formule ont été calculées à partir des éléments fournis et confirmés par le propriétaire du logement lors de son
                étude énergétique et en fonction des performances énergétiques communiquées par les fabricants sur leurs produits. Les formules comprenant
                un home management prennent également en compte des modifications de comportement de consommation électrique du propriétaire du
                logement. Elles sont données à titre indicatif et ne sont pas contractuelles."
          />
          <PdfEvolutionPrix
            prixActuel={
              monInstallation.profitability?.economies?.[0]?.electricity_network_price?.toFixed(
                2
              ) || ElectricityPrice.toFixed(2)
            }
            prixMoyenReseau={prixMoyenKwHReseau.toFixed(2)}
            prixMoyenSolaire={
              monInstallation.profitability?.economies?.[0]?.electricity_solar_price?.toFixed(
                2
              ) || "0,13"
            }
            image={evolutionPrix}
          />
          <View wrap={false}>
            <PdfTitreGris title="Rentabilité" />
            <PdfText text="Pour financer votre projet solaire, plusieurs options s’offrent à vous : paiement comptant ou recours à un organisme de financement. " />
            <PdfEncadreBlancText
              items={[
                {
                  question: devisOptions.isHelpsDisplayed
                    ? "Coût de votre projet (aides déduites) : "
                    : "Coût de votre projet : ",
                  reponse: devisOptions.isHelpsDisplayed
                    ? scenarioChosen.total_cost_help_deducted + ` €`
                    : scenarioChosen.total_cost_without_help + ` €`,
                },
                {
                  question: "Rentabilité annuelle : ",
                  reponse:
                    scenarioChosen.internal_rate_return &&
                    Number(scenarioChosen.internal_rate_return * 100)
                      .toFixed(0)
                      .toString() + " %",
                },
                {
                  question: "Durée d’amortissement : ",
                  reponse:
                    monInstallation.profitability?.project_depreciation_period.toString() +
                    " année(s)",
                },
              ]}
            />
            <PdfTextItalic
              text="La simulation de vos gains et votre rentabilité a été calculée à partir des estimations de performance de votre projet mentionnées précédemment.
              Cette simulation est donnée à titre indicatif et n’est pas contractuelle."
            />
          </View>
        </PdfLayoutEtude>

        <PdfPagination
          date={`${moment().format("DD/MM/YYYY")}`}
          prenom={capitalize(firstNameInputValue)}
          nom={capitalize(lastNameInputValue)}
          reference={documentId}
        />
      </Page>

      {/* IMPACT ENVIRONNEMENTAL */}
      <Page size="A4" style={pdfEtudePvStyles.pagePaddingZero}>
        <View style={{ height: "100vh" }}>
          <PdfHeader isMargeBottom />
          <PdfThirdTitle
            logo={impactEnvironnementalBlue}
            text={"Impact environnemental"}
          />
          <PdfLayoutEtude isDemoVersion={isDemoVersion}>
            <PdfText text="Grâce à l'installation de panneaux photovoltaïques,  vous réduisez de façon notable votre empreinte carbone ! À titre de comparaison, un français émet en moyenne  8,9 tCO2/an." />
          </PdfLayoutEtude>

          <View
            style={{
              backgroundColor: "#eaeaeb",
              height: "100%",
              marginTop: 50,
            }}
          >
            <View
              style={{
                width: "80%",
                margin: "-50 auto 30",
              }}
            >
              <Image src={impactEnvironnemental} />
              <View
                style={{
                  position: "absolute",
                  top: 45,
                  left: 60,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "DMSansBold",
                    color: "white",
                  }}
                >
                  {monInstallation.impactEnvironnemental?.CO2_equivalents.CO2_savings_25years.toFixed(
                    0
                  )}{" "}
                  tCO2 évitées sur 25 ans
                </Text>
              </View>
              <View
                style={{
                  position: "absolute",
                  left: "51%",
                  bottom: 165,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "DMSansBold",
                  }}
                >
                  {monInstallation.impactEnvironnemental?.CO2_equivalents.km_car.value.toFixed(
                    0
                  )}{" "}
                  {
                    monInstallation.impactEnvironnemental?.CO2_equivalents
                      .km_car.label
                  }
                </Text>
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  parcourus en voiture thermique.
                </Text>
              </View>
              <View
                style={{
                  position: "absolute",
                  left: "51%",
                  bottom: 35,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "DMSansBold",
                  }}
                >
                  {monInstallation.impactEnvironnemental?.CO2_equivalents.heating_consumption.value.toFixed(
                    0
                  )}{" "}
                  {
                    monInstallation.impactEnvironnemental?.CO2_equivalents
                      .heating_consumption.label
                  }
                </Text>
                <Text
                  style={{
                    fontSize: 9,
                  }}
                >
                  de consommation de chauffage.
                </Text>
              </View>
            </View>
            <PdfLayoutEtude isDemoVersion={isDemoVersion}>
              <PdfTextItalic text="L'impact environnemental de votre projet se calcule à partir de la réduction d'émissions de gaz à effet de serre induite par la production d'électricité renouvelable de votre installation photovoltaïque. Un kWh que vous produisez a une teneur en carbone inférieure à un kWh tiré du réseau électrique. Pour calculer cet impact, nous nous basons sur les données de l'Ademe." />
            </PdfLayoutEtude>
          </View>
          <PdfPagination
            date={`${moment().format("DD/MM/YYYY")}`}
            prenom={capitalize(firstNameInputValue)}
            nom={capitalize(lastNameInputValue)}
            reference={documentId}
          />
        </View>
      </Page>

      {/* AIDES */}
      {devisOptions.isHelpsDisplayed ? (
        <PdfEtudeHelpsPage
          documentId={documentId}
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          studyInformation={studyInformation}
          scenarioChosen={scenarioChosen}
          studyType={IOreeStudyType.PHOTOVOLTAIQUE}
        />
      ) : null}

      {/* PROCHAINES ETAPES */}
      {/* Following page is specific to Tuco : shloud not be displayed with other companies */}
      {commercialUserInformation.userCompany?.toLocaleLowerCase() === "tuco" ? (
        <Page size="A4" style={pdfEtudePvStyles.page}>
          <PdfHeader />;
          <PdfLayoutEtude isDemoVersion={isDemoVersion}>
            <View style={pdfEtudePvStyles.prochainesEtapes}>
              <View style={pdfEtudePvStyles.prochainesEtapes__titles}>
                <Text
                  style={pdfEtudePvStyles.containerPoseurs__left__titles__title}
                >
                  Les prochaines étapes
                </Text>
                <Text
                  style={
                    pdfEtudePvStyles.containerPoseurs__left__titles__subtitle
                  }
                >
                  de votre projet
                </Text>
              </View>
              <View style={pdfEtudePvStyles.prochainesEtapes__description}>
                <Text>
                  Vous pouvez désormais suivre toute l’avancée de votre projet
                  sur votre
                </Text>
                <Text>Espace Client TUCO.</Text>
              </View>
              <View style={pdfEtudePvStyles.prochainesEtapes__premiereEtape}>
                <PdfEtape
                  number="1."
                  title="Étude énergétique"
                  description="Retrouvez votre étude et votre devis sur votre espace client."
                  isBlue
                />
                <Image
                  style={
                    pdfEtudePvStyles.prochainesEtapes__premiereEtape__Arrow
                  }
                  src={vousEtesIci}
                />
                <Image
                  style={
                    pdfEtudePvStyles.prochainesEtapes__premiereEtape__Image
                  }
                  src={espaceClient}
                />
              </View>
              <PdfEtape
                number="2."
                title="Signature"
                description="Signez votre bon de commande et démarrez votre projet !"
              />
              <PdfEtape
                number="3."
                title="Installation"
                description="Planifiez avec votre chargé de projet la date de votre installation. Pendant ce temps, nos équipes s'occupent de réaliser toutes vos démarches administratives."
                isBlue
              />
              <PdfEtape
                number="4."
                title="Bravo, vous êtes équipé !"
                description="Profitez de votre installation et consommez votre propre énergie. Vous pourrez suivre toutes les performances de votre centrale sur une plateforme dédiée."
              />
              <View style={pdfEtudePvStyles.prochainesEtapes__containerCode}>
                <Image src={encartParrainage} />
                <Text
                  style={pdfEtudePvStyles.prochainesEtapes__containerCode__text}
                >
                  {studyInformation.membership_code
                    ? studyInformation.membership_code
                    : "Demandez à votre conseiller"}
                </Text>
              </View>
            </View>
          </PdfLayoutEtude>
          <PdfPagination
            date={`${moment().format("DD/MM/YYYY")}`}
            prenom={capitalize(firstNameInputValue)}
            nom={capitalize(lastNameInputValue)}
            reference={documentId}
          />
        </Page>
      ) : null}
    </Document>
  );
};
