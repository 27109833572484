import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  container__containerInfos__lign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    paddingBottom: 8,
    paddingTop: 8,
    borderBottom: "1px solid #a8aaaf",
  },
  container__containerInfos__lign__left: {},
  container__containerInfos__lign__right: {},
});

interface Props {
  title: string;
  description?: string;
  value: string;
}
export const PdfContentFormuleLign = ({ title, description, value }: Props) => {
  return (
    <View style={styles.container__containerInfos__lign}>
      <View style={styles.container__containerInfos__lign__left}>
        <Text>{title}</Text>
        <View style={{ marginLeft: 10, color: "#50545f", maxWidth: 200 }}>
          <Text>{description}</Text>
        </View>
      </View>
      <View style={styles.container__containerInfos__lign__right}>
        <Text style={{ fontFamily: "DMSansBold" }}>{value}</Text>
      </View>
    </View>
  );
};
