//Styles import
import { DSColors } from "@web/shared/src/styles/variables";
import "./PVProductPage.scss";

// Libraries import
import { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";

// Components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { Dualite } from "@web/shared/dist/components/DesignSystem/Boutons/Dualite/Dualite";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";
import { ProductFilterContainer } from "../filterComponent/ProductFilterContainer";

// Services import
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { formatDropdownOptionsFromSageProducts } from "../../../services/RenovationGlobale/formatDropdownOptionsFromSageProducts";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import {
  IAvailableCalepinages,
  IAvailablePower,
  InverterBrand,
  ISageProduct,
} from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  SelectedCharacteristics,
  TFraisSupp,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";
import { useGetAvailableCalepinagesForPersonalizedScenarios } from "../../../services/MonInstallation/useGetAvailableCalepinagesForPersonalizedScenarios";
import { formatSageProductsForDropdown } from "../../../services/RenovationGlobale/formatProductSelectedForDropdown";

// Local interfaces declaration
interface IFilterSAGEProducts {
  sageProduct: ISageProduct[];
  sageProductCharacteristic?: keyof ISageProduct;
  powerSelected: IOption[];
  brandSelected: IOption[];
  inverterTechnologySelected: IOption[];
}

interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay?: IDataProduct[] | undefined;
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const PVProductPage = ({
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  // HOOKS CALLS
  const dispatch = useAppDispatch();
  const {
    SAGEItemsInfo: productsFromSage,
    studyInformation,
    userAuthInfo,
    cartProducts: cartProductsToDisplay,
  } = useAppSelector((state) => state);

  const {
    getAvailableCalepinagesForPersonalizedScenarios,
    isLoading: isLoadingGetAvailableCalepinages,
  } = useGetAvailableCalepinagesForPersonalizedScenarios();

  // STATES DECLARATION
  const [productSelected, setProductSelected] = useState<IOption[]>(
    (selectedProductIndex || selectedProductIndex === 0) &&
      cartProductsToDisplay
      ? [
          {
            label: cartProductsToDisplay[selectedProductIndex].product_name,
            value: cartProductsToDisplay[selectedProductIndex].sage_reference,
          },
        ]
      : []
  );
  const [availableCalepinages, setAvailableCalepinages] =
    useState<IAvailableCalepinages | null>(null);
  const [PVPowerSelected, setPVPowerSelected] = useState<IOption[]>([]);
  const [brand, setBrand] = useState<IOption[]>([]);
  const [inventerTechnology, setInventerTechnology] = useState<IOption[]>([]);
  const [batteryFullName, setBatteryFullName] = useState<string>( // Battery full name value format must match the values'format of the dropdown (cf. availableBatteries variable below)
    (selectedProductIndex || selectedProductIndex === 0) &&
      cartProductsToDisplay[selectedProductIndex] &&
      cartProductsToDisplay[selectedProductIndex]?.selected_characteristics
        .n_batteries
      ? `${
          cartProductsToDisplay[selectedProductIndex]?.product_characteristics
            ?.inverter_brand || ""
        } ${
          cartProductsToDisplay[selectedProductIndex].selected_characteristics
            .n_batteries
        } x ${
          cartProductsToDisplay[selectedProductIndex].selected_characteristics
            .single_battery_capacity_kWh
        }kWh`
      : ""
  );
  const [isKitHomeManagement, setIsKitHomeManagement] = useState<boolean>(
    (selectedProductIndex || selectedProductIndex === 0) &&
      cartProductsToDisplay
      ? cartProductsToDisplay[selectedProductIndex].selected_characteristics
          .kit_home_management || false
      : false
  );
  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    (selectedProductIndex || selectedProductIndex === 0) &&
      cartProductsToDisplay
      ? cartProductsToDisplay[selectedProductIndex].extra_charges_added || []
      : []
  );
  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);

  // FUNCTIONS DECLARATION
  const getAvailableCalepinages = async () => {
    const availableCalepinagesResponse =
      await getAvailableCalepinagesForPersonalizedScenarios({
        household_id: studyInformation?.household_id || "",
        userToken: userAuthInfo.userToken || "",
        study_id: studyInformation?.study_id || "",
        building_id: studyInformation?.building_id || "",
      });

    availableCalepinagesResponse &&
      setAvailableCalepinages(availableCalepinagesResponse);
  };

  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product selected we can not add it to the selectin
    }

    //If we have an selectedProductIndex it means the product is already in the products to diplay : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProductsToDisplay];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProductsToDisplay, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  // Function used to filter SAGE products according to the values entered in the dropdown
  // We filter the product list to display only the SAGE products matching the dropdown value
  // Note : the dropdown value should not filter its own product_characteristics to be able to select another product_characteristics after selecting a first one
  const filterSageProductsAccordingToDropdownValues = ({
    sageProduct,
    sageProductCharacteristic,
    powerSelected,
    brandSelected,
    inverterTechnologySelected,
  }: IFilterSAGEProducts) => {
    return sageProduct.filter((product) => {
      return (
        (sageProductCharacteristic === "Puissance" ||
          product.Puissance?.toString() ===
            (powerSelected?.[0]?.value ?? product.Puissance?.toString())) &&
        (sageProductCharacteristic === "Marque_pannel" ||
          product.Marque_pannel?.toString() ===
            (brandSelected?.[0]?.value ?? product.Marque_pannel?.toString())) &&
        (sageProductCharacteristic === "inverter_technology" ||
          product.inverter_technology?.toString() ===
            (inverterTechnologySelected?.[0]?.value ??
              product.inverter_technology?.toString()))
      );
    });
  };

  // VARIABLES DECLARATION

  // We filter the SAGE products to only keep the SAGE PV products that are in the dataProductsFromCategory products list from data backend (= products that are elligible for the house)
  const sagePvProducts = productsFromSage.filter((itemInfo) =>
    dataProductsFromCategory.some(
      (dataProduct) => dataProduct.sage_reference === itemInfo.AR_Ref
    )
  );

  // Then we filter the PV SAGE products to only keep the ones that exist in the available calepinages list (= that fit in the roof's surface and orientation)
  const sagePvProductsEligibles = useMemo(
    () =>
      sagePvProducts.filter(
        (pvSageProduct) =>
          pvSageProduct.Puissance &&
          availableCalepinages &&
          // We check if the power of the pv kit is available
          availableCalepinages.available_calepinages?.[
            pvSageProduct.Puissance?.toString() as IAvailablePower
          ] &&
          // Then we check if the brand of the inverter is available for this power (some of the inverter brands are not available for all the powers)
          // We check the pvSageProduct.AR_Design key and not the pvSageProduct.inverter_technology key as SAGE DB info are not perfectly maintained and it can be empty
          Object.keys(
            availableCalepinages.available_calepinages?.[
              pvSageProduct.Puissance?.toString() as IAvailablePower
            ]
          ).some((inverter_brand) =>
            pvSageProduct.AR_Design?.toLowerCase().includes(
              inverter_brand.toLowerCase()
            )
          )
      ),
    [sagePvProducts, availableCalepinages]
  );

  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProductsFromCategory
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.find((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: filterSageProductsAccordingToDropdownValues({
      sageProduct: sagePvProductsEligibles,
      brandSelected: brand,
      inverterTechnologySelected: inventerTechnology,
      powerSelected: PVPowerSelected,
    }),
    dataProducts: dataProductsFromCategory,
  });

  const productToAdd = dataProductsFromCategory.find(
    (product) => product.sage_reference === productSelected?.[0]?.value
  );

  // We get the calepinage information according to the power and the inverter brand of the selected PV product
  const calepinageFoundForSelectedPVProduct =
    availableCalepinages?.available_calepinages?.[
      productToAdd?.product_characteristics?.power?.toString() as IAvailablePower
    ]?.[
      productToAdd?.product_characteristics?.inverter_brand?.toString() as InverterBrand
    ];

  const availableBatteries =
    calepinageFoundForSelectedPVProduct?.battery_information
      ?.filter((battery) => battery.n_batteries !== 0)
      ?.map((battery) => {
        return {
          // Exemple 1 x 3kWh
          label: `${
            productToAdd?.product_characteristics?.inverter_brand || ""
          } ${battery.n_batteries} x ${battery.single_battery_capacity_kWh}kWh`,
          value: `${
            productToAdd?.product_characteristics?.inverter_brand || ""
          } ${battery.n_batteries} x ${battery.single_battery_capacity_kWh}kWh`,
          single_battery_capacity_kWh: battery.single_battery_capacity_kWh,
          n_batteries: battery.n_batteries,
          sage_reference: battery.battery_sage_reference,
        };
      }) || [];

  // The availableCalepinage determine if a kit home management is available for a given power and inverter brand
  // through an array that can be [true, false] (=optional) or [true] (=mandatory) or [false] (=not available)
  const kitHomeManagementAvailability =
    calepinageFoundForSelectedPVProduct?.kit_home_management || [];

  const isKitHomeManagementMandatory =
    kitHomeManagementAvailability.length === 1 &&
    kitHomeManagementAvailability[0] === true;

  // At each rendering we update the selected characteristics of the productToAdd with the values selected in the form
  (() => {
    if (!productToAdd) return null;
    const selectedBattery = availableBatteries.find(
      (battery) => battery.value === batteryFullName
    );

    const selectedBatteryDATAProduct =
      productToAdd.product_characteristics.available_batteries?.find(
        (battery) => battery.sage_reference === selectedBattery?.sage_reference
      );

    const selectedCharacteristics: SelectedCharacteristics = {
      ...productToAdd?.selected_characteristics,
      n_batteries: selectedBattery?.n_batteries || 0,
      battery_sage_reference: selectedBattery?.sage_reference,
      battery_installation_sage_reference:
        selectedBatteryDATAProduct?.installation_sage_reference,
      single_battery_capacity_kWh:
        selectedBattery?.single_battery_capacity_kWh || 0,
      kit_home_management: isKitHomeManagementMandatory || isKitHomeManagement, // If the kit home management is mandatory (inverter brand = enphase) we force it to true
    };
    productToAdd.selected_characteristics = selectedCharacteristics;
    productToAdd.extra_charges_added = fraisSupp;
    productToAdd.isProductRecommended = false; // By default when we add a product to the cart
    productToAdd.isSelected = true; // By default when we add a product to the cart
  })();

  // COMPONENT LIFE CYCLE
  useEffect(() => {
    getAvailableCalepinages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // COMPONENT RENDER
  if (isLoadingGetAvailableCalepinages)
    return (
      <div className="PVProductPage__LoaderContainer">
        <p>Chargement des calepinages disponibles pour la toiture ...</p>
        <LoadingComponent diameter={60} />
      </div>
    );

  if (sagePvProductsEligibles.length === 0)
    return <div>Pas de produits disponible</div>;

  return (
    <div className="PVProductPage">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />
      <section className="PVProductPage__FormContainer">
        <div className="PVProductPage__SubTitle">
          <h3>Produit : </h3>
        </div>
        <div className="PVProductPage__ProductContainer">
          <div className="PVProductPage__Dropdown">
            <Dropdown
              options={sageProductFormatedForDropdown}
              optionsSelected={productSelected}
              onSelect={(option) => {
                manageDropdownSingleSelection(option, setProductSelected);
                setBatteryFullName("");
              }}
              multipleChoices={false}
              tags={false}
              placeholder="Choisir mon installation"
              maxHeight="40vh"
              searchable={true}
            />
          </div>
          <div className="PVProductPage__SVG">
            {productSelected?.[0]?.value ? (
              <SVG src={Trash} onClick={() => setProductSelected([])} />
            ) : null}
          </div>
        </div>
        <ProductFilterContainer>
          <div className="PVProductPage__FiltersContent">
            <div className="PVProductPage__QuestionContainer">
              <div className="PVProductPage__Question">
                <div className="PVProductPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts: filterSageProductsAccordingToDropdownValues(
                        {
                          sageProduct: sagePvProductsEligibles,
                          sageProductCharacteristic: "Puissance",
                          brandSelected: brand,
                          inverterTechnologySelected: inventerTechnology,
                          powerSelected: PVPowerSelected,
                        }
                      ).filter((product) => product.FA_CodeFamille === "PV"),
                      productCharacteristic: "Puissance",
                    })}
                    optionsSelected={PVPowerSelected}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setPVPowerSelected);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Puissance"
                  />
                </div>
                <div className="PVProductPage__SVG">
                  {PVPowerSelected?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setPVPowerSelected([])} />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="PVProductPage__QuestionContainer">
              <div className="PVProductPage__Question">
                <div className="PVProductPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts: filterSageProductsAccordingToDropdownValues(
                        {
                          sageProduct: sagePvProductsEligibles,
                          sageProductCharacteristic: "Marque_pannel",
                          brandSelected: brand,
                          inverterTechnologySelected: inventerTechnology,
                          powerSelected: PVPowerSelected,
                        }
                      ),
                      productCharacteristic: "Marque_pannel",
                    })}
                    optionsSelected={brand}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(option, setBrand);
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Marque"
                  />
                </div>
                <div className="PVProductPage__SVG">
                  {brand?.[0]?.value ? (
                    <SVG src={Trash} onClick={() => setBrand([])} />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="PVProductPage__QuestionContainer">
              <div className="PVProductPage__Question">
                <div className="PVProductPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts: filterSageProductsAccordingToDropdownValues(
                        {
                          sageProduct: sagePvProductsEligibles,
                          sageProductCharacteristic: "inverter_technology",
                          brandSelected: brand,
                          inverterTechnologySelected: inventerTechnology,
                          powerSelected: PVPowerSelected,
                        }
                      ),
                      productCharacteristic: "inverter_technology",
                    })}
                    optionsSelected={inventerTechnology}
                    onSelect={(option) => {
                      manageDropdownSingleSelection(
                        option,
                        setInventerTechnology
                      );
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Technologie Onduleur"
                  />
                </div>
                <div className="PVProductPage__SVG">
                  {inventerTechnology?.[0]?.value ? (
                    <SVG
                      src={Trash}
                      onClick={() => setInventerTechnology([])}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ProductFilterContainer>
        <div className="PVProductPage__QuantiteContainer">
          <label>
            Nombre de module :{" "}
            {productToAdd?.product_characteristics.power &&
              (
                Number(productToAdd.product_characteristics.power) / 500
              ).toString()}
          </label>
        </div>

        <div className="PVProductPage__SubTitle">
          <h3>Options : </h3>
        </div>
        {availableBatteries.length > 0 ? (
          <div className="PVProductPage__DropdownContainer">
            <label>Batterie de stockage :</label>
            <div className="PVProductPage__Question">
              <div className="PVProductPage__Dropdown">
                <DropdownV2
                  placeholder="Selectionner..."
                  options={availableBatteries}
                  values={batteryFullName ? [batteryFullName] : []}
                  onSelect={(option) => {
                    setBatteryFullName(option.value?.toString() || "");
                  }}
                  isRequired={true}
                />
              </div>
              <div className="PVProductPage__SVG">
                {batteryFullName ? (
                  <SVG src={Trash} onClick={() => setBatteryFullName("")} />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {kitHomeManagementAvailability.length > 0 ? (
          <div className="PVProductPage__DualiteContainer">
            <label>Kit de Home Management :</label>
            <div className="PVProductPage__Dualite">
              <Dualite
                binaryValue={
                  isKitHomeManagementMandatory
                    ? isKitHomeManagementMandatory
                    : isKitHomeManagement
                }
                onSelect={(value) => setIsKitHomeManagement(value)}
                isRequired={true}
                isDisabled={kitHomeManagementAvailability.length === 1}
                messageIfDisabled={
                  isKitHomeManagementMandatory
                    ? "Le kit de Home Management est obligatoire pour cette marque"
                    : kitHomeManagementAvailability.length === 1
                    ? "Pas de kit disponible"
                    : ""
                }
              />
            </div>
          </div>
        ) : null}
      </section>
      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}
      {fraisSupp.length > 0 ? (
        <section className="PVProductPage__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}
      <section className="PVProductPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {productToAdd ? (
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      ) : null}
    </div>
  );
};
