import "./ProductCard.scss";

// Components
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Design system
import arrowRight from "@web/shared/dist/assets/images/DesignSystemIcons/arrow-right.svg";
import { Checkbox } from "@web/shared/dist/components/DesignSystem/Checkboxes/Checkbox/Checkbox";

// Interfaces import
import {
  IDataProduct,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { productPrices } from "../../../services/RenovationGlobale/calculateProductPrice";
import { productsImagesDictionnary } from "../../../services/RenovationGlobale/productsImagesDictionnary";
import { productsNamesDictionnary } from "../../../services/RenovationGlobale/productsNamesDictionnary";
import { euroShortFormat } from "../../../services/euroFormatter";

// Local interface declaration
interface Props {
  productInfo: IDataProduct;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  setProductCategorySelected: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProductIndex: React.Dispatch<React.SetStateAction<number | null>>;
  product_index: number;
  isActive: boolean;
  isManageCartActive: boolean;
  isRecommendedProduct: boolean;
  isEligibleRenoGlobal?: boolean;
  isLoadingGetProjectKPI: boolean;
}

export const ProductCard = ({
  productInfo,
  setScreenSelected,
  setProductCategorySelected,
  setSelectedProductIndex,
  product_index,
  isActive = true,
  isManageCartActive,
  isRecommendedProduct,
  isEligibleRenoGlobal = true,
  isLoadingGetProjectKPI,
}: Props) => {
  const dispatch = useAppDispatch();
  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);
  const sageProducts = useAppSelector((state) => state.SAGEItemsInfo);

  const onClickOnProduct = ({
    product_type,
    product_index,
  }: {
    product_type?: string;
    product_index: number;
  }) => {
    setProductCategorySelected(product_type || "");
    setSelectedProductIndex(product_index);
    setScreenSelected("product_page_from_project_page");
  };

  const { totalPriceWithTax, totalHelp } = productPrices({
    product: productInfo,
    isEligibleRenoGlobal,
  });

  const onClickSelectProduct = () => {
    const newProductToDisplay = JSON.parse(
      JSON.stringify(cartProductsToDisplay)
    ); // Object deep copy
    if (isActive) {
      newProductToDisplay[product_index].isSelected = false;
    } else {
      newProductToDisplay[product_index].isSelected = true;
    }
    dispatch(
      setCartProductsAction({
        cartProducts: newProductToDisplay,
      })
    );
  };

  return (
    <div
      className={`ProductCard ${isActive ? "ProductCard--active" : ""}
       ${isRecommendedProduct ? "ProductCard--isRecommended" : ""}
      `}
    >
      <div
        className={`ProductCard__Priority ${
          isActive ? "ProductCard__Priority--active" : ""
        } ${isRecommendedProduct ? "" : "isNotRecommendedProduct"}`}
      >
        <p>
          {productInfo.product_type === "PV"
            ? "Optimisation"
            : "Priorité N° " + productInfo.recommendation_index}
        </p>
      </div>
      <div className="ProductCard__Checkbox">
        {isManageCartActive && (
          <Checkbox
            isChecked={isActive}
            onClick={() => onClickSelectProduct()}
          />
        )}
      </div>
      <div className="ProductCard__ImageContainer">
        <img
          src={productsImagesDictionnary({
            product_type: productInfo.product_type,
          })}
          alt={`produit ${productInfo.product_type}`}
        />
        <p className="ProductCard__Category">
          {productsNamesDictionnary({ product_type: productInfo.product_type })}
        </p>
        <h2 className="ProductCard__Title">
          {productInfo.product_alias || productInfo.product_name}
        </h2>

        <ProductCharacteristics
          productInfo={productInfo}
          sageProduct={sageProducts.find(
            (sageProduct) => sageProduct.AR_Ref === productInfo.sage_reference
          )}
        />

        {productInfo.extra_charges_added?.map((extraCharges, index) => {
          return (
            <p
              className="ProductCard__Description"
              key={index + extraCharges.sage_reference}
            >
              {extraCharges.product_name +
                " : " +
                euroShortFormat(extraCharges.price_with_tax)}
            </p>
          );
        })}
      </div>
      <div>
        <div className="ProductCard__ContainerChiffres">
          <h3>{euroShortFormat(totalPriceWithTax)}</h3>
          {!isLoadingGetProjectKPI && productInfo.product_type !== "AUTRE" ? (
            productInfo.product_type === "PV" || !isEligibleRenoGlobal ? (
              <>
                <h4 title={productInfo.help_breakdown}>
                  Aides : {euroShortFormat(totalHelp)}
                </h4>
                <br></br>
              </>
            ) : (
              <p className="ProductCard__ContainerHelpRG">
                <strong>Aides : </strong>
                <span>MaPrimeRénov' Rénovation d'ampleur</span>
              </p>
            )
          ) : (
            <>
              <br></br>
              <br></br>
            </>
          )}
        </div>
        <div className="ProductCard__Cta">
          <CTAButton
            name=""
            iconSrc={arrowRight}
            category={isActive ? "secondary" : "tertiary"}
            family={isActive ? "vitee" : "becqe"}
            onClick={() =>
              onClickOnProduct({
                product_type: productInfo.product_type,
                product_index,
              })
            }
            isDisabled={isManageCartActive}
          />
        </div>
      </div>
    </div>
  );
};

function ProductCharacteristics({
  productInfo,
  sageProduct,
}: {
  productInfo: IDataProduct;
  sageProduct?: ISageProduct;
}) {
  switch (productInfo.product_type) {
    case "PV":
      return (
        <>
          {productInfo.product_characteristics?.power ? (
            <>
              <p className="ProductCard__Description">{`Puissance : ${productInfo.product_characteristics.power}kW`}</p>
              <p className="ProductCard__Description">{`Nombre de panneaux : ${
                productInfo.product_characteristics.power / 500
              }`}</p>
            </>
          ) : null}

          {productInfo.selected_characteristics.kit_home_management === true ? (
            <p className="ProductCard__Description">
              {productInfo.product_characteristics.kit_home_management?.alias ||
                "Kit home management"}
            </p>
          ) : null}
          {productInfo.selected_characteristics?.n_batteries ? (
            <p className="ProductCard__Description">
              {`Batterie(s) : ${productInfo.selected_characteristics?.n_batteries}`}
            </p>
          ) : null}
        </>
      );
    case "CET":
    case "BALLON":
      return productInfo.product_characteristics?.ecs_storage_volume ? (
        <p className="ProductCard__Description">{`Capacité : ${productInfo.product_characteristics.ecs_storage_volume} L`}</p>
      ) : null;
    case "ITE":
    case "ISOA":
    case "ISO":
    case "ISOP":
      return productInfo.selected_characteristics?.surface_to_isolate ? (
        <p className="ProductCard__Description">{`Surface à isoler : ${Math.round(
          productInfo.selected_characteristics?.surface_to_isolate
        )} m²`}</p>
      ) : null;

    case "PACAIRO":
      return productInfo.product_characteristics?.power ? (
        <p className="ProductCard__Description">{`Puissance : ${productInfo.product_characteristics.power}kW`}</p>
      ) : null;
    case "PACAIRAIR":
      return (
        <>
          {productInfo.selected_characteristics?.surface_to_heat ? (
            <p className="ProductCard__Description">{`Surface à chauffer : ${Math.round(
              productInfo.selected_characteristics?.surface_to_heat
            )} m²`}</p>
          ) : null}
          {productInfo.selected_characteristics.rooms_information?.map((room) =>
            room.interior_units.map((interiorUnit) => (
              <p
                className="ProductCard__Description"
                key={interiorUnit.interior_unit_id}
              >
                {"+ " + (interiorUnit.alias || interiorUnit.name)}
              </p>
            ))
          )}
        </>
      );
    case "FENETRES":
      return (
        <>
          {productInfo.selected_characteristics?.productQuantity ? (
            <p className="ProductCard__Description">{`Quantité : ${Math.round(
              productInfo.selected_characteristics?.productQuantity
            )}`}</p>
          ) : null}
          {sageProduct?.Description ? (
            <p className="ProductCard__Description">
              {sageProduct.Description}
            </p>
          ) : null}
          {sageProduct?.Marque ? (
            <p className="ProductCard__Description">{`Marque : ${sageProduct.Marque}`}</p>
          ) : null}
          {sageProduct?.Gamme ? (
            <p className="ProductCard__Description">{`Gamme : ${sageProduct.Gamme}`}</p>
          ) : null}
          {sageProduct?.["Type de produit"] ? (
            <p className="ProductCard__Description">{`Type de vitrage : ${sageProduct["Type de produit"]}`}</p>
          ) : null}
        </>
      );
    case "AUTRE":
      return productInfo.selected_characteristics.free_product_description ? (
        <p className="ProductCard__Description">{`Description : ${productInfo.selected_characteristics.free_product_description}`}</p>
      ) : null;
    default:
      return null;
  }
}
