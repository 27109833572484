// Interface import
import {
  IYearlyExtraSales,
  IYearlySavings,
} from "../../interfaces/generalInterfaces";

// Loacal interface
interface IProps {
  economiesData: IYearlySavings;
  surplusResale: IYearlyExtraSales[];
  fundingPeriod: number;
  helpAmount: number;
  isDeferralOption: boolean;
  monthlyPayment: number;
  tvaRefundAmount: number;
}

interface IRGSavings {
  heating: number[];
  ecs: number[];
  autoconsumption: number[];
  resale: number[];
}

export interface ICashflowGraphData {
  absoluteYear: number;
  year: number;
  annuality: number;
  restToPay: number;
  totalSavings: number;
  refundAmount: number;
  refundAmountSpreaded: number;
  tvaRefundAmount: number;
  helpsRefundAmount: number;
}

interface IPVSavingGraphData {
  saving: number;
  extraElectricitySales: number;
  refundAmountSpreaded: number;
}

type IRGSavingGraphData = {
  heating: number;
  ecs: number;
  autoconsumption: number;
  resale: number;
};

export interface IPVCashflowGraphData
  extends ICashflowGraphData,
    IPVSavingGraphData {}

export interface IRGCashflowGraphData
  extends ICashflowGraphData,
    IRGSavingGraphData {}

export const profitabilityGraphDataCalculation = ({
  economiesData,
  surplusResale,
  fundingPeriod,
  helpAmount,
  isDeferralOption,
  monthlyPayment,
  tvaRefundAmount = 0,
}: IProps) => {
  const annuality = monthlyPayment * 12;
  // Function needed to build the cashflowGraphData
  const round = (number: number) => Math.round(number * 100) / 100;
  const annualityAccordingToDeferralOption = (year: number) => {
    // The deferral option allows the customer to defer their first monthly payment until the sixth month.

    if (isDeferralOption && (year === 1 || year === fundingPeriod + 1))
      return Math.round(annuality / 2);

    if (year > fundingPeriod) return 0;

    return Math.round(annuality);
  };
  const totalSavingscalculus = (year: number, helpIncluded: boolean) => {
    return round(
      economiesData[year].economy +
        surplusResale[year].euros +
        (helpIncluded ? (year === 2 ? helpAmount : 0) : 0)
    );
  };
  const financialEffortCalculus = (year: number) => {
    const financialEffort =
      annualityAccordingToDeferralOption(year) -
      totalSavingscalculus(year, false);

    return financialEffort > 0 ? financialEffort : 0;
  };

  let totalRefundAmount = helpAmount + tvaRefundAmount;
  const refundAmontSpreadedCalculus = ({ year }: { year: number }) => {
    // helpAmontSpreaded is like a pot/pool that we empty every month depending on the financialEffort
    let refundAmontSpreaded = 0;
    if (year < 2) return 0;

    if (totalRefundAmount === 0) return 0;

    // if not enought in helpCounter we goint to use its rest
    if (totalRefundAmount - financialEffortCalculus(year) < 0) {
      refundAmontSpreaded = totalRefundAmount;
      totalRefundAmount = 0;
      return refundAmontSpreaded;
    }

    refundAmontSpreaded = financialEffortCalculus(year);
    totalRefundAmount -= refundAmontSpreaded;

    return refundAmontSpreaded;
  };

  // Building the cashflowGraphData
  const cashflowGraphData: IPVCashflowGraphData[] = [];
  // we generate a new array with data formated for graph
  for (const yearAsString in economiesData) {
    const absoluteYear = Number(yearAsString);
    const year = new Date().getFullYear();
    const financialEffort = financialEffortCalculus(absoluteYear);
    const annuality = annualityAccordingToDeferralOption(absoluteYear);
    const refundAmountSpreaded = refundAmontSpreadedCalculus({
      year: absoluteYear,
    });
    const totalSavings = totalSavingscalculus(absoluteYear, false);

    cashflowGraphData.push({
      absoluteYear: absoluteYear,
      year: year + absoluteYear - 1,
      annuality: annuality,
      saving: economiesData[absoluteYear].economy,
      extraElectricitySales: surplusResale[absoluteYear].euros,
      restToPay: financialEffort - refundAmountSpreaded,
      refundAmount: absoluteYear === 2 ? helpAmount + tvaRefundAmount : 0, // Helps and tva refund occured during the second year
      tvaRefundAmount: absoluteYear === 2 ? tvaRefundAmount : 0, // Tva refund occured during the second year
      helpsRefundAmount: absoluteYear === 2 ? helpAmount : 0, // Helps refund occured during the second year
      refundAmountSpreaded: refundAmountSpreaded,
      totalSavings: totalSavings,
    });
  }

  if (totalRefundAmount > 0 && cashflowGraphData[1]?.refundAmountSpreaded) {
    cashflowGraphData[1].refundAmountSpreaded += totalRefundAmount;
  }

  const totalCashEffort = cashflowGraphData.reduce((acc, data) => {
    return acc + data.restToPay;
  }, 0);

  const totalCostFunding = cashflowGraphData.reduce((acc, data) => {
    return acc + data.annuality;
  }, 0);

  return {
    cashflowGraphData,
    helpAmount,
    totalCashEffort,
    totalCostFunding,
  };
};

// RG calculus

const annualityAccordingToDeferralOption = ({
  year,
  isDeferralOption,
  fundingPeriod,
  annuality,
}: {
  year: number;
  isDeferralOption: boolean;
  fundingPeriod: number;
  annuality: number;
}) => {
  // The deferral option allows the customer to defer their first monthly payment until the sixth month.

  if (isDeferralOption && (year === 0 || year === fundingPeriod))
    return Math.round(annuality / 2);

  if (year >= fundingPeriod) return 0;

  return Math.round(annuality);
};

export const formatProfitabilityRGGraphData = ({
  savings,
  isDeferralOption,
  fundingPeriod,
  annuality,
  totalhelps,
}: {
  savings: IRGSavings | null;
  isDeferralOption: boolean;
  fundingPeriod: number;
  annuality: number;
  totalhelps: number;
}) => {
  if (!savings)
    return {
      profitabilityRGGraphData: [],
      totalCashEffort: 0,
    };

  // function to calculate spreaded help
  let helpCounter = totalhelps;
  const helpAmontSpreadedCalculus = ({
    year,
    restToPay,
  }: {
    year: number;
    restToPay: number;
  }) => {
    // helep Amount spread over the following months
    // helpAmontSpreaded is like a pot/pool that we empty every month depending on the financialEffort
    let helpAmontSpreaded = 0;

    if (year < 1) return 0;

    if (helpCounter === 0) return 0;

    // if not enought in helpCounter we goint to use its rest
    if (helpCounter - restToPay < 0) {
      helpAmontSpreaded = helpCounter;
      helpCounter = 0;
      return helpAmontSpreaded;
    }

    helpAmontSpreaded = restToPay;
    helpCounter -= helpAmontSpreaded;

    return helpAmontSpreaded;
  };

  let profitabilityRGGraphData: IRGCashflowGraphData[] = [];

  // format data to have all informations needed for the graph per year by 25 years
  for (let i = 0; i < 25; i++) {
    const year = new Date().getFullYear() + i;

    // get value of all array in savings regarding the years
    profitabilityRGGraphData[i] = {
      ...profitabilityRGGraphData[i],
      autoconsumption: savings.autoconsumption[i],
      ecs: savings.ecs[i],
      heating: savings.heating[i],
      resale: savings.resale[i],
      totalSavings: Object.values(savings).reduce(
        (sum, arr) => sum + arr[i],
        0
      ),
    };

    // add year and annuality
    profitabilityRGGraphData[i] = {
      ...profitabilityRGGraphData[i],
      year: year,
      absoluteYear: i + 1,
      annuality: annualityAccordingToDeferralOption({
        year: i,
        isDeferralOption,
        fundingPeriod,
        annuality,
      }),
    };

    const restToPay =
      profitabilityRGGraphData[i].annuality -
        profitabilityRGGraphData[i].totalSavings <
      0
        ? 0
        : profitabilityRGGraphData[i].annuality -
          profitabilityRGGraphData[i].totalSavings;

    const helpsSpreaded = helpAmontSpreadedCalculus({
      restToPay: restToPay,
      year: i,
    });

    // Add rest to pay, helps and helps spreaded
    profitabilityRGGraphData[i] = {
      ...profitabilityRGGraphData[i],
      restToPay: restToPay - helpsSpreaded,
      refundAmount: i !== 1 ? 0 : totalhelps,
      refundAmountSpreaded: helpsSpreaded,
    };
  }

  return {
    profitabilityRGGraphData,
    totalCashEffort: profitabilityRGGraphData.reduce(
      (acc, curr) => acc + curr.restToPay,
      0
    ),
  };
};
