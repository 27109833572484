import { CRMlabelToValueDictionnary } from "@web/shared/dist/datas/CRMvalueToLabelDictionary";
import {
  ICommandeData,
  IStudyInformation,
} from "../../../interfaces/generalInterfaces";

export function formatPotentielSolaire(
  studyInformation: IStudyInformation
): ICommandeData["potentiel_solaire"] {
  const roof_area_to_equip = studyInformation.roof_area_to_equip;

  let solar_potential_area = null;

  if (roof_area_to_equip && roof_area_to_equip >= 0) {
    if (roof_area_to_equip <= 15) {
      solar_potential_area = 629950000;
    } else if (roof_area_to_equip <= 25) {
      solar_potential_area = 629950001;
    } else if (roof_area_to_equip <= 35) {
      solar_potential_area = 629950002;
    } else if (roof_area_to_equip <= 55) {
      solar_potential_area = 629950003;
    } else {
      solar_potential_area = 629950004;
    }
  }

  return {
    potentiel_ensoleillement: null,
    exposure:
      CRMlabelToValueDictionnary(
        "orientation",
        studyInformation.roof_exposition
      ) ?? null,
    solar_potential_area: solar_potential_area,
    impact_masque_solaire:
      CRMlabelToValueDictionnary(
        "impactMasqueSolaire",
        studyInformation.roof_solar_mask?.toString()
      ) ?? null,
  };
}
