import { useState } from "react";
import "./projectOverview.scss";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// imagesImports
import pictoDottedArrow from "../../../assets/images/picto-dotted-arrow.svg";

// services import
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { DataPersonalizedScenarioPvExtended } from "../../../interfaces/generalInterfaces";
import { TFraisSupp } from "../../../interfaces/renoglobaleInterface";
import { euroShortFormat } from "../../../services/euroFormatter";
import { calculatePVpathPricesFromDataScenario } from "../../../services/PV/calculatePVpathPrices";
import { PvOptionsSubType } from "../FormulePersonnalisableForm";

interface IProps {
  errorMessageEnSavoirPlusButton: string;
  nombreDeModules: string | number;
  puissance: string | number;
  batterieDeStockages: boolean | null | undefined;
  nombreDeBatteries: string | number;
  kitHomeManagement: boolean | null | undefined;
  typeDeRaccordement: string | number;
  goNext: (() => void) | undefined;
  isGetPersonalizedScenarioLoading?: boolean;
  personalizedScenarioChosen: DataPersonalizedScenarioPvExtended | null;
  isRecalculateNeeded: boolean;
  extraFees: TFraisSupp[];
}

export const ProjectOverview = ({
  nombreDeModules,
  puissance,
  batterieDeStockages,
  nombreDeBatteries,
  kitHomeManagement,
  typeDeRaccordement,
  goNext,
  errorMessageEnSavoirPlusButton,
  isGetPersonalizedScenarioLoading,
  personalizedScenarioChosen,
  isRecalculateNeeded,
  extraFees,
}: IProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onSubmitButton = () => {
    setErrorMessage("");
    if (
      goNext &&
      nombreDeModules &&
      puissance &&
      typeDeRaccordement &&
      kitHomeManagement != null &&
      (!batterieDeStockages || (batterieDeStockages && nombreDeBatteries))
    ) {
      goNext();
    } else {
      setErrorMessage("Merci de remplir tous les champs");
    }
  };

  const arrowText = (text: string) => {
    return (
      <div className="arrowAndText">
        <img
          src={pictoDottedArrow}
          alt="pictoDottedArrow"
          width={13}
          height={8}
        />
        <p>{text}</p>
      </div>
    );
  };

  const { totalProjectCostAllAvantagesDeducted } =
    calculatePVpathPricesFromDataScenario({
      scenarioChosen: personalizedScenarioChosen,
      extraFees,
    });

  return (
    <div className={`ProjectOverview ${isRecalculateNeeded ? "needBlur" : ""}`}>
      <div className="ProjectOverview__ImageContainer">
        {isGetPersonalizedScenarioLoading ? (
          <div className="ProjectOverview__LoaderContainer">
            <LoadingComponent diameter={60} />
          </div>
        ) : null}
      </div>
      <div className="ProjectOverview__OptionsRecap">
        {nombreDeModules && arrowText(`${nombreDeModules}\xa0modules`)}
        {batterieDeStockages &&
          nombreDeBatteries !== "0" &&
          arrowText(`${nombreDeBatteries}\xa0batterie(s)\xa0de\xa0stockage`)}
        {puissance && arrowText(`Puissance\xa0${puissance}\xa0kWc`)}
        {kitHomeManagement && arrowText(`1\xa0kit\xa0home\xa0management`)}
        {personalizedScenarioChosen?.options.map((option, index) => (
          <div key={index}>{arrowText(option.alias || option.name)}</div>
        ))}
      </div>
      <div className="ProjectOverview__ProjectCostRecap">
        <div>
          <p>Coût du projet</p>
          <p>
            {"*Aides déduites" +
              (personalizedScenarioChosen?.options.find(
                (option) => option.sub_type === PvOptionsSubType.TVA_REFUND
              )
                ? ", remboursement TVA déduit"
                : "")}
          </p>
        </div>
        <div>
          <p>{euroShortFormat(totalProjectCostAllAvantagesDeducted)}</p>
        </div>
      </div>
      <div className="ProjectOverview__ButtonContainer">
        <CTAButton
          name="Continuer"
          onClick={onSubmitButton}
          type="button"
          isDisabled={isRecalculateNeeded}
        />
      </div>
      <ErrorMessage
        errorMessage={errorMessage || errorMessageEnSavoirPlusButton}
      />
    </div>
  );
};
