import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Interface import
import { IImpactEnvironnemental } from "../../interfaces/generalInterfaces";

//Service import
import { setImpactEnvironnementalAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

// Local interface declaration
interface IGetImpactEnvironnementalProps {
  userToken: string | null;
  number_of_panels: number | null;
  number_of_batteries: number | null;
  building_id?: string | null;
  household_id: string | null | undefined;
  kit_home_management: boolean | null;
  indexScenarioChosen: number | null;
  inverter_brand: string | null;
  study_id: string;
  single_battery_capacity: number | null;
  panel_indices?: number[] | null;
  isPersonalizedScenario: boolean;
}

export const useGetImpactEnvironnemental = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const getImpactEnvironnemental = async ({
    userToken,
    number_of_panels,
    number_of_batteries,
    building_id,
    household_id,
    kit_home_management,
    indexScenarioChosen,
    inverter_brand,
    study_id,
    single_battery_capacity,
    panel_indices,
    isPersonalizedScenario,
  }: IGetImpactEnvironnementalProps) => {
    const response = await axiosGetRequest<IImpactEnvironnemental>(
      `${BACKEND_URL}/oree2/pv/studies/${study_id}/impactEnvironnemental?building_id=${building_id}&household_id=${household_id}&number_of_panels=${number_of_panels}&is_personalized_scenario=${isPersonalizedScenario}&kit_home_management=${kit_home_management}&number_of_batteries=${number_of_batteries}&inverter_brand=${inverter_brand}&single_battery_capacity=${single_battery_capacity}${
        (panel_indices?.length || 0) > 0
          ? "&panel_indices=" + panel_indices
          : ""
      }`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(
        setImpactEnvironnementalAction({
          impactEnvironnemental: { ...response, indexScenarioChosen },
        })
      );
    } else {
      setErrorMessage(
        "Erreur lors de la récupération des données d'impact environnemental"
      );
    }
  };
  return {
    getImpactEnvironnemental,
    isLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
