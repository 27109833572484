export type WindowsInputFormData = {
  simple: number;
  double: number;
  triple: number;
};

export const defaultWindowsInputFormData: WindowsInputFormData = {
  simple: 0,
  double: 0,
  triple: 0,
};
