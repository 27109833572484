import { Document, Font } from "@react-pdf/renderer";

// Fonts
import DMSansBold from "../../assets/pdf/fonts/DMSansBold.ttf";
import DMSansMedium from "../../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../../assets/pdf/fonts/DMSansRegular.ttf";

// Interfaces import
import {
  HelpsOptions,
  ICommercialUserInformation,
  IMonInstallation,
  ISageProduct,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IProjectKPI,
} from "../../interfaces/renoglobaleInterface";

// Utils import
import { getSageProductFromDataProduct } from "../../utils/sageUtils";

// Pages import
import { PdfBatteryPage } from "./PdfBatteryPage";
import { PdfCETPage } from "./PdfCETPage";
import { PdfManagementFees } from "./PDFDevisManagementFees";
import { PdfFreeProductPage } from "./PdfFreeProductPage";
import { PdfInformationContractuelle } from "./PdfInformationContractuelle";
import { PdfISOComblePage } from "./PdfISOComblePage";
import { PdfISOPlancherPage } from "./PdfISOPlancherPage";
import { PdfISORampantsPage } from "./PdfISORampantsPage";
import { PdfITEPage } from "./PdfITEPage";
import { PdfITIPage } from "./PdfITIPage";
import { PdfKitHomeManagementPage } from "./PdfKitHomeManagementPage";
import { PdfPACAirAirPage } from "./PdfPACAirAirPage";
import { PdfPACAirEauPage } from "./PdfPACAirEauPage";
import { PdfPVPageOne } from "./PdfPVPageOne";
import { PdfPVPageTwo } from "./PdfPVPageTwo";
import { PdfRetractationForm } from "./PdfRetractationForm";
import { PdfSummaryPage } from "./PdfSummaryPage";
import { PdfVMCPage } from "./PdfVMCPage";
import { PdfWindowPage } from "./PdfWindowPage";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});

interface IProps {
  documentId: string;
  finalCartProducts: IDataProduct[];
  studyInformation: IStudyInformation;
  monInstallation: IMonInstallation;
  sageProduct: ISageProduct[];
  commercialUserInformation: ICommercialUserInformation;
  emailInputValue: string;
  firstNameInputValue: string;
  lastNameInputValue: string;
  phoneNumberInputValue: string;
  coupons: { label: string; type: string; value: number }[];
  totalValueCoupon?: number | null;
  totalDevisWithoutTax: number;
  totalTVADevis: number;
  totalDevisWithTaxes: number;
  totalHelp: number;
  totalMPRHelp: number;
  restToPay: number;
  RGProjectKPI?: IProjectKPI | null;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
  totalDeductibleHelps: number;
  totalRefundableHelps: number;
}
export const PdfDevisRG = ({
  studyInformation,
  documentId,
  finalCartProducts,
  emailInputValue,
  firstNameInputValue,
  lastNameInputValue,
  phoneNumberInputValue,
  commercialUserInformation,
  sageProduct: sageProducts,
  coupons,
  totalValueCoupon,
  totalDevisWithoutTax,
  totalTVADevis,
  totalDevisWithTaxes,
  totalHelp,
  totalMPRHelp,
  restToPay,
  RGProjectKPI,
  isDemoVersion,
  devisOptions,
  totalDeductibleHelps,
  totalRefundableHelps,
}: IProps) => {
  const capitalize = (str: string | null) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatNumberWithSpacesAndDecimal = (
    number: number,
    decimalPlaces: number,
    isDeducted = false
  ) => {
    const parts = Number(number).toFixed(decimalPlaces).split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const decimalPart = parts[1];
    return (isDeducted ? "-" : "") + decimalPart
      ? `${integerPart},${decimalPart}`
      : integerPart;
  };

  // ------------ PV --------------------

  const PVProduct = finalCartProducts.find(
    (product) =>
      product.product_type === "PV" && product.sage_reference.includes("KITPV")
  );

  const SolarPannelsSageProduct = sageProducts.find(
    (item) => item.NO_RefDet === "THOMSON400" || item.NO_RefDet === "THOMSON500" // We can not use the PVProduct.sage_reference that is linked to the Kit and not the solar pannels
  );

  const OnduleurSageProduct = sageProducts.find(
    (item) =>
      item.FA_CodeFamille === "ONDULEUR" &&
      item.AR_Ref?.includes(PVProduct?.sage_reference || "")
  );

  const OptimiseurSageProduct = sageProducts.find(
    (item) =>
      item.FA_CodeFamille === "OPTIMISEUR" &&
      item.AR_Ref?.includes(PVProduct?.sage_reference || "")
  );

  const CoffretACDCSageProduct = sageProducts.find(
    (item) =>
      item.NO_RefDet === "COFFRETACDCMONO" &&
      item.AR_Ref?.includes(PVProduct?.sage_reference || "")
  );

  const kitHomeManagementSageProduct = sageProducts.find(
    (item) =>
      item.AR_Ref ===
      PVProduct?.product_characteristics.kit_home_management?.sage_reference
  );
  const batterySageProduct = sageProducts.find(
    (item) => item.FA_CodeFamille === "BATTERIE"
  );

  // ------------- CET -----------

  const CETProduct = finalCartProducts.find(
    (product) =>
      product.product_type === "CET" || product.product_type === "BALLON"
  );

  const CETSageProduct = sageProducts.find(
    (item) => item.AR_Ref === CETProduct?.sage_reference
  );

  // ------------- PAC Air/Eau -----------

  const PACAirEauProduct = finalCartProducts.find(
    (product) => product.product_type === "PACAIRO"
  );

  const PACAirEauSageProduct = sageProducts.find(
    (item) => item.AR_Ref === PACAirEauProduct?.sage_reference
  );

  // ------------- PAC Air/Air -----------

  const PACAirAirProducts = finalCartProducts.filter(
    (product) => product.product_type === "PACAIRAIR"
  ); // (we can have multiple PAC air/air)

  // ------------- ITE -----------

  const ITEProduct = finalCartProducts.find(
    (product) => product.product_type === "ITE"
  );

  const ITESageProduct = sageProducts.find(
    (item) => item.AR_Ref === ITEProduct?.sage_reference
  );

  // ------------- ITI -----------

  const ITIProduct = finalCartProducts.find(
    (product) => product.product_type === "ITI"
  );

  const ITISageProduct = sageProducts.find(
    (item) => item.AR_Ref === ITIProduct?.sage_reference
  );

  // ------------- VMC -----------

  const VMCProduct = finalCartProducts.find(
    (product) => product.product_type === "VMC"
  );

  const VMCSageProduct = sageProducts.find(
    (item) => item.AR_Ref === VMCProduct?.sage_reference
  );
  // ------------- ISO Comble -----------

  const ISOCombleProduct = finalCartProducts.find(
    (product) => product.product_type === "ISO"
  );

  const ISOCombleSageProduct = sageProducts.find(
    (item) => item.AR_Ref === ISOCombleProduct?.sage_reference
  );

  // ------------- ISO Rampants -----------

  const ISORampantsProduct = finalCartProducts.find(
    (product) => product.product_type === "ISOA"
  );

  const ISORampantsSageProduct = sageProducts.find(
    (item) => item.AR_Ref === ISORampantsProduct?.sage_reference
  );

  // ------------- ISO plancher bas -----------

  const ISOPlancherProduct = finalCartProducts.find(
    (product) => product.product_type === "ISOP"
  );

  const ISOPlancherSageProduct = sageProducts.find(
    (item) => item.AR_Ref === ISOPlancherProduct?.sage_reference
  );

  // ------------- Window -----------

  const windowProduct = finalCartProducts.filter(
    (product) => product.product_type === "FENETRES"
  );

  // ------------- Free Product -----------

  const freeProducts = finalCartProducts.filter(
    (product) => product.product_type === "AUTRE"
  );

  const isEligibleRenoGlobal = RGProjectKPI?.is_eligible ?? true;

  return (
    <Document>
      {/* FOR NOW THIS PAGE IS NOT DISPLAY-> TO SEE LATER WITH THE PRODUCT TEAM */}
      {/* {RGProjectKPI?.is_eligible ? (
        <PdfFirstPageRGEligible
          documentId={documentId}
          capitalize={capitalize}
          commercialUserInformation={commercialUserInformation}
          emailInputValue={emailInputValue}
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          phoneNumberInputValue={phoneNumberInputValue}
          studyInformation={studyInformation}
          RGProjectKPI={RGProjectKPI}
        />
      ) : null} */}
      {/* Summary page */}
      <PdfSummaryPage
        documentId={documentId}
        formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
        batterySageProduct={batterySageProduct}
        capitalize={capitalize}
        finalCartProducts={finalCartProducts}
        commercialUserInformation={commercialUserInformation}
        emailInputValue={emailInputValue}
        firstNameInputValue={firstNameInputValue}
        kitHomeManagementSageProduct={kitHomeManagementSageProduct}
        lastNameInputValue={lastNameInputValue}
        phoneNumberInputValue={phoneNumberInputValue}
        studyInformation={studyInformation}
        coupons={coupons}
        PVProduct={PVProduct}
        SolarPannelsSageProduct={SolarPannelsSageProduct}
        OnduleurSageProduct={OnduleurSageProduct}
        OptimiseurSageProduct={OptimiseurSageProduct}
        CoffretACDCSageProduct={CoffretACDCSageProduct}
        totalValueCoupon={Number(totalValueCoupon)}
        totalDevisWithoutTax={totalDevisWithoutTax}
        totalTVADevis={totalTVADevis}
        totalDevisWithTaxes={totalDevisWithTaxes}
        totalHelp={totalHelp}
        toalMPRHelp={totalMPRHelp}
        restToPay={restToPay}
        isEligibleRenoGlobal={isEligibleRenoGlobal}
        ITEProduct={ITEProduct}
        ITIProduct={ITIProduct}
        PACAirEauProduct={PACAirEauProduct}
        PACAirAirProducts={PACAirAirProducts}
        CETProduct={CETProduct}
        ISOCombleProduct={ISOCombleProduct}
        ISORampantsProduct={ISORampantsProduct}
        ISOPlancherProduct={ISOPlancherProduct}
        isDemoVersion={isDemoVersion}
        devisOptions={devisOptions}
        totalDeductibleHelps={totalDeductibleHelps}
        totalRefundableHelps={totalRefundableHelps}
      />
      {/*Management Fees page in case RG eligible */}
      {isEligibleRenoGlobal ? (
        <PdfManagementFees
          documentId={documentId}
          commercialUserInformation={commercialUserInformation}
          finalCartProducts={finalCartProducts}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* PV page 1/2 */}
      {PVProduct ? (
        <PdfPVPageOne
          CoffretACDCSageProduct={CoffretACDCSageProduct}
          OnduleurSageProduct={OnduleurSageProduct}
          OptimiseurSageProduct={OptimiseurSageProduct}
          PVProduct={PVProduct}
          SolarPannelsSageProduct={SolarPannelsSageProduct}
          documentId={documentId}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
      {/* PV page 2/2 */}
      {PVProduct ? (
        <PdfPVPageTwo
          PVProduct={PVProduct}
          documentId={documentId}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* Kit home management page */}
      {PVProduct?.selected_characteristics.kit_home_management && (
        <PdfKitHomeManagementPage
          PVProduct={PVProduct}
          documentId={documentId}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          studyInformation={studyInformation}
          kitHomeManagementSageProduct={kitHomeManagementSageProduct}
          commercialUserInformation={commercialUserInformation}
          isDemoVersion={isDemoVersion}
        />
      )}

      {/* Battery page */}
      {(PVProduct?.selected_characteristics.n_batteries || 0) > 0 && (
        <PdfBatteryPage
          PVProduct={PVProduct}
          documentId={documentId}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          batterySageProduct={batterySageProduct}
          commercialUserInformation={commercialUserInformation}
          isDemoVersion={isDemoVersion}
        />
      )}

      {/* CET page */}
      {CETProduct ? (
        <PdfCETPage
          documentId={documentId}
          CETProduct={CETProduct}
          CETSageProduct={CETSageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* PACAirEau page */}
      {PACAirEauProduct ? (
        <PdfPACAirEauPage
          documentId={documentId}
          PACAirEauProduct={PACAirEauProduct}
          PACAirEauSageProduct={PACAirEauSageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* PACAirAIr page (we can have multiple PAC air/air) */}
      {PACAirAirProducts?.map((pacAirAirProduct, index) => (
        <PdfPACAirAirPage
          key={index}
          documentId={documentId}
          PACAirAirProduct={pacAirAirProduct}
          sageProducts={sageProducts}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ))}
      {/* Window page (we can have windows and french windows) */}
      {windowProduct?.map((window, windowIndex) => (
        <PdfWindowPage
          key={windowIndex}
          documentId={documentId}
          windowProduct={window}
          sageProduct={getSageProductFromDataProduct(sageProducts, window)}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ))}

      {/* ITE page */}
      {ITEProduct ? (
        <PdfITEPage
          documentId={documentId}
          ITEProduct={ITEProduct}
          ITESageProduct={ITESageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* ITI page */}
      {ITIProduct ? (
        <PdfITIPage
          documentId={documentId}
          ITIProduct={ITIProduct}
          ITISageProduct={ITISageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* VMC page */}
      {VMCProduct ? (
        <PdfVMCPage
          documentId={documentId}
          VMCProduct={VMCProduct}
          VMCSageProduct={VMCSageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* ISO comble page */}
      {ISOCombleProduct ? (
        <PdfISOComblePage
          documentId={documentId}
          ISOComble={ISOCombleProduct}
          ISOCombleSageProduct={ISOCombleSageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* ISO rampants page */}
      {ISORampantsProduct ? (
        <PdfISORampantsPage
          documentId={documentId}
          ISORampants={ISORampantsProduct}
          ISORampantsSageProduct={ISORampantsSageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* ISO plancher page */}
      {ISOPlancherProduct ? (
        <PdfISOPlancherPage
          documentId={documentId}
          ISOPlancher={ISOPlancherProduct}
          ISOPlancherSageProduct={ISOPlancherSageProduct}
          formatNumberWithSpacesAndDecimal={formatNumberWithSpacesAndDecimal}
          commercialUserInformation={commercialUserInformation}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
          isDemoVersion={isDemoVersion}
          devisOptions={devisOptions}
        />
      ) : null}

      {/* Produits libres */}
      {freeProducts.length > 0
        ? freeProducts.map((freeProduct) => {
            return (
              <PdfFreeProductPage
                key={freeProduct.product_name}
                documentId={documentId}
                freeProduct={freeProduct}
                formatNumberWithSpacesAndDecimal={
                  formatNumberWithSpacesAndDecimal
                }
                commercialUserInformation={commercialUserInformation}
                isEligibleRenoGlobal={isEligibleRenoGlobal}
                isDemoVersion={isDemoVersion}
              />
            );
          })
        : null}

      {/* Contract information page */}
      <PdfInformationContractuelle
        commercialUserInformation={commercialUserInformation}
        isDemoVersion={isDemoVersion}
      />

      {/* Retractation form page */}
      <PdfRetractationForm
        commercialUserInformation={commercialUserInformation}
        isDemoVersion={isDemoVersion}
      />
    </Document>
  );
};
