import { useCallback } from "react";
import {
  FinancingData,
  HelpsOptions,
  IOreeStudyType,
} from "../../interfaces/generalInterfaces";
import { formatBDCPayload } from "../../pages/Recapitulatif/Service/formatBDCPayload";
import { formatFinancementInfos } from "../../pages/Recapitulatif/Service/formatFinancementInfos";
import { setStudyInfoAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useGenerateCrmBdc } from "./useGenerateCrmBdc";
import { useGenerateCrmDocusign } from "./useGenerateCrmDocusign";
import { useGenerateCrmQuote } from "./useGenerateCrmQuote";
import { useGetBdcStatus } from "./useGetBdcStatus";
import { useUploadDocumentsToCrm } from "./useUploadDocumentsToCrm";

/**
 * Hook to interact with CRM API (via Eero API)
 */
export function useCrmApi(studyType: IOreeStudyType) {
  const studyId = useAppSelector((state) => state.studyInformation.study_id);
  const bdcId = useAppSelector((state) => state.studyInformation.last_bdc_id);
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const cartProducts = useAppSelector((state) => state.cartProducts);
  const RGProjectKPI = useAppSelector((state) => state.RGProjectKPI);
  const SAGEItemsInfo = useAppSelector((state) => state.SAGEItemsInfo);
  const scenarioChosen = useAppSelector((state) => state.scenarioChosen);

  const dispatch = useAppDispatch();

  const getBdcStatusQuery = useGetBdcStatus({
    bdcId,
    studyId,
    enabled: !!bdcId, // We want to fetch the bdc status only if we have a bdc_id
  });

  // Upload PDFs documents to CRM
  const uploadDocumentsToCrmMutation = useUploadDocumentsToCrm();

  // Generate CRM Quote
  const generateCrmQuoteMutation = useGenerateCrmQuote();

  /**
   * Send data to CRM to generate a CRM Opportunity, the CRM quote, and upload the PDFs documents
   * @returns Return the quote id if the quote was successfully generated, otherwise return null
   */
  const generateCrmQuote = useCallback(
    async ({
      quotePDFId,
      devisOptions,
      coupons,
      restToPay,
    }: {
      quotePDFId: string;
      devisOptions: HelpsOptions;
      coupons:
        | {
            label: string;
            type: string;
            value: number;
            isMembershipCode: boolean;
          }[]
        | undefined;
      restToPay?: number;
    }) => {
      let payload;
      if (studyType === IOreeStudyType.RENOVATION_GLOBALE) {
        payload = formatBDCPayload({
          studyInformation,
          idDevisBecqe: quotePDFId,
          userAuthInfo,
          cartProducts,
          devisOptions,
          SAGEItemsInfo,
          coupons,
          saveWithoutCreateBDC: true,
          RGProjectKPI,
          bdcStatus: getBdcStatusQuery.data?.state,
          restToPay,
        });
      } else {
        // PV
        payload = formatBDCPayload({
          studyInformation,
          scenarioChosen,
          idDevisBecqe: quotePDFId,
          userAuthInfo,
          devisOptions,
          coupons,
          saveWithoutCreateBDC: true,
          bdcStatus: getBdcStatusQuery.data?.state,
        });
      }

      try {
        const response = await generateCrmQuoteMutation.mutateAsync({
          studyId: studyInformation.study_id ?? null,
          payload: { commonInfo: payload },
        });

        dispatch(
          setStudyInfoAction({
            studyInformation: {
              quote_id: response.quoteId,
            },
          })
        );

        return response.quoteId;
      } catch (error) {
        dispatch(
          setStudyInfoAction({
            studyInformation: {
              quote_id: null,
            },
          })
        );

        return null;
      }
    },
    [
      RGProjectKPI,
      SAGEItemsInfo,
      cartProducts,
      dispatch,
      generateCrmQuoteMutation,
      getBdcStatusQuery.data?.state,
      scenarioChosen,
      studyInformation,
      studyType,
      userAuthInfo,
    ]
  );

  const generateCrmBdcMutation = useGenerateCrmBdc();

  /**
   * Send data to CRM to generate the CRM BDC
   * @returns Return the BDC infos if the BDC was successfully generated, otherwise return null
   */
  const generateCrmBdc = useCallback(
    async ({
      quoteId,
      financingInformationFormatForCRM,
      restToPay,
    }: {
      quoteId: string;
      financingInformationFormatForCRM: FinancingData;
      restToPay: number;
    }) => {
      const isBdcCancelled =
        getBdcStatusQuery.data?.state?.toLowerCase() === "annulé";

      const orderToCancelId = isBdcCancelled
        ? null
        : studyInformation.last_bdc_id ?? null;

      const requestData = {
        quoteId: quoteId,
        data: {
          commercialId: userAuthInfo.crm_user_id ?? null,
          orderToCancelId,
          financementInfos: formatFinancementInfos(
            financingInformationFormatForCRM,
            restToPay
          ),
        },
      };

      try {
        const response = await generateCrmBdcMutation.mutateAsync({
          studyId: studyInformation.study_id ?? null,
          data: requestData,
        });

        dispatch(
          setStudyInfoAction({
            studyInformation: {
              last_bdc_id: response.orderId,
            },
          })
        );

        return response;
      } catch (error) {
        dispatch(
          setStudyInfoAction({
            studyInformation: {
              last_bdc_id: null,
            },
          })
        );

        return null;
      }
    },
    [
      dispatch,
      generateCrmBdcMutation,
      getBdcStatusQuery.data?.state,
      studyInformation.last_bdc_id,
      studyInformation.study_id,
      userAuthInfo.crm_user_id,
    ]
  );

  const generateCrmDocusignMutation = useGenerateCrmDocusign();

  /**
   * Send data to CRM to generate the Docusign
   * @returns Returns the response. It does not contain any useful information for now
   */
  const generateCrmDocusign = useCallback(
    async ({
      salesOrderId,
      financingInformationFormatForCRM,
      restToPay,
    }: {
      salesOrderId: string;
      financingInformationFormatForCRM: FinancingData;
      restToPay: number;
    }) => {
      const requestData = {
        salesOrderId: salesOrderId,
        data: {
          commercialId: userAuthInfo.crm_user_id ?? null,
          orderToCancelId: null,
          financementInfos: formatFinancementInfos(
            financingInformationFormatForCRM,
            restToPay
          ),
        },
      };

      try {
        const response = await generateCrmDocusignMutation.mutateAsync({
          studyId: studyInformation.study_id ?? null,
          data: requestData,
        });

        return response;
      } catch (error) {
        return null;
      }
    },
    [
      generateCrmDocusignMutation,
      studyInformation.study_id,
      userAuthInfo.crm_user_id,
    ]
  );

  return {
    getBdcStatusQuery,

    uploadDocumentsToCrmMutation,

    generateCrmQuoteMutation,
    generateCrmQuote,

    generateCrmBdcMutation,
    generateCrmBdc,

    generateCrmDocusignMutation,
    generateCrmDocusign,
  };
}
