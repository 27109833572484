import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  documentId: string;
  finalCartProducts: IDataProduct[];
  commercialUserInformation: ICommercialUserInformation;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfManagementFees = ({
  documentId,
  finalCartProducts,
  commercialUserInformation,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const { totalInstallationPriceWithTax, totalInstallationPriceWithoutTax } =
    finalCartProducts.reduce(
      (accumulator, product) => {
        const { installationPriceWithTax, installationPriceWithoutTax } =
          productPrices({
            product,
            isEligibleRenoGlobal: true,
            devisOptions,
          });
        accumulator.totalInstallationPriceWithTax =
          accumulator.totalInstallationPriceWithTax + installationPriceWithTax;
        accumulator.totalInstallationPriceWithoutTax =
          accumulator.totalInstallationPriceWithoutTax +
          installationPriceWithoutTax;

        return accumulator;
      },
      {
        totalInstallationPriceWithTax: 0,
        totalInstallationPriceWithoutTax: 0,
      }
    );

  // management fees TVA is calculated regarding the average TVA of the installation
  const managementFeesTVA =
    Math.round(
      (((totalInstallationPriceWithTax - totalInstallationPriceWithoutTax) *
        100) /
        totalInstallationPriceWithoutTax) *
        10
    ) / 10;

  const managementFeesTTC = 1374;
  const managementFeesHT = managementFeesTTC / (1 + managementFeesTVA / 100);
  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              "Frais de gestion et d'installation pour la rénovation globale"
            }
          />
          <PdfSubTitleProduct title={""} />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Frais de gestion"
              quantity={1}
              prixUnitaire={managementFeesHT}
              TVA={managementFeesTVA}
              montantTTC={managementFeesTTC}
            />
            <View style={styles.inlineComponents}>
              <Text>
                Prise en charge des démarches administratives et gestion de
                projet/SAV
              </Text>
            </View>
            <View style={styles.inlineComponents}>
              <Text>
                Constitution des dossiers d’aides financières éventuellement
                liées au projet
              </Text>
            </View>
            {finalCartProducts.some((product) => {
              return product.product_type === "PV";
            }) ? (
              <>
                <View style={styles.inlineComponents}>
                  <Text>Réalisation de la déclaration préalable en mairie</Text>
                </View>
                <View style={styles.inlineComponents}>
                  <Text>Demande de raccordement</Text>
                </View>
                <View style={styles.inlineComponents}>
                  <Text style={{ marginBottom: 10 }}>
                    Obtention de l’attestation CONSUEL
                  </Text>
                </View>
              </>
            ) : null}

            <PdfRowBackgroundProduit
              name="Frais de gestion offerts"
              montantTTC={-managementFeesTTC}
            />
          </View>
          <PdfTotalContainer
            devisOptions={devisOptions}
            totalHT={0}
            totalTVA={0}
            totalTTC={0}
            totalHelp={0}
            resteACharge={0}
            isSubProduct={true}
          />
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
