// Libraries import
import { useState } from "react";
import { useAxiosGet } from "./../useAxiosGet";

// Services imports
import { IDataScenarioPvExtended } from "../../interfaces/generalInterfaces";
import { setMesScenariosInfoAction } from "./../../redux/appActions";
import { useAppDispatch } from "./../../redux/store/hook";

interface IProps {
  building_id: string;
  household_id: string;
  userToken: string;
  study_id: string;
}

export const useGetMesScenarios = () => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getMesScenarios = async ({
    building_id,
    household_id,
    userToken,
    study_id,
  }: IProps): Promise<IDataScenarioPvExtended[] | void> => {
    setErrorMessage("");
    const response = await axiosGetRequest<{
      calepinages: IDataScenarioPvExtended[];
    }>(
      `${process.env.REACT_APP_EERO_URL}/oree2/pv/studies/${study_id}/recommendedScenarios?building_id=${building_id}&household_id=${household_id}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    if (response) {
      dispatch(
        setMesScenariosInfoAction({
          mesScenarios: response.calepinages,
        })
      );
      return response.calepinages;
    }
  };

  return {
    getMesScenarios,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
