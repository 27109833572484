import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfEtape } from "../../components/Pdf/Pdf-Etude/PdfEtape/PdfEtape";
import { PdfHeader } from "../../components/Pdf/Pdf-Etude/PdfHeader/PdfHeader";
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Images
import espaceClient from "../../assets/pdf/images/espace-client.png";
import vousEtesIci from "../../assets/pdf/images/vous-etes-ici.png";

// Interfaces import
import {
  IOreeStudyType,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  studyInformation: IStudyInformation;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  prochainesEtapes: {
    marginTop: 70,
    display: "flex",
  },
  prochainesEtapes__titles: {
    marginBottom: 10,
  },
  prochainesEtapes__description: {
    fontSize: 9,
    marginBottom: 20,
  },
  prochainesEtapes__premiereEtape: {
    marginLeft: 40,
  },
  prochainesEtapes__containerCode__text: {
    position: "absolute",
    fontSize: 9,
    color: "#08B77F",
    right: 48,
    top: 70,
  },
  prochainesEtapes__premiereEtape__Image: {
    position: "absolute",
    width: 100,
    right: 25,
    top: "50%",
    transform: "translate(0%, -50%)",
  },
  prochainesEtapes__premiereEtape__Arrow: {
    position: "absolute",
    right: 165,
    top: 12,
    width: 70,
  },
  prochainesEtapes__left__titles: {
    marginBottom: 20,
  },
  prochainesEtapes__left__titles__title: {
    fontSize: 28,
    color: `${DSColors.OffBlack}`,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__left__titles__subtitle: {
    fontSize: 20,
    color: `${DSColors.PlanetGreen}`,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCode: {
    marginTop: 20,
    backgroundColor: "#D1F3F2",
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    gap: 30,
  },
  prochainesEtapes__containerCodeLeft: {
    flex: 1.3,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  prochainesEtapes__containerCodeTextOne: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCodeTextTwo: {
    fontSize: 9,
    fontFamily: "DMSansRegular",
  },
  prochainesEtapes__containerCodeTextThree: {
    fontWeight: "bold",
    fontSize: 9,
    fontFamily: "DMSansBold",
  },
  prochainesEtapes__containerCodeRight: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  prochainesEtapes__containerCodeRightBorder: {
    border: "1px dashed #50545f",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 5,
    marginLeft: 10,
  },
  prochainesEtapes__containerCodeTextRight: {
    fontSize: 9,
  },
  prochainesEtapes__containerCodeRightCode: {
    fontSize: 9,
    color: "#08B77F",
  },
});

export const PdfEtudeRGLastPage = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  studyInformation,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfHeader />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.prochainesEtapes}>
          <View style={localStyle.prochainesEtapes__titles}>
            <Text style={localStyle.prochainesEtapes__left__titles__title}>
              Les prochaines étapes
            </Text>
            <Text style={localStyle.prochainesEtapes__left__titles__subtitle}>
              de votre projet
            </Text>
          </View>
          <View style={localStyle.prochainesEtapes__description}>
            <Text>
              Vous pouvez désormais suivre toute l’avancée de votre projet sur
              votre
            </Text>
            <Text>Espace Client TUCO.</Text>
          </View>
          <View style={localStyle.prochainesEtapes__premiereEtape}>
            <PdfEtape
              number="1."
              title="Étude énergétique"
              description="Retrouvez votre étude et votre devis sur votre espace client."
              isBlue
            />
            <Image
              style={localStyle.prochainesEtapes__premiereEtape__Arrow}
              src={vousEtesIci}
            />
            <Image
              style={localStyle.prochainesEtapes__premiereEtape__Image}
              src={espaceClient}
            />
          </View>
          <PdfEtape
            number="2."
            title="Signature"
            description="Signez votre bon de commande et démarrez votre projet !"
          />
          <PdfEtape
            number="3."
            title="Installation"
            description="Planifiez avec votre chargé de projet la date de votre installation. Pendant ce temps, nos équipes s'occupent de réaliser toutes vos démarches administratives."
            isBlue
          />
          <PdfEtape
            number="4."
            title="Bravo, vous êtes équipé !"
            description="Profitez de votre installation et consommez votre propre énergie. Vous pourrez suivre toutes les performances de votre centrale sur une plateforme dédiée."
          />
          <View wrap={false} style={localStyle.prochainesEtapes__containerCode}>
            <View style={localStyle.prochainesEtapes__containerCodeLeft}>
              <Text style={localStyle.prochainesEtapes__containerCodeTextOne}>
                {"Parrainez un proche !"}
              </Text>
              <Text style={localStyle.prochainesEtapes__containerCodeTextTwo}>
                {
                  "Vous connaissez quelqu'un qui envisage un projet de rénovation énergétique ?"
                }
              </Text>
              <Text style={localStyle.prochainesEtapes__containerCodeTextThree}>
                {
                  "Partagez votre code parrainage et bénéficiez de 200 euros après son installation !"
                }
              </Text>
            </View>
            <View style={localStyle.prochainesEtapes__containerCodeRight}>
              <View
                style={localStyle.prochainesEtapes__containerCodeRightBorder}
              >
                <Text
                  style={localStyle.prochainesEtapes__containerCodeTextRight}
                >
                  {"VOTRE CODE"}
                </Text>
                <Text
                  style={localStyle.prochainesEtapes__containerCodeRightCode}
                >
                  {studyInformation.membership_code
                    ? studyInformation.membership_code
                    : "Demandez à votre conseiller"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType={IOreeStudyType.RENOVATION_GLOBALE}
      />
    </Page>
  );
};
