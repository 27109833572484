// Style import
import { DSColors } from "@web/shared/src/styles/variables";
import "./checkoutContainer.scss";

// Libraries import
import { useState } from "react";

//Components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { CheckoutButtonsDefault } from "../CheckoutButtonsDefault/CheckoutButtonsDefault";
import { CheckoutButtonsDemo } from "../CheckoutButtonsDemo/CheckoutButtonsDemo";

// images import
import pictoTrash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { FetchBdcStatusResponse } from "../../../api/hooks/useGetBdcStatus";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { euroShortFormat } from "../../../services/euroFormatter";

// Local interface declaration
interface Coupon {
  label: string;
  type: string;
  value: number;
}

export interface CheckoutContainerProps {
  isCouponValideErrorMessage: string;
  handleUseCouponButton: (couponName: string) => Promise<void>;
  handleDeleteCoupon: (indexCoupon: number) => void;
  totalDevisWithTaxes: number;
  totalValueCoupon: number;
  coupons: Coupon[];
  isGetInformationForPDFEtudeLoading: boolean;
  generateQuotePDF: (args: { isDemoVersion?: boolean }) => Promise<any>;
  generateStudyPDF: (args: { isDemoVersion?: boolean }) => Promise<any>;
  isGenerateQuotePDFLoading: boolean;
  isGenerateStudyPDFLoading: boolean;

  totalHelp: number;

  totalProjectCostHelpsDeducted?: number;
  bdcStatus: FetchBdcStatusResponse | undefined;
  isLoadingGetBdcStatus?: boolean;
  isErrorGetBdcStatus?: boolean;
  errorMessageGetBdcStatus?: string;

  setIsSubscriptionModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaveModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckoutContainer = ({
  isCouponValideErrorMessage,
  handleUseCouponButton,
  handleDeleteCoupon,
  totalDevisWithTaxes,
  totalValueCoupon,
  coupons,

  isGetInformationForPDFEtudeLoading,
  generateQuotePDF,
  generateStudyPDF,
  isGenerateQuotePDFLoading,
  isGenerateStudyPDFLoading,

  totalHelp,
  totalProjectCostHelpsDeducted,
  bdcStatus,
  isLoadingGetBdcStatus,
  isErrorGetBdcStatus,
  errorMessageGetBdcStatus,

  setIsSubscriptionModaleOpen,
  setIsSaveModaleOpen,
}: CheckoutContainerProps) => {
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const scenarioChosen = useAppSelector((state) => state.scenarioChosen);

  const isDemoVersion =
    userAuthInfo.companyId === "e72bf170-5328-465f-92ef-059129279edc"; // Id of the demo company. Same id in dev and prod.

  return (
    <div className="CheckoutContainer">
      <AddCouponSection
        handleUseCouponButton={handleUseCouponButton}
        isCouponValideErrorMessage={isCouponValideErrorMessage}
      />

      <PricesSection
        totalHelp={totalHelp}
        totalDevisWithTaxes={totalDevisWithTaxes}
        totalValueCoupon={totalValueCoupon}
        totalProjectCostHelpsDeducted={totalProjectCostHelpsDeducted}
        coupons={coupons}
        handleDeleteCoupon={handleDeleteCoupon}
      />

      {isDemoVersion ? (
        <CheckoutButtonsDemo
          generateQuotePDF={generateQuotePDF}
          generateStudyPDF={generateStudyPDF}
          isGenerateQuotePDFLoading={isGenerateQuotePDFLoading}
          isGenerateStudyPDFLoading={isGenerateStudyPDFLoading}
          isGetInformationForPDFEtudeLoading={
            isGetInformationForPDFEtudeLoading
          }
        />
      ) : (
        <CheckoutButtonsDefault
          studyInformation={studyInformation}
          userAuthInfo={userAuthInfo}
          scenarioChosen={scenarioChosen}
          isGetInformationForPDFEtudeLoading={
            isGetInformationForPDFEtudeLoading
          }
          setIsSubscriptionModaleOpen={setIsSubscriptionModaleOpen}
          setIsSaveModaleOpen={setIsSaveModaleOpen}
          bdcStatus={bdcStatus}
          isLoadingGetBdcStatus={isLoadingGetBdcStatus}
          isErrorGetBdcStatus={isErrorGetBdcStatus}
          errorMessageGetBdcStatus={errorMessageGetBdcStatus}
        />
      )}
    </div>
  );
};

function AddCouponSection({
  handleUseCouponButton,
  isCouponValideErrorMessage,
}: {
  handleUseCouponButton: (couponName: string) => Promise<void>;
  isCouponValideErrorMessage: string;
}) {
  const [isAdvantageCodeShown, setIsAdvantageCodeShown] = useState(false);
  const [isPromoCodeShown, setIsPromoCodeShown] = useState(false);

  const [advantageCode, setAdvantageCode] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");

  return (
    <div className="CheckoutContainer__CouponContainer">
      <h3
        onClick={() => setIsAdvantageCodeShown(!isAdvantageCodeShown)}
        className={isAdvantageCodeShown ? "title svgTranslate" : "title"}
      >
        Vous avez un code parrainage ?
      </h3>
      <div className={isAdvantageCodeShown ? "wrapper" : "hide"}>
        <TextInput
          value={advantageCode}
          onChange={(event) => setAdvantageCode(event.target.value)}
          placeholder="Code avantage"
          iconHidden
          inputDescriptionHidden
          inputLableHidden
        />
        <CTAButton
          onClick={() => {
            handleUseCouponButton(advantageCode);
          }}
          name="Appliquer"
          category="tertiary"
        />
      </div>
      <div style={{ display: isAdvantageCodeShown ? "block" : "none" }}>
        <BoutonPicto
          text="Ajouter un code promo"
          onClick={() => setIsPromoCodeShown(!isPromoCodeShown)}
          color={DSColors.Mint}
        />
      </div>
      <div
        className={
          isAdvantageCodeShown && isPromoCodeShown ? "wrapper" : "hide"
        }
      >
        <TextInput
          value={promoCode}
          onChange={(event) => setPromoCode(event.target.value)}
          placeholder="Code promo"
          iconHidden
          inputDescriptionHidden
          inputLableHidden
        />
        <CTAButton
          onClick={() => handleUseCouponButton(promoCode)}
          name="Appliquer"
          category="tertiary"
        />
      </div>
      {isCouponValideErrorMessage && (
        <ErrorMessage errorMessage={isCouponValideErrorMessage} />
      )}
    </div>
  );
}

function PricesSection({
  totalDevisWithTaxes,
  totalHelp,
  totalValueCoupon,
  totalProjectCostHelpsDeducted,
  coupons,
  handleDeleteCoupon,
}: {
  totalHelp: number | undefined;
  totalDevisWithTaxes: number;
  totalValueCoupon: number;
  totalProjectCostHelpsDeducted: number | undefined;
  coupons: Coupon[];
  handleDeleteCoupon: (indexCoupon: number) => void;
}) {
  const [isCodeDetailShown, setIsCodeDetailShown] = useState(false);

  return (
    <div className="CheckoutContainer__PricesContainer">
      <div className="CheckoutContainer__PricesDetails">
        <p>Sous total</p>
        <p>{euroShortFormat(totalDevisWithTaxes || 0)}</p>
      </div>

      {totalHelp ? (
        <div className="CheckoutContainer__HelpsDetails">
          <p>Total des aides</p>
          <p>{euroShortFormat(totalHelp)}</p>
        </div>
      ) : null}

      {coupons.length > 0 && (
        <div className="CheckoutContainer__priceWithDropdown">
          <div
            onClick={() => setIsCodeDetailShown(!isCodeDetailShown)}
            className={
              isCodeDetailShown
                ? "CheckoutContainer__CouponsDetails svgTranslate"
                : "CheckoutContainer__CouponsDetails"
            }
          >
            <p>Remise</p>
            <p>{euroShortFormat(Number(totalValueCoupon) || 0)}</p>
          </div>
          <div
            className={
              isCodeDetailShown ? "CheckoutContainer__dropdown" : "dropdownhide"
            }
          >
            {coupons.map((coupon, index) => {
              return (
                <div className="item" key={index}>
                  <div className="name">
                    <p>{coupon.label}</p>
                    <img
                      src={pictoTrash}
                      alt="picto trash"
                      onClick={() => handleDeleteCoupon(index)}
                    />
                  </div>
                  {coupon.type === "percentage" ? (
                    <p>
                      {coupon.value} {/* POURCENTAGE A GERER AVEC PRIX FINAL */}
                    </p>
                  ) : (
                    <p>{euroShortFormat(Number(coupon.value))}</p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="CheckoutContainer__RestToPay">
        <p>Coût du projet aides déduites</p>
        <p>{euroShortFormat(totalProjectCostHelpsDeducted || 0)}</p>
      </div>
    </div>
  );
}
