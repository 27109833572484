import { useState } from "react";

//Library import
import { useNavigate, useParams } from "react-router-dom";
import { resetAllStudyInformationAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useDisconnectApp } from "../../services/useDisconnectApp";

//Style import
import { Layout } from "../Layout/Layout";
import "./navigationBar.scss";

//Images import
import logOut from "@web/shared/dist/assets/images/DesignSystemIcons/log-out.svg";
import burgerMenu from "../../assets/images/burgerMenu.svg";
import burgerMenuClose from "../../assets/images/burgerMenuClose.svg";
import goBackTo from "../../assets/images/goBackTo.svg";
import home from "../../assets/images/Navbar/home.svg";
import source from "../../assets/images/Navbar/source.svg";
import start from "../../assets/images/Navbar/start.svg";
import study from "../../assets/images/Navbar/study.svg";
import { IOreeStudyType } from "../../interfaces/generalInterfaces";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";

//Local interface
interface Props {
  allTitles?: string[];
  currentStepIndex?: number;
  currentSubStepIndex?: number;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: {
    newCurrentStepIndex: number;
    newCurrentSubStepIndex?: number;
    isGoforwardAllowed?: boolean;
  }) => void;
  isBurgerMenuVisible?: boolean;
  isGoBackToVisible?: boolean;
  isOngletVisible?: boolean;
  study_type: IOreeStudyType;
}

export const NavigationBar = ({
  allTitles,
  currentStepIndex,
  currentSubStepIndex,
  goTo,
  isBurgerMenuVisible,
  isGoBackToVisible,
  isOngletVisible,
  study_type,
}: Props) => {
  const navigate = useNavigate();
  const { disconnectApp } = useDisconnectApp();
  const dispatch = useAppDispatch();
  const { userAuthInfo } = useAppSelector((state) => state);
  const { saveLastPageVisited } = useSaveLastPageVisited();
  const { study_id } = useParams();

  const [menuBurgerShown, setMenuBurgerShown] = useState(false);

  const handleOnClickGoBackToMenu = () => {
    if (currentStepIndex === 0) {
      dispatch(resetAllStudyInformationAction(null));
      navigate("/");
    }

    //return to Mon projet perfomance/Rentabilité/Impact environnemental page from Récapitulatif
    if (
      study_type === IOreeStudyType.RENOVATION_GLOBALE &&
      currentStepIndex === 5
    ) {
      return (
        goTo &&
        goTo({
          newCurrentStepIndex: 4,
          newCurrentSubStepIndex: 1,
          isGoforwardAllowed: true,
        })
      );
    }

    if (currentStepIndex && currentSubStepIndex) {
      return (
        goTo &&
        goTo({
          newCurrentStepIndex: currentStepIndex,
          newCurrentSubStepIndex: currentSubStepIndex - 1,
          isGoforwardAllowed: false,
        })
      );
    }

    goTo &&
      currentStepIndex &&
      goTo({
        newCurrentStepIndex: currentStepIndex - 1,
        newCurrentSubStepIndex: 0,
        isGoforwardAllowed: false,
      });
  };

  const pageDictionary: { [key: number]: string } = {
    0: "startStudy",
    1: "my_informations",
    2: "my_consumptions",
    3: "my_solar_potential",
    4: "my_project",
  };

  return (
    <>
      <Layout>
        <div className="NavigationBar__Container">
          {isGoBackToVisible && (
            <div
              onClick={() => handleOnClickGoBackToMenu()}
              className="NavigationBar__goBackto"
            >
              <img src={goBackTo} alt="gobackto menu" />
            </div>
          )}
          <div
            className={
              !isOngletVisible
                ? "NavigationBar NavigationBarNoBG"
                : "NavigationBar"
            }
          >
            {allTitles && currentStepIndex !== undefined && goTo
              ? allTitles.map((title, index) => {
                  const isStepPassed = index < currentStepIndex;
                  const isStepActive = index <= currentStepIndex;
                  return (
                    <div
                      className={`NavigationBar__TitleSection ${
                        isStepActive && "TitleActive"
                      } ${isStepPassed && "TitlePassed"}`}
                      key={index}
                      onClick={() => {
                        saveLastPageVisited({
                          last_page_visited: pageDictionary[index],
                          study_id: study_id || "",
                          userToken: userAuthInfo.userToken,
                        });

                        //return to Mon projet perfomance/Rentabilité/Impact environnemental page from Récapitulatif
                        if (
                          study_type === IOreeStudyType.RENOVATION_GLOBALE &&
                          currentStepIndex === 5
                        ) {
                          return (
                            goTo &&
                            goTo({
                              newCurrentStepIndex: 4,
                              newCurrentSubStepIndex: 1,
                              isGoforwardAllowed: true,
                            })
                          );
                        }

                        goTo({
                          newCurrentStepIndex: index,
                          newCurrentSubStepIndex: 0,
                          isGoforwardAllowed: false,
                        });
                      }}
                    >
                      <h1>{title}</h1>
                      <div
                        className={`NavigationBar__Underlined ${
                          isStepActive && "UnderlinedActive"
                        } ${isStepPassed && "UnderlinePassed"}`}
                      ></div>
                    </div>
                  );
                })
              : null}
          </div>

          {isBurgerMenuVisible && (
            <div
              onClick={() => {
                setMenuBurgerShown(true);
              }}
              className="NavigationBar__burgerMenu"
            >
              <img src={burgerMenu} alt="burger menu" />
            </div>
          )}
        </div>
      </Layout>

      <div
        className={
          menuBurgerShown
            ? "NavigationBar__containerMenu opened"
            : "NavigationBar__containerMenu"
        }
      >
        <div className="NavigationBar__containerMenu__menu">
          <div onClick={() => navigate("/")} className="Profile">
            <img src={home} alt="profil" />
            <h2>Orée</h2>
          </div>
          <li className="Separate"></li>
          <ul>
            <li onClick={() => navigate("/oree-home")}>
              <img src={start} alt="profil" />
              <p>Nouvelle étude</p>
            </li>
            <li onClick={() => navigate("/mes-etudes")}>
              <img src={study} alt="profil" />
              <p>Mes études</p>
            </li>
            {userAuthInfo.companyId ===
            "b997f1a6-1bf0-e911-a812-000d3a4aabe1" ? (
              // b997f1a6-1bf0-e911-a812-000d3a4aabe1 => tucoenergie
              <li onClick={() => navigate("/projetGedeon")}>
                <img src={source} alt="profil" />
                <p>Carte des installations</p>
              </li>
            ) : null}
            <li
              className="Deconnexion"
              onClick={() => {
                disconnectApp();
                navigate("/");
              }}
            >
              <img src={logOut} alt="se déconnecter pictogramme" />
              Se déconnecter
            </li>
          </ul>

          <img
            onClick={() => {
              setMenuBurgerShown(false);
            }}
            src={burgerMenuClose}
            alt=" close"
            className="close"
          />
        </div>
      </div>
    </>
  );
};
