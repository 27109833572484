import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

import { IConsumptionData } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  userToken: string | null;
  building_id: string | null;
  household_id: string | null;
  study_id?: string | null;
}

export const useGetMesConsommations = () => {
  const BACKEND_URL = process.env.REACT_APP_EERO_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [allConsumptionData, setAllConsumptionData] =
    useState<IConsumptionData>();

  const getMesConsommations = async ({
    userToken,
    building_id,
    household_id,
    study_id,
  }: Props) => {
    const response = await axiosGetRequest<IConsumptionData>(
      `${BACKEND_URL}/oree2/studies/${study_id}/householdConsumption?household_id=${household_id}&building_id=${building_id}`,

      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    response && setAllConsumptionData(response);
    return response;
  };
  return {
    getMesConsommations,
    isLoading,
    errorMessage: axiosErrorMessage,
    allConsumptionData,
  };
};
