//interfaces import
import {
  FinancingData,
  HelpsOptions,
  ICommandeData,
  ISageProduct,
  IScenario,
  IStudyInformation,
  IUserAuthInfo,
} from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IProjectKPI,
} from "../../../interfaces/renoglobaleInterface";

//dictionaries import
import { CRMlabelToValueDictionnary } from "@web/shared/dist/datas/CRMvalueToLabelDictionary";

//utils import
import { formatEtudeLogement } from "./formatEtudeLogement";
import { formatFinancementInfos } from "./formatFinancementInfos";
import { formatListeProduit } from "./formatListeProduit";
import { formatPotentielSolaire } from "./formatPotentielSolaire";
import { formatPropect } from "./formatProspect";
import { formatVosConso } from "./formatVosConso";

interface IProps {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  scenarioChosen?: IScenario;
  idDevisBecqe: string;
  cartProducts?: IDataProduct[];
  SAGEItemsInfo?: ISageProduct[];
  saveWithoutCreateBDC?: boolean;
  coupons?: {
    label: string;
    type: string;
    value: number;
    isMembershipCode: boolean;
  }[];
  RGProjectKPI?: IProjectKPI | null;
  devisOptions: HelpsOptions;
  financingInformationFormatForCRM?: FinancingData;
  bdcStatus: string | undefined;
  restToPay?: number;
}

//exported functions
export const formatBDCPayload = ({
  studyInformation,
  scenarioChosen,
  idDevisBecqe,
  cartProducts,
  SAGEItemsInfo,
  userAuthInfo,
  saveWithoutCreateBDC = false,
  coupons,
  RGProjectKPI,
  devisOptions,
  financingInformationFormatForCRM,
  bdcStatus,
  restToPay = 0,
}: IProps): ICommandeData => {
  return {
    prospect: formatPropect({ studyInformation, userAuthInfo }),

    etude_logement: formatEtudeLogement(studyInformation),

    potentiel_solaire: formatPotentielSolaire(studyInformation),

    vos_conso: formatVosConso(studyInformation),

    liste_produit: formatListeProduit({
      studyInformation,
      scenarioChosen,
      devisOptions,
      RGProjectKPI,
      cartProducts,
      SAGEItemsInfo,
    }),

    code_promo: {
      codePromotion: {
        label:
          coupons?.find((coupon) => !coupon.isMembershipCode)?.label || null,
        value:
          coupons?.find((coupon) => !coupon.isMembershipCode)?.value || null,
      },
      codeParrainage:
        coupons?.find((coupon) => coupon.isMembershipCode)?.label || null,
    },

    idDevisBecqe: idDevisBecqe,
    typeEtude:
      CRMlabelToValueDictionnary("studyType", studyInformation.study_type) ||
      629950038,

    onlyquote: saveWithoutCreateBDC,

    financementInfos: formatFinancementInfos(
      financingInformationFormatForCRM,
      restToPay
    ),

    // In case the sale order (bdc) is canceled the subscription to a new sale order is considered as a new one (and not a replacement)
    orderToCancelId:
      bdcStatus?.toLowerCase() === "annulé" // "annulé" => status received from CRM
        ? null
        : studyInformation.last_bdc_id || null,
  };
};
