//Stylesl import
import { DSColors } from "@web/shared/src/styles/variables";
import "./ITEPage.scss";

//Libraries import
import find from "lodash.find";
import { useState } from "react";
import SVG from "react-inlinesvg";

// Components import
import { Dropdown } from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";

import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";
import { ProductFilterContainer } from "../filterComponent/ProductFilterContainer";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  ProductFormFilterOption,
  TFraisSupp,
  TProjectPages,
  TSingleHelp,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { filterSageProductsAccordingToFormValues } from "../../../services/RenovationGlobale/filterSageProductsAccordingToFormValues";
import { formatDropdownOptionsFromSageProducts } from "../../../services/RenovationGlobale/formatDropdownOptionsFromSageProducts";
import { formatSageProductsForDropdown } from "../../../services/RenovationGlobale/formatProductSelectedForDropdown";

// Local interfaces declaration
interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: (props: {
    cartProductsToDisplay: IDataProduct[];
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const ITEPage = ({
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { SAGEItemsInfo: productsFromSage } = useAppSelector((state) => state);

  // We filter the SAGE products to only keep the SAGE ITE products that are in the dataProductsFromCategory products list from data backend (= products that are elligible for the house)
  const productsCharacteristicsListForITEFromSAGE = productsFromSage.filter(
    (itemInfo) =>
      dataProductsFromCategory.some(
        (dataProduct) => dataProduct.sage_reference === itemInfo.AR_Ref
      )
  );

  const cartProductsToDisplay = useAppSelector((state) => state.cartProducts);
  const selectedProduct =
    selectedProductIndex || selectedProductIndex === 0
      ? cartProductsToDisplay[selectedProductIndex]
      : null;

  // States
  const initialBrandValue = {
    sageProductKey: "Marque" as keyof ISageProduct,
    label: null,
    value: null,
  };
  const initialIsolationTypeValue = {
    sageProductKey: "Gamme" as keyof ISageProduct,
    label: null,
    value: null,
  };
  const [productSelected, setProductSelected] = useState<IOption[]>(
    selectedProduct && cartProductsToDisplay
      ? [
          {
            label:
              selectedProduct.product_alias || selectedProduct.product_name,
            value: selectedProduct.sage_reference,
          },
        ]
      : []
  );

  const [initialHelpPerMeter, setInitialHelpPerMeter] = useState<
    TSingleHelp[] | null
  >(
    (selectedProduct &&
      dataProductsFromCategory?.filter(
        (product) => product.sage_reference === selectedProduct.sage_reference
      )[0]?.help) ||
      null
  );
  const [brand, setBrand] =
    useState<ProductFormFilterOption>(initialBrandValue);
  const [isolationType, setIsolationType] = useState<ProductFormFilterOption>(
    initialIsolationTypeValue
  );
  const [surfaceToIsolate, setSurfaceToIsolate] = useState<number | null>(
    selectedProduct && cartProductsToDisplay
      ? Math.round(
          selectedProduct.selected_characteristics.surface_to_isolate || 0
        )
      : 0
  );
  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    selectedProduct && cartProductsToDisplay
      ? selectedProduct.extra_charges_added || []
      : []
  );
  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);

  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProductsFromCategory
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.some((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  const productToAdd = (() => {
    const newProductList: IDataProduct[] = JSON.parse(
      JSON.stringify(dataProductsFromCategory)
    ); //Deep Copy avoid to updtate products_list

    const product = newProductList.find(
      (product) => product.sage_reference === productSelected?.[0]?.value
    );
    if (!product) {
      return undefined;
    }

    product.extra_charges_added = fraisSupp;
    product.selected_characteristics.surface_to_isolate =
      surfaceToIsolate || undefined;

    // As helps come  €/square meter we need to multiply by the surface
    product.help.map((singleHelp) => {
      const maxSurfaceEligibleForHelp =
        singleHelp.help_type === "mpr" && (surfaceToIsolate || 0) > 100
          ? 100
          : surfaceToIsolate || 0; // ITE "mpr" help can't be calculate for more than 100m²
      const initialSingleHelp = initialHelpPerMeter?.find(
        (initialHelp) => initialHelp.name === singleHelp.name
      );
      if (initialSingleHelp) {
        return (singleHelp.value =
          maxSurfaceEligibleForHelp * (initialSingleHelp.value || 0));
      }
      return singleHelp;
    });

    return product;
  })();

  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product selected we can not add it to the selectin
    }

    productToAdd.isProductRecommended =
      find(cartProductsToDisplay, productToAdd)?.isProductRecommended || false;
    productToAdd.isSelected = true;

    //If we have an selectedProductIndex it means the product is already in the recommended products : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProductsToDisplay];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );

      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProductsToDisplay, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: filterSageProductsAccordingToFormValues({
      sageProducts: productsCharacteristicsListForITEFromSAGE,
      filterValues: [brand, isolationType],
    }),
    dataProducts: dataProductsFromCategory,
  });

  return productsCharacteristicsListForITEFromSAGE.length === 0 ? (
    <div>Pas de produits disponible</div>
  ) : (
    <div className="ITEPage">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />
      <section className="ITEPage__FormContainer">
        <div className="ITEPage__SubTitle">
          <h3>Produit : </h3>
        </div>
        <div className="ITEPage__ProductContainer">
          <div className="ITEPage__Dropdown">
            <Dropdown
              options={sageProductFormatedForDropdown}
              optionsSelected={productSelected}
              onSelect={(option) => {
                setInitialHelpPerMeter(() => {
                  return (
                    dataProductsFromCategory?.find(
                      (elem: IDataProduct) =>
                        elem.sage_reference === option.value
                    )?.help || null
                  );
                });
                setProductSelected(() => [option]);
              }}
              multipleChoices={false}
              tags={false}
              placeholder="Choisir mon isolation"
              maxHeight="40vh"
              searchable={true}
            />
          </div>
          <div className="ITEPage__SVG">
            {productSelected?.[0]?.value ? (
              <SVG src={Trash} onClick={() => setProductSelected([])} />
            ) : null}
          </div>
        </div>

        <div className="ITEPage__SubTitle">
          <h3>Surface (m²):</h3>
        </div>
        <div>
          <div className="ITEPage__Question">
            <div className="ITEPage__Textinput">
              <TextInput
                inputLableHidden
                type="number"
                inputDescriptionHidden
                iconType="squaredMeters"
                iconHidden={false}
                placeholder="Surface à isoler"
                value={surfaceToIsolate?.toString()}
                onChange={(e) => {
                  setSurfaceToIsolate(e.target.valueAsNumber);
                }}
              />
            </div>
            <div className="ITEPage__SVG">
              {surfaceToIsolate ? (
                <SVG src={Trash} onClick={() => setSurfaceToIsolate(null)} />
              ) : null}
            </div>
          </div>
        </div>

        <ProductFilterContainer>
          <div className="ITEPage__FiltersContent">
            <div className="ITEPage__Question">
              <div className="ITEPage__Dropdown">
                <Dropdown
                  options={formatDropdownOptionsFromSageProducts({
                    sageProducts: filterSageProductsAccordingToFormValues({
                      sageProducts: productsCharacteristicsListForITEFromSAGE,
                      filterValues: [isolationType], // We want to filter all the form values except the current one, to avoid not being able to select another value after selecting the first one
                    }),
                    productCharacteristic: "Marque",
                  })}
                  optionsSelected={brand.value ? [brand] : []}
                  onSelect={(option) => {
                    setBrand((state) => {
                      return { ...state, ...option };
                    });
                  }}
                  multipleChoices={false}
                  tags={false}
                  placeholder="Marque"
                />
              </div>
              <div className="ITEPage__SVG">
                {brand?.value ? (
                  <SVG
                    src={Trash}
                    onClick={() => setBrand(initialBrandValue)}
                  />
                ) : null}
              </div>
            </div>

            <div className="ITEPage__Question">
              <div className="ITEPage__Dropdown">
                <Dropdown
                  options={formatDropdownOptionsFromSageProducts({
                    sageProducts: filterSageProductsAccordingToFormValues({
                      sageProducts: productsCharacteristicsListForITEFromSAGE,
                      filterValues: [brand], // We want to filter all the form values except the current one, to avoid not being able to select another value after selecting the first one
                    }),
                    productCharacteristic: "Gamme",
                  })}
                  optionsSelected={isolationType.value ? [isolationType] : []}
                  onSelect={(option) => {
                    setIsolationType((state) => {
                      return { ...state, ...option };
                    });
                  }}
                  multipleChoices={false}
                  tags={false}
                  placeholder="Type d'isolation"
                />
              </div>
              <div className="ITEPage__SVG">
                {isolationType?.value ? (
                  <SVG
                    src={Trash}
                    onClick={() => setIsolationType(initialIsolationTypeValue)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </ProductFilterContainer>
      </section>
      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}
      {fraisSupp.length > 0 ? (
        <section className="ITEPage__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}
      <section className="ITEPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {productToAdd && surfaceToIsolate ? (
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      ) : null}
    </div>
  );
};
