import "./CheckoutButtonsDefault.scss";

// Libraries import
import { useState } from "react";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { ErrorBoundary } from "../../ErrorBoundary/ErrorBoundary";

// Services import
import { useCreateOree2Study } from "../../../services/MesInformations/useCreateOree2Study";
import { useSaveLastPageVisited } from "../../../services/Oree2/useSaveLastPageVisited";
import { switchToRenoGlobaleStudy } from "../../../services/Recapitulatif/switchToRenoGlobaleStudy";
import { useSaveCartProducts } from "../../../services/RenovationGlobale/MonProjet/useSaveCartProducts";

// Interfaces import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { FetchBdcStatusResponse } from "../../../api/hooks/useGetBdcStatus";
import { AUTHORIZATION_RIGHTS_LIST } from "../../../data/authorization_rights_list";
import {
  IScenario,
  IStudyInformation,
  IUserAuthInfo,
} from "../../../interfaces/generalInterfaces";
import { useAppSelector } from "../../../redux/store/hook";

// Local interfaces delcaration
interface IProps {
  isGetInformationForPDFEtudeLoading?: boolean;
  scenarioChosen?: IScenario;
  userAuthInfo: IUserAuthInfo;
  studyInformation: IStudyInformation;
  errorMessages?: string;
  setIsSubscriptionModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaveModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  bdcStatus?: FetchBdcStatusResponse;
  isLoadingGetBdcStatus: boolean | undefined;
  isErrorGetBdcStatus: boolean | undefined;
  errorMessageGetBdcStatus: string | undefined;
}

export const CheckoutButtonsDefault = ({
  isGetInformationForPDFEtudeLoading,
  scenarioChosen,
  userAuthInfo,
  studyInformation,
  errorMessages,
  setIsSubscriptionModaleOpen,
  setIsSaveModaleOpen,
  bdcStatus,
  isLoadingGetBdcStatus,
  isErrorGetBdcStatus,
  errorMessageGetBdcStatus,
}: IProps) => {
  // Redux hooks
  const authorization_rights = useAppSelector(
    (state) => state.commercialUserInformation.authorization_rights
  );

  // Custom hooks
  const { saveCartProducts, errorMessage: errorMessageSaveCartProducts } =
    useSaveCartProducts();
  const {
    createOree2Study,
    errorMessage: errorMessageSaveCustomerInformation,
  } = useCreateOree2Study();
  const { saveLastPageVisited } = useSaveLastPageVisited();

  // Local state declaration
  const [
    isLoadingSwitchToRenoGlobaleStudy,
    setIsLoadingSwitchToRenoGlobaleStudy,
  ] = useState<boolean>(false);

  // Events handlers
  const handleSwitchToRenoGlobaleStudy = async () => {
    setIsLoadingSwitchToRenoGlobaleStudy(true);
    await switchToRenoGlobaleStudy({
      saveCartProducts,
      createOree2Study,
      userAuthInfo,
      studyInformation,
      scenarioChosen,
      saveLastPageVisited,
    });
    setIsLoadingSwitchToRenoGlobaleStudy(false);
  };

  const onClickOnCheckout = () => {
    setIsSubscriptionModaleOpen(true);
  };

  // A sale order is on going if we have a bdc_id and the status is not to canceled
  const isSaleOrderOnGoing =
    studyInformation.last_bdc_id &&
    bdcStatus?.state?.toLowerCase() !== "annulé";

  //If we have a sage_reference_PV it means we are in a PV study
  const isPvStudy = !!scenarioChosen?.sage_reference_PV;

  const isUserAuthorizedRenovationGlobale = authorization_rights?.includes(
    AUTHORIZATION_RIGHTS_LIST.oree2_renovationglobale
  );

  return (
    <div className="CheckoutButtonsDefault">
      <BdcStatus
        bdcStatus={bdcStatus}
        isLoading={isLoadingGetBdcStatus}
        isError={isErrorGetBdcStatus}
        errorMessage={errorMessageGetBdcStatus}
      />

      <div className="CheckoutButtonsDefault__ButtonsContainer">
        <CheckoutButton
          bdcStatus={bdcStatus?.state}
          isLoading={isGetInformationForPDFEtudeLoading}
          onClick={onClickOnCheckout}
        />

        {/* If we are in a PV study and a sale order is not on going, we allow to switch to a RG study, to allow up-sale */}
        {isPvStudy &&
        !isSaleOrderOnGoing &&
        isUserAuthorizedRenovationGlobale ? (
          <div className="CheckoutButtonsDefault__RenoGlobaleButton">
            <CTAButton
              name="Poursuivre vers une rénovation globale"
              onClick={handleSwitchToRenoGlobaleStudy}
              isLoading={
                isLoadingSwitchToRenoGlobaleStudy ||
                isGetInformationForPDFEtudeLoading
              }
              category="tertiary"
            />
          </div>
        ) : null}

        {/* We allow to save the study only if a sale order is not on going
        to avoid generating a new quote without cancelling the ongoing sale order*/}
        {!isSaleOrderOnGoing ? (
          <div className="CheckoutButtonsDefault__SaveButton">
            <CTAButton
              name="Enregistrer"
              category="secondary"
              isLoading={isGetInformationForPDFEtudeLoading}
              onClick={() => setIsSaveModaleOpen(true)}
            />
          </div>
        ) : null}
      </div>

      <div className="CheckoutButtonsDefault__ErrorMessageContainer">
        <ErrorMessage
          errorMessage={
            errorMessages ||
            errorMessageSaveCustomerInformation ||
            errorMessageSaveCartProducts
          }
        />
      </div>
    </div>
  );
};

interface BdcStatusProps {
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  bdcStatus?: FetchBdcStatusResponse;
}

const BdcStatus = (props: BdcStatusProps) => {
  if (props.isLoading)
    return (
      <div className="BdcStatus">
        <div className="BdcStatus__LoaderContainer">
          <p>Chargement du statut du bon de commande</p>
          <LoadingComponent diameter={40} />
        </div>
      </div>
    );

  if (props.isError)
    return (
      <div className="BdcStatus">
        <div className="BdcStatus__ErrorMessage">
          <ErrorMessage
            errorMessage={props.errorMessage || "Une erreur s'est produite"}
          />
        </div>
      </div>
    );

  // If we don't have a bdc status we don't want to diplay it
  if (!props.bdcStatus?.state) return null;

  return (
    <ErrorBoundary>
      <div className="BdcStatus">
        <div className="BdcStatus__Info">
          <p>
            <strong>Statut du bon de commande :</strong>
          </p>{" "}
          <p>{props.bdcStatus.state}</p>
        </div>
        {props.bdcStatus?.statusReason && (
          <div className="BdcStatus__Info">
            <p>
              <strong>Information :</strong>
            </p>{" "}
            <p>{props.bdcStatus?.statusReason}</p>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

interface CheckoutButtonProps {
  bdcStatus?: string | null;
  isLoading?: boolean;
  onClick: () => void;
}

const CheckoutButton = (props: CheckoutButtonProps) => {
  const lastBdcId = useAppSelector(
    (state) => state.studyInformation.last_bdc_id
  );
  if (lastBdcId && props.bdcStatus?.toLowerCase() !== "annulé")
    return (
      <div className="CheckoutButton">
        <CTAButton
          name="Annuler et remplacer"
          onClick={props.onClick}
          isLoading={props.isLoading}
        />
      </div>
    );

  if (lastBdcId && props.bdcStatus?.toLowerCase() === "annulé")
    return (
      <div className="CheckoutButton">
        <CTAButton
          name="Souscrire à la nouvelle offre"
          onClick={props.onClick}
          isLoading={props.isLoading}
        />
      </div>
    );

  // Default button
  return (
    <div className="CheckoutButton">
      <CTAButton
        name="Souscrire à l’offre"
        onClick={props.onClick}
        isLoading={props.isLoading}
      />
    </div>
  );
};
