// Libraries imports
import { createAction } from "@reduxjs/toolkit";

// Other imports
import * as types from "./constants";

// Interfaces import
import {
  CompanySettings,
  DataPersonalizedScenarioPvExtended,
  ICommercialUserInformation,
  IDataScenarioPvExtended,
  IDimensionnement,
  IImpactEnvironnemental,
  IPerformance,
  IProfitability,
  ISageProduct,
  IScenario,
  ISolarPotentialStepsStatus,
  IStudyInformation,
  IUserAuthInfo,
} from "../interfaces/generalInterfaces";
import { IDataProduct, IProjectKPI } from "../interfaces/renoglobaleInterface";

// REDUX ACTIONS TO CALL WHEN REDUX STORE MODIFICATION IS NEEDED

// Reset redux state
export const setInitalStateAction = createAction(types.SET_INITAL_STATE);

// Save user authentication information in redux
export const setUserAuthInfoAction = createAction<{
  userAuthInfo: IUserAuthInfo;
}>(types.SET_USER_AUTH_INFO);

export const setCommercialUserInfoAction = createAction<{
  commercialUserInformation: ICommercialUserInformation;
}>(types.SET_COMMERCIAL_USER_INFORMATION);

export const setStudyInfoAction = createAction<{
  studyInformation: IStudyInformation | null;
}>(types.SET_STUDY_INFO);

export const setMesScenariosInfoAction = createAction<{
  mesScenarios: IDataScenarioPvExtended[];
}>(types.SET_MES_SCENARIOS_INFO);

export const setIndexScenariosOptionChosenAction = createAction<{
  indexOptionChosen: number | null;
}>(types.SET_INDEX_SCENARIOS_OPTION_CHOSEN);

export const setPersonalizedScenarioAction = createAction<{
  personalizedScenario: DataPersonalizedScenarioPvExtended;
}>(types.SET_PERSONALIZED_SCENARIO);

export const setDimensionnementAction = createAction<{
  dimensionnement: IDimensionnement;
}>(types.SET_DIMENSIONNEMENT);

export const setPerformanceAction = createAction<{
  performance: IPerformance;
}>(types.SET_PERFORMANCE);

export const setProfitabilityAction = createAction<{
  profitability: IProfitability;
}>(types.SET_PROFITABILITY);

export const setImpactEnvironnementalAction = createAction<{
  impactEnvironnemental: IImpactEnvironnemental;
}>(types.SET_IMPACTENVIRONNEMENTAL);

export const resetAllStudyInformationAction = createAction<null>(
  types.RESET_ALL_STUDY_INFO
);

// Save mes informations info in redux
export const setSAGEItemsInfoAction = createAction<{
  SAGEItemsInfo: ISageProduct[];
  isFlushed?: boolean; // Delete all the previous items in the redux store
}>(types.SET_SAGE_ITEMS_INFO);

export const setScenarioChosenAction = createAction<{
  scenarioChosen: Partial<IScenario>;
}>(types.SET_SCENARIO_CHOSEN);

export const setCartProductsAction = createAction<{
  cartProducts: IDataProduct[];
}>(types.SET_CART_PRODUCTS);

export const setRGProjectKPIAction = createAction<{
  projectKPI: IProjectKPI | null;
}>(types.SET_RG_PROJECT_KPI);

export const setSolarPotentialStepsStatusAction = createAction<{
  solarPotentialStepsStatus: ISolarPotentialStepsStatus;
}>(types.SET_SOLAR_POTENTIAL_STEPS_STATUS);

export const setCompanySettingsAction = createAction<{
  companySettings: CompanySettings;
}>(types.SET_COMPANY_SETTINGS);
