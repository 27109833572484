import { WindowsInputFormData } from "../../pages/MonLogementRG/MonLogementRG.types";
import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

export interface SaveLogementRequest {
  annee_de_construction?: number | null;
  nombre_de_niveaux?: number | null;
  nombre_de_pieces?: number | null;
  hauteur_sous_plafond?: number | null;
  surface_au_sol?: number | null;
  house_surface?: number | null;
  annee_isolation_murs?: string | null;
  annee_isolation_combles?: string | null;
  annee_isolation_sous_sol?: string | null;
  annee_isolation_garage?: string | null;
  type_de_murs?: (string | null)[];
  type_de_combles?: (string | null)[];
  type_de_ventilation?: (string | null)[];
  type_de_sous_sol?: (string | null)[];
  type_de_garage?: (string | null)[];
  number_of_occupants?: number | null;
  fiscally_french?: boolean | null;
  occupation_type?: string | null;
  income_color?: string | null;
  fenetres?: WindowsInputFormData;
  portes_fenetres?: WindowsInputFormData;
}

type SaveLogementVariables = {
  study_id: string;
  payload: SaveLogementRequest;
};

type SaveLogementResponse = { message: string };

type SaveLogementErrorResponse = {
  details?: { message: string }[];
};

async function saveLogement(variables: SaveLogementVariables) {
  const response = await eeroApi.put<SaveLogementResponse>(
    `oree2/rg/saveLogement/${variables.study_id}`,
    variables.payload
  );
  return response.data;
}

/**
 * Custom hook for saving logement data into database using eero API.
 * Return the mutation result and an error message if the request failed.
 */

export function useSaveLogementRq() {
  const mutation = useAuthMutation<
    SaveLogementResponse,
    SaveLogementVariables,
    SaveLogementErrorResponse
  >({
    mutationFn: saveLogement,
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (mutation.isError) {
    const requestErrorMessage = mutation.error.response?.data?.details
      ?.map((detail) => detail.message)
      .join("\n");

    errorMessage =
      requestErrorMessage ||
      mutation.genericErrorMessage ||
      "Impossible de sauvegarder les données de logement.";
  }

  return { ...mutation, errorMessage };
}
