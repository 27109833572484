// Components import
import { CETPage } from "../../pages/ProductsPageRG/CETPage/CETPage";
import { FreeProduct } from "../../pages/ProductsPageRG/FreeProduct/FreeProduct";
import { ISOComblesPage } from "../../pages/ProductsPageRG/ISOComblesPage/ISOComblesPage";
import { ISOPlancherPage } from "../../pages/ProductsPageRG/ISOPlancher/ISOPlancher";
import { ISORampantsPage } from "../../pages/ProductsPageRG/ISORampants/ISORampantsPage";
import { ITEPage } from "../../pages/ProductsPageRG/ITEPage/ITEPage";
import { ITIPage } from "../../pages/ProductsPageRG/ITIPage/ITIPage";
import { PACAirAirPage } from "../../pages/ProductsPageRG/PACAirAirPage/PACAirAirPage";
import { PACAirEauPage } from "../../pages/ProductsPageRG/PACAirEauPage/PACAirEauPage";
import { PVProductPage } from "../../pages/ProductsPageRG/PVProductPage/PVProductPage";
import { VMCPage } from "../../pages/ProductsPageRG/VMCPage/VMCPage";
import { WindowPage } from "../../pages/ProductsPageRG/WindowPage/WindowPage";

// Interface import
import {
  IDataProduct,
  TProjectPages,
} from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  selectedProductCategory: string;
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay?: IDataProduct[] | undefined;
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}

export const selectProductPageToDisplay = ({
  selectedProductCategory,
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  switch (selectedProductCategory) {
    case "PV":
      return (
        <PVProductPage
          dataProductsFromCategory={dataProductsFromCategory}
          selectedProductIndex={selectedProductIndex}
          setScreenSelected={setScreenSelected}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "ITE":
      return (
        <ITEPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "ITI":
      return (
        <ITIPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "ISO":
      return (
        <ISOComblesPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "ISOA":
      return (
        <ISORampantsPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "ISOP":
      return (
        <ISOPlancherPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "CET":
    case "BALLON":
      return (
        <CETPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "PACAIRO":
      return (
        <PACAirEauPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "VMC":
      return (
        <VMCPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "PACAIRAIR":
      return (
        <PACAirAirPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "FENETRES":
      return (
        <WindowPage
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    case "AUTRE":
      return (
        <FreeProduct
          dataProductsFromCategory={dataProductsFromCategory}
          setScreenSelected={setScreenSelected}
          selectedProductIndex={selectedProductIndex}
          fetchProjectKPI={fetchProjectKPI}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      );
    default:
      return null;
  }
};
