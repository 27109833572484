//Styles import
import { DSColors } from "@web/shared/src/styles/variables";
import "./WindowPage.scss";

// Libraries import
import find from "lodash.find";
import { useState } from "react";
import SVG from "react-inlinesvg";

// Components import
import { BoutonPicto } from "@web/shared/dist/components/DesignSystem/Boutons/BoutonPicto/BoutonPicto";
import { Dropdown } from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { ExtraChargesCarrousel } from "../../../containers/ProductPageRG/ExtraChargesCarrousel/ExtraChargesCarrousel";
import { ExtraChargesListItem } from "../../../containers/ProductPageRG/ExtraChargesListItem/ExtraChargesListItem";
import { ExtraChargesModal } from "../../../containers/ProductPageRG/ExtraChargesModal/ExtraChargesModal";
import { ProductTotalPrice } from "../../../containers/ProductPageRG/ProductTotalPrice/ProductTotalPrice";
import { Window } from "../../../containers/Window/Window";
import { ProductFilterContainer } from "../filterComponent/ProductFilterContainer";

// Images import
import Trash from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";

// Interfaces import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  ProductFormFilterOption,
  TFraisSupp,
  TProjectPages,
  WindowProduct,
  WindowType,
} from "../../../interfaces/renoglobaleInterface";

//Services import
import { useImmer } from "use-immer";
import { PacAirAirQuantiteContainer } from "../../../containers/PacAirAirQuantiteContainer/PacAirAirQuantiteContainer";
import { setCartProductsAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { filterSageProductsAccordingToFormValues } from "../../../services/RenovationGlobale/filterSageProductsAccordingToFormValues";
import { formatDropdownOptionsFromSageProducts } from "../../../services/RenovationGlobale/formatDropdownOptionsFromSageProducts";
import { formatSageProductsForDropdown } from "../../../services/RenovationGlobale/formatProductSelectedForDropdown";
import {
  isFrenchWindowReference,
  isWindowReference,
} from "../../../utils/sageUtils";
import { defaultWindowsInputFormData } from "../../MonLogementRG/MonLogementRG.types";

// Local interfaces declaration

interface IOption {
  label: string | null;
  value: string | null;
}

interface IProps {
  dataProductsFromCategory: IDataProduct[];
  selectedProductIndex?: number | null;
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay: IDataProduct[];
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}
export const WindowPage = ({
  dataProductsFromCategory,
  selectedProductIndex,
  setScreenSelected,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const storedWindows = useAppSelector(
    (state) => state.studyInformation.fenetres || defaultWindowsInputFormData
  );
  const storedFrenchWindows = useAppSelector(
    (state) =>
      state.studyInformation.portes_fenetres || defaultWindowsInputFormData
  );

  const productsFromSage = useAppSelector((state) => state.SAGEItemsInfo);
  const cartProducts = useAppSelector((state) => state.cartProducts);

  const totalStoredWindows = {
    windows: storedWindows.simple + storedWindows.double + storedWindows.triple,
    frenchWindows:
      storedFrenchWindows.simple +
      storedFrenchWindows.double +
      storedFrenchWindows.triple,
  };

  // We filter the SAGE products to only keep the SAGE window products that are in the dataProductsFromCategory products list from data backend (= products that are elligible for the house)
  const windowSageProducts = productsFromSage.filter((itemInfo) =>
    dataProductsFromCategory.some(
      (dataProduct) => dataProduct.sage_reference === itemInfo.AR_Ref
    )
  );

  const cartSelectedProduct =
    selectedProductIndex || selectedProductIndex === 0
      ? cartProducts[selectedProductIndex]
      : null;

  // States
  const [selectedSageProduct, setSelectedSageProduct] = useState<IOption[]>(
    cartSelectedProduct
      ? [
          {
            label:
              cartSelectedProduct.product_alias ||
              cartSelectedProduct.product_name,
            value: cartSelectedProduct.sage_reference,
          },
        ]
      : []
  );

  const selectedSageReference = selectedSageProduct[0]?.value;
  const selectedSageLabel = selectedSageProduct[0]?.label;

  const initialBrandValue = {
    sageProductKey: "Marque" as keyof ISageProduct,
    label: null,
    value: null,
  };
  const initialGammeValue = {
    sageProductKey: "Gamme" as keyof ISageProduct,
    label: null,
    value: null,
  };
  const [brand, setBrand] =
    useState<ProductFormFilterOption>(initialBrandValue);
  const [gamme, setGamme] =
    useState<ProductFormFilterOption>(initialGammeValue);

  const [windows, setWindows] = useImmer<WindowProduct[]>(
    cartSelectedProduct?.selected_characteristics.windows || []
  );
  const [fraisSupp, setFraisSupp] = useState<TFraisSupp[]>(
    cartSelectedProduct?.extra_charges_added || []
  );
  const [isModaleFraisSuppOpen, setIsModaleFraisSuppOpen] =
    useState<boolean>(false);

  // Constants
  const initialFilteredListFraisSuppValue: TFraisSupp[] = [];

  const windowTypeLabel = isWindowReference(selectedSageReference)
    ? WindowType.FENETRE
    : WindowType.PORTE_FENETRE;

  const simpleWindowQuantity = isWindowReference(selectedSageReference)
    ? storedWindows.simple
    : storedFrenchWindows.simple;

  // Merge all frais supp of all products in one array and remove duplicate
  const filteredListPotentialFraisSupp = dataProductsFromCategory
    .reduce((acc, product) => {
      return [...acc, ...product.potential_products_to_add];
    }, initialFilteredListFraisSuppValue)
    .reduce((accTwo, fraisSupp) => {
      if (
        accTwo.find((elem) => elem.sage_reference === fraisSupp.sage_reference)
      ) {
        return accTwo;
      }
      return [...accTwo, fraisSupp];
    }, initialFilteredListFraisSuppValue);

  // IIFE that re-build the product object to add to the cart each time a form value is updated
  const productToAdd = (() => {
    const newProductList: IDataProduct[] = structuredClone(
      dataProductsFromCategory
    );

    const product = newProductList.find(
      (product) => product.sage_reference === selectedSageReference
    );
    if (!product) {
      return undefined;
    }
    product.extra_charges_added = fraisSupp;

    // We add the form value selected by the user
    product.selected_characteristics.windows = windows;
    product.selected_characteristics.productQuantity = windows.length;

    // Update help values to cap them to the number of simple windows
    product.help.forEach((singleHelp) => {
      if (singleHelp.help_type === "mpr") {
        const numberOfWindowsEligible = Math.min(
          windows.length,
          simpleWindowQuantity
        );

        singleHelp.value = numberOfWindowsEligible * singleHelp.value;
      }

      if (singleHelp.help_type === "cee") {
        const totalWindowSurface = windows.reduce((acc, window) => {
          if (!window.height || !window.width) return acc;
          return acc + window.height * window.width;
        }, 0);

        singleHelp.value = totalWindowSurface * singleHelp.value;
      }
    });

    return product;
  })();

  const isFormValid =
    productToAdd &&
    windows.length > 0 &&
    windows.every((window) => window.height && window.width && window.type);

  // Form functions
  const onClickAddWindow = ({
    value,
    sageReference,
  }: {
    value: number;
    sageReference: string | null;
  }) => {
    if (value > windows.length) {
      switch (windowTypeLabel) {
        case WindowType.FENETRE:
          if (windows.length < totalStoredWindows.windows) {
            setWindows((draft) => {
              draft.push({
                id:
                  "windowId_" +
                  Math.random().toString(36).substring(7) +
                  "_" +
                  Date.now(),
                sageReference,
                isOpen: true,
                height: 1.2,
                width: 1.0,
                type: "battante",
                comment: "",
                category: WindowType.FENETRE,
              });
            });
          }
          break;
        case WindowType.PORTE_FENETRE:
          if (windows.length < totalStoredWindows.frenchWindows) {
            setWindows((draft) => {
              draft.push({
                id:
                  "windowId_" +
                  Math.random().toString(36).substring(7) +
                  "_" +
                  Date.now(),
                sageReference,
                isOpen: true,
                height: 2.2,
                width: 1.2,
                type: "battante",
                comment: "",
                category: WindowType.PORTE_FENETRE,
              });
            });
          }
          break;
        default:
          setWindows((draft) => {
            draft.push({
              id:
                "windowId_" +
                Math.random().toString(36).substring(7) +
                "_" +
                Date.now(),
              sageReference,
              isOpen: true,
              comment: "",
              category: WindowType.FENETRE,
            });
          });
      }

      return;
    }
    setWindows((draft) => {
      draft.pop();
    });
    return;
  };

  const addProductToMySelection = () => {
    if (!productToAdd) {
      return; // If not product find we can not add it to the selection
    }

    productToAdd.isProductRecommended =
      find(cartProducts, productToAdd)?.isProductRecommended || false;
    productToAdd.isSelected = true;

    //If we have an selectedProductIndex it means the product is already in the recommended products : we need to update it
    if (selectedProductIndex || selectedProductIndex === 0) {
      const newProductToDisplay = [...cartProducts];
      newProductToDisplay.splice(selectedProductIndex, 1, productToAdd);
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    } else {
      // Otherwise we just add the new product to the recommended products
      const newProductToDisplay = [...cartProducts, productToAdd];
      dispatch(
        setCartProductsAction({
          cartProducts: newProductToDisplay,
        })
      );
      fetchProjectKPI({ cartProductsToDisplay: newProductToDisplay });
    }

    // We navigate back to the "my project" page
    setScreenSelected("project");
  };

  const onDeleteProduct = () => {
    setSelectedSageProduct([]);
    setWindows([]);
  };

  const sageProductFormatedForDropdown = formatSageProductsForDropdown({
    sageProducts: filterSageProductsAccordingToFormValues({
      sageProducts: windowSageProducts,
      filterValues: [brand, gamme],
    }),
    dataProducts: dataProductsFromCategory, // We need to pass the dataProductsFromCategory to get the alias of the product
  });

  if (windowSageProducts.length === 0) {
    return <div>Pas de produits disponible</div>;
  }

  return (
    <div className="WindowPage">
      <ExtraChargesModal
        isModaleFraisSuppOpen={isModaleFraisSuppOpen}
        setFraisSupp={setFraisSupp}
        setIsModaleFraisSuppOpen={setIsModaleFraisSuppOpen}
      />

      <section className="WindowPage__FormContainer">
        <div className="WindowPage__SubTitle">
          <h3>Produit : </h3>
        </div>
        <div className="WindowPage__ProductContent">
          <div className="WindowPage__Question">
            <div className="WindowPage__Dropdown">
              <Dropdown
                options={sageProductFormatedForDropdown}
                optionsSelected={selectedSageProduct}
                onSelect={(option) => {
                  setSelectedSageProduct([option]);
                  setWindows([]);
                }}
                multipleChoices={false}
                tags={false}
                placeholder="Fenêtre ou porte-fenêtre"
                maxHeight="32vh"
                searchable={true}
              />
            </div>
            <div className="WindowPage__SVG">
              {selectedSageProduct.length > 0 ? (
                <SVG src={Trash} onClick={onDeleteProduct} />
              ) : null}
            </div>
          </div>
        </div>
        <ProductFilterContainer>
          <div className="WindowPage__FiltersContent">
            <div className="WindowPage__QuestionContainer">
              <div className="WindowPage__Question">
                <div className="WindowPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts: filterSageProductsAccordingToFormValues({
                        sageProducts: windowSageProducts,
                        filterValues: [gamme], // We want to filter all the form values except the current one, to avoid not being able to select another value after selecting the first one
                      }),
                      productCharacteristic: "Marque",
                    })}
                    optionsSelected={brand.value ? [brand] : []}
                    onSelect={(option) => {
                      setBrand((state) => {
                        return { ...state, ...option };
                      });
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Marque"
                  />
                </div>
                <div className="WindowPage__SVG">
                  {brand?.value ? (
                    <SVG
                      src={Trash}
                      onClick={() => setBrand(initialBrandValue)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="WindowPage__QuestionContainer">
              <div className="WindowPage__Question">
                <div className="WindowPage__Dropdown">
                  <Dropdown
                    options={formatDropdownOptionsFromSageProducts({
                      sageProducts: filterSageProductsAccordingToFormValues({
                        sageProducts: windowSageProducts,
                        filterValues: [brand], // We want to filter all the form values except the current one, to avoid not being able to select another value after selecting the first one
                      }),
                      productCharacteristic: "Gamme",
                    })}
                    optionsSelected={gamme.value ? [gamme] : []}
                    onSelect={(option) => {
                      setGamme((state) => {
                        return { ...state, ...option };
                      });
                    }}
                    multipleChoices={false}
                    tags={false}
                    placeholder="Gamme"
                  />
                </div>
                <div className="WindowPage__SVG">
                  {gamme?.value ? (
                    <SVG
                      src={Trash}
                      onClick={() => setGamme(initialGammeValue)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ProductFilterContainer>
      </section>

      <section>
        {selectedSageProduct.length > 0 ? (
          <>
            <div className="WindowPage__Question">
              <PacAirAirQuantiteContainer
                title={`Nombre de ${windowTypeLabel} à ajouter :`}
                value={windows.length}
                onPlusMinusClick={(value) => {
                  onClickAddWindow({
                    value,
                    sageReference: selectedSageReference,
                  });
                }}
              />
            </div>

            {windows.length >= totalStoredWindows.windows &&
            isWindowReference(selectedSageReference) ? (
              <p className="WindowPage__UserMessage">
                Vous avez atteint le nombre maximum de fenêtres
              </p>
            ) : null}

            {windows.length >= totalStoredWindows.frenchWindows &&
            isFrenchWindowReference(selectedSageReference) ? (
              <p className="WindowPage__UserMessage">
                Vous avez atteint le nombre maximum de portes-fenêtres
              </p>
            ) : null}
          </>
        ) : null}

        <div className="WindowPage__Rooms">
          {windows.map((window, index) => {
            return (
              <Window
                key={window.id}
                window={window}
                windowIndex={index}
                setWindows={setWindows}
                name={windowTypeLabel}
                numberOfWindows={windows.length}
                maxNumberOfWindows={
                  isWindowReference(selectedSageReference)
                    ? totalStoredWindows.windows
                    : isFrenchWindowReference(selectedSageReference)
                    ? totalStoredWindows.frenchWindows
                    : 0
                }
              />
            );
          })}
        </div>
      </section>

      {filteredListPotentialFraisSupp.length > 0 ? (
        <ExtraChargesCarrousel
          filteredListFraisSupp={filteredListPotentialFraisSupp}
          setFraisSupp={setFraisSupp}
        />
      ) : null}

      {fraisSupp.length > 0 ? (
        <section className="WindowPage__ListFraisSuppContainer">
          {fraisSupp.map((extraCharge, index) => {
            return (
              <ExtraChargesListItem
                key={index + extraCharge.product_name}
                extraCharge={extraCharge}
                setFraisSupp={setFraisSupp}
              />
            );
          })}
        </section>
      ) : null}

      <section className="WindowPage__ButtonPicto">
        {
          <BoutonPicto
            text="Ajouter une spécificité"
            color={DSColors.Mint}
            onClick={() => setIsModaleFraisSuppOpen(true)}
          />
        }
      </section>

      {isFormValid ? (
        <ProductTotalPrice
          addProductToMySelection={addProductToMySelection}
          fraisSupp={fraisSupp}
          productSelected={productToAdd}
          isUpdateproduct={selectedProductIndex !== undefined}
          isEligibleRenoGlobal={isEligibleRenoGlobal}
        />
      ) : windows.length > 0 ? (
        <p className="WindowPage__UserMessage">
          {`Informations manquantes pour les ${selectedSageLabel?.toLocaleLowerCase()}(s) :`}
          {windows
            .filter((window) => !(window.height && window.width && window.type))
            .map((window, index, array) => {
              const windowIndex =
                windows.findIndex((elem) => elem.id === window.id) + 1;
              return index === array.length - 1
                ? windowIndex
                : windowIndex + ", ";
            })}
        </p>
      ) : null}
    </div>
  );
};
