import { Text } from "@react-pdf/renderer";
import { ISageProduct } from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

interface FormatPdfProductDescription {
  sageProduct?: ISageProduct;
  dataProduct?: IDataProduct;
}

/**
 * From Sage product and data product information, formats the product description for PDF.
 * If a field is empty, it will not be displayed.
 * @returns {React.ReactNode} The formatted product description.
 */
export const formatPdfProductDescription = ({
  sageProduct,
  dataProduct,
}: FormatPdfProductDescription) => {
  return (
    <>
      <>
        {dataProduct?.product_characteristics?.power ? (
          <Text>{`Puissance : ${dataProduct?.product_characteristics.power}`}</Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.Alimentation && sageProduct.Alimentation.length > 0 ? (
          <Text>{`Alimentation : ${sageProduct.Alimentation}`}</Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.Description && sageProduct.Description.length > 0 ? (
          <Text>{sageProduct.Description}</Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.Dimensions ? (
          <Text>
            {sageProduct.Dimensions && sageProduct.Dimensions.length > 0
              ? `Taille : ${sageProduct.Dimensions}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Ref fluide frigorigène"] ? (
          <Text>
            {sageProduct["Ref fluide frigorigène"].length > 0
              ? `Référence fluide frigorigène : ${sageProduct["Ref fluide frigorigène"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Profil de soutirage"] ? (
          <Text>
            {sageProduct["Profil de soutirage"].length > 0
              ? `Profil de soutirage : ${sageProduct["Profil de soutirage"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Norme et Certification"] &&
        sageProduct["Norme et Certification"]?.length > 0 ? (
          <Text>
            {sageProduct["Norme et Certification"]
              ? `Normes et certifications : ${sageProduct["Norme et Certification"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Classe energétique (Froid/Chaud"] &&
        sageProduct["Classe energétique (Froid/Chaud"]?.length > 0 ? (
          <Text>
            {sageProduct["Classe energétique (Froid/Chaud"]
              ? `Classe énergétique : ${sageProduct["Classe energétique (Froid/Chaud"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {dataProduct?.product_characteristics?.scop ? (
          <Text>{`COP : ${dataProduct?.product_characteristics?.scop}`}</Text>
        ) : null}
      </>
      ,
      <>
        {dataProduct?.product_characteristics?.etas ? (
          <Text>{`Etas : ${dataProduct?.product_characteristics?.etas}`}</Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Garantie Fabricant"] &&
        sageProduct["Garantie Fabricant"]?.length > 0 ? (
          <Text>
            {sageProduct["Garantie Fabricant"]
              ? `Garantie Fabriquant : ${sageProduct["Garantie Fabricant"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Garantie main d'oeuvre"] &&
        sageProduct["Garantie main d'oeuvre"]?.length > 0 ? (
          <Text>
            {sageProduct["Garantie main d'oeuvre"]
              ? `Garantie main d'oeuvre : ${sageProduct["Garantie main d'oeuvre"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Type de produit"] &&
        sageProduct["Type de produit"]?.length > 0 ? (
          <Text>
            {sageProduct["Type de produit"]
              ? `Type de vitrage : ${sageProduct["Type de produit"]}`
              : null}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Résistance Thermique "] ? (
          <Text>
            Résistance thermique : {sageProduct["Résistance Thermique "]}
          </Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["type de vitrage "] ? (
          <Text>Type de vitrage : {sageProduct["type de vitrage "]}</Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Facteur solaire"] ? (
          <Text>Facteur solaire : {sageProduct["Facteur solaire"]}</Text>
        ) : null}
      </>
      ,
      <>
        {sageProduct?.["Coef de transmission surfacique"] ? (
          <Text>
            Coefficient de transmission surfacique :{" "}
            {sageProduct["Coef de transmission surfacique"]}
          </Text>
        ) : null}
      </>
    </>
  );
};
