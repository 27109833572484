// Librairie import
import { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { CSSTransition } from "react-transition-group";

// Style import
import "./PacAirAirRoom.scss";

// Components import
import ChevronDown from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-down.svg";
import Trash2 from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";
import { PacAirAirInputContainer } from "../PacAirAirInputContainer/PacAirAirInputContainer";
import { PacAirAirInteriorUnit } from "../PacAirAirInteriorUnit/PacAirAirInteriorUnit";
import { PacAirAirQuantiteContainer } from "../PacAirAirQuantiteContainer/PacAirAirQuantiteContainer";

// Interfaces import
import { ISageProduct } from "../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IRoomPacAirAir,
} from "../../interfaces/renoglobaleInterface";

// Local interfaces declaration
interface Props {
  room: IRoomPacAirAir;
  roomIndex: number;
  isBlockOpen: boolean;
  setIsBlocksOpen: React.Dispatch<React.SetStateAction<boolean[]>>;
  blockHeight: number;
  setBlocksHeight: React.Dispatch<React.SetStateAction<number[]>>;
  roomId?: string | null;
  onClickDeleteRoom: ({ roomIndex }: { roomIndex: number }) => void;
  setRooms: React.Dispatch<React.SetStateAction<IRoomPacAirAir[]>>;
  listOfInteriorUnitsAvailables: ISageProduct[];
  interior_unit_installation_sage_reference: string | null | undefined;
  products_list: IDataProduct[];
  isMonosplit: boolean;
}

export const PacAirAirRoom = ({
  room,
  roomIndex,
  isBlockOpen,
  setIsBlocksOpen,
  blockHeight,
  setBlocksHeight,
  onClickDeleteRoom,
  setRooms,
  listOfInteriorUnitsAvailables,
  interior_unit_installation_sage_reference,
  products_list,
  isMonosplit,
}: Props) => {
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);
  const [isTabFinishedToOpen, setIsTabFinishedToOpen] = useState(false); // Usefull to set overflow to visible or to hidden to ensure the dropdown options are visibles
  const interiorUnits = room.interior_units;
  const numberOfInteriorUnits = interiorUnits?.length || 0;

  const calcHeight = (DOMelement: HTMLElement, index: number) => {
    const height = DOMelement.offsetHeight;
    setBlocksHeight((state) => {
      const newState = [...state];
      newState[index] = height + 60;
      return newState;
    });
  };

  const onExit = (index: number) => {
    setBlocksHeight((state) => {
      const newState = [...state];
      newState[index] = 0;
      return newState;
    });
  };

  const onClickBlock = (index: number) => {
    setIsBlocksOpen((state) => {
      const newState = [...state];
      newState[index] = !state[index];
      return newState;
    });
  };

  const roomDomElement: React.LegacyRef<HTMLDivElement> = useRef(null);

  // If an interior unit is added we recalculate the room's DOM element height
  useEffect(() => {
    roomDomElement.current && calcHeight(roomDomElement.current, roomIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interiorUnits.length]);

  const onClickOnUpdateNumberOfInteriorUnit = ({
    value,
    roomIndex,
    room,
  }: {
    value: number;
    roomIndex: number;
    room: IRoomPacAirAir;
  }) => {
    // We update the number of interior units in the room state
    setRooms((state) => {
      const newState = [...state];

      if (value > state[roomIndex].interior_units.length) {
        const newInteriorUnits = [
          ...state[roomIndex].interior_units,
          {
            room_id: room.room_id,
            interior_unit_id: "interior_unit_id_" + value + "_" + Date.now(),
          },
        ];

        newState[roomIndex].interior_units = newInteriorUnits;
        return newState;
      }
      newState[roomIndex].interior_units = state[
        roomIndex
      ].interior_units.slice(0, value);
      return newState;
    });
  };

  const onChangePower = ({
    roomIndex,
    interiorUnitIndex,
    interior_unit_sage_reference,
  }: {
    roomIndex: number;
    interiorUnitIndex: number;
    interior_unit_sage_reference?: string | null;
  }) => {
    const interiorUnit = products_list.find(
      (product) => product.sage_reference === interior_unit_sage_reference
    );

    // We update the number of interior units in the room state
    // We add the information of the interior unit from the data products list (prices, etc.) in the room state
    setRooms((state) => {
      const newState = structuredClone(state);
      newState[roomIndex].interior_units[interiorUnitIndex].name =
        interiorUnit?.product_name;
      newState[roomIndex].interior_units[interiorUnitIndex].alias =
        interiorUnit?.product_alias;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_sage_reference = interior_unit_sage_reference;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_installation_sage_reference =
        interior_unit_installation_sage_reference;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_TVA_percentage = interiorUnit?.TVA_percentage;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_installation_TVA_percentage =
        interiorUnit?.installation_tva_percentage;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_installation_price_with_tax =
        interiorUnit?.installation_price_with_tax;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_installation_price_without_tax =
        interiorUnit?.installation_price_without_tax;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_price_with_tax = interiorUnit?.product_price_with_tax;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_price_without_tax =
        interiorUnit?.product_price_without_tax;
      newState[roomIndex].interior_units[
        interiorUnitIndex
      ].interior_unit_installation_sage_reference =
        interiorUnit?.sage_reference_installation;
      return newState;
    });
  };

  const onChangeRoomSurface = ({
    roomIndex,
    interiorUnitIndex,
    surface_to_heat,
  }: {
    roomIndex: number;
    interiorUnitIndex: number;
    surface_to_heat: number;
  }) => {
    setRooms((state) => {
      const newState = [...state];
      newState[roomIndex].interior_units[interiorUnitIndex].surface_to_heat =
        surface_to_heat;
      return newState;
    });
  };

  const onChangeRoomHeight = ({
    roomIndex,
    newValue,
  }: {
    roomIndex: number;
    newValue: number | undefined;
  }) => {
    setRooms((state) => {
      const newState = [...state];
      newState[roomIndex].room_height = newValue;
      return newState;
    });
  };

  const onDeleteInteriorUnit = ({
    interior_unit_id,
  }: {
    interior_unit_id: string;
  }) => {
    setRooms((state) => {
      const newState = [...state];
      const newInteriorUnits = state[roomIndex].interior_units.filter(
        (interiorUnit) => interiorUnit.interior_unit_id !== interior_unit_id
      );
      newState[roomIndex].interior_units = newInteriorUnits;
      return newState;
    });
  };

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer la pièce n°${
          roomIndex + 1
        } ?`}
      >
        <div className="PacAirAirRoom__Modale">
          <CTAButton
            name="Non"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen(false)}
          />
          <CTAButton
            name="Oui"
            onClick={() => {
              onClickDeleteRoom({ roomIndex });
              setIsDeleteModaleOpen(false);
            }}
          />
        </div>
      </Modale>
      <div className="PacAirAirRoom__Block" key={roomIndex}>
        <div className="PacAirAirRoom__BlockTitle">
          <div
            className="PacAirAirRoom__BlockTitleLeftContainer"
            onClick={() => onClickBlock(roomIndex)}
          >
            <div className="PacAirAirRoom__BlockTitleLeft">
              <div className="PacAirAirRoom__Chevron">
                <CSSTransition
                  in={isBlockOpen}
                  timeout={300}
                  classNames={"tab-chevron"}
                >
                  <img src={ChevronDown} alt="arrow-down" />
                </CSSTransition>
              </div>
              <H2 title={"Pièce " + (roomIndex + 1)} />
            </div>
          </div>
          <div
            className="PacAirAirRoom___Trash"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          >
            <SVG src={Trash2} />
          </div>
        </div>
        {isBlockOpen ? <div className="PacAirAirRoom__Trait" /> : null}
        <div
          className="PacAirAirRoom__BlockContainer"
          style={{
            height: blockHeight,
            overflow: isTabFinishedToOpen ? "visible" : "hidden",
          }}
        >
          <CSSTransition
            in={isBlockOpen}
            unmountOnExit
            timeout={300}
            classNames={"tab-content"}
            onEnter={(DomElement: HTMLElement) =>
              calcHeight(DomElement, roomIndex)
            }
            onExit={() => onExit(roomIndex)}
            onEntered={() => setIsTabFinishedToOpen(true)}
            onExiting={() => setIsTabFinishedToOpen(false)}
          >
            <div className="PacAirAirRoom__BlockContent" ref={roomDomElement}>
              <div className="PacAirAirRoom__Question">
                <PacAirAirInputContainer
                  title="Hauteur sous plafond :"
                  iconType="meters"
                  placeholder="XXXX"
                  type="number"
                  value={room.room_height?.toString() ?? ""}
                  onChange={(value) =>
                    onChangeRoomHeight({
                      roomIndex,
                      newValue: value ? value : undefined,
                    })
                  }
                />
              </div>
              <div className="PacAirAirRoom__Question">
                <PacAirAirQuantiteContainer
                  title="Unités intérieures :"
                  value={numberOfInteriorUnits}
                  isMonosplit={isMonosplit}
                  onPlusMinusClick={(value) =>
                    onClickOnUpdateNumberOfInteriorUnit({
                      value,
                      roomIndex,
                      room,
                    })
                  }
                />
              </div>
              {interiorUnits?.map((interiorUnit, interiorUnitIndex) => {
                return (
                  <PacAirAirInteriorUnit
                    roomIndex={roomIndex}
                    interiorUnit={interiorUnit}
                    interiorUnitIndex={interiorUnitIndex}
                    key={interiorUnitIndex}
                    onChangePower={onChangePower}
                    onChangeRoomSurface={onChangeRoomSurface}
                    listOfInteriorUnitsAvailables={
                      listOfInteriorUnitsAvailables
                    }
                    onDeleteInteriorUnit={onDeleteInteriorUnit}
                    products_list={products_list}
                  />
                );
              })}
            </div>
          </CSSTransition>
        </div>
      </div>
    </>
  );
};
