import "./ProfitabilityComponent.scss";

// Libraries import
import { useEffect, useState } from "react";

// Components import
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { Layout } from "../../../components/Layout/Layout";
import { KWhPriceComponent } from "../../../components/ProfitabilityComponents/KWhPriceComponent/KWhPriceComponent";
import { TopSectionMainInformation } from "../../../components/ProfitabilityComponents/TopSectionMainInformation/TopSectionMainInformation";
import { TotalGainContainer } from "../../../components/ProfitabilityComponents/TotalGainContainer/TotalGainContainer";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetProfitability } from "../../../services/OptionChosen/useGetProfitability";

// Interfaces import

// Local interface declaration
interface Props {
  building_id?: string | null;
  household_id: string | null;
}

export const ProfitabilityComponent = ({
  building_id,
  household_id,
}: Props) => {
  const { monInstallation, userAuthInfo, studyInformation, scenarioChosen } =
    useAppSelector((state) => state);

  const { getProfitability, isLoading, errorMessage } = useGetProfitability();
  const [filterYearsFilter, setFilterYearsFilter] = useState<{
    label: string | null;
    value: string | number | null;
  }>({ label: "25 ans", value: "25" });

  useEffect(() => {
    if (
      scenarioChosen.is_personalized_scenario ||
      monInstallation.indexOptionChosen !==
        monInstallation.profitability?.indexScenarioChosen
    ) {
      getProfitability({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen.number_of_panels ?? null,
        building_id: building_id,
        household_id: household_id,
        kit_home_management: scenarioChosen.kit_home_management ?? null,
        number_of_batteries: scenarioChosen.number_of_batteries || 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        inverter_brand: scenarioChosen.inverter_brand || null,
        study_id: studyInformation?.study_id || "",
        single_battery_capacity: scenarioChosen.single_battery_capacity || -1,
        panel_indices: scenarioChosen.calepinage_chosen_indexes,
        extra_fees: scenarioChosen?.extra_fees,
        isPersonalizedScenario:
          scenarioChosen.is_personalized_scenario || false,
        options:
          scenarioChosen.options?.map((option) => option.sage_reference) ||
          null,
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ProfitabilityComponent">
      <Layout>
        {isLoading ? (
          <div className="ProfitabilityComponent__LoadingContainer">
            <LoadingComponent diameter={60} />
          </div>
        ) : errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : (
          <>
            <section className="ProfitabilityComponent__TopSection">
              <TopSectionMainInformation
                profitability={monInstallation.profitability}
                scenarioChosen={scenarioChosen}
              />
            </section>
            <p className="ProfitabilityComponent__SectionTitle">
              Gains cumulés
            </p>
            <section className="ProfitabilityComponent__TotalGainContainer">
              {monInstallation.profitability && (
                <div>
                  <TotalGainContainer
                    profitability={monInstallation.profitability}
                    setFilterYearsFilter={setFilterYearsFilter}
                    filterYearsFilter={filterYearsFilter}
                  />
                </div>
              )}
            </section>
            <p className="ProfitabilityComponent__SectionTitle">
              Prix moyen du kWh
            </p>
            <section className="ProfitabilityComponent__KWhPriceComponent">
              <KWhPriceComponent
                profitability={monInstallation.profitability}
                filterYearsFilter={filterYearsFilter}
              />
            </section>
          </>
        )}
      </Layout>
    </div>
  );
};
