import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { HelpsOptions } from "../../../../interfaces/generalInterfaces";
import { formatNumberWithSpacesAndDecimal } from "../../../../utils/formatNumberWithSpacesAndDecimal";

// Create styles
const styles = StyleSheet.create({
  containerTotalRight: {
    alignSelf: "flex-end",
    width: "50%",
    borderTop: "2px solid #525360",
    padding: "10 0 0 4",
    position: "relative",
  },
  totalItem: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    padding: "4 6",
  },
  totalItemBonusBorder: {
    border: "2px solid #08B77F",
    borderRadius: 4,
    padding: "4 6",
    marginBottom: 5,
  },
  totalItemBonusBackground: {
    backgroundColor: "#08B77F",
    borderRadius: 4,
    padding: "4 6",
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
  },

  totalItemTitle: {
    color: "#525360",
  },
  totalItemNumber: {
    color: "#525360",
    fontFamily: "DMSansBold",
  },
  totalItemTitleBonus: {
    color: "#08B77F",
    textTransform: "uppercase",
    fontFamily: "DMSansBold",
  },
  totalItemNumberBonus: {
    color: "#08B77F",
    fontFamily: "DMSansBold",
  },
  totalItemTitleRestant: {
    color: "white",
  },
  totalItemNumberRestant: {
    color: "white",
    fontFamily: "DMSansBold",
  },

  containerTotal: {
    display: "flex",
    flexDirection: "row",
  },

  totalHelp: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
    padding: "2 6",
    textTransform: "uppercase",
  },

  helpItem: {
    display: "flex",
    flexDirection: "row",

    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    fontSize: 8,
  },

  totalItemTitleHelp: {
    color: "#08B77F",
  },
  totalItemNumberHelp: {
    color: "#08B77F",
    fontFamily: "DMSansRegular",
  },
});

interface IProps {
  totalHT?: number;
  totalTVA?: number;
  totalTTC?: number;
  refundableHelp?: number;
  deductibleHelp?: number;
  totalHelp?: number;
  resteACharge?: number;
  devisOptions?: HelpsOptions;
  totalValueCoupon?: number | null;
  isSubProduct?: boolean;
}

export const PdfTotalContainer = ({
  totalHT,
  totalTVA,
  totalTTC,
  refundableHelp,
  deductibleHelp,
  totalHelp,
  resteACharge,
  devisOptions,
  totalValueCoupon,
  isSubProduct = false,
}: IProps) => {
  return (
    <View style={styles.containerTotalRight} break>
      {totalHT ? (
        <View style={styles.totalItem}>
          <Text style={styles.totalItemTitle}>Total HT</Text>
          <Text style={styles.totalItemNumber}>
            {formatNumberWithSpacesAndDecimal({
              number: totalHT,
              decimalPlaces: 2,
            })}{" "}
            €
          </Text>
        </View>
      ) : null}
      {totalTVA ? (
        <View style={styles.totalItem}>
          <Text style={styles.totalItemTitle}>Total TVA</Text>
          <Text style={styles.totalItemNumber}>
            {formatNumberWithSpacesAndDecimal({
              number: totalTVA,
              decimalPlaces: 2,
            })}{" "}
            €
          </Text>
        </View>
      ) : null}
      {totalTTC ? (
        <View style={styles.totalItem}>
          <Text style={styles.totalItemTitle}>Total TTC</Text>
          <Text style={styles.totalItemNumber}>
            {formatNumberWithSpacesAndDecimal({
              number: totalTTC,
              decimalPlaces: 2,
            })}{" "}
            €
          </Text>
        </View>
      ) : null}
      {totalHelp && devisOptions?.isHelpsDisplayed ? (
        <View style={[styles.totalItemBonusBorder]}>
          <View style={styles.helpItem}>
            <Text style={styles.totalItemTitleBonus}>Total aides</Text>
            <Text style={styles.totalItemNumberBonus}>
              {formatNumberWithSpacesAndDecimal({
                number: totalHelp,
                decimalPlaces: 2,
              })}{" "}
              €
            </Text>
          </View>

          {refundableHelp ? (
            <View style={styles.helpItem}>
              <Text style={styles.totalItemTitleHelp}>
                Dont en remboursement
              </Text>
              <Text style={styles.totalItemNumberHelp}>
                {formatNumberWithSpacesAndDecimal({
                  number: refundableHelp,
                  decimalPlaces: 2,
                  isDeductible: false,
                })}{" "}
                €
              </Text>
            </View>
          ) : null}
          {deductibleHelp ? (
            <View style={styles.helpItem}>
              <Text style={styles.totalItemTitleHelp}>Dont en déduction</Text>
              <Text style={styles.totalItemNumberHelp}>
                {formatNumberWithSpacesAndDecimal({
                  number: deductibleHelp,
                  decimalPlaces: 2,
                  isDeductible: true,
                })}{" "}
                €
              </Text>
            </View>
          ) : null}
        </View>
      ) : null}
      {totalValueCoupon && (
        <View style={[styles.totalItem, styles.totalItem]}>
          <Text style={styles.totalItemTitleBonus}>Total remise</Text>
          <Text style={styles.totalItemNumberBonus}>
            {formatNumberWithSpacesAndDecimal({
              number: totalValueCoupon,
              decimalPlaces: 2,
              isDeductible: true,
            })}{" "}
            €
          </Text>
        </View>
      )}
      {resteACharge ? (
        <View style={styles.totalItemBonusBackground}>
          <Text style={styles.totalItemTitleRestant}>
            {isSubProduct ? "Sous total produit" : "Reste à charge"}
          </Text>
          <Text style={styles.totalItemNumberRestant}>
            {formatNumberWithSpacesAndDecimal({
              number: resteACharge,
              decimalPlaces: 2,
            })}{" "}
            €
          </Text>
        </View>
      ) : null}
    </View>
  );
};
