import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfHeader } from "../../components/Pdf/Pdf-Etude/PdfHeader/PdfHeader";
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Interfaces import
import {
  ICommercialUserInformation,
  IOreeStudyType,
} from "../../interfaces/generalInterfaces";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Images
import montagne1 from "../../assets/pdf/images/montagne1.png";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  commercialUserInformation: ICommercialUserInformation;
  documentId: string;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerVotreEtude: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },

  containerVotreEtude__left__titles: {
    marginBottom: 20,
  },
  containerVotreEtude__left__titles__title: {
    fontSize: 28,
    color: `${DSColors.OffBlack}`,
    fontFamily: "DMSansBold",
  },
  containerVotreEtude__left__titles__subtitle: {
    fontSize: 20,
    color: `${DSColors.PlanetGreen}`,
    fontFamily: "DMSansBold",
  },
  containerVotreEtude__left__descriptions: {
    fontSize: 9,
  },
  containerVotreEtude__left__descriptions__description: {
    marginBottom: 5,
  },
  imageCouvertureBas: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
  },
});

export const PdfEtudeRGPageThree = ({
  firstNameInputValue,
  lastNameInputValue,
  commercialUserInformation,
  documentId,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfHeader />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerVotreEtude}>
          <View style={localStyle.containerVotreEtude__left__titles}>
            <Text style={localStyle.containerVotreEtude__left__titles__title}>
              Votre étude
            </Text>
            <Text
              style={localStyle.containerVotreEtude__left__titles__subtitle}
            >
              rénovation globale personnalisée
            </Text>
          </View>
          <View style={localStyle.containerVotreEtude__left__descriptions}>
            <Text
              style={
                localStyle.containerVotreEtude__left__descriptions__description
              }
            >
              Merci d’avoir réalisé une étude énergétique avec{" "}
              {commercialUserInformation.userCompany?.toLocaleUpperCase()} !
            </Text>
            <Text
              style={
                localStyle.containerVotreEtude__left__descriptions__description
              }
            >
              Retrouvez dans ce document votre étude complète de votre situation
              énergétique.
            </Text>
            <Text
              style={[
                localStyle.containerVotreEtude__left__descriptions__description,
                { fontFamily: "DMSansBold", marginBottom: 8 },
              ]}
            >
              Un devis détaillé de votre projet accompagne ce document.
            </Text>
            <Text
              style={
                localStyle.containerVotreEtude__left__descriptions__description
              }
            >
              N’hésitez à recontacter votre expert énergétique pour plus
              d’informations.
            </Text>
          </View>
        </View>
      </PdfLayoutEtude>
      <Image style={localStyle.imageCouvertureBas} src={montagne1} />
      <PdfPagination
        isWhite
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType={IOreeStudyType.RENOVATION_GLOBALE}
      />
    </Page>
  );
};
