import { Font, Page, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import DMSansBold from "../../assets/pdf/fonts/DMSansBold.ttf";
import DMSansItalic from "../../assets/pdf/fonts/DMSansItalic.ttf";
import DMSansMedium from "../../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../../assets/pdf/fonts/DMSansRegular.ttf";
import { PdfHeader } from "../../components/Pdf/Pdf-Etude/PdfHeader/PdfHeader";
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Styles import
import { PdfThirdTitle } from "../../components/Pdf/Pdf-Etude/PdfThirdTitle/PdfThirdTitle";

// Images import
import { useMemo } from "react";
import aides from "../../assets/pdf/images/aides.png";
import {
  IOreeStudyType,
  IScenario,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";
import {
  IProjectKPI,
  TSingleHelp,
} from "../../interfaces/renoglobaleInterface";
import { calculateHouseholdIncomeRange } from "../../services/HelpsCalculation/calculateHouseholdIncomeRange";
import { calculatePVpathPricesFromEeroScenario } from "../../services/PV/calculatePVpathPrices";

Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});
Font.register({
  family: "DMSansItalic",
  src: DMSansItalic,
  format: "truetype",
});

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  studyInformation: IStudyInformation;
  RGProjectKPI?: IProjectKPI;
  scenarioChosen?: IScenario;
  studyType: IOreeStudyType;
}

export const PdfEtudeHelpsPage = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  studyInformation,
  RGProjectKPI,
  scenarioChosen,
  studyType,
}: IProps) => {
  const { number_of_occupants, zipcode, income_color, occupation_type } =
    studyInformation;

  const helpPvPath: TSingleHelp = {
    name: "Prime à l'investissement",
    value: scenarioChosen
      ? calculatePVpathPricesFromEeroScenario({ scenarioChosen })?.totalHelps
      : 0,
    is_eligible: true,
    help_type: "other",
  };

  const helps =
    studyType === IOreeStudyType.RENOVATION_GLOBALE
      ? [
          ...(RGProjectKPI?.product_helps || []),
          ...(RGProjectKPI?.reno_globale_helps || []),
        ].filter((help) => help.is_eligible)
      : studyType === IOreeStudyType.PHOTOVOLTAIQUE
      ? [helpPvPath]
      : [];

  const hasPrimeALInvestissement = !!helps?.find(
    (help) => help.name === "Prime à l'investissement"
  );

  const hasMprHelp = !!helps?.find((help) => help.help_type === "mpr");

  const mprTotal = helps
    ?.filter((help) => help.help_type === "mpr")
    .reduce((total, help) => total + Number(help.value), 0);

  const powerNeeded =
    studyType === IOreeStudyType.RENOVATION_GLOBALE
      ? RGProjectKPI?.updated_products?.find(
          (product) => product.product_type === "PV"
        )?.product_characteristics?.power
      : studyType === IOreeStudyType.PHOTOVOLTAIQUE
      ? scenarioChosen?.power_needed
      : null;

  const capitalize = (str: string | null) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const incomeRange = useMemo(
    () =>
      calculateHouseholdIncomeRange({
        number_of_occupants,
        income_color,
        zipcode,
      }),
    [number_of_occupants, income_color, zipcode]
  );

  return (
    <Page
      size="A4"
      style={{
        color: "black",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        fontFamily: "DMSansRegular",
        fontSize: 9,
      }}
    >
      <PdfHeader isMargeBottom />
      <View fixed style={{ marginBottom: 6 }}>
        <PdfThirdTitle logo={aides} text={"Aides"} />
      </View>
      <PdfLayoutEtude isDemoVersion={false}>
        <View>
          {studyType === IOreeStudyType.RENOVATION_GLOBALE ? (
            <>
              <View>
                <Text
                  style={{
                    fontFamily: "DMSansBold",
                    color: "#44474F",
                    fontSize: 12,
                    marginBottom: 8,
                  }}
                >
                  Vos aides Financières
                </Text>
                <Text>
                  Dans le cadre de votre projet de rénovation énergétique et en
                  tant que producteur d'électricité verte, vous avez le droit à
                  certaines aides financières.
                </Text>
                <Text style={{ marginTop: 8 }}>
                  Selon les informations que vous nous avez transmises vous
                  pouvez être éligibles:
                </Text>
                <View
                  style={{ marginLeft: 10, marginBottom: 15, marginTop: 8 }}
                >
                  {helps
                    ?.filter((help) => help.is_eligible)
                    .map((help, index) => {
                      return (
                        <Text key={index}>
                          {"\u2022"} {help.name}
                        </Text>
                      );
                    })}
                </View>
              </View>

              {hasMprHelp ? (
                <View break>
                  <View
                    style={{
                      backgroundColor: DSColors.SGLight05,
                      marginBottom: 24,
                      padding: 10,
                      borderRadius: 15,
                    }}
                  >
                    <Text style={{ fontFamily: "DMSansBold", fontSize: 12 }}>
                      Ma Prime Rénov'
                    </Text>
                    <View>
                      <Text style={{ marginLeft: 10, marginTop: 8 }}>
                        Ma Prime Rénov' est une aide financière qui permet
                        d'améliorer le confort de votre maison tout en
                        participant à la réduction de vos factures d'énergie.
                      </Text>
                      <Text
                        style={{
                          marginBottom: 10,
                          marginTop: 8,
                          fontFamily: "DMSansBold",
                          fontSize: 12,
                        }}
                      >
                        Les conditions pour bénéficier de Ma Prime Rénov'
                      </Text>
                      <Text style={{ marginBottom: 8, marginLeft: 10 }}>
                        Vous pouvez bénéficier de Ma prime Rénov' car vous êtes
                        :
                      </Text>
                    </View>
                    <View style={{ marginLeft: 20 }}>
                      <Text>
                        {"\u2022"} {occupation_type}
                      </Text>
                      <Text>
                        {"\u2022"} Votre maison est achevée depuis plus de 15
                        ans
                      </Text>
                      <Text>
                        {"\u2022"} Vous faites appel à TUCO entreprise certifiée
                        RGE, pour la réalisation de vos travaux
                      </Text>
                    </View>
                    <Text
                      style={{
                        marginBottom: 8,
                        marginTop: 10,
                        fontFamily: "DMSansBold",
                        fontSize: 12,
                      }}
                    >
                      Plafonnement de l'aide
                    </Text>
                    <Text style={{ marginBottom: 10, marginLeft: 10 }}>
                      Le montant de l'aide ne peut dépasser 20 000 € par
                      logement sur une période de 5 ans.
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "DMSansBold",
                        marginBottom: 8,
                        fontSize: 12,
                      }}
                    >
                      Les solutions d'économies d'énergie éligibles à Ma Prime
                      Rénov':
                    </Text>
                    <View style={{ marginBottom: 24 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          borderBottom: "4px solid #80B9F0",
                          color: "#80B9F0",
                        }}
                      >
                        <View
                          style={{
                            width: "30%",
                            padding: 5,
                            margin: "auto",
                            fontFamily: "DMSansBold",
                          }}
                        >
                          <Text>Solutions éligibles</Text>
                        </View>
                        <View
                          style={{
                            width: "30%",
                            padding: 5,
                            margin: "auto",
                            fontFamily: "DMSansBold",
                          }}
                        >
                          <Text>Montant des aides</Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            padding: 5,
                            margin: "auto",
                            fontFamily: "DMSansBold",
                          }}
                        >
                          <Text>
                            Qualifications exigées pour les professionnels
                            réalisant les travaux
                          </Text>
                        </View>
                      </View>
                      <View>
                        {helps
                          ?.filter((help) => help.help_type === "mpr")
                          .map((help, index) => {
                            return (
                              <View
                                style={{
                                  flexDirection: "row",
                                  borderBottom: "2px solid #80B9F0",
                                }}
                                key={index}
                              >
                                <View
                                  style={{
                                    width: "30%",
                                    padding: 15,
                                    paddingLeft: 5,
                                    fontFamily: "DMSansBold",
                                  }}
                                >
                                  <Text>{help.name}</Text>
                                </View>
                                <View
                                  style={{
                                    width: "30%",
                                    padding: 15,
                                    paddingLeft: 5,
                                  }}
                                >
                                  <Text>
                                    {help.value.toFixed(2).replace(".", ",")} €
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "40%",
                                    padding: 15,
                                    paddingLeft: 5,
                                  }}
                                >
                                  <Text>Professionnel RGE</Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 12,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "48.0%",
                        padding: 15,
                        border: "2px solid #44BA82",
                        borderRadius: 15,
                        justifyContent: "space-between",
                      }}
                    >
                      {incomeRange ? (
                        <>
                          <Text
                            style={{
                              marginBottom: 15,
                              fontFamily: "DMSansBold",
                              color: "#44BA82",
                              fontSize: 12,
                              flexWrap: "wrap",
                            }}
                          >
                            Vos informations personnelles ayant un impact sur le
                            montant de votre prime:
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              margin: "auto",
                              borderBottom: "2px solid #44BA82",
                            }}
                          >
                            <Text style={{ width: "35%" }}>Vos revenus</Text>
                            <Text
                              style={{
                                width: "65%",
                                textAlign: "right",
                                paddingRight: 5,
                              }}
                            >
                              {incomeRange}
                            </Text>
                          </View>
                        </>
                      ) : null}
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "48.0%",
                        border: "2px solid #ED8C00",
                        borderRadius: 15,
                        padding: 15,
                      }}
                    >
                      <Text
                        style={{
                          marginBottom: 15,
                          fontFamily: "DMSansBold",
                          color: "#ED8C00",
                          fontSize: 12,
                        }}
                      >
                        Monsieur {firstNameInputValue} {lastNameInputValue}{" "}
                        voici le montant de votre Prime Rénov'
                        <Text style={{ verticalAlign: "super", fontSize: 8 }}>
                          (1)
                        </Text>
                        :{" "}
                      </Text>
                      <Text style={{ marginBottom: 10, width: "80%" }}>
                        Selon votre situation personnelle et les solutions
                        d'économies d'énergie choisies, vous pouvez bénéficier
                        de:
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          padding: 15,
                          backgroundColor: "#05A358",
                          color: "white",
                          borderRadius: 5,
                        }}
                      >
                        Votre Prime Rénov':{" "}
                        {mprTotal?.toFixed(2).replace(".", ",")} €
                      </Text>
                    </View>
                  </View>
                </View>
              ) : null}
            </>
          ) : null}
          {hasPrimeALInvestissement ? (
            <View break>
              <View
                style={{
                  backgroundColor: DSColors.SGLight05,
                  marginBottom: 10,
                  padding: 10,
                  borderRadius: 15,
                }}
              >
                <Text
                  style={{
                    marginBottom: 10,
                    fontFamily: "DMSansBold",
                    fontSize: 12,
                  }}
                >
                  La prime à l'investissement
                </Text>
                <View>
                  <Text style={{ marginBottom: 24 }}>
                    Par l'arrêté tarifaire du 6 octobre 2021, l'État verse à
                    tout producteur d'électricité solaire en autoconsommation
                    une prime pour amortir l'investissement initial. Le montant
                    de la prime est sujet à la puissance de l'installation. En
                    tant que producteur d'électricité verte, vous pouvez
                    bénéficier de cette prime qui sera versée une fois par an
                    après la mise en service de votre installation.
                  </Text>
                  <Text>
                    Voici en détail le montant de votre prime à
                    l'investissement.
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "46.0%",
                    padding: 15,
                    border: "2px solid #E85E53",
                    borderRadius: 15,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 15,
                      fontFamily: "DMSansBold",
                      color: "#E85E53",
                      fontSize: 12,
                      flexWrap: "wrap",
                    }}
                  >
                    Détail du montant de votre prime à l'investissement :
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      borderBottom: "2px solid #E85E53",
                    }}
                  >
                    <Text style={{ width: "70%" }}>
                      Puissance de votre installation photovoltaïque
                    </Text>
                    <Text
                      style={{
                        width: "28%",
                        textAlign: "right",
                        paddingRight: 5,
                        margin: "auto",
                      }}
                    >
                      {powerNeeded} Wc
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "2px solid #E85E53",
                      marginBottom: 10,
                    }}
                  >
                    <Text style={{ width: "70%" }}>
                      Montant de la prime à l'investissement pour chaque kWc
                      installé
                    </Text>
                    <Text
                      style={{
                        width: "28%",
                        textAlign: "right",
                        paddingRight: 5,
                        margin: "auto",
                      }}
                    >
                      {studyType === IOreeStudyType.RENOVATION_GLOBALE
                        ? (
                            ((RGProjectKPI?.product_helps.find(
                              (help) => help.name === "Prime à l'investissement"
                            )?.value || 0) /
                              (powerNeeded || 1)) *
                            1000
                          )
                            .toFixed(2)
                            .replace(".", ",")
                        : studyType === IOreeStudyType.PHOTOVOLTAIQUE
                        ? (
                            (helpPvPath.value || 1) /
                            ((powerNeeded || 1) / 1000)
                          )
                            .toFixed(2)
                            .replace(".", ",") + " €"
                        : null}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "2px solid #E85E53",
                    }}
                  >
                    <Text style={{ width: "70%" }}>
                      Montant total de votre prime à l'investissement versée sur
                      la première année
                    </Text>
                    <Text
                      style={{
                        width: "28%",
                        textAlign: "right",
                        paddingRight: 5,
                        margin: "auto",
                      }}
                    >
                      {studyType === IOreeStudyType.RENOVATION_GLOBALE
                        ? RGProjectKPI?.product_helps
                            .find(
                              (help) => help.name === "Prime à l'investissement"
                            )
                            ?.value.toFixed(2)
                            .replace(".", ",") || 0
                        : studyType === IOreeStudyType.PHOTOVOLTAIQUE
                        ? helpPvPath.value.toFixed(2).replace(".", ",") + " €"
                        : null}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50.0%",
                    height: "60%",
                    border: "2px solid #4096E8",
                    borderRadius: 15,
                    padding: 15,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 15,
                      fontFamily: "DMSansBold",
                      color: "#4096E8",
                      fontSize: 12,
                    }}
                  >
                    Monsieur {firstNameInputValue} voici le montant de votre
                    Prime à l'investissement
                    <Text style={{ verticalAlign: "super", fontSize: 8 }}>
                      (1)
                    </Text>
                    :{" "}
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      padding: 15,
                      backgroundColor: "#05A358",
                      color: "white",
                      borderRadius: 5,
                    }}
                  >
                    Votre prime à l'investissement:{" "}
                    {studyType === IOreeStudyType.RENOVATION_GLOBALE
                      ? RGProjectKPI?.product_helps
                          .find(
                            (help) => help.name === "Prime à l'investissement"
                          )
                          ?.value.toFixed(2)
                          .replace(".", ",")
                      : studyType === IOreeStudyType.PHOTOVOLTAIQUE
                      ? helpPvPath.value.toFixed(2).replace(".", ",") + " €"
                      : null}
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
          <View break>
            <View
              break
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 15,
                marginLeft: "30%",
              }}
            >
              <Text
                style={{
                  marginBottom: 24,
                  fontFamily: "DMSansBold",
                  color: "#44BA82",
                  fontSize: 12,
                  flexWrap: "wrap",
                }}
              >
                Total de vos aides
              </Text>
              <View style={{ marginBottom: 10 }}>
                <View style={{ flexDirection: "column" }}>
                  {helps?.map((help, index) => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                        key={index}
                      >
                        <Text
                          style={{
                            width: "auto",
                            borderBottom: "2px solid #44BA82",
                          }}
                        >
                          {"\u2022"} {help.name}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "DMSansBold",
                            width: "auto",
                            textAlign: "right",
                            paddingRight: 5,
                          }}
                        >
                          {help.value.toFixed(2).replace(".", ",")} €
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
              <Text
                style={{
                  textAlign: "center",
                  padding: 5,
                  backgroundColor: "#05A358",
                  color: "white",
                  borderRadius: 5,
                }}
              >
                Montant total de vos aides cumulées:{" "}
                {studyType === IOreeStudyType.RENOVATION_GLOBALE
                  ? Math.floor(RGProjectKPI?.total_help || 0)
                  : studyType === IOreeStudyType.PHOTOVOLTAIQUE
                  ? helpPvPath.value
                  : 0}
                ,00 €
              </Text>
            </View>
          </View>
          <View break style={{ fontSize: 7, marginTop: 12 }}>
            <Text style={{ marginBottom: 2 }}>
              (1) Montants calculés conformément aux dispositions législatives
              et réglementaires en vigueur à la date de réalisation de l'étude
              de l'habitat, sur la base des informations communiquées par le
              client, sous réserve d'éventuelles modifications ultérieures.
            </Text>
            <Text>
              Les aides financières étant soumises à des conditions ne dépendant
              pas de TUCO, celle-ci ne saurait aucunement être tenue de leur
              obtention. L'obtention des aides financières étant affectée
              d'aléa, elle ne sauraient constituer une condition déterminante de
              l'engagement du client.
            </Text>
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
      />
    </Page>
  );
};
