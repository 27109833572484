import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";

// Services import
import { IConsumptionsDataDonuts } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetConsumptionDonuts";

// Images import
import bois from "../../assets/pdf/images/bois.png";
import electricite from "../../assets/pdf/images/electricite.png";
import fioul from "../../assets/pdf/images/Fioul.png";
import vousEtesIci from "../../assets/pdf/images/fleche.png";
import gaz from "../../assets/pdf/images/gaz.png";

// Interfaces import
import {
  IOreeStudyType,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Data import
import { Fragment } from "react";
import { mesConsommationsQuestionsData } from "../../pages/MesConsommationsAndEquipmentsRG/MesConsommationsQuestionsData";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  studyInformation: IStudyInformation;
  imageDonut: string | null;
  donutsData: IConsumptionsDataDonuts | null;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerVotreLogement: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
  },
  pageTitleContent: {
    position: "relative",
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },

  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    fontSize: 8,
    marginBottom: 10,
    marginTop: 10,
  },
  textStrong: {
    fontFamily: "DMSansBold",
    color: DSColors.OffBlack,
    fontSize: 8,
  },
  pastilleContainer: {
    border: "1px solid #D3D4D7",
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    color: DSColors.OffBlack,
  },
  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
  },
  pageSubTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
  pageTitleUnderline: {
    position: "absolute",
    width: 255,
    height: "45%",
    bottom: 0,
    backgroundColor: DSColors.ZOLight02,
  },

  containerMesConsommations: {},
  blockMesConsommations: {
    border: "1px solid #D3D4D7",
    borderRadius: 5,
  },
  blockMesConsommationsHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: DSColors.ABLight06,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  blockMesConsommationsSubHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    borderBottom: "1px solid #D3D4D7",
  },
  blockMesConsommationsHeaderTextContainerFirst: {
    width: "25%",
    display: "flex",
  },
  blockMesConsommationsHeaderTextContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    maxWidth: 120,
  },
  blockMesConsommationsHeaderImage: {
    height: 15,
  },
  blockMesConsommationsHeaderText: {
    fontFamily: "DMSansBold",
    color: DSColors.OffBlack,
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  blockMesConsommationsSubHeaderText: {
    flex: 1,
    fontFamily: "DMSansRegular",
    color: DSColors.StableGrey,
    fontSize: 7,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  blockMesConsommationsContent: {
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  blockMesConsommationsColumnFirst: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
  },
  blockMesConsommationsColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: 120,
  },
  blockMesConsommationsValueContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 5,
    flexWrap: "wrap",
  },
  blockMesConsommationsValueContainerFirst: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 5,
    flexWrap: "wrap",
    borderBottom: "1px solid #D3D4D7",
  },
  blockMesConsommationsLabel: {
    fontFamily: "DMSansRegular",
    color: DSColors.StableGrey,
    fontSize: 7,
  },
  blockMesConsommationsLabelStrong: {
    fontFamily: "DMSansBold",
    color: DSColors.StableGrey,
    fontSize: 7,
  },
  blockMesConsommationsLabelFirst: {
    fontFamily: "DMSansRegular",
    color: DSColors.StableGrey,
    fontSize: 7,
  },
  blockMesConsommationsValue: {
    fontFamily: "DMSansBold",
    color: DSColors.StableGrey,
    fontSize: 7,
  },

  containerDeperdition: {
    marginTop: 20,
  },
  blockRepartition: {
    marginTop: 5,
  },
  imageDonutContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  imageDonut: {
    width: "100%",
    maxWidth: 250,
  },
  blockRepartitionLegend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  blockRepartitionLegendText: {
    fontFamily: "DMSansRegular",
    color: DSColors.StableGrey,
    fontSize: 10,
  },
  blockRepartitionLegendStrong: {
    fontFamily: "DMSansBold",
    color: DSColors.StableGrey,
    fontSize: 12,
    marginTop: 5,
  },
});

export const PdfEtudeRGPageMesConsommations = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  studyInformation,
  imageDonut,
  donutsData,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const isGaz = studyInformation.source_energie?.includes("gaz");
  const isFioul = studyInformation.source_energie?.includes("fioul");
  const isBois = studyInformation.source_energie?.includes("bois");

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerVotreLogement}>
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <View style={localStyle.pageTitleUnderline} />
              <Text style={localStyle.pageTitle}>
                Mes consommations et équipements
              </Text>
            </View>
          </View>
          <View style={localStyle.textContainer}>
            <Text style={localStyle.textStrong}>
              Votre contrat d’électricité :
            </Text>
            <View style={localStyle.pastilleContainer}>
              <Text>
                {
                  mesConsommationsQuestionsData.electricite.typeDInstallation.options.find(
                    (option) =>
                      option.value ===
                      studyInformation.electric_installation_type?.toString()
                  )?.label
                }
              </Text>
            </View>
            <View style={localStyle.pastilleContainer}>
              <Text>{studyInformation.subscribed_power + " kVA"}</Text>
            </View>
            <View style={localStyle.pastilleContainer}>
              <Text>
                {
                  mesConsommationsQuestionsData.electricite.optionTarifaire.options.find(
                    (option) =>
                      option.value ===
                      studyInformation.tarification_option?.toString()
                  )?.label
                }
              </Text>
            </View>
          </View>
          {/* Mes consommations table */}
          <View style={localStyle.containerMesConsommations}>
            <View style={localStyle.blockMesConsommations}>
              {/* Header */}
              <View style={localStyle.blockMesConsommationsHeader}>
                <View
                  style={
                    localStyle.blockMesConsommationsHeaderTextContainerFirst
                  }
                ></View>
                <View
                  style={localStyle.blockMesConsommationsHeaderTextContainer}
                >
                  <Image
                    source={electricite}
                    style={localStyle.blockMesConsommationsHeaderImage}
                  />
                  <Text style={localStyle.blockMesConsommationsHeaderText}>
                    Electricité :{" "}
                  </Text>
                </View>
                {isGaz ? (
                  <View
                    style={localStyle.blockMesConsommationsHeaderTextContainer}
                  >
                    <Image
                      source={gaz}
                      style={localStyle.blockMesConsommationsHeaderImage}
                    />
                    <Text style={localStyle.blockMesConsommationsHeaderText}>
                      Gaz :{" "}
                    </Text>
                  </View>
                ) : null}
                {isBois ? (
                  <View
                    style={localStyle.blockMesConsommationsHeaderTextContainer}
                  >
                    <Image
                      source={bois}
                      style={localStyle.blockMesConsommationsHeaderImage}
                    />
                    <Text style={localStyle.blockMesConsommationsHeaderText}>
                      Bois :{" "}
                    </Text>
                  </View>
                ) : null}
                {isFioul ? (
                  <View
                    style={localStyle.blockMesConsommationsHeaderTextContainer}
                  >
                    <Image
                      source={fioul}
                      style={localStyle.blockMesConsommationsHeaderImage}
                    />
                    <Text style={localStyle.blockMesConsommationsHeaderText}>
                      Fioul :{" "}
                    </Text>
                  </View>
                ) : null}
              </View>

              {/* Sub Header */}
              <View style={localStyle.blockMesConsommationsSubHeader}>
                <View
                  style={
                    localStyle.blockMesConsommationsHeaderTextContainerFirst
                  }
                >
                  <Text style={localStyle.blockMesConsommationsLabel}>
                    Consommation annuelle :{" "}
                  </Text>
                </View>
                <View
                  style={localStyle.blockMesConsommationsHeaderTextContainer}
                >
                  <Text style={localStyle.blockMesConsommationsLabelStrong}>
                    {studyInformation.annual_consumption_in_euros +
                      "€ - " +
                      studyInformation.annual_consumption_in_kwh +
                      "kWh (" +
                      ((
                        (studyInformation.annual_consumption_in_euros || 0) /
                        (studyInformation.annual_consumption_in_kwh || 1)
                      )?.toFixed(2) || "?") +
                      "€/kWh)"}
                  </Text>
                </View>

                {isGaz ? (
                  <View
                    style={localStyle.blockMesConsommationsHeaderTextContainer}
                  >
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {studyInformation.gaz_consommation_euros +
                        "€ - " +
                        studyInformation.gaz_consommation_kwh +
                        " kWh"}
                    </Text>
                  </View>
                ) : null}
                {isBois ? (
                  <View
                    style={localStyle.blockMesConsommationsHeaderTextContainer}
                  >
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {studyInformation.bois_consommation_euros + "€"}
                    </Text>
                  </View>
                ) : null}
                {isFioul ? (
                  <View
                    style={localStyle.blockMesConsommationsHeaderTextContainer}
                  >
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {studyInformation.fioul_consommation_euros + "€"}
                    </Text>
                  </View>
                ) : null}
              </View>

              {/* Content */}
              <View style={localStyle.blockMesConsommationsContent}>
                <View style={localStyle.blockMesConsommationsColumnFirst}>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      Chauffage
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Surface chauffée :
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Type de chauffage :
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Nombre d’émetteur(s) :
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Type d’émetteur :
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      Eau chaude sanitaire (ECS)
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Capacité de stockage :{" "}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Année d’installation :{" "}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabel}>
                      Type d’installation :{" "}
                    </Text>{" "}
                  </View>
                </View>

                {/* Electricite column */}
                <View style={localStyle.blockMesConsommationsColumn}>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {" "}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {studyInformation.electricite_surface_chauffee
                        ? studyInformation.electricite_surface_chauffee + " m²"
                        : "----"}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {(studyInformation?.electricite_type_chauffage?.length ||
                        0) > 0
                        ? mesConsommationsQuestionsData.electricite.typeDeChauffage.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.electricite_type_chauffage?.toString()
                          )?.label
                        : "----"}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {studyInformation.electricite_nombre_emetteur || "----"}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {(studyInformation?.electricite_type_emetteur?.length ||
                        0) > 0
                        ? mesConsommationsQuestionsData.electricite.typeDEmetteur.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.electricite_type_emetteur?.toString()
                          )?.label
                        : "----"}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {" "}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {mesConsommationsQuestionsData.electricite.capaciteDeStockage.options.find(
                        (option) =>
                          option.value ===
                          studyInformation.electricite_storage_capacity?.toString()
                      )?.label || "----"}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {studyInformation.electricite_installation_year || "----"}
                    </Text>{" "}
                  </View>
                  <View style={localStyle.blockMesConsommationsValueContainer}>
                    <Text style={localStyle.blockMesConsommationsLabelStrong}>
                      {(studyInformation?.electricite_type_ballon_eau_chaude
                        ?.length || 0) > 0
                        ? mesConsommationsQuestionsData.electricite.typeDeBallon.options.find(
                            (option) =>
                              option.value ===
                              studyInformation.electricite_type_ballon_eau_chaude?.toString()
                          )?.label
                        : "----"}
                    </Text>{" "}
                  </View>
                </View>

                {/* Gaz column */}
                {isGaz ? (
                  <View style={localStyle.blockMesConsommationsColumn}>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {" "}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {studyInformation.gaz_surface_chauffee
                          ? studyInformation.gaz_surface_chauffee + " m²"
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {(studyInformation?.gaz_type_chauffage?.length || 0) > 0
                          ? mesConsommationsQuestionsData.gaz.typeDeChaudiere.options.find(
                              (option) =>
                                option.value ===
                                studyInformation.gaz_type_chauffage?.toString()
                            )?.label
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {studyInformation.gaz_nombre_emetteur || "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {(studyInformation?.gaz_type_emetteur?.length || 0) > 0
                          ? mesConsommationsQuestionsData.gaz.typeDEmetteur.options.find(
                              (option) =>
                                option.value ===
                                studyInformation.gaz_type_emetteur?.toString()
                            )?.label
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {" "}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {mesConsommationsQuestionsData.gaz.capaciteDeStockage.options.find(
                          (option) =>
                            option.value ===
                            studyInformation.gaz_storage_capacity?.toString()
                        )?.label || "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                  </View>
                ) : null}

                {/* Bois column */}
                {isBois ? (
                  <View style={localStyle.blockMesConsommationsColumn}>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {" "}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {studyInformation.bois_surface_chauffee
                          ? studyInformation.bois_surface_chauffee + " m²"
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {(studyInformation?.bois_type_chauffage?.length || 0) >
                        0
                          ? mesConsommationsQuestionsData.bois.typeDeChauffage.options.find(
                              (option) =>
                                option.value ===
                                studyInformation.bois_type_chauffage?.toString()
                            )?.label
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {studyInformation.bois_nombre_emetteur || "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {(studyInformation?.bois_type_emetteur?.length || 0) > 0
                          ? mesConsommationsQuestionsData.bois.typeDEmetteur.options.find(
                              (option) =>
                                option.value ===
                                studyInformation.bois_type_emetteur?.toString()
                            )?.label
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {" "}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                  </View>
                ) : null}

                {/* Fioul column */}
                {isFioul ? (
                  <View style={localStyle.blockMesConsommationsColumn}>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {" "}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {studyInformation.fioul_surface_chauffee
                          ? studyInformation.fioul_surface_chauffee + " m²"
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {(studyInformation?.fioul_type_chauffage?.length || 0) >
                        0
                          ? mesConsommationsQuestionsData.fioul.typeDeChaudiere.options.find(
                              (option) =>
                                option.value ===
                                studyInformation.fioul_type_chauffage?.toString()
                            )?.label
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {studyInformation.fioul_nombre_emetteur || "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {(studyInformation?.fioul_type_emetteur?.length || 0) >
                        0
                          ? mesConsommationsQuestionsData.fioul.typeDEmetteur.options.find(
                              (option) =>
                                option.value ===
                                studyInformation.fioul_type_emetteur?.toString()
                            )?.label
                          : "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {" "}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {mesConsommationsQuestionsData.fioul.capaciteDeStockage.options.find(
                          (option) =>
                            option.value ===
                            studyInformation.fioul_storage_capacity?.toString()
                        )?.label || "----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                    <View
                      style={localStyle.blockMesConsommationsValueContainer}
                    >
                      <Text style={localStyle.blockMesConsommationsLabelStrong}>
                        {"----"}
                      </Text>{" "}
                    </View>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
          <View style={localStyle.textContainer}>
            {(studyInformation.electricite_principaux_equipments?.length || 0) >
            0 ? (
              <>
                <Text style={localStyle.textStrong}>
                  Autres équipements électriques :
                </Text>
                {studyInformation.electricite_principaux_equipments?.map(
                  (equipment, index) => {
                    return (
                      <Fragment key={index}>
                        <Text style={localStyle.textStrong}>
                          {
                            mesConsommationsQuestionsData.electricite.principauxEquipements.options.find(
                              (option) => option.value === equipment?.toString()
                            )?.label
                          }
                        </Text>
                        {index + 1 !==
                          (studyInformation.electricite_principaux_equipments
                            ?.length || 0) && (
                          <Text style={localStyle.textStrong}>{" / "}</Text>
                        )}
                      </Fragment>
                    );
                  }
                ) || "Aucun autre équipement renseigné"}
              </>
            ) : null}
          </View>
          <View style={localStyle.containerDeperdition}>
            <View style={localStyle.pageTitleContainer}>
              <View style={localStyle.pageSubTitleContent}>
                <Image
                  style={localStyle.pageSubTitleImage}
                  source={vousEtesIci}
                />
                <Text style={localStyle.pageSubTitle}>
                  Répartition de ma consommation énergétique
                </Text>
              </View>
            </View>
            <View style={localStyle.blockRepartition}>
              {imageDonut ? (
                <View style={localStyle.imageDonutContainer}>
                  <Image src={imageDonut} style={localStyle.imageDonut} />
                </View>
              ) : (
                "Image non disponible"
              )}
              <View style={localStyle.blockRepartitionLegend}>
                <Text style={localStyle.blockRepartitionLegendText}>
                  Montant total de la facture énergétique :
                </Text>
                <Text style={localStyle.blockRepartitionLegendStrong}>
                  {donutsData?.total_bill || "NA"}€/an
                </Text>
              </View>
            </View>
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType={IOreeStudyType.RENOVATION_GLOBALE}
      />
    </Page>
  );
};
