import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfOnePan } from "../../components/Pdf/Pdf-Etude/PdfOnePan/PdfOnePan";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";
import { PdfEtudeHeaderRG } from "./PdfEtudeHeaderRG";

// Interfaces import
import {
  IOreeStudyType,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  documentId: string;
  studyInformation: IStudyInformation;
  imageSolarPotential: string | null;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerVotreLogement: {
    height: "100%",
  },
  pageTitleContainer: {
    textAlign: "left",
    height: 30,
  },
  pageTitleContent: {
    position: "relative",
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },

  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    fontSize: 8,
    marginBottom: 10,
    marginTop: 10,
  },
  textStrong: {
    fontFamily: "DMSansBold",
    color: DSColors.OffBlack,
    fontSize: 8,
  },
  pastilleContainer: {
    border: "1px solid #D3D4D7",
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    color: DSColors.OffBlack,
  },
  pageSubTitleContent: {
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    alignItems: "center",
  },
  pageSubTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: DSColors.OffBlack,
    fontFamily: "DMSansBold",
  },
  pageSubTitleImage: {
    width: 25,
    marginRight: 5,
  },
  pageTitleUnderline: {
    position: "absolute",
    width: 328,
    height: "45%",
    bottom: 0,
    backgroundColor: DSColors.ZOLight02,
  },
  imageSolarPotentialContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    border: "1px solid #D3D4D7",
    borderRadius: 5,
  },
  imageSolarPotential: {
    width: "100%",
  },
  pansContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 20,
  },
});

export const PdfEtudeRGPageSolarPotential = ({
  firstNameInputValue,
  lastNameInputValue,
  documentId,
  studyInformation,
  imageSolarPotential,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfEtudeHeaderRG />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerVotreLogement}>
          <View style={localStyle.pageTitleContainer}>
            <View style={localStyle.pageTitleContent}>
              <View style={localStyle.pageTitleUnderline} />
              <Text style={localStyle.pageTitle}>
                Votre potentiel solaire estimé de votre logement
              </Text>
            </View>
          </View>
          {imageSolarPotential ? (
            <View style={localStyle.imageSolarPotentialContainer}>
              <Image
                src={imageSolarPotential}
                style={localStyle.imageSolarPotential}
              />
            </View>
          ) : null}
          <View style={localStyle.pansContainer}>
            {studyInformation?.roof_sections?.map((pan, index) => {
              return (
                <PdfOnePan
                  key={index}
                  number={(index + 1).toString()}
                  orientation={pan.orientation ?? null}
                  surface={pan.area_m2 ?? null}
                  pente={pan.slope_degrees ?? null}
                />
              );
            })}
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType={IOreeStudyType.RENOVATION_GLOBALE}
      />
    </Page>
  );
};
