import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DSColors } from "@web/shared/src/styles/variables";
import moment from "moment";

// Components
import { PdfHeader } from "../../components/Pdf/Pdf-Etude/PdfHeader/PdfHeader";
import { PdfLayoutEtude } from "../../components/Pdf/Pdf-Etude/PdfLayoutEtude/PdfLayoutEtude";
import { PdfPagination } from "../../components/Pdf/Pdf-Etude/PdfPagination/PdfPagination";

// Interfaces import
import {
  ICommercialUserInformation,
  IOreeStudyType,
} from "../../interfaces/generalInterfaces";

// Styles import
import { PdfEtudeRGStyles as styles } from "./PdfEtudeRGStyles";

// Images
import aides from "../../assets/pdf/images/aides.png";
import co2Rouge from "../../assets/pdf/images/co2Rouge.png";
import installation from "../../assets/pdf/images/installation.png";
import interlocuteur from "../../assets/pdf/images/interlocuteur.png";
import certirenov from "../../assets/pdf/images/logo-certirenov.png";
import logoFrenchtech from "../../assets/pdf/images/logoFrenchtech.png";
import logoGreenTech from "../../assets/pdf/images/logoGreenTech.png";
import logoIgnFab from "../../assets/pdf/images/logoIgnFab.png";
import logoIso from "../../assets/pdf/images/logoIso.png";
import partenaires from "../../assets/pdf/images/partenaires.png";
import poseurs from "../../assets/pdf/images/poseurs.png";
import rge from "../../assets/pdf/images/rge.png";

interface IProps {
  firstNameInputValue: string;
  lastNameInputValue: string;
  commercialUserInformation: ICommercialUserInformation;
  documentId: string;
  isDemoVersion: boolean;
}

// Create styles
export const localStyle = StyleSheet.create({
  containerPoseurs: {
    display: "flex",
    flexDirection: "row",
    marginTop: 120,
    alignItems: "center",
  },
  containerPoseurs__left: {
    width: "50%",
  },
  containerPoseurs__right: {
    width: "50%",
    position: "absolute",
    right: -58,
  },

  containerPoseurs__left__titles: {
    marginBottom: 20,
  },
  containerPoseurs__left__titles__title: {
    fontSize: 28,
    color: `${DSColors.OffBlack}`,
    fontFamily: "DMSansBold",
  },
  containerPoseurs__left__titles__subtitle: {
    fontSize: 20,
    color: `${DSColors.PlanetGreen}`,
    fontFamily: "DMSansBold",
  },
  containerPoseurs__left__descriptions: {
    fontSize: 9,
  },
  containerPoseurs__left__descriptions__description: {
    marginBottom: 5,
  },
  containerStatistiques: {
    backgroundColor: "#e0e7fa",
    padding: "20 68",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 60,
  },
  containerStatistiques__element: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 9,
    width: "47%",
    height: "auto",
    padding: 10,
  },
  containerStatistiques__element__image: {
    width: 40,
    height: 40,
    objectFit: "contain",
    marginRight: 8,
  },
  containerStatistiques__element__text: {
    display: "flex",
    width: "100%",
  },
  certifications: {
    marginTop: 20,
  },
  certifications__title: {
    fontSize: 12,
    color: "#50545f",
    fontFamily: "DMSansBold",
    marginBottom: 10,
  },
  certifications__containerImages: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  certifications__containerImages__image: {
    width: "20%",
    maxHeight: "80",
    objectFit: "contain",
  },
});

export const PdfEtudePresentationTuco = ({
  firstNameInputValue,
  lastNameInputValue,
  commercialUserInformation,
  documentId,
  isDemoVersion,
}: IProps) => {
  function capitalize(str: string | null) {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Page size="A4" style={styles.page}>
      <PdfHeader />
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.containerPoseurs}>
          <View style={localStyle.containerPoseurs__left}>
            <View style={localStyle.containerPoseurs__left__titles}>
              <Text style={localStyle.containerPoseurs__left__titles__title}>
                {commercialUserInformation.userCompany?.toLocaleUpperCase()}
              </Text>
              <Text style={localStyle.containerPoseurs__left__titles__subtitle}>
                Économisez sur vos factures d’énergie
              </Text>
            </View>
            <View style={localStyle.containerPoseurs__left__descriptions}>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Fondée en 2009, TUCO est une entreprise Greentech qui a pour
                mission de démocratiser la transition énergétique.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                TUCO propose à tous les foyers Français des travaux de
                rénovation énergétique en toute sérénité ! Grâce à une offre clé
                en main, nous installons des solutions d'efficacité énergétique
                (isolation, pompe à chaleur, photovoltaïque...) partout en
                France.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Nous gérons pour nos clients leurs projets de A à Z : de l'étude
                de faisabilité à la réalisation des travaux en passant par les
                démarches administratives et la collecte des aides.
              </Text>
              <Text
                style={
                  localStyle.containerPoseurs__left__descriptions__description
                }
              >
                Nous permettons ainsi à chacun de maîtriser sa facture
                énergétique ainsi que son impact environnemental.
              </Text>
            </View>
          </View>
          <View style={localStyle.containerPoseurs__right}>
            <Image src={poseurs} />
          </View>
        </View>
      </PdfLayoutEtude>
      <View style={localStyle.containerStatistiques}>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={installation}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              <Text style={{ fontFamily: "DMSansBold" }}>
                5 000 installations{" "}
              </Text>
              par an.
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={aides}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              <Text style={{ fontFamily: "DMSansBold" }}>Gestion </Text>des
              démarches administratives et des aides.
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={partenaires}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              Partenaire des{" "}
              <Text style={{ fontFamily: "DMSansBold" }}>
                meilleurs fabricants européens.
              </Text>
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={interlocuteur}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              <Text style={{ fontFamily: "DMSansBold" }}>
                Interlocuteur dédié{" "}
              </Text>
              pendant tout le projet.
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={rge}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              Installation partout en France par nos{" "}
              <Text style={{ fontFamily: "DMSansBold" }}>
                professionnels RGE.
              </Text>
            </Text>
          </View>
        </View>
        <View style={localStyle.containerStatistiques__element}>
          <Image
            style={localStyle.containerStatistiques__element__image}
            src={co2Rouge}
          />
          <View style={localStyle.containerStatistiques__element__text}>
            <Text>
              <Text style={{ fontFamily: "DMSansBold" }}>
                Modèle à impact :
              </Text>{" "}
              96 % d’émissions CO2 en moins avec TUCO.
            </Text>
          </View>
        </View>
      </View>
      <PdfLayoutEtude isDemoVersion={isDemoVersion}>
        <View style={localStyle.certifications}>
          <Text style={localStyle.certifications__title}>
            Nos certifications et labels qualité :
          </Text>
          <View style={localStyle.certifications__containerImages}>
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoGreenTech}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoIso}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoIgnFab}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={logoFrenchtech}
            />
            <Image
              style={localStyle.certifications__containerImages__image}
              src={certirenov}
            />
          </View>
        </View>
      </PdfLayoutEtude>
      <PdfPagination
        date={`${moment().format("DD/MM/YYYY")}`}
        prenom={capitalize(firstNameInputValue)}
        nom={capitalize(lastNameInputValue)}
        reference={documentId}
        studyType={IOreeStudyType.RENOVATION_GLOBALE}
      />
    </Page>
  );
};
