// Librairie import
import { useState } from "react";
import SVG from "react-inlinesvg";
import { CSSTransition } from "react-transition-group";

// Style import
import "./Window.scss";

// Components import
import ChevronDown from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-down.svg";
import Trash2 from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";

// Interfaces import
import {
  ChoixMultiple,
  IOption,
} from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { CTACopyPaste } from "@web/shared/dist/components/DesignSystem/CTACopyPaste/CTACopyPaste";
import { TextArea } from "@web/shared/dist/components/DesignSystem/Inputs/TextArea/TextArea";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Updater } from "use-immer";
import Accordion from "../../components/Accordion/Accordion";
import { WindowProduct } from "../../interfaces/renoglobaleInterface";
import { isFrenchWindowReference } from "../../utils/sageUtils";
import { capitalize } from "../../utils/stringUtils";
import { meterToMillimeter } from "../../utils/unitUtils";

// Local interfaces declaration
interface Props {
  window: WindowProduct;
  windowIndex: number;
  setWindows: Updater<WindowProduct[]>;
  name: string | null;
  maxNumberOfWindows: number;
  numberOfWindows: number;
}

export const Window = ({
  window,
  windowIndex,
  setWindows,
  name,
  maxNumberOfWindows,
  numberOfWindows,
}: Props) => {
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);

  const windowOptions: IOption[] = [
    { label: "Battante", value: "battante" },
    { label: "Coulissante", value: "coulissante" },
  ];

  const onClickDuplicateWindow = (windowIndex: number) => {
    setWindows((draft) => {
      const newWindow = { ...draft[windowIndex] };

      //# Generate random id to avoid duplicate id
      const newId =
        "windowId_" +
        Math.random().toString(36).substring(7) +
        "_" +
        Date.now();
      newWindow.id = newId;
      newWindow.isOpen = true;
      draft.splice(windowIndex + 1, 0, newWindow);
    });
  };

  const onClickDeleteWindow = (windowIndex: number) => {
    setWindows((draft) => {
      return draft.filter((_, index) => index !== windowIndex);
    });
  };

  const onClickBlock = (index: number) => {
    setWindows((draft) => {
      draft[index].isOpen = !draft[index].isOpen;
    });
  };

  const onChangeWindowWidth = ({
    windowIndex,
    width,
  }: {
    windowIndex: number;
    width: number;
  }) => {
    setWindows((draft) => {
      draft[windowIndex].width = width;
    });
  };

  const onChangeWindowHeight = ({
    windowIndex,
    height,
  }: {
    windowIndex: number;
    height: number | undefined;
  }) => {
    setWindows((draft) => {
      draft[windowIndex].height = height;
    });
  };

  const onChangeWindowSubType = ({
    windowIndex,
    option,
  }: {
    windowIndex: number;
    option: IOption | null;
  }) => {
    if (!option) return;

    setWindows((draft) => {
      // If the option is already selected, we remove it
      if (draft[windowIndex].type === option.value) {
        draft[windowIndex].type = null;
      }
      // Otherwise, we set the new value
      draft[windowIndex].type = option.value;
    });
  };

  const onChangeWindowComment = ({
    windowIndex,
    comment,
  }: {
    windowIndex: number;
    comment: string | undefined;
  }) => {
    setWindows((draft) => {
      // Otherwise, we set the new value
      draft[windowIndex].comment = comment;
    });
  };

  const windowOptionSelected =
    windowOptions.find((option) => option.value === window.type) || null;

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer la fenêtre n°${
          windowIndex + 1
        } ?`}
      >
        <div className="Window__Modale">
          <CTAButton
            name="Non"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen(false)}
          />
          <CTAButton
            name="Oui"
            onClick={() => {
              onClickDeleteWindow(windowIndex);
              setIsDeleteModaleOpen(false);
            }}
          />
        </div>
      </Modale>

      <div className="Window__Block" key={windowIndex}>
        <div className="Window__BlockTitle">
          <div
            className="Window__BlockTitleLeftContainer"
            onClick={() => onClickBlock(windowIndex)}
          >
            <div className="Window__BlockTitleLeft">
              <div className="Window__Chevron">
                <CSSTransition
                  in={window.isOpen}
                  timeout={300}
                  classNames={"tab-chevron"}
                >
                  <img src={ChevronDown} alt="arrow-down" />
                </CSSTransition>
              </div>
              <H2 title={capitalize(name) + " " + (windowIndex + 1)} />
            </div>
          </div>
          <div className="Window__BlockTitleRightContainer">
            {numberOfWindows < maxNumberOfWindows && (
              <div>
                <CTACopyPaste
                  onClick={() => onClickDuplicateWindow(windowIndex)}
                  name="Dupliquer"
                />
              </div>
            )}
            <div
              className="Window___Trash"
              onClick={() => setIsDeleteModaleOpen((state) => !state)}
            >
              <SVG src={Trash2} />
            </div>
          </div>
        </div>

        <Accordion isOpen={window.isOpen}>
          <div className="Window__Trait" />
          <div className="Window__BlockContent">
            {isFrenchWindowReference(window.sageReference) ? (
              <div className="Window__Question">
                <p className={"Window__QuestionTitle"}>Fermeture :</p>
                <div className={`Window__FieldContainer`}>
                  <ChoixMultiple
                    options={windowOptions}
                    value={
                      windowOptionSelected?.value ? [windowOptionSelected] : []
                    }
                    onClick={(option) =>
                      onChangeWindowSubType({ windowIndex, option })
                    }
                    isRequired={true}
                  />
                </div>
              </div>
            ) : null}

            <div className="Window__Question">
              <p className={"Window__QuestionTitle"}>Hauteur :</p>
              <div className={`Window__FieldContainer`}>
                <TextInput
                  iconType="mm"
                  iconHidden={false}
                  placeholder="XXXX"
                  type="number"
                  value={
                    typeof window.height === "number"
                      ? meterToMillimeter(window.height).toString()
                      : ""
                  } // We store the width in meters but display it in millimeters
                  onChange={(event) =>
                    onChangeWindowHeight({
                      windowIndex,
                      height: event.target.valueAsNumber / 1000, // We store the width in meters
                    })
                  }
                />
              </div>
            </div>
            <div className="Window__Question">
              <p className={"Window__QuestionTitle"}>Largeur :</p>
              <div className={`Window__FieldContainer`}>
                <TextInput
                  iconType="mm"
                  iconHidden={false}
                  placeholder="XXXX"
                  type="number"
                  value={
                    typeof window.width === "number"
                      ? meterToMillimeter(window.width).toString()
                      : ""
                  } // We store the width in meters but display it in millimeters
                  onChange={(event) =>
                    onChangeWindowWidth({
                      windowIndex,
                      width: event.target.valueAsNumber / 1000, // We store the width in meters
                    })
                  }
                />
              </div>
            </div>
            <div className="Window__Question">
              <p className={"Window__QuestionTitle"}>Commentaire&nbsp;:</p>
              <div className={`Window__CommentContainer`}>
                <TextArea
                  value={window.comment ?? ""}
                  onChange={(event) =>
                    onChangeWindowComment({
                      windowIndex,
                      comment: event.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </>
  );
};
