// Libraries import
import { AxiosResponse } from "axios";
import { useState } from "react";

// services import
import {
  setScenarioChosenAction,
  setStudyInfoAction,
} from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";
import { useAxiosPut } from "../useAxiosPut";

// Interfaces import
import { IScenario } from "../../interfaces/generalInterfaces";

interface Props {
  userToken?: string | null;
  study_id?: string | null;
  scenario: IScenario;
}

interface IRequestResponse {
  scenario_id: string | null;
}

export const useSaveScenario = () => {
  const dispatch = useAppDispatch();
  const {
    axiosPutRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPut();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveScenario = async ({
    userToken,
    study_id,
    scenario,
  }: Props): Promise<AxiosResponse | void> => {
    setErrorMessage("");

    const response = await axiosPutRequest<IRequestResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/pv/studies/${study_id}/selectedScenario`,
      { study_id, scenario },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    if (response && response.data.scenario_id) {
      // We update the studyInformation in the store with the scenario_id
      dispatch(
        setStudyInfoAction({
          studyInformation: {
            scenario_id: response.data.scenario_id,
          },
        })
      );

      // Wa update the scenario chosen in the store with the scenario_id
      dispatch(
        setScenarioChosenAction({
          scenarioChosen: {
            scenario_id: response.data.scenario_id,
            is_personalized_scenario: scenario.is_personalized_scenario,
          },
        })
      );
    }
    return response;
  };

  return {
    saveScenario,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
