import { CompanySettings } from "../../interfaces/generalInterfaces";
import eeroApi from "../eeroApiAxios";
import { useAuthQuery } from "./useAuthQuery";

type FetchCompanySettingsVariables = {
  companyId?: string | null;
  enabled?: boolean;
};
type FetchCompanySettingsErrorResponse = {
  message: string;
};
/**
 * Fetch company settings from Eero API
 * For now it only gets UI settings allowing to conditionnaly display some features
 */
async function fetchCompanySettings({
  companyId,
}: FetchCompanySettingsVariables) {
  const response = await eeroApi.get<CompanySettings>(
    `/oree2/company/${companyId}/settings`
  );

  return response.data;
}

/**
 * Custom hook for fetching Sale Order status from CRM API, with eero API as middleware.
 */
export function useGetCompanySettings({
  companyId,
  enabled = true,
}: FetchCompanySettingsVariables) {
  const query = useAuthQuery<
    CompanySettings,
    FetchCompanySettingsErrorResponse
  >({
    queryKey: ["getCompanySettings", companyId],
    queryFn: () => fetchCompanySettings({ companyId }),
    enabled,
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (query.isError) {
    const requestErrorMessage = query.error.response?.data?.message;

    errorMessage =
      requestErrorMessage ||
      query.genericErrorMessage ||
      "Impossible de récupérer la configuration de l'entreprise.";
  }

  return { ...query, errorMessage };
}
