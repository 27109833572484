import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

// Components import
import { PdfBanner } from "../../components/Pdf/Pdf-Devis/PdfBanner/PdfBanner";
import { PdfFooter } from "../../components/Pdf/Pdf-Devis/PdfFooter/PdfFooter";
import { PdfHeader } from "../../components/Pdf/Pdf-Devis/PdfHeader/PdfHeader";
import { PdfItemDetail } from "../../components/Pdf/Pdf-Devis/PdfItemDetail/PdfItemDetail";
import { PdfLayout } from "../../components/Pdf/Pdf-Devis/PdfLayout/PdfLayout";
import { PdfPagination } from "../../components/Pdf/Pdf-Devis/PdfPagination/PdfPagination";
import { PdfRowBackgroundProduit } from "../../components/Pdf/Pdf-Devis/PdfRowBackgroundProduit/PdfRowBackgroundProduit";
import { PdfRowProduit } from "../../components/Pdf/Pdf-Devis/PdfRowProduit/PdfRowProduit";
import { PdfSubTitleProduct } from "../../components/Pdf/Pdf-Devis/PdfSubTitleProduct/PdfSubTitleProduct";
import { PdfTitreProduit } from "../../components/Pdf/Pdf-Devis/PdfTitreProduit/PdfTitreProduit";
import { PdfDevisRGStyle as styles } from "./PdfDevisRGStyle";

// Services import
import { productPrices } from "../../services/RenovationGlobale/calculateProductPrice";

// Interfaces imoport
import { PdfTotalContainer } from "../../components/Pdf/Pdf-Devis/PdfTotalContainer/PdfTotalContainer";
import {
  HelpsOptions,
  ICommercialUserInformation,
  ISageProduct,
} from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";
import { formatPdfProductDescription } from "../../services/Devis/formatPdfProductDescription";
import { capitalize } from "../../utils/stringUtils";
import { meterToMillimeter } from "../../utils/unitUtils";

// Local interface declaration
interface IProps {
  documentId: string;
  windowProduct: IDataProduct | undefined;
  commercialUserInformation: ICommercialUserInformation;
  isEligibleRenoGlobal: boolean;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
  sageProduct?: ISageProduct;
}

export const PdfWindowPage = ({
  documentId,
  windowProduct,
  commercialUserInformation,
  isEligibleRenoGlobal,
  isDemoVersion,
  devisOptions,
  sageProduct: windowSageProduct,
}: IProps) => {
  const {
    extraChargeWithoutTax: windowExtraChargeWithoutTax,
    totalPriceWhithoutTax: windowTotalPriceWhithoutTax,
    totalTVA: windowTotalTVA,
    totalPriceWithTax: windowTotalPriceWithTax,
    totalHelp: windowTotalHelp,
    totalResteACharge: windowTotalResteACharge,
    extraChargeWithTax: windowExtraChargeWithTax,
    installationPriceWithoutTax: windowInstallationPriceWithoutTax,
    installationPriceWithTax: windowInstallationPriceWithTax,
    managementFeesTTC: windowManagementFeesTTC,
    managementFeesHT: windowManagementFeesHT,
    totalDeductibleHelps: windowTotalDeductibleHelps,
    totalRefundableHelps: windowTotalRefundableHelps,
    productPriceWhithTaxe: windowProductPriceWithTax,
    productPriceWhithoutTaxe: windowProductPriceWithoutTax,
  } = productPrices({
    product: windowProduct,
    isEligibleRenoGlobal,
    devisOptions,
  });

  const quantity =
    windowProduct?.selected_characteristics?.productQuantity || 1;

  const windows = windowProduct?.selected_characteristics?.windows || [];

  return (
    <Page size="A4" style={styles.pagePadding}>
      <View>
        <View fixed>
          <PdfBanner />
        </View>

        <PdfLayout isDemoVersion={isDemoVersion}>
          <View fixed>
            <PdfHeader
              commercialUserInformation={commercialUserInformation}
              devis={documentId}
              dateDevis={`${moment().format("DD/MM/YYYY")}`}
              logoBase64={commercialUserInformation.company_logo_base_64}
            />
          </View>
          <PdfTitreProduit
            title={
              windowProduct?.product_alias ||
              windowProduct?.product_name ||
              "Produit NC"
            }
          />
          <PdfSubTitleProduct
            title={
              "Référence fabricant : " +
                windowSageProduct?.["Référence fabricant"] || "NC"
            }
          />
          <View style={styles.containerTable}>
            <View style={styles.firstAbscisse}>
              <Text style={styles.descriptionProduits}>Description</Text>
              <Text style={styles.quantite}>Quantité</Text>
              <Text style={styles.prixUnitaireHT}>Prix unitaire HT</Text>
              <Text style={styles.tva}>Tva</Text>
              <Text style={styles.montantTTC}>Montant ttc</Text>
            </View>

            <PdfRowBackgroundProduit
              name="Matériel"
              quantity={quantity}
              prixUnitaire={windowProductPriceWithoutTax / quantity || 0}
              TVA={windowProduct?.TVA_percentage || 0}
              montantTTC={windowProductPriceWithTax || 0}
            />

            <PdfItemDetail
              title="Dimensions"
              description={windows.map((window) => {
                return (
                  <Text key={window.id}>
                    {/* Windows dimensions are stored in meters (data backend constraint) but we want to display them in milimeters (business constraint) */}
                    {meterToMillimeter(window.width)} x{" "}
                    {meterToMillimeter(window.height)} mm (
                    {capitalize(window.type)})
                  </Text>
                );
              })}
            />

            {/* window */}
            {windowSageProduct ? (
              <PdfItemDetail
                key={windowSageProduct.AR_Ref}
                title={
                  windowProduct?.product_name
                    ? windowSageProduct.NO_Qte
                      ? windowSageProduct.NO_Qte +
                        " " +
                        windowProduct?.product_name
                      : windowProduct?.product_name
                    : ""
                }
                description={[
                  formatPdfProductDescription({
                    dataProduct: windowProduct,
                    sageProduct: windowSageProduct,
                  }),
                ]}
              />
            ) : null}

            <View wrap={false}>
              <PdfRowBackgroundProduit
                name="Forfait installation"
                quantity={quantity}
                prixUnitaire={windowInstallationPriceWithoutTax / quantity}
                TVA={windowProduct?.TVA_percentage || 0}
                montantTTC={windowInstallationPriceWithTax}
              />
              <View style={styles.inlineComponents}>
                <Text>Déplacement</Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Dépose du matériel existant (mais n’inclut pas l’enlèvement et
                  l’évacuation de celui-ci)
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>
                  Fourniture des équipements nécessaires à la pose des matériels
                </Text>
              </View>
              <View style={styles.inlineComponents}>
                <Text>Mise en service de l’installation</Text>
              </View>
            </View>
            {(windowProduct?.extra_charges_added?.length || 0) > 0 && (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Spécificité du chantier"
                  quantity={windowProduct?.extra_charges_added?.length}
                  prixUnitaire={windowExtraChargeWithoutTax || 0}
                  TVA={windowProduct?.TVA_percentage || 0}
                  montantTTC={windowExtraChargeWithTax || 0}
                />
                {windowProduct?.extra_charges_added?.map((extraCharge) => {
                  return (
                    <PdfRowProduit
                      key={extraCharge.product_name}
                      name={extraCharge.product_name}
                      quantity={1}
                      prixUnitaire={extraCharge.price_without_tax}
                      TVA={extraCharge.TVA_percentage}
                      montantTTC={extraCharge.price_with_tax}
                      description={extraCharge.description}
                    />
                  );
                })}
              </View>
            )}
            <View wrap={false}>
              {windowManagementFeesTTC !== 0 ? (
                <>
                  <PdfRowBackgroundProduit
                    name="Frais de gestion"
                    quantity={1}
                    prixUnitaire={windowManagementFeesHT}
                    TVA={windowProduct?.installation_tva_percentage}
                    montantTTC={windowManagementFeesTTC}
                  />
                  <View style={styles.inlineComponents}>
                    <Text>
                      Prise en charge des démarches administratives et gestion
                      de projet/SAV
                    </Text>
                  </View>
                  <View style={styles.inlineComponents}>
                    <Text style={{ marginBottom: 10 }}>
                      Constitution des dossiers d’aides financières
                      éventuellement liées au projet
                    </Text>
                  </View>

                  <PdfRowBackgroundProduit
                    name="Frais de gestion offerts"
                    montantTTC={-windowManagementFeesTTC}
                  />
                </>
              ) : null}
            </View>
            {windowTotalHelp && devisOptions.isHelpsDisplayed ? (
              <View wrap={false}>
                <PdfRowBackgroundProduit
                  name="Aides"
                  aides={windowProduct?.help
                    .filter((help) => help.is_eligible)
                    .map((help) => {
                      return {
                        aide: help.name,
                        montant: help.value,
                        isDeducted:
                          help.help_type === "mpr"
                            ? devisOptions.isMPRHelpsDeducted
                            : help.help_type === "cee"
                            ? devisOptions.isCEEHelpsDeducted
                            : false,
                      };
                    })}
                />
              </View>
            ) : null}
            <PdfTotalContainer
              devisOptions={devisOptions}
              totalHT={windowTotalPriceWhithoutTax}
              totalTVA={windowTotalTVA}
              totalTTC={windowTotalPriceWithTax}
              totalHelp={windowTotalHelp}
              resteACharge={windowTotalResteACharge}
              deductibleHelp={windowTotalDeductibleHelps}
              refundableHelp={windowTotalRefundableHelps}
              isSubProduct={true}
            />
          </View>
        </PdfLayout>
      </View>
      <View fixed style={styles.containerPagination}>
        <PdfPagination />
      </View>
      <PdfFooter fixed commercialUserInformation={commercialUserInformation} />
    </Page>
  );
};
