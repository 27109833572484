export const formatPhoneNumber = (number: string | null | undefined) => {
  if (number) {
    const regex = /^(\d{2})\s*(\d{2})\s*(\d{2})\s*(\d{2})\s*(\d{2})$/;
    const match = number.trim().match(regex);
    if (match) {
      return `+33 ${match[1][1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    } else {
      return number;
    }
  }
};
