// import React from "react";
import React from "react";
// Styles import
import SVG from "react-inlinesvg";
import styled from "styled-components";
// imgs import
import minus from "../../../../assets/images/DesignSystemIcons/minus.svg";
import plus from "../../../../assets/images/DesignSystemIcons/plus.svg";
import { DSColors } from "../../../../styles/variables";

export interface UpDownButtonProps {
  value: number;
  increment?: number;
  onPlusMinusClick: (value: number) => void;
  minValue?: number;
  maxValue?: number;
}
const Container = styled.div`
  display: flex;
`;
const Wrapper = styled.div`
  border: 1px solid ${DSColors.SGLight02};
  border-radius: 4px;
  background-color: ${DSColors.white};
  width: 155px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 45px;
  font-family: "DM Sans", serif;
  font-weight: 500;
  font-size: 16px;
  path {
    fill: #08b77f;
  }

  > div:hover {
    cursor: pointer;
  }
`;
const MinusSvg = styled(SVG)<{ value: number; minValue: number }>`
  filter: ${(props) =>
    props.value > props.minValue ? "unset" : "grayscale(1)"};
`;

const PlusSvg = styled(SVG)<{ value: number; maxValue: number }>`
  filter: ${(props) =>
    props.value < props.maxValue ? "unset" : "grayscale(1)"};
`;

export const Quantite: React.FC<UpDownButtonProps> = ({
  value = 0,
  increment,
  onPlusMinusClick,
  minValue = 0,
  maxValue = Infinity,
}) => {
  return (
    <Container>
      <Wrapper>
        <ButtonsWrapper>
          <div
            onClick={() =>
              onPlusMinusClick(
                value === minValue ? minValue : (value -= increment || 1)
              )
            }
          >
            <MinusSvg src={minus} value={value} minValue={minValue} />
          </div>

          {value}

          <div
            onClick={() => {
              onPlusMinusClick(
                value === maxValue ? maxValue : (value += increment || 1)
              );
            }}
          >
            <PlusSvg src={plus} value={value} maxValue={maxValue} />
          </div>
        </ButtonsWrapper>
      </Wrapper>
    </Container>
  );
};
