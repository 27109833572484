import {
  DataPersonalizedScenarioPvExtended,
  IAvailableCalepinages,
} from "../../interfaces/generalInterfaces";
import eeroApi from "../eeroApiAxios";
import { useAuthQuery } from "./useAuthQuery";

type UseGetScenarioProps = {
  pathQueries: {
    studyId: string | null;
  };
  paramsQueries: {
    buildingId: string | null;
    householdId: string | null;
    numberOfPanels: number | null;
    numberOfBatteries: number | null;
    kitHomeManagement: boolean | null;
    inverter_brand: string | null;
    single_battery_capacity: number | null;
    panel_indices?: number[] | null;
    totalExtraFees: number;
    options: string[] | null;
  };
  personalizedScenarios?: IAvailableCalepinages;
};

type FetchPersonalizedScenarioErrorResponse = {
  message: string;
  error?: string;
};

async function fetchPersonalizedScenario(props: UseGetScenarioProps) {
  const { paramsQueries, pathQueries, personalizedScenarios } = props; // Extract query params from queryKey
  const response = await eeroApi.get<DataPersonalizedScenarioPvExtended>(
    `/oree2/pv/studies/${pathQueries.studyId}/personalizedScenario`,
    {
      params: {
        ...paramsQueries,
      },
    }
  );

  // If we have a personalized calepinage, we need to add it to the personalized scenario
  const responseExtended = response.data;

  if (personalizedScenarios?.ortho_panel_coordinates) {
    const orthoCoordinatesEntries = Object.entries(
      personalizedScenarios?.ortho_panel_coordinates
    ).filter(([key]) => paramsQueries.panel_indices?.includes(Number(key)));

    responseExtended.calepinage_chosen_ortho_coordinates = Object.fromEntries(
      orthoCoordinatesEntries
    );
  }
  if (personalizedScenarios?.solar_panel_coordinates) {
    const solarCoordinatesEntries = Object.entries(
      personalizedScenarios?.solar_panel_coordinates || {}
    ).filter(([key]) => paramsQueries.panel_indices?.includes(Number(key)));

    responseExtended.calepinage_chosen_solar_coordinates = Object.fromEntries(
      solarCoordinatesEntries
    );
  }

  paramsQueries.panel_indices &&
    (responseExtended.calepinage_chosen_indexes = paramsQueries.panel_indices);
  personalizedScenarios?.ortho_image &&
    (responseExtended.calepinage_image = personalizedScenarios?.ortho_image);
  personalizedScenarios?.solar_potential_image &&
    (responseExtended.solar_potential_image =
      personalizedScenarios?.solar_potential_image);
  return responseExtended;
}

/**
 * Custom hook for fetching the personalized scenario information from eero, with eeroApi as middleware.
 */
export function useGetPersonalizedScenario(props: UseGetScenarioProps) {
  const query = useAuthQuery<
    DataPersonalizedScenarioPvExtended,
    FetchPersonalizedScenarioErrorResponse
  >({
    queryKey: [
      "getPersonalizedScenario",
      { ...props.pathQueries, ...props.paramsQueries },
    ], // Allow to cache the query if the queryKeys are the same
    queryFn: () => fetchPersonalizedScenario(props),
    enabled: false, // We don't want to fetch the personalized scenario on component mount
  });

  // Priorization of error messages : first the expected request error message, then the generic error message, then default error message
  let errorMessage = "";
  if (query.isError) {
    const requestErrorMessage =
      query.error.response?.data?.message || query.error.response?.data?.error;

    errorMessage =
      requestErrorMessage ||
      query.genericErrorMessage ||
      "Impossible de récupérer le scénario personnalisé.";
  }

  return { ...query, errorMessage };
}
