import { Document } from "@react-pdf/renderer";

// Interfaces import
import {
  HelpsOptions,
  ICommercialUserInformation,
  IMonInstallation,
  IOreeStudyType,
  ISageProduct,
  IStudyInformation,
} from "../../interfaces/generalInterfaces";

// Pages
import {
  IDataProduct,
  IPerformanceRG,
  IProjectKPI,
} from "../../interfaces/renoglobaleInterface";
import { IConsumptionsDataDonuts } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetConsumptionDonuts";
import { PdfEtudeHelpsPage } from "../pdf-etude/PdfEtudePageAides";
import { PdfEtudePresentationMisterMenuiserie } from "./PdfEtudePresentationMisterMenuiserie";
import { PdfEtudePresentationTuco } from "./PdfEtudePresentationTuco";
import { PdfEtudeRGHomePage } from "./PdfEtudeRGHomePage";
import { PdfEtudeRGLastPage } from "./PdfEtudeRGLastPage";
import { PdfEtudeRGPageImpactEnvironmental } from "./PdfEtudeRGPageImpactEnvironmental";
import { PdfEtudeRGPageMesConsommations } from "./PdfEtudeRGPageMesConsommations";
import { PdfEtudeRGPagePerformance } from "./PdfEtudeRGPagePerformance";
import { PdfEtudeRGPagePerformancePart2 } from "./PdfEtudeRGPagePerformancePart2";
import { PdfEtudeRGPageRecapitulatif } from "./PdfEtudeRGPageRecapitulatif";
import { PdfEtudeRGPageRentabilité } from "./PdfEtudeRGPageRentabilite";
import { PdfEtudeRGPageSolarPotential } from "./PdfEtudeRGPageSolarPotential";
import { PdfEtudeRGPageThree } from "./PdfEtudeRGPageThree";
import { PdfEtudeRGPageVotreLogement } from "./PdfEtudeRGPageVotreLogement";

interface IProps {
  studyInformation: IStudyInformation;
  RGProjectKPI: IProjectKPI | null;
  emailInputValue: string;
  firstNameInputValue: string;
  lastNameInputValue: string;
  phoneNumberInputValue: string;
  commercialUserInformation: ICommercialUserInformation;
  documentId: string;
  imageHeatLoss?: string | null;
  imageDonut?: string | null;
  donutsData?: IConsumptionsDataDonuts | null;
  imageSolarPotential?: string | null;
  cartProducts: IDataProduct[];
  sageProducts: ISageProduct[];
  performanceRGData: IPerformanceRG | null | undefined;
  imageSolarPerformance?: string | null;
  imageAutoconsommation?: string | null;
  imageAutoproduction?: string | null;
  imageRentabilite?: string | null;
  greenValueData: number[] | null;
  monInstallation: IMonInstallation;
  restToPay: number;
  isDemoVersion: boolean;
  devisOptions: HelpsOptions;
}

export const PdfEtudeRG = ({
  studyInformation,
  RGProjectKPI,
  emailInputValue,
  firstNameInputValue,
  lastNameInputValue,
  phoneNumberInputValue,
  commercialUserInformation,
  documentId,
  imageHeatLoss,
  imageDonut,
  donutsData,
  imageSolarPotential,
  cartProducts,
  sageProducts,
  performanceRGData,
  imageSolarPerformance,
  imageAutoconsommation,
  imageAutoproduction,
  imageRentabilite,
  greenValueData,
  monInstallation,
  isDemoVersion,
  devisOptions,
}: IProps) => {
  const isPVProductPresentInCart = cartProducts.some(
    (elem) => elem.product_type === "PV"
  );
  return (
    <Document>
      {/* render a page */}

      <PdfEtudeRGHomePage
        studyInformation={studyInformation}
        emailInputValue={emailInputValue}
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        phoneNumberInputValue={phoneNumberInputValue}
        commercialUserInformation={commercialUserInformation}
        documentId={documentId}
        isDemoVersion={isDemoVersion}
      />

      {/* Following page is specific to Tuco and should not be displayed for other companies */}
      {commercialUserInformation.userCompany?.toLocaleLowerCase() === "tuco" ? (
        <PdfEtudePresentationTuco
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          commercialUserInformation={commercialUserInformation}
          documentId={documentId}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
      {/* Following page is specific to Mister Menuiserie and should not be displayed for other companies */}
      {commercialUserInformation.userCompany
        ?.toLocaleLowerCase()
        .includes("menuiserie") ? (
        <PdfEtudePresentationMisterMenuiserie
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
      <PdfEtudeRGPageThree
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        commercialUserInformation={commercialUserInformation}
        documentId={documentId}
        isDemoVersion={isDemoVersion}
      />
      <PdfEtudeRGPageVotreLogement
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        documentId={documentId}
        studyInformation={studyInformation}
        imageHeatLoss={imageHeatLoss || null}
        isDemoVersion={isDemoVersion}
      />
      <PdfEtudeRGPageMesConsommations
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        documentId={documentId}
        studyInformation={studyInformation}
        imageDonut={imageDonut || null}
        donutsData={donutsData || null}
        isDemoVersion={isDemoVersion}
      />
      {isPVProductPresentInCart && studyInformation.solar_potential_image ? (
        <PdfEtudeRGPageSolarPotential
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          studyInformation={studyInformation}
          imageSolarPotential={imageSolarPotential || null}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
      <PdfEtudeRGPageRecapitulatif
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        documentId={documentId}
        RGProjectKPI={RGProjectKPI}
        cartProducts={cartProducts}
        sageProducts={sageProducts}
        isDemoVersion={isDemoVersion}
      />
      <PdfEtudeRGPagePerformance
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        documentId={documentId}
        RGProjectKPI={RGProjectKPI}
        performanceRGData={performanceRGData}
        imageSolarPerformance={imageSolarPerformance || null}
        isPVProductPresentInCart={isPVProductPresentInCart}
        isDemoVersion={isDemoVersion}
      />
      {isPVProductPresentInCart ? (
        <PdfEtudeRGPagePerformancePart2
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          performanceRGData={performanceRGData}
          imageAutoconsommation={imageAutoconsommation || null}
          imageAutoproduction={imageAutoproduction || null}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
      <PdfEtudeRGPageRentabilité
        firstNameInputValue={firstNameInputValue}
        lastNameInputValue={lastNameInputValue}
        documentId={documentId}
        imageRentabilite={imageRentabilite || null}
        greenValueData={greenValueData}
        isDemoVersion={isDemoVersion}
      />
      {devisOptions.isHelpsDisplayed ? (
        <PdfEtudeHelpsPage
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          studyInformation={studyInformation}
          RGProjectKPI={RGProjectKPI || undefined}
          studyType={IOreeStudyType.RENOVATION_GLOBALE}
        />
      ) : null}
      {monInstallation?.impactEnvironnemental?.CO2_equivalents
        ?.CO2_savings_25years ? (
        <PdfEtudeRGPageImpactEnvironmental
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          monInstallation={monInstallation}
          isDemoVersion={isDemoVersion}
        />
      ) : null}

      {/* FOR NOW WE REMOVE IT : WAITING FOR UX UPDATE FROM PRODUCT TEAM */}
      {/* {studyInformation.type_of_payment === "Organisme de financement" ? (
        <PdfEtudeRGPageFinancement
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          studyInformation={studyInformation}
          restToPay={restToPay}
          isDemoVersion={isDemoVersion}
        />
      ) : null} */}
      {/* Following page is specific to Tuco : shloud not be displayed with other companies */}

      {commercialUserInformation.userCompany?.toLocaleLowerCase() === "tuco" ? (
        <PdfEtudeRGLastPage
          firstNameInputValue={firstNameInputValue}
          lastNameInputValue={lastNameInputValue}
          documentId={documentId}
          studyInformation={studyInformation}
          isDemoVersion={isDemoVersion}
        />
      ) : null}
    </Document>
  );
};
