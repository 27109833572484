// Libraries import
import { useState } from "react";
import { useAxiosGet } from "./useAxiosGet";

// Services imports
import { setCommercialUserInfoAction } from "../redux/appActions";
import { useAppDispatch } from "./../redux/store/hook";

interface IProps {
  userId: string;
  userToken: string;
}

interface IGetCommercialInformationResponse {
  email: string;
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
  company_name: string | null;
  role: string | null;
  authorization_rights?: number[];
  company_address: string | null;
  company_postal_code: string | null;
  company_city: string | null;
  company_service_client_email: string | null;
  company_service_client_phone: string | null;
  company_registre_du_commerce_numero: string | null;
  company_tva_intracommunautaire_numero: string | null;
  company_capital: string | null;
  company_siret: string | null;
  company_website_url?: string | null;
  company_id: string;
  company_logo_base_64: string | null;
  company_raison_sociale: string | null;
}

export const useGetCommercialInformation = () => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getCommercialInformation = async ({
    userId,
    userToken,
  }: IProps): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IGetCommercialInformationResponse>(
      `${process.env.REACT_APP_EERO_URL}/oree2/commercials/${userId}/commercialInformation`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    if (response) {
      dispatch(
        setCommercialUserInfoAction({
          commercialUserInformation: {
            userCompany: response.company_name,
            userEmail: response.email,
            userFirstName: response.firstname,
            userLastName: response.lastname,
            userPhoneNumber: response.phonenumber,
            userRole: response.role,
            authorization_rights: response.authorization_rights,
            company_address: response.company_address,
            company_postal_code: response.company_postal_code,
            company_city: response.company_city,
            company_service_client_email: response.company_service_client_email,
            company_service_client_phone: response.company_service_client_phone,
            company_registre_du_commerce_numero:
              response.company_registre_du_commerce_numero,
            company_tva_intracommunautaire_numero:
              response.company_tva_intracommunautaire_numero,
            company_capital: response.company_capital,
            company_siret: response.company_siret,
            company_website_url: response.company_website_url,
            company_id: response.company_id,
            company_logo_base_64: response.company_logo_base_64,
            company_raison_sociale: response.company_raison_sociale,
          },
        })
      );
    } else {
      setErrorMessage("Erreur lors de l'import de vos données");
    }
  };

  return {
    getCommercialInformation,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
