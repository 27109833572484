// Style import
import "./MesInformationsForm.scss";

// Libraries import
import { Wrapper } from "@googlemaps/react-wrapper";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { emailRegex, phoneNumberRegex } from "../../../utils/validationPaterns";

// Images import
import pictoFormulaire from "../../../assets/images/picto-formulaire-bonhomme.svg";
import pictoLocalisation from "../../../assets/images/picto-localisation-france.svg";

// @shared components import
import { Dropdown } from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";

// Services imports
import { setStudyInfoAction } from "../../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { useCreateOree2Study } from "../../../services/MesInformations/useCreateOree2Study";
import { useGetCustomerInformation } from "../../../services/MesInformations/useGetCustomerInformation";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { FooterHills } from "../../../components/FooterHills/FooterHills";
import { LoadingScreen } from "../../../containers/LoadingScreen/LoadingScreen";
import { InputAddressRG } from "../../MesInformationsRG/MesInformationsRGForm/InputAddress/InputAddressRG";

// Interfaces import
import {
  IGoTo,
  IOreeStudyType,
  UiFeatures,
} from "../../../interfaces/generalInterfaces";
import { useGetStudy } from "../../../services/Studies/useGetStudy";
import { INavigateData } from "../../../services/useNavigation/useNavigation";

// Datas import
import { howDidYouKnowUsOptions } from "../../MesInformationsRG/MesInformationsRGForm/MesInformationsRGForm";

// Local interfaces delcaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
  setIsGoToFunctionActive: React.Dispatch<React.SetStateAction<boolean>>;
  study_type: IOreeStudyType;
}

interface IOption {
  label: string | null;
  value: string | null;
}

export const MesInformationsForm = ({ goNext, study_type }: Props) => {
  const navigate = useNavigate();
  const { study_id } = useParams();
  const dispatch = useAppDispatch();

  // CUSTOM HOOK IMPORTS
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const commercialCompanyName =
    useAppSelector((state) => state.commercialUserInformation?.userCompany) ||
    userAuthInfo.company_name ||
    null; // If for some case the commercialUserInformation is not available
  const companyUiSettings = useAppSelector(
    (state) => state.companySettings.uiSettings
  );
  const {
    getCustomerInformation,
    errorMessage: getCustomerInformationErrorMessage,
  } = useGetCustomerInformation();
  const {
    createOree2Study,
    isLoading: isSaveCustomerInformationLoading,
    errorMessage: saveCustomerInformationErrorMessage,
  } = useCreateOree2Study();
  const { getStudy } = useGetStudy();

  // LOCAL VARIABLES
  const isHowDidYouKnowUsEnable =
    companyUiSettings?.find(
      (feature) => feature.feature === UiFeatures.HOW_DID_YOU_KNOW_US
    )?.is_enabled ?? true; // By default the feature is enabled

  // LOCAL STATES
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(
    studyInformation.firstname || ""
  );

  const [lastName, setLastName] = useState<string>(
    studyInformation.lastname || ""
  );
  const [email, setEmail] = useState<string>(studyInformation.email || "");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    studyInformation.phonenumber || ""
  );
  const [locationInfo, setLocationInfo] = useState<{
    lat?: number;
    lng?: number;
    zipCode?: string;
    city?: string | null;
    address?: string;
  }>({
    address: studyInformation.address || "",
    zipCode: studyInformation.zipcode || "",
  });

  const [howDidYouKnowUs, setHowDidYouKnowUs] = useState<IOption[]>(
    isHowDidYouKnowUsEnable
      ? studyInformation.how_did_you_know_us
        ? [
            {
              label: studyInformation.how_did_you_know_us,
              value: studyInformation.how_did_you_know_us,
            },
          ]
        : []
      : [{ label: commercialCompanyName, value: commercialCompanyName }] // If the howDidYouKnowUs feature is not enabled, we set the commercial company name as default "how did you know us" value
  );

  // ON CHANGE EVENTS FUNCTIONS
  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const onSelectLocation = ({
    markerLat,
    markerLng,
    zipCode,
    city,
    description,
  }: {
    markerLat: number;
    markerLng: number;
    zipCode: string;
    city: string | null;
    description: string;
  }) => {
    setLocationInfo((state) => {
      return {
        ...state,
        lat: markerLat,
        lng: markerLng,
        zipCode: zipCode,
        city,
        address: description,
      };
    });
  };

  const onClickNext = async () => {
    const response = await createOree2Study({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id || null,
      customer_id: studyInformation.customer_id,
      firstname: firstName,
      lastname: lastName,
      email: email.toLocaleLowerCase(),
      phonenumber: phoneNumber,
      zipcode: locationInfo.zipCode,
      city: locationInfo.city,
      address: locationInfo.address,
      latitude: locationInfo.lat,
      longitude: locationInfo.lng,
      becqe_firstname: firstName,
      becqe_lastname: lastName,
      study_type,
      how_did_you_know_us: howDidYouKnowUs[0].value,
    });

    if (response) {
      if (response.data?.isRedirectToExistingStudy) {
        setIsModalOpen(true);
        getStudy({
          userToken: userAuthInfo.userToken,
          study_id: response.data.study_id,
        });
        return;
      }
      response.data.study_id &&
        !window.location.pathname.includes(response.data.study_id) &&
        navigate(`/etude/${study_type}/${response.data.study_id}`); // Allow to update pathname params with the study_id in the case of a new study (wihout navigating)
      goNext();
      return;
    }
    return;
  };

  const onClickOnCancel = () => {
    dispatch(
      setStudyInfoAction({
        studyInformation: null,
      })
    );
    setIsModalOpen(false);
  };

  const fetchGetUserInformation = async () => {
    studyInformation.study_id &&
      !studyInformation.customer_id &&
      (await getCustomerInformation({
        userToken: userAuthInfo.userToken,
        study_id: studyInformation.study_id,
      }));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchGetUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFormDisabled =
    !(locationInfo.address || studyInformation.address) ||
    !firstName ||
    !lastName ||
    !phoneNumber?.match(phoneNumberRegex) ||
    !email?.match(emailRegex);
  return (
    <>
      <Modale
        isModaleOpen={isModalOpen}
        title="Etude existante !"
        text="Un étude est déjà en cours pour cet email et cette adresse."
      >
        <div className="mesInformationsForm__ModalContent">
          <p>Charger les données et continuer ?</p>
          <div className="mesInformationsForm__ModalButtons">
            <CTAButton
              name="Annuler"
              category={"secondary"}
              onClick={onClickOnCancel}
            />
            <CTAButton name="Continuer" onClick={onClickNext} />
          </div>
        </div>
      </Modale>

      <div className="mesInformationsForm">
        {isLoading ? (
          <LoadingScreen sentenceOne="Chargement des données utilisateur ..." />
        ) : getCustomerInformationErrorMessage ? (
          <div className="mesInformationsForm__ErrorMessage">
            <ErrorMessage errorMessage={getCustomerInformationErrorMessage} />
          </div>
        ) : (
          <div className="mesInformationsForm__Container">
            <form className="mesInformationsForm__FormContainer">
              {isHowDidYouKnowUsEnable ? (
                <div className="mesInformationsForm__TextInputsContainers">
                  <section className="mesInformationsForm__TextInputsDescription">
                    <p>
                      {`Comment avez vous connu ${
                        userAuthInfo.company_name?.toLocaleUpperCase() ||
                        "nos services"
                      } ?`}
                      {study_id !== "new" ? " *" : null}
                    </p>
                  </section>
                  <section
                    style={{ gridTemplateColumns: "1fr" }}
                    className="mesInformationsRGForm__DropdownContainer"
                  >
                    <Dropdown
                      options={howDidYouKnowUsOptions}
                      optionsSelected={howDidYouKnowUs}
                      onSelect={(option) => {
                        setHowDidYouKnowUs([option]);
                      }}
                      multipleChoices={false}
                      placeholder={"Choisir"}
                      tags={false}
                      searchable={false}
                    />
                  </section>
                </div>
              ) : null}
              <div className="mesInformationsForm__TextInputsContainers">
                <section className="mesInformationsForm__TextInputsDescription">
                  <img
                    src={pictoFormulaire}
                    alt="icone"
                    width={61}
                    height={43}
                  />
                  <p>Veuillez indiquer les informations de contact&nbsp;:</p>
                </section>
                <section className="mesInformationsForm__InputsList">
                  <TextInput
                    value={firstName}
                    placeholder={"Prénom"}
                    onChange={onFirstNameChange}
                    autoComplete="off"
                  />
                  <TextInput
                    value={lastName}
                    placeholder={"Nom de famille"}
                    onChange={onLastNameChange}
                    autoComplete="off"
                  />
                  <TextInput
                    value={email}
                    placeholder={"Email"}
                    onChange={onEmailChange}
                    autoComplete="off"
                  />
                  <TextInput
                    value={phoneNumber}
                    placeholder="Numéro de téléphone"
                    onChange={onPhoneNumberChange}
                    autoComplete="off"
                  />
                </section>
              </div>
              <div className="mesInformationsForm__TextInputsContainers">
                <section className="mesInformationsForm__TextInputsDescription">
                  <img
                    src={pictoLocalisation}
                    alt="icone"
                    width={61}
                    height={43}
                  />
                  <p>
                    Quelle est l’adresse du logement à équiper&nbsp;?
                    {study_id !== "new" ? " *" : null}
                  </p>
                </section>
                <section
                  style={{ gridTemplateColumns: "1fr" }}
                  className="mesInformationsForm__InputsList"
                >
                  <Wrapper
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
                    libraries={["places"]}
                  >
                    <InputAddressRG
                      onSelectLocation={onSelectLocation}
                      addressInRedux={studyInformation.address || ""}
                      isDisabled={study_id !== "new"}
                    />
                  </Wrapper>
                  {study_id !== "new" ? (
                    <p className="mesInformationsForm__Asterisque">
                      * Si vous souhaitez changer d'adresse merci de démarrer
                      une nouvelle étude
                    </p>
                  ) : null}
                </section>
              </div>
            </form>
            {email && email?.length > 0 && !email?.match(emailRegex) && (
              <ErrorMessage
                errorMessage={
                  "Le format de l'adresse mail indiquée n'est pas valide"
                }
              />
            )}
            {phoneNumber &&
              phoneNumber.length > 0 &&
              !phoneNumber?.match(phoneNumberRegex) && (
                <ErrorMessage
                  errorMessage={
                    "Le format du numéro de téléphone indiqué n'est pas valide"
                  }
                />
              )}
            <div className="mesInformationsForm__ButtonContainer">
              <CTAButton
                name="Suivant"
                onClick={onClickNext}
                isLoading={isSaveCustomerInformationLoading}
                isDisabled={isFormDisabled}
              />
            </div>
            <div className="mesInformationsForm__ErrorMessage">
              <ErrorMessage
                errorMessage={saveCustomerInformationErrorMessage}
              />
            </div>
            <FooterHills isHouseVisible />
          </div>
        )}
      </div>
    </>
  );
};
