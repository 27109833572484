import { MutationStatus } from "@tanstack/react-query";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { PropsWithChildren, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { ValidationCheck } from "../../../Animations/ValidationCheck/ValidationCheck";
import "./recapitulatifLoaderModale.scss";
// import questionMark from "@web/shared/dist/assets/images/DesignSystemIcons/question-mark-circle.svg";
import pauseCircle from "@web/shared/src/assets/images/DesignSystemIcons/pause-circle.svg";

export enum Mode {
  SAVE = "save",
  SUBSCRIBE = "subscribe",
}

enum Document {
  QUOTE = "quote",
  STUDY = "study",
  BDC = "bdc",
}

type RecapitualifLoaderModaleProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mode: Mode;

  generateCrmQuoteStatus: MutationStatus;
  generateCrmBdcStatus: MutationStatus;
  generateCrmDocusignStatus: MutationStatus;

  saveStudyPdfInAbsStatus: MutationStatus;
  saveQuotePdfInAbsStatus: MutationStatus;

  sendDocumentsByMailStatus: MutationStatus;

  pdfQuoteUrl: string | null;
  pdfStudyUrl: string | null;

  pdfBdcBase64: string | null;

  onClickCreateDocusign: () => void;
  onClickSendMail: () => void;
  onClickFinish: () => void;
};

export default function RecapitulatifLoaderModale({
  isOpen,
  setIsOpen,
  mode,

  generateCrmQuoteStatus,
  generateCrmBdcStatus,
  generateCrmDocusignStatus,

  saveStudyPdfInAbsStatus,
  saveQuotePdfInAbsStatus,

  sendDocumentsByMailStatus,

  pdfQuoteUrl,
  pdfStudyUrl,

  pdfBdcBase64,

  onClickCreateDocusign,
  onClickSendMail,
  onClickFinish,
}: RecapitualifLoaderModaleProps) {
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);

  let iframeSrc = "";
  switch (currentDocument) {
    case Document.QUOTE:
      iframeSrc = pdfQuoteUrl ?? "";
      break;
    case Document.STUDY:
      iframeSrc = pdfStudyUrl ?? "";
      break;
    case Document.BDC:
      iframeSrc = `data:application/pdf;base64,${pdfBdcBase64 ?? ""}`;
      break;
    default:
      iframeSrc = "";
  }

  useEffect(() => {
    if (!isOpen) setCurrentDocument(null);
  }, [isOpen]);

  return (
    <Modale
      isModaleOpen={isOpen}
      setShowModal={setIsOpen}
      title="Vos documents sont en cours de création"
      maxHeight="90vh"
      width="95vw"
      maxWidth="95vw"
    >
      <div className="RecapitulatifLoaderModale">
        <section className="RecapitulatifLoaderModale__LoaderSection">
          <LoaderText status={pdfStudyUrl ? "success" : "pending"}>
            <p>Création du PDF Étude</p>
          </LoaderText>

          <LoaderText status={pdfQuoteUrl ? "success" : "pending"}>
            <p>Création du PDF Devis</p>
          </LoaderText>

          {mode === Mode.SUBSCRIBE ? (
            <>
              <LoaderText status={generateCrmQuoteStatus}>
                <p>Création du devis sur le CRM</p>
              </LoaderText>

              <LoaderText status={generateCrmBdcStatus}>
                <p>Création du bon de commande sur le CRM</p>
              </LoaderText>

              <LoaderText status={generateCrmDocusignStatus}>
                <p>Création du Docusign</p>
              </LoaderText>

              <LoaderText
                status={
                  generateCrmDocusignStatus === "success" ? "success" : "idle"
                }
              >
                <p>Envoi du Docusign par mail</p>
              </LoaderText>

              <LoaderText status={sendDocumentsByMailStatus}>
                <p>Envoi des documents Orée par mail</p>
              </LoaderText>
            </>
          ) : (
            <>
              <LoaderText status={saveQuotePdfInAbsStatus}>
                <p>Sauvegarde du devis</p>
              </LoaderText>

              <LoaderText status={saveStudyPdfInAbsStatus}>
                <p>Sauvegarde du document étude</p>
              </LoaderText>

              <LoaderText status={sendDocumentsByMailStatus}>
                <p>Envoi des documents Orée par mail</p>
              </LoaderText>
            </>
          )}
        </section>

        <section className="RecapitulatifLoaderModale__ActionButtonSection">
          <CTAButton
            name="Voir l'étude Orée"
            category="secondary"
            isDisabled={!pdfStudyUrl}
            onClick={() => {
              if (!pdfStudyUrl) return;
              setCurrentDocument(Document.STUDY);
            }}
          />
          <CTAButton
            name="Voir le devis Orée"
            category="secondary"
            isDisabled={!pdfQuoteUrl}
            onClick={() => {
              if (!pdfQuoteUrl) return;
              setCurrentDocument(Document.QUOTE);
            }}
          />
          {mode === Mode.SUBSCRIBE ? (
            <>
              <CTAButton
                name="Voir le bon de commande"
                category="secondary"
                isDisabled={generateCrmBdcStatus !== "success"}
                onClick={() => {
                  if (!pdfBdcBase64) return;
                  setCurrentDocument(Document.BDC);
                }}
              />
              <CTAButton
                name={
                  (generateCrmDocusignStatus === "success"
                    ? "Recréer"
                    : "Créer") + " le docusign et envoyer par mail"
                }
                isDisabled={generateCrmBdcStatus !== "success"}
                isLoading={generateCrmDocusignStatus === "pending"}
                onClick={onClickCreateDocusign}
              />
              <CTAButton
                name="Terminer"
                isDisabled={generateCrmDocusignStatus !== "success"}
                onClick={onClickFinish}
              />
            </>
          ) : (
            <>
              <CTAButton
                name="Envoyer par mail"
                isDisabled={!pdfQuoteUrl || !pdfStudyUrl}
                isLoading={sendDocumentsByMailStatus === "pending"}
                onClick={onClickSendMail}
              />
              <CTAButton name="Terminer" onClick={onClickFinish} />
            </>
          )}
        </section>

        {currentDocument && (
          <section className="RecapitulatifLoaderModale__DocumentSection">
            <iframe title="Document de l'étude" src={iframeSrc}></iframe>
          </section>
        )}
      </div>
    </Modale>
  );
}

function LoaderText({
  status,
  children,
}: PropsWithChildren<{
  status: MutationStatus;
}>) {
  return (
    <div className="RecapitulatifLoaderModale__LoaderTextContainer">
      <StatusIcon status={status} />
      {children}
    </div>
  );
}

function StatusIcon({ status }: { status: MutationStatus }) {
  switch (status) {
    case "success":
      return <ValidationCheck diameter={30} success={true} />;
    case "error":
      return <ValidationCheck diameter={30} success={false} />;
    case "pending":
      return <LoadingComponent diameter={30} />;
    case "idle":
      return (
        <SVG src={pauseCircle} className="RecapitulatifLoaderModale__SVG" />
      );
    default:
      return <span>❓</span>;
  }
}
