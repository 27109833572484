// Interfaces import
import { HelpsOptions } from "../../interfaces/generalInterfaces";
import { IDataProduct } from "../../interfaces/renoglobaleInterface";

// Local interface declaration
interface IProps {
  product?: IDataProduct;
  isEligibleRenoGlobal: boolean;
  devisOptions?: HelpsOptions;
}

export const productPrices = ({
  product,
  isEligibleRenoGlobal = true,
  devisOptions,
}: IProps) => {
  const productQuantity =
    product?.selected_characteristics?.productQuantity || 1;

  const productPriceWithoutTax = ({ product }: { product?: IDataProduct }) => {
    if (
      product?.product_type === "ITE" ||
      product?.product_type === "ITI" ||
      product?.product_type === "ISOA" ||
      product?.product_type === "ISO" ||
      product?.product_type === "ISOP"
    ) {
      return (
        Math.round(
          product?.product_price_without_tax *
            (product?.selected_characteristics?.surface_to_isolate || 0) *
            100
        ) / 100
      );
    }

    // If the product has interior units we need to add to the price of the product all the prices of its associated interior units
    const totalPriceWithoutTaxInteriorUnits =
      product?.selected_characteristics?.rooms_information?.reduce(
        (acc, room) => {
          return (
            acc +
            room.interior_units?.reduce((acc, interiorUnit) => {
              return acc + (interiorUnit?.interior_unit_price_without_tax || 0);
            }, 0)
          );
        },
        0
      ) || 0;

    // If the product has a battery and/or home management we need to add to the price of the product all the prices of its associated battery and home management
    const battery = product?.product_characteristics.available_batteries?.find(
      (battery) =>
        battery.sage_reference ===
        product?.selected_characteristics.battery_sage_reference
    );

    const batteryPriceWhithoutTax =
      (product?.selected_characteristics.n_batteries || 0) *
      (battery?.price_without_tax || 0);
    const homeManagementPriceWhithoutTax = product?.selected_characteristics
      ?.kit_home_management
      ? product.product_characteristics.kit_home_management
          ?.price_without_tax || 0
      : 0;

    // Total price without taxes, including the options (that can be =0)
    const totalPriceWithoutTaxes =
      (product?.product_price_without_tax || 0) +
      totalPriceWithoutTaxInteriorUnits +
      batteryPriceWhithoutTax +
      homeManagementPriceWhithoutTax;

    return Math.round(totalPriceWithoutTaxes * productQuantity * 100) / 100;
  };

  const productPriceWithTax = ({ product }: { product?: IDataProduct }) => {
    if (
      product?.product_type === "ITE" ||
      product?.product_type === "ITI" ||
      product?.product_type === "ISOA" ||
      product?.product_type === "ISO" ||
      product?.product_type === "ISOP"
    ) {
      return (
        Math.round(
          product?.product_price_with_tax *
            (product?.selected_characteristics?.surface_to_isolate || 0) *
            100
        ) / 100
      );
    }

    // If the product is a PAC Air/Air we need to add to the price of the product all the prices of its associated interior units
    const totalPriceWithTaxesInteriorUnits =
      product?.selected_characteristics.rooms_information?.reduce(
        (acc, room) => {
          return (
            acc +
            room.interior_units?.reduce((acc, interiorUnit) => {
              return acc + (interiorUnit?.interior_unit_price_with_tax || 0);
            }, 0)
          );
        },
        0
      ) || 0;

    const battery = product?.product_characteristics.available_batteries?.find(
      (battery) =>
        battery.sage_reference ===
        product?.selected_characteristics.battery_sage_reference
    );

    const batteryPriceWhithTax =
      (product?.selected_characteristics.n_batteries || 0) *
      (battery?.price_with_tax || 0);
    const homeManagementPriceWhithTax = product?.selected_characteristics
      ?.kit_home_management
      ? product.product_characteristics.kit_home_management?.price_with_tax || 0
      : 0;

    // Total price with taxes, including the options (that can be =0)
    const totalPriceWithTaxes =
      (product?.product_price_with_tax || 0) +
      totalPriceWithTaxesInteriorUnits +
      batteryPriceWhithTax +
      homeManagementPriceWhithTax;

    return Math.round(totalPriceWithTaxes * productQuantity * 100) / 100;
  };

  const productTotalHelp = ({
    product,
    isEligibleRenoGlobal,
  }: {
    product?: IDataProduct;
    isEligibleRenoGlobal: boolean;
  }) => {
    const totalHelpMPR = isEligibleRenoGlobal
      ? 0
      : product?.help
          ?.filter((help) => help.is_eligible && help.help_type === "mpr")
          .reduce((total, help) => total + help.value, 0) || 0;

    const totalHelpCEE = isEligibleRenoGlobal
      ? 0
      : product?.help
          ?.filter((help) => help.is_eligible && help.help_type === "cee")
          .reduce((total, help) => total + help.value, 0) || 0;

    const totalHelpOther =
      product?.help
        ?.filter(
          (help) =>
            help.is_eligible &&
            help.help_type !== "mpr" &&
            help.help_type !== "cee"
        )
        .reduce((total, help) => total + help.value, 0) || 0;

    const totalHelp = totalHelpMPR + totalHelpCEE + totalHelpOther;

    const totalDeductibleHelps =
      (devisOptions?.isCEEHelpsDeducted ? totalHelpCEE : 0) +
      (devisOptions?.isMPRHelpsDeducted ? totalHelpMPR : 0);
    const totalRefundableHelps =
      (!devisOptions?.isCEEHelpsDeducted ? totalHelpCEE : 0) +
      (!devisOptions?.isMPRHelpsDeducted ? totalHelpMPR : 0) +
      totalHelpOther;

    return {
      totalHelp,
      totalHelpCEE,
      totalHelpMPR,
      totalHelpOther,
      totalDeductibleHelps,
      totalRefundableHelps,
    };
  };

  const productInstallationPriceWithoutTax = ({
    product,
  }: {
    product?: IDataProduct;
  }) => {
    if (
      product?.product_type === "ITE" ||
      product?.product_type === "ITI" ||
      product?.product_type === "ISOA" ||
      product?.product_type === "ISO" ||
      product?.product_type === "ISOP"
    ) {
      return (
        Math.round(
          product?.installation_price_without_tax *
            (product?.selected_characteristics?.surface_to_isolate || 0) *
            100
        ) / 100
      );
    }

    // If the product has interior units we need to add to the price of the product all the prices of its associated interior units
    const totalPriceInstallationInteriorUnitsWithoutTax =
      product?.selected_characteristics.rooms_information?.reduce(
        (acc, room) => {
          return (
            acc +
            room.interior_units?.reduce((acc, interiorUnit) => {
              return (
                acc +
                (interiorUnit?.interior_unit_installation_price_without_tax ||
                  0)
              );
            }, 0)
          );
        },
        0
      ) || 0;

    const battery = product?.product_characteristics.available_batteries?.find(
      (battery) =>
        battery.sage_reference ===
        product?.selected_characteristics.battery_sage_reference
    );

    const batteryInstallationPriceWhithoutTax =
      (product?.selected_characteristics.n_batteries || 0) *
      (battery?.installation_price_without_tax || 0);
    const homeManagementInstallationPriceWhithoutTax = product
      ?.selected_characteristics?.kit_home_management
      ? product.product_characteristics.kit_home_management
          ?.installation_price_without_tax || 0
      : 0;

    const totalPriceInstallationWithoutTax =
      (product?.installation_price_without_tax || 0) +
      totalPriceInstallationInteriorUnitsWithoutTax +
      batteryInstallationPriceWhithoutTax +
      homeManagementInstallationPriceWhithoutTax;

    return (
      Math.round(totalPriceInstallationWithoutTax * productQuantity * 100) / 100
    );
  };

  const productInstallationPriceWithTax = ({
    product,
  }: {
    product?: IDataProduct;
  }) => {
    if (
      product?.product_type === "ITE" ||
      product?.product_type === "ITI" ||
      product?.product_type === "ISOA" ||
      product?.product_type === "ISO" ||
      product?.product_type === "ISOP"
    ) {
      return (
        Math.round(
          product?.installation_price_with_tax *
            (product?.selected_characteristics?.surface_to_isolate || 0) *
            100
        ) / 100
      );
    }

    // If the product is a PAC Air/Air we need to add to the installation price of the product all the installation prices of its associated interior units

    const totalPriceInstallationInteriorUnitsWithTaxes =
      product?.selected_characteristics.rooms_information?.reduce(
        (acc, room) => {
          return (
            acc +
            room.interior_units?.reduce((acc, interiorUnit) => {
              return (
                acc +
                (interiorUnit?.interior_unit_installation_price_with_tax || 0)
              );
            }, 0)
          );
        },
        0
      ) || 0;

    const battery = product?.product_characteristics.available_batteries?.find(
      (battery) =>
        battery.sage_reference ===
        product?.selected_characteristics.battery_sage_reference
    );

    const batteryInstallationPriceWhithTax =
      (product?.selected_characteristics.n_batteries || 0) *
      (battery?.installation_price_with_tax || 0);
    const homeManagementInstallationPriceWhithTax = product
      ?.selected_characteristics?.kit_home_management
      ? product.product_characteristics.kit_home_management
          ?.installation_price_with_tax || 0
      : 0;

    const totalPriceInstallationWithTaxes =
      (product?.installation_price_with_tax || 0) +
      totalPriceInstallationInteriorUnitsWithTaxes +
      batteryInstallationPriceWhithTax +
      homeManagementInstallationPriceWhithTax;

    return (
      Math.round(totalPriceInstallationWithTaxes * productQuantity * 100) / 100
    );
  };

  const productExtraChargeWithoutTax = ({
    product,
  }: {
    product?: IDataProduct;
  }) => {
    return (
      product?.extra_charges_added?.reduce(
        (acc, curr) => acc + (curr.price_without_tax || 0),
        0
      ) || 0
    );
  };

  const productExtraChargeWithTax = ({
    product,
  }: {
    product?: IDataProduct;
  }) => {
    return (
      product?.extra_charges_added?.reduce(
        (acc, curr) => acc + (curr.price_with_tax || 0),
        0
      ) || 0
    );
  };

  // -------- Product ----------
  const productOnlyTotalTVA =
    productPriceWithTax({ product: product }) -
    productPriceWithoutTax({ product: product });

  // --------Installation ----------

  const installationPriceWithoutTax = productInstallationPriceWithoutTax({
    product: product,
  });
  const installationPriceWithTax = productInstallationPriceWithTax({
    product: product,
  });

  const installationTotalTVA =
    installationPriceWithTax - installationPriceWithoutTax;

  // ------ Extra Charges -------
  const extraChargeWithoutTax = productExtraChargeWithoutTax({
    product: product,
  });

  const extraChargeWithTax = productExtraChargeWithTax({
    product: product,
  });
  const extraChargeTotalTVA = extraChargeWithTax - extraChargeWithoutTax;

  // ------- Help -------------
  const {
    totalHelp,
    totalHelpCEE,
    totalHelpMPR,
    totalDeductibleHelps,
    totalRefundableHelps,
    totalHelpOther,
  } = productTotalHelp({
    product: product,
    isEligibleRenoGlobal,
  });

  // -------- Total ---------
  const productPriceWhithoutTaxe = productPriceWithoutTax({
    product: product,
  });
  const productPriceWhithTaxe = productPriceWithTax({
    product: product,
  });

  const totalPriceWhithoutTax =
    productPriceWithoutTax({ product: product }) +
    productInstallationPriceWithoutTax({
      product: product,
    }) +
    productExtraChargeWithoutTax({
      product: product,
    });

  const totalTVA =
    productOnlyTotalTVA + installationTotalTVA + extraChargeTotalTVA;

  const totalPriceWithTax =
    productPriceWithTax({ product: product }) +
    productInstallationPriceWithTax({
      product: product,
    }) +
    productExtraChargeWithTax({
      product: product,
    });

  const totalPriceWithTaxWithoutHelpAndExtraCharge =
    totalPriceWithTax - extraChargeWithTax;

  const totalResteACharge =
    totalPriceWithTax -
    (devisOptions?.isCEEHelpsDeducted ? totalHelpCEE : 0) -
    (devisOptions?.isMPRHelpsDeducted ? totalHelpMPR : 0);

  const managementFeesTTC = isEligibleRenoGlobal ? 0 : 486;
  const managementFeesHT =
    managementFeesTTC /
    (1 + (product?.installation_tva_percentage || 20) / 100);

  return {
    productOnlyTotalTVA: Math.round(productOnlyTotalTVA * 100) / 100,
    installationPriceWithoutTax:
      Math.round(installationPriceWithoutTax * 100) / 100,
    installationPriceWithTax: Math.round(installationPriceWithTax * 100) / 100,
    installationTotalTVA: Math.round(installationTotalTVA * 100) / 100,
    extraChargeWithoutTax: Math.round(extraChargeWithoutTax * 100) / 100,
    extraChargeWithTax: Math.round(extraChargeWithTax * 100) / 100,
    extraChargeTotalTVA: Math.round(extraChargeTotalTVA * 100) / 100,
    totalHelp: Math.round(totalHelp * 100) / 100,
    totalPriceWhithoutTax: Math.round(totalPriceWhithoutTax * 100) / 100,
    totalTVA: Math.round(totalTVA * 100) / 100,
    totalPriceWithTax: Math.round(totalPriceWithTax * 100) / 100,
    totalResteACharge: Math.round(totalResteACharge * 100) / 100,
    totalPriceWithTaxWithoutHelpAndExtraCharge:
      Math.round(totalPriceWithTaxWithoutHelpAndExtraCharge * 100) / 100,
    managementFeesTTC: Math.round(managementFeesTTC * 100) / 100,
    managementFeesHT: Math.round(managementFeesHT * 100) / 100,
    totalDeductibleHelps: Math.round(totalDeductibleHelps * 100) / 100,
    totalRefundableHelps: Math.round(totalRefundableHelps * 100) / 100,
    totalHelpCEE: Math.round(totalHelpCEE * 100) / 100,
    totalHelpMPR: Math.round(totalHelpMPR * 100) / 100,
    totalHelpOther: Math.round(totalHelpOther * 100) / 100,
    productPriceWhithoutTaxe: Math.round(productPriceWhithoutTaxe * 100) / 100,
    productPriceWhithTaxe: Math.round(productPriceWhithTaxe * 100) / 100,
  };
};
