// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services import
import { setStudyInfoAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

// Interfaces imports
import { IStudyInformation } from "../../interfaces/generalInterfaces";

// Local interface declaration
interface IProps {
  study_id?: string | null;
  userToken: string | null;
}

export const useGetStudy = () => {
  const {
    isLoading,
    errorMessage: axiosErrorMessage,
    axiosGetRequest,
  } = useAxiosGet();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getStudy = async ({ userToken, study_id }: IProps) => {
    setErrorMessage("");

    const response = await axiosGetRequest<IStudyInformation>(
      `${process.env.REACT_APP_EERO_URL}/oree2/studies/${study_id}/studyInformation`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      const parsedRoofSection = response.roof_sections?.map((section) =>
        JSON.parse(section.toString())
      );
      dispatch(
        setStudyInfoAction({
          studyInformation: { ...response, roof_sections: parsedRoofSection },
        })
      );
      return response;
    } else {
      setErrorMessage(
        "Erreur lors de la récupération de la liste de vos études"
      );
      return;
    }
  };

  return {
    getStudy,
    isLoading,
    errorMessage: axiosErrorMessage || errorMessage,
  };
};
