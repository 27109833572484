import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

type ResponseData = {
  bDCFileName: null;
  bDCUrl: null;
  orderId: null;
  opportunityId: string;
  quoteId: string;
};

async function generateCrmQuote({
  studyId,
  payload,
}: {
  studyId: string | null;
  payload: any;
}): Promise<ResponseData> {
  return eeroApi
    .post(`/oree2/studies/${studyId}/crm/generateQuote`, payload)
    .then((res) => res.data);
}

export function useGenerateCrmQuote() {
  const mutation = useAuthMutation({
    mutationFn: generateCrmQuote,
    retry: 1,
  });

  return mutation;
}
