import { FinancementInfos } from "../../interfaces/generalInterfaces";
import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

type ResquestData = {
  salesOrderId: string;
  data: {
    commercialId: string | null;
    orderToCancelId: string | null;
    financementInfos: FinancementInfos;
  };
};

async function generateCrmDocusign({
  studyId,
  data,
}: {
  studyId: string | null;
  data: ResquestData;
}) {
  return eeroApi
    .post(`/oree2/studies/${studyId}/crm/generateDocusign`, data)
    .then((res) => res.data);
}

export function useGenerateCrmDocusign() {
  const mutation = useAuthMutation({
    mutationFn: generateCrmDocusign,
    retry: 1,
  });

  return mutation;
}
