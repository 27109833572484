import { useMemo, useState } from "react";
import { useAppSelector } from "../../redux/store/hook";
import eeroApi from "../eeroApiAxios";
import { useAuthMutation } from "./useAuthMutation";

type StudyPdfData = {
  studyPDFId: string;
  studyPDFAsBase64: string;
  studyPDFAsBase64NoPrefix: string;
  studyPDFAsBlob: Blob;
};

type QuotePdfData = {
  quotePDFId: string;
  quotePDFAsBase64: string;
  quotePDFAsBase64NoPrefix: string;
  quotePDFAsBlob: Blob;
};

type Pdfs = StudyPdfData & QuotePdfData & { projectId: string };

/**
 * Hook to generate Oree documents such as 'devis' pdf or 'etude' pdf.
 */
export function useOreeDocuments() {
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const commercialUserInformation = useAppSelector(
    (state) => state.commercialUserInformation
  );

  const [projectId, setProjectId] = useState<string | null>(null);
  const [quotePdf, setQuotePdf] = useState<QuotePdfData | null>(null);
  const [studyPdf, setStudyPdf] = useState<StudyPdfData | null>(null);

  const saveStudyPdfInAbsAndDbMutation = useAuthMutation({
    mutationFn: async ({ pdfs }: { pdfs: Pdfs }) => {
      const studyId = studyInformation.study_id;

      const form = new FormData();
      form.append("fileType", "etude");
      form.append("fileBase64", pdfs.studyPDFAsBase64);
      form.append("fileName", pdfs.studyPDFId);
      form.append("document_id", pdfs.studyPDFId);
      form.append("project_id", pdfs.projectId);
      studyId && form.append("study_id", studyId);

      return eeroApi.post("/oree2/azureBlobStorage/postFileFromAzure", form);
    },
  });

  const saveQuotePdfInAbsAndDbMutation = useAuthMutation({
    mutationFn: async ({ pdfs }: { pdfs: Pdfs }) => {
      const studyId = studyInformation.study_id;

      const form = new FormData();
      form.append("fileType", "devis");
      form.append("fileBase64", pdfs.quotePDFAsBase64);
      form.append("fileName", pdfs.quotePDFId);
      form.append("document_id", pdfs.quotePDFId);
      form.append("project_id", pdfs.projectId);
      studyId && form.append("study_id", studyId);

      return eeroApi.post("/oree2/azureBlobStorage/postFileFromAzure", form);
    },
  });

  const updateDocumentsWithBdcInfoMutation = useAuthMutation({
    mutationFn: async (variables: {
      document_id: string | null;
      bdc_id: string | null;
      bdc_file_name: string | null;
      bdc_url: string | null;
    }) => {
      const studyId = studyInformation.study_id;

      return eeroApi.put(`/oree2/updateDocument/${studyId}`, {
        documentPayload: variables,
      });
    },
  });

  const sendDocumentsByMailMutation = useAuthMutation({
    mutationFn: async () => {
      const studyId = studyInformation.study_id ?? "";

      const body = {
        email: studyInformation.becqe_email,
        firstName: studyInformation.firstname,
        lastName: studyInformation.becqe_lastname,
        documentIDDevis: quotePdf?.quotePDFId ?? null,
        documentIDEtude: studyPdf?.studyPDFId ?? null,
        commercialFirstName: commercialUserInformation.userFirstName,
        commercialLastName: commercialUserInformation.userLastName,
        commercialEmail: commercialUserInformation.userEmail,
        commercialPhone: commercialUserInformation.userPhoneNumber,
      };

      return eeroApi.post(`/oree2/rg/sendEmailDevisEtude/${studyId}`, body);
    },
  });

  const quotePdfUrl = useMemo(() => {
    if (quotePdf) {
      return URL.createObjectURL(quotePdf.quotePDFAsBlob);
    } else {
      return null;
    }
  }, [quotePdf]);

  const studyPdfUrl = useMemo(() => {
    if (studyPdf) {
      return URL.createObjectURL(studyPdf.studyPDFAsBlob);
    } else {
      return null;
    }
  }, [studyPdf]);

  return {
    projectId,
    setProjectId,

    quotePdf,
    quotePdfUrl,
    setQuotePdf,

    studyPdf,
    studyPdfUrl,
    setStudyPdf,

    sendDocumentsByMailMutation,

    saveStudyPdfInAbsAndDbMutation,

    saveQuotePdfInAbsAndDbMutation,

    updateDocumentsWithBdcInfoMutation,
  };
}
