import {
  aideTypeToCRMaideId,
  CRMlabelToValueDictionnary,
} from "@web/shared/dist/datas/CRMvalueToLabelDictionary";
import { PvOptionsSubType } from "../../../containers/FormulePersonnalisableForm/FormulePersonnalisableForm";
import {
  CentralPhotovoltaiques,
  Chauffe,
  CrmProductWindow,
  HelpsOptions,
  ICommandeData,
  IOreeStudyType,
  IPacAirAir,
  ISageProduct,
  IScenario,
  Isolation,
  IStudyInformation,
  KitPvOption,
  ProductCommonBases,
} from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IProjectKPI,
  TFraisSupp,
  TSingleHelp,
} from "../../../interfaces/renoglobaleInterface";
import { capitalize } from "../../../utils/stringUtils";

export function formatListeProduit({
  studyInformation,
  scenarioChosen,
  devisOptions,
  RGProjectKPI,
  cartProducts,
  SAGEItemsInfo,
}: {
  studyInformation: IStudyInformation;
  scenarioChosen: IScenario | undefined;
  devisOptions: HelpsOptions;
  RGProjectKPI: IProjectKPI | null | undefined;
  cartProducts: IDataProduct[] | undefined;
  SAGEItemsInfo: ISageProduct[] | undefined;
}): ICommandeData["liste_produit"] {
  if (studyInformation.study_type === IOreeStudyType.RENOVATION_GLOBALE) {
    return formatListeProduitRG({
      studyInformation,
      devisOptions,
      RGProjectKPI,
      cartProducts,
      SAGEItemsInfo,
    });
  } else {
    return formatListeProduitPV(studyInformation, scenarioChosen, devisOptions);
  }
}

/**
 * Format the list of products for the PV study type
 */
function formatListeProduitPV(
  studyInformation: IStudyInformation,
  scenarioChosen: IScenario | undefined,
  devisOptions: HelpsOptions
) {
  const scenarioData = {
    battery: {
      battery_pose_price_without_tax:
        scenarioChosen?.battery_pose_price_without_tax,
      battery_pose_sage_reference: scenarioChosen?.battery_pose_sage_reference,
      battery_price_without_tax: scenarioChosen?.battery_price_without_tax,
      battery_sage_reference: scenarioChosen?.battery_sage_reference,
    },
    kit_home_management: {
      kit_pose_price_without_tax: scenarioChosen?.kit_pose_price_without_tax,
      kit_pose_sage_reference: scenarioChosen?.kit_pose_sage_reference,
      kit_price_without_tax: scenarioChosen?.kit_price_without_tax,
      kit_sage_reference: scenarioChosen?.kit_sage_reference,
    },
    pv: {
      pose_price_without_tax: scenarioChosen?.pv_pose_price_without_tax,
      pose_sage_reference: scenarioChosen?.pv_pose_sage_reference,
      pv_price_without_tax: scenarioChosen?.pv_price_without_tax,
      pv_sage_reference: scenarioChosen?.pv_sage_reference,
    },
  };

  return {
    centralPhotovoltaiques: [
      {
        aides: devisOptions.isHelpsDisplayed
          ? [
              {
                amount: scenarioChosen?.total_helps,
                aideId: aideTypeToCRMaideId({
                  aideType: "Prime à l'investissement",
                  isDev: process.env.REACT_APP_ENVIRONMENT === "development",
                }),
                isDeducted: false, // Prime à l'investissement is never deductible
              },
            ]
          : null,
        id_Sage: scenarioChosen?.pv_sage_reference,
        puissanceCentrale: scenarioChosen?.power_needed,
        price: scenarioChosen?.pv_price_without_tax || null,
        tvaCode: CRMlabelToValueDictionnary(
          "tvaCode",
          scenarioChosen?.tva?.toString()
        ),
        quantity: 1,
        poseProduct: {
          id_Sage: scenarioData?.pv.pose_sage_reference
            ? scenarioData?.pv.pose_sage_reference
            : null,
          price: scenarioData?.pv.pose_price_without_tax
            ? scenarioData?.pv.pose_price_without_tax
            : null,
          tvaCode: CRMlabelToValueDictionnary(
            "tvaCode",
            scenarioChosen?.tva?.toString()
          ),
          quantity: 1,
        },
        subsituedProducts: getSubsituedProducts(scenarioChosen) || [],
        writableProperties: [
          {
            value: "1", //id for Autoconsommation avec vente du surplus
            name: "Type de raccordement",
          },
          {
            value: "1", //id for "Surimposition"
            name: "Type de fixation",
          },
          {
            value:
              CRMlabelToValueDictionnary(
                "alimentation",
                studyInformation.electric_installation_type?.toString()
              )?.toString() || "1",
            name: "Alimentation",
          },
          {
            valueString: "Noir",
            name: "Couleur des modules choisie",
          },
          {
            value: String(
              scenarioChosen?.selfconsumption_rate &&
                (scenarioChosen?.selfconsumption_rate * 100 > 80
                  ? 80
                  : Math.round(scenarioChosen.selfconsumption_rate * 100))
            ),
            name: "Pourcentage autoconsomation",
          },
        ],
      },
    ],
    noReferencedProducts: [
      ...formatExtraChargeProduct({
        extraFees: scenarioChosen?.extra_fees || undefined,
      }),
      ...formatOptionProduct({
        options:
          scenarioChosen?.options?.filter(
            (option) => option.sub_type !== PvOptionsSubType.TVA_REFUND
          ) || undefined,
      }),
    ],
  };
}

function getSubsituedProducts(scenarioChosen: IScenario | undefined) {
  const scenarioData = {
    battery: {
      battery_pose_price_without_tax:
        scenarioChosen?.battery_pose_price_without_tax,
      battery_pose_sage_reference: scenarioChosen?.battery_pose_sage_reference,
      battery_price_without_tax: scenarioChosen?.battery_price_without_tax,
      battery_sage_reference: scenarioChosen?.battery_sage_reference,
    },
    kit_home_management: {
      kit_pose_price_without_tax: scenarioChosen?.kit_pose_price_without_tax,
      kit_pose_sage_reference: scenarioChosen?.kit_pose_sage_reference,
      kit_price_without_tax: scenarioChosen?.kit_price_without_tax,
      kit_sage_reference: scenarioChosen?.kit_sage_reference,
    },
    pv: {
      pose_price_without_tax: scenarioChosen?.pv_pose_price_without_tax,
      pose_sage_reference: scenarioChosen?.pv_pose_sage_reference,
      pv_price_without_tax: scenarioChosen?.pv_price_without_tax,
      pv_sage_reference: scenarioChosen?.pv_sage_reference,
    },
  };

  const batteryData = {
    price: scenarioData.battery.battery_price_without_tax,
    tvaCode: CRMlabelToValueDictionnary(
      "tvaCode",
      scenarioChosen?.tva?.toString()
    ),
    quantity: scenarioChosen?.number_of_batteries || 0,
    id_Sage: scenarioData?.battery.battery_sage_reference,
    //battery pose product
    poseProduct: {
      id_Sage: scenarioData?.battery.battery_pose_sage_reference,
      price: scenarioData?.battery.battery_pose_price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        scenarioChosen?.tva?.toString()
      ),
      quantity: scenarioChosen?.number_of_batteries || 0,
    },
  };

  const kitHomeManagementData = {
    price: scenarioData.kit_home_management.kit_price_without_tax,
    tvaCode: CRMlabelToValueDictionnary(
      "tvaCode",
      scenarioChosen?.tva?.toString()
    ),
    quantity: 1,
    id_Sage: scenarioData?.kit_home_management.kit_sage_reference,
    //kit home management pose product
    poseProduct: {
      id_Sage: scenarioData?.kit_home_management.kit_pose_sage_reference,
      price: scenarioData?.kit_home_management.kit_pose_price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        scenarioChosen?.tva?.toString()
      ),
      quantity: 1,
      ...(scenarioData?.kit_home_management.kit_sage_reference && {
        key: "value",
      }),
    },
  };

  return [
    ...(scenarioChosen?.number_of_batteries ? [batteryData] : []),
    ...(scenarioChosen?.kit_home_management &&
    !scenarioData?.pv?.pv_sage_reference?.includes("0EN")
      ? [kitHomeManagementData]
      : []), // For Enphase products there is a Kit home management directly integrated to the PV product. So it exists (kit_home_management=true) but not as a separate product.
  ];
}

function formatExtraChargeProduct({ extraFees }: { extraFees?: TFraisSupp[] }) {
  // For each extra charge return the formatted object
  return (
    extraFees?.map((extraCharge) => {
      return {
        productName: extraCharge.product_name,
        tvaCode:
          CRMlabelToValueDictionnary(
            "tvaCode",
            extraCharge.TVA_percentage?.toString()
          ) || 629950002, // 629950002 = 20%
        quantity: 1,
        montant_ht: extraCharge.price_without_tax?.toString() || "",
        description: extraCharge.description || "",
      };
    }) || []
  );
}

function formatOptionProduct({ options }: { options?: KitPvOption[] }) {
  // For each extra charge return the formatted object
  return (
    options?.map((option) => {
      return {
        productName: option.name,
        tvaCode:
          CRMlabelToValueDictionnary("tvaCode", option.tva?.toString()) ||
          629950002, // 629950002 = 20%
        quantity: 1,
        montant_ht: option.price_without_tax?.toString() || "",
        description: "",
      };
    }) || []
  );
}

/**
 * Format the list of products for the RG study type
 */
function formatListeProduitRG({
  studyInformation,
  devisOptions,
  RGProjectKPI,
  cartProducts,
  SAGEItemsInfo,
}: {
  studyInformation: IStudyInformation;
  devisOptions: HelpsOptions;
  RGProjectKPI: IProjectKPI | null | undefined;
  cartProducts: IDataProduct[] | undefined;
  SAGEItemsInfo: ISageProduct[] | undefined;
}) {
  const allExtraFeesRg =
    cartProducts?.reduce((extraCharges: TFraisSupp[], product) => {
      return product?.extra_charges_added
        ? [...extraCharges, ...product.extra_charges_added]
        : [...extraCharges];
    }, []) || [];

  /** This variable's value is intended to be modified in formatProduct for isolation cases */
  let isFirstISOProduct = { value: true };

  const formatProductArgs = {
    studyInformation,
    devisOptions,
    RGProjectKPI,
    cartProducts: cartProducts || [],
    productsFromSAGE: SAGEItemsInfo,
  };

  return {
    centralPhotovoltaiques: formatProduct({
      ...formatProductArgs,
      type: ["PV"],
    }),
    chauffesEau: formatProduct({
      ...formatProductArgs,
      type: ["CET", "BALLON"],
    }),
    pompesChaleurAirEau: formatProduct({
      ...formatProductArgs,
      type: ["PACAIRO"],
    }),
    pompesChaleurAirAir: formatProduct({
      ...formatProductArgs,
      type: ["PACAIRAIR"],
    }),
    isolations: formatProduct({
      ...formatProductArgs,
      type: ["ITE", "ITI", "ISOA", "ISO", "ISOP"],
      isFirstISOProduct,
    }),
    relampingLEDs: formatProduct({
      ...formatProductArgs,
      type: ["VMC"],
    }),
    fenetres: formatProduct({
      ...formatProductArgs,
      type: ["FENETRES"],
    }),
    noReferencedProducts: [
      ...formatFreeProduct({ cartProducts }),
      ...formatExtraChargeProduct({
        extraFees: allExtraFeesRg,
      }),
    ],
  };
}

function formatProduct({
  studyInformation,
  devisOptions,
  RGProjectKPI,
  cartProducts,
  productsFromSAGE,
  type,
  isFirstISOProduct,
}: {
  studyInformation: IStudyInformation;
  devisOptions: HelpsOptions;
  RGProjectKPI: IProjectKPI | null | undefined;
  cartProducts: IDataProduct[];
  productsFromSAGE: ISageProduct[] | undefined;
  type: string[];
  isFirstISOProduct?: { value: boolean };
}) {
  const newTab: any = [];
  cartProducts
    ?.filter(
      (product) => product.isSelected && type.includes(product.product_type)
    )
    .map((product) => {
      const productFromSAGE = productsFromSAGE?.find(
        (productCharacteristics) =>
          productCharacteristics.AR_Ref === product.sage_reference
      );

      switch (product.product_type) {
        case "PV":
          return newTab.push({
            subsituedProducts: getPVSubsituedRGProducts(product),
            writableProperties: [
              {
                value: "1", //id for Autoconsommation avec vente du surplus
                name: "Type de raccordement",
              },
              {
                value: "1", //id for "Surimposition"
                name: "Type de fixation",
              },
              {
                valueString: "Noir",
                name: "Couleur des modules choisie",
              },
              {
                value:
                  CRMlabelToValueDictionnary(
                    "alimentation",
                    studyInformation.electric_installation_type?.toString()
                  )?.toString() || "1",
                name: "Alimentation",
              },
            ],
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: 1,
              price: product.installation_price_without_tax,
            },
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: 1,
            puissanceCentrale: product.product_characteristics.power,
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            aides: getAidesRGProduct({
              helps: product.help,
              devisOptions,
            }),
          } satisfies ProductCommonBases & CentralPhotovoltaiques);
        case "BALLON":
        case "CET":
          return newTab.push({
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            cop_scop: product.product_characteristics.scop?.toString() || null,
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: 1,
            producttypecode:
              product.product_type === "CET"
                ? CRMlabelToValueDictionnary(
                    "productTypeCode",
                    "Chauffe - eau thermodynamique"
                  )
                : CRMlabelToValueDictionnary(
                    "productTypeCode",
                    "Chauffe - eau électrique"
                  ),
            capacity: productFromSAGE
              ? Number(productFromSAGE["Capacité (l)"])
              : null,
            puissance: product.product_characteristics.power ?? 0, // not used by CRM
            tempsDeChauffeParHeure: null,
            capacityOptValue: null,
            discount: null,
            ecsInteg: null,
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: 1,
              price: product.installation_price_without_tax,
            },
            subsituedProducts: null,
            writableProperties: null,
            aides: RGProjectKPI?.is_eligible
              ? null
              : getAidesRGProduct({
                  helps: product.help,
                  devisOptions,
                }),
          } satisfies ProductCommonBases & Chauffe);
        case "PACAIRO":
          return newTab.push({
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            producttypecode: CRMlabelToValueDictionnary(
              "productTypeCode",
              "Pompe à chaleur Air/Eau"
            ),
            cop_scop:
              product?.product_characteristics?.scop?.toString() || null,
            capacity: null,
            puissance: product.product_characteristics.power || null,
            capacityOptValue: null,
            discount: null,
            tempsDeChauffeParHeure: null,
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: 1,
            ecsInteg: product.product_characteristics.used_for_ecs || null,
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: 1,
              price: product.installation_price_without_tax,
            },
            aides: RGProjectKPI?.is_eligible
              ? null
              : getAidesRGProduct({
                  helps: product.help,
                  devisOptions,
                }),
            subsituedProducts: null,
            writableProperties: null,
          } satisfies ProductCommonBases & Chauffe);
        case "PACAIRAIR":
          return newTab.push({
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            cop_scop: null,
            puissance: product.product_characteristics.power || null,
            discount: null,
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: 1,
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: 1,
              price: product.installation_price_without_tax,
            },
            aides: RGProjectKPI?.is_eligible
              ? null
              : getAidesRGProduct({
                  helps: product.help,
                  devisOptions,
                }),
            writableProperties: [
              ...(productFromSAGE?.["Classe energétique (Froid/Chaud"]
                ? [
                    {
                      valueString:
                        productFromSAGE?.["Classe energétique (Froid/Chaud"],
                      name: "Classe énergetique",
                    },
                  ]
                : []),
              ...(product?.product_characteristics?.scop?.toString()
                ? [
                    {
                      value: product?.product_characteristics?.scop?.toString(),
                      name: "COP/SCOP",
                    },
                  ]
                : []),
              ...(productFromSAGE?.Gamme
                ? [
                    {
                      valueString: productFromSAGE?.Gamme,
                      name: "Gamme",
                    },
                  ]
                : []),
              ...(productFromSAGE?.Marque
                ? [
                    {
                      valueString: productFromSAGE?.Marque,
                      name: "Marque",
                    },
                  ]
                : []),
              ...(productFromSAGE?.["Garantie Fabricant"]
                ? [
                    {
                      valueString: productFromSAGE?.["Garantie Fabricant"],
                      name: "Garantie Fabricant",
                    },
                  ]
                : []),
              ...(productFromSAGE?.Poid?.toString()
                ? [
                    {
                      valueString: productFromSAGE?.Poid?.toString(),
                      name: "Poids",
                    },
                  ]
                : []),
              ...(product?.selected_characteristics.surface_to_heat?.toString()
                ? [
                    {
                      value:
                        product?.selected_characteristics.surface_to_heat?.toString(),
                      name: "Surface chauffée (m²)",
                    },
                  ]
                : []),
              ...(productFromSAGE?.Dimensions
                ? [
                    {
                      valueString: productFromSAGE?.Dimensions,
                      name: "Taille",
                    },
                  ]
                : []),
            ],
            subsituedProducts: getPacAirAirSubsituedRGProducts(
              product,
              productsFromSAGE
            ),
          } satisfies ProductCommonBases & IPacAirAir);
        case "ITE":
        case "ITI":
        case "ISOA":
        case "ISO":
        case "ISOP":
          const tab = {
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            producttypecode: CRMlabelToValueDictionnary(
              "productTypeCode",
              "Isolation"
            ),
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: product.selected_characteristics.surface_to_isolate
              ? Math.round(product.selected_characteristics.surface_to_isolate)
              : 0,
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: product.selected_characteristics.surface_to_isolate
                ? Math.round(
                    product.selected_characteristics.surface_to_isolate
                  )
                : 0,
              price: product.installation_price_without_tax,
            },
            discount: null,
            epaisseur: null,
            conductivity: null,
            subsituedProducts: null,
            writableProperties: null,
            aides: RGProjectKPI?.is_eligible
              ? isFirstISOProduct?.value
                ? getAidesRGProduct({
                    helps: RGProjectKPI.reno_globale_helps,
                    devisOptions,
                  })
                : null
              : getAidesRGProduct({
                  helps: product.help,
                  devisOptions,
                }),
            isolatedZones: [
              {
                zoneName: studyInformation.type_de_murs?.join(", ") || null,
                surface: product.selected_characteristics.surface_to_isolate
                  ? Math.round(
                      product.selected_characteristics.surface_to_isolate
                    )
                  : null,
                typedeParoi:
                  (studyInformation.type_de_murs &&
                    CRMlabelToValueDictionnary(
                      "wallType",
                      studyInformation.type_de_murs[0]
                    )) ??
                  null,
                typeOperation: [
                  CRMlabelToValueDictionnary(
                    "operationType",
                    product.product_type
                  ),
                ],
              },
            ],
            typeOperation: null,
          } satisfies ProductCommonBases & Isolation;
          if (isFirstISOProduct) {
            isFirstISOProduct.value = false;
          }
          return newTab.push(tab);
        case "VMC": // Simple flux only
          return newTab.push({
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            producttypecode: CRMlabelToValueDictionnary(
              "productTypeCode",
              "LED" // TO UPDATE WHEN CRM WILL BE READY
            ),
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: 1,
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: 1,
              price: product.installation_price_without_tax,
            },
            aides: RGProjectKPI?.is_eligible
              ? null
              : getAidesRGProduct({
                  helps: product.help,
                  devisOptions,
                }),
            subsituedProducts: null,
            writableProperties: null,
          } satisfies ProductCommonBases);
        case "FENETRES":
          const tabFenetres = {
            id_Sage: product.sage_reference,
            price: product.product_price_without_tax,
            producttypecode: CRMlabelToValueDictionnary(
              "productTypeCode",
              "Isolation"
            ),
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage.toString()
            ),
            quantity: product?.selected_characteristics?.productQuantity ?? 1,
            writableProperties: [
              ...(productFromSAGE?.["Résistance Thermique "]
                ? [
                    {
                      valueString: productFromSAGE?.["Résistance Thermique "],
                      name: "Résistance thermique",
                    },
                  ]
                : []),
              ...(productFromSAGE?.["type de vitrage "]
                ? [
                    {
                      valueString: productFromSAGE?.["type de vitrage "],
                      name: "Type de vitrage",
                    },
                  ]
                : []),
              ...(productFromSAGE?.["Facteur solaire"]
                ? [
                    {
                      valueString: productFromSAGE?.["Facteur solaire"],
                      name: "Facteur solaire",
                    },
                  ]
                : []),
              ...(productFromSAGE?.["Coef de transmission surfacique"]
                ? [
                    {
                      valueString:
                        productFromSAGE?.["Coef de transmission surfacique"],
                      name: "Coefficient de transmission surfacique",
                    },
                  ]
                : []),
              ...[
                {
                  valueString:
                    product?.selected_characteristics?.windows
                      ?.map((w) => {
                        return `\n- ${w.width} x ${w.height}m (${capitalize(
                          w.type
                        )})`;
                      })
                      .join("") || "",
                  name: "Dimensions",
                },
              ],
            ],
            poseProduct: {
              id_Sage: product.sage_reference_installation,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.installation_tva_percentage.toString()
              ),
              quantity: product?.selected_characteristics?.productQuantity || 1,
              price: product.installation_price_without_tax,
            },
            aides: RGProjectKPI?.is_eligible
              ? null
              : getAidesRGProduct({
                  helps: product.help,
                  devisOptions,
                }),
            discount: null,
            subsituedProducts: null,
            description: product.selected_characteristics.windows
              ?.map(
                (window, index) =>
                  capitalize(window.category) +
                  " " +
                  window.type +
                  " n°" +
                  (index + 1) +
                  " -> dimension : " +
                  window.width +
                  " x " +
                  window.height +
                  "m, commentaire : " +
                  window.comment
              )
              .join("; "), // Ex : "Fenêtre battante n°1 -> dimension : 1 x 1.2m, commentaire : mon commentaire 1; Fenêtre battante n°2 -> dimension : 1 x 1.2m, commentaire : mon commentaire 2"
          } satisfies ProductCommonBases & CrmProductWindow;

          return newTab.push(tabFenetres);
        default:
          console.log(`Product type ${product.product_type} not handled`);
      }

      return product;
    });

  return newTab;
}

/**
 * in this function we check if there are batterie and / or kit home and we build the json consequently for RG study only
 * */
function getPVSubsituedRGProducts(product: IDataProduct) {
  const battery = product.product_characteristics.available_batteries?.find(
    (battery) =>
      battery.sage_reference ===
      product.selected_characteristics.battery_sage_reference
  );

  const batteryData = {
    price: battery?.price_without_tax,
    tvaCode: CRMlabelToValueDictionnary(
      "tvaCode",
      battery?.tva_percentage?.toString()
    ),
    quantity: product.selected_characteristics.n_batteries || 0,
    id_Sage: battery?.sage_reference,
    poseProduct: {
      id_Sage: battery?.installation_sage_reference,
      price: battery?.installation_price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        battery?.installation_tva_percentage?.toString()
      ),
      quantity: product.selected_characteristics.n_batteries ? 1 : 0, // Same installation price if we need to install one or more batteries
    },
  };

  const kitHomeManagementData = {
    price:
      product.product_characteristics.kit_home_management?.price_without_tax,
    tvaCode: CRMlabelToValueDictionnary(
      "tvaCode",
      product.product_characteristics.kit_home_management?.tva_percentage?.toString()
    ),
    quantity: 1,
    id_Sage:
      product?.product_characteristics?.kit_home_management?.sage_reference,
    poseProduct: {
      id_Sage:
        product?.product_characteristics?.kit_home_management
          ?.installation_sage_reference,
      price:
        product.product_characteristics.kit_home_management
          ?.installation_price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        product.product_characteristics.kit_home_management?.installation_tva_percentage?.toString()
      ),
      quantity: 1,
    },
  };

  return [
    ...(product.selected_characteristics.n_batteries !== 0
      ? [batteryData]
      : []),
    ...(product.selected_characteristics.kit_home_management &&
    !product.sage_reference?.includes("0EN") // For Enphase products there is a Kit home management directly integrated to the PV product. So it exists (kit_home_management=true) but not as a separate product.
      ? [kitHomeManagementData]
      : []),
  ];
}

/**
 * Function to build aides object
 * */
function getAidesRGProduct({
  helps,
  devisOptions,
}: {
  helps: TSingleHelp[];
  devisOptions: HelpsOptions;
}) {
  if (devisOptions.isHelpsDisplayed === false) return null;
  const formatHelps = helps
    .filter((helps) => helps.is_eligible && helps.value > 0)
    .map((help) => {
      return {
        amount: help.value,
        aideId: aideTypeToCRMaideId({
          aideType: help.name,
          isDev: process.env.REACT_APP_ENVIRONMENT === "development",
        }),
        isDeducted:
          help.help_type === "mpr"
            ? devisOptions.isMPRHelpsDeducted
            : help.help_type === "cee"
            ? devisOptions.isCEEHelpsDeducted
            : false,
      };
    });
  return formatHelps;
}

function getPacAirAirSubsituedRGProducts(
  product: IDataProduct,
  SAGEItemsInfo: ISageProduct[] | undefined
) {
  const pacAirAirInteriorUnits =
    product.selected_characteristics.rooms_information?.flatMap((room) =>
      room.interior_units.map((interior_unit) => {
        const productCharacteristicsFromSAGE = SAGEItemsInfo?.find(
          (productCharacteristics) =>
            productCharacteristics.AR_Ref ===
            interior_unit.interior_unit_sage_reference
        );
        return {
          price: interior_unit.interior_unit_price_without_tax,
          tvaCode: CRMlabelToValueDictionnary(
            "tvaCode",
            interior_unit.interior_unit_TVA_percentage?.toString()
          ),
          quantity: 1,
          id_Sage: interior_unit.interior_unit_sage_reference,
          poseProduct: {
            id_Sage: interior_unit.interior_unit_installation_sage_reference,
            price: interior_unit.interior_unit_installation_price_without_tax,
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              interior_unit.interior_unit_installation_TVA_percentage?.toString()
            ),
            quantity: 1,
          },
          writableProperties: [
            ...(productCharacteristicsFromSAGE?.[
              "Classe energétique (Froid/Chaud"
            ]
              ? [
                  {
                    valueString:
                      productCharacteristicsFromSAGE?.[
                        "Classe energétique (Froid/Chaud"
                      ],
                    name: "Classe énergetique",
                  },
                ]
              : []),
            ...(product?.product_characteristics?.scop?.toString()
              ? [
                  {
                    value: product?.product_characteristics?.scop?.toString(),
                    name: "COP/SCOP",
                  },
                ]
              : []),
            ...(productCharacteristicsFromSAGE?.Gamme
              ? [
                  {
                    valueString: productCharacteristicsFromSAGE?.Gamme,
                    name: "Gamme",
                  },
                ]
              : []),
            ...(productCharacteristicsFromSAGE?.Marque
              ? [
                  {
                    valueString: productCharacteristicsFromSAGE?.Marque,
                    name: "Marque",
                  },
                ]
              : []),
            ...(productCharacteristicsFromSAGE?.["Garantie Fabricant"]
              ? [
                  {
                    valueString:
                      productCharacteristicsFromSAGE?.["Garantie Fabricant"],
                    name: "Garantie Fabricant",
                  },
                ]
              : []),
            ...(productCharacteristicsFromSAGE?.Poid?.toString()
              ? [
                  {
                    valueString:
                      productCharacteristicsFromSAGE?.Poid?.toString(),
                    name: "Poids",
                  },
                ]
              : []),
            ...(productCharacteristicsFromSAGE?.Dimensions
              ? [
                  {
                    valueString: productCharacteristicsFromSAGE?.Dimensions,
                    name: "Taille",
                  },
                ]
              : []),
            ...(interior_unit.surface_to_heat?.toString()
              ? [
                  {
                    value: interior_unit.surface_to_heat?.toString(),
                    name: "Surface chauffée (m²)",
                  },
                ]
              : []),
          ],
        };
      })
    ) || [];

  return [...pacAirAirInteriorUnits];
}

function formatFreeProduct({
  cartProducts,
}: {
  cartProducts?: IDataProduct[];
}) {
  // For each product with product_type = "AUTRE" return the formatted object
  return (
    cartProducts
      ?.filter((product) => product.product_type === "AUTRE")
      .map((product) => {
        return {
          productName: product.product_name,
          tvaCode:
            CRMlabelToValueDictionnary(
              "tvaCode",
              product.TVA_percentage?.toString()
            ) || 629950002, // 629950002 = 20%
          quantity: 1,
          montant_ht: product.product_price_without_tax?.toString() || "",
          description:
            product.selected_characteristics?.free_product_description || "",
        };
      }) || []
  );
}
