import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";

// Components
import { PdfContentFormuleLign } from "../PdfContentFormuleLign/PdfContentFormuleLign";

// Fonts
import DMSansBold from "../../../../assets/pdf/fonts/DMSansBold.ttf";
import DMSansItalic from "../../../../assets/pdf/fonts/DMSansItalic.ttf";
import DMSansMedium from "../../../../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../../../../assets/pdf/fonts/DMSansRegular.ttf";
import {
  ISageProduct,
  KitPvOption,
} from "../../../../interfaces/generalInterfaces";

// Register font
Font.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});
Font.register({
  family: "DMSansItalic",
  src: DMSansItalic,
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    overflow: "hidden",
  },
  container__image: {
    borderRadius: "50%",
    width: 100,
    marginBottom: 10,
  },
  container__title: {
    fontSize: 14,
    color: "#50545f",
    fontFamily: "DMSansItalic",
    marginBottom: 15,
  },
  container__containerInfos: {
    fontSize: 9,
    width: "90%",
    backgroundColor: "white",
    border: "2px solid #e9eaeb",
    borderRadius: 10,
    padding: "0 15 15 15",
    marginBottom: 30,
    overflow: "hidden",
  },
});

interface Props {
  image1: string;
  panneaux: number | null | undefined;
  puissance: number | null | undefined;
  kitHome: number | null;
  batterie: number | null;
  pitch: string | null | undefined;
  nameKitHome: (string | null | undefined)[];
  nameBatterie: (string | null | undefined)[];
  inverter_brand: string | null | undefined;
  SAGEItemsInfo: ISageProduct[];
  options?: KitPvOption[];
}

export const PdfContentFormuleP1 = ({
  image1,
  panneaux,
  puissance,
  kitHome,
  batterie,
  pitch,
  nameKitHome,
  nameBatterie,
  inverter_brand,
  SAGEItemsInfo,
  options = [],
}: Props) => {
  return (
    <>
      <View
        style={{
          position: "absolute",
          width: "100vw",
          height: 150,
          backgroundColor: "#e9eaeb",
          left: "0%",
          top: 170,
        }}
      ></View>
      <View style={styles.container}>
        <Image style={styles.container__image} src={image1} />
        <Text style={styles.container__title}>{pitch}</Text>
        <View style={styles.container__containerInfos}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "-18px",
            }}
          >
            {panneaux && (
              <PdfContentFormuleLign
                title={"Panneaux Thomson"}
                description="Garantis 20 ans, et 85 % de la puissance de production garantie à la 30ème année"
                value={panneaux.toString()}
              />
            )}
            {puissance && (
              <PdfContentFormuleLign
                title={"Puissance"}
                value={puissance + " kWc"}
              />
            )}
            {kitHome && (
              <PdfContentFormuleLign
                title={nameKitHome?.toString() || "Kit home management"}
                value={kitHome.toString()}
              />
            )}
            {batterie && (
              <PdfContentFormuleLign
                title={`Batterie(s) ${nameBatterie}`}
                value={batterie.toString()}
              />
            )}
            <PdfContentFormuleLign
              title={"Type de raccordement"}
              value={"Autoconsommation + Revente"}
            />
            {inverter_brand && (
              <PdfContentFormuleLign
                title={`Onduleur ${
                  inverter_brand.charAt(0).toUpperCase() +
                  inverter_brand.slice(1)
                }`}
                description={
                  SAGEItemsInfo?.find(
                    (item) => item.FA_CodeFamille === "ONDULEUR"
                  )?.["Garantie Fabricant"]
                    ? "Garantie " +
                      SAGEItemsInfo?.find(
                        (item) => item.FA_CodeFamille === "ONDULEUR"
                      )?.["Garantie Fabricant"]
                    : ""
                }
                value={"1"}
              />
            )}
            {SAGEItemsInfo?.some(
              (item) => item.FA_CodeFamille === "OPTIMISEUR"
            ) && (
              <PdfContentFormuleLign
                title={"Optimiseurs SolarEdge"}
                description={
                  "Garanti " +
                    SAGEItemsInfo?.find(
                      (item) => item.FA_CodeFamille === "OPTIMISEUR"
                    )?.["Garantie Fabricant"] || ""
                }
                value={
                  SAGEItemsInfo?.find(
                    (item) => item.FA_CodeFamille === "OPTIMISEUR"
                  )?.NO_Qte?.toString() || ""
                }
              />
            )}

            {options.length > 0 ? (
              <View>
                <Text
                  style={{
                    fontFamily: "DMSansItalic",
                    marginTop: 10,
                    marginBottom: 0,
                    color: "#525360",
                  }}
                >
                  Autres options :
                </Text>
                {options.map((option, index) => (
                  <PdfContentFormuleLign
                    title={option.alias || option.name}
                    value={"1"}
                    key={index}
                  />
                ))}
              </View>
            ) : (
              0
            )}
          </View>
        </View>
      </View>
    </>
  );
};
