// react import
import { CSSProperties, FormEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Updater, useImmer } from "use-immer";
// style import
import "./MonLogementRG.scss";
// Components import
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import {
  Dropdown,
  manageDropdownSingleSelection,
} from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { Layout } from "../../components/Layout/Layout";
import { SubHeader } from "../../containers/SubHeader/SubHeader";

// imgs import
import parametreMaison from "@web/shared/dist/assets/images/DesignSystemIllustrations/parametres-maison.svg";

// Data imports
import { monLogementRGMultipleChoicesData as questionData } from "./monLogementRGMultipleChoicesData";

// services import
import {
  SaveLogementRequest,
  useSaveLogementRq,
} from "../../api/hooks/useSaveLogementRq";
import { setStudyInfoAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useSaveLastPageVisited } from "../../services/Oree2/useSaveLastPageVisited";

// Interfaces import
import { INavigateData } from "../../services/useNavigation/useNavigation";
import {
  defaultWindowsInputFormData,
  WindowsInputFormData,
} from "./MonLogementRG.types";

// local interfaces declaration
interface IOption {
  label: string | null;
  value: string | null;
}
interface Props {
  goNext: (data?: INavigateData<string>) => void;
}

export const MonLogementRG = ({ goNext }: Props) => {
  const { study_id } = useParams();
  const {
    mutateAsync: saveLogement,
    isPending,
    isError,
    errorMessage,
  } = useSaveLogementRq();

  const dispatch = useAppDispatch();
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const { saveLastPageVisited } = useSaveLastPageVisited();
  // this function filters the options lists with the values that are present in the state (after eero call)
  //and is used to preselect the form fields
  const findPreselectedOptions = (
    questionsList: IOption[],
    values: (string | null)[] | undefined
  ): {
    label: string;
    value: string;
  }[] => {
    return (
      (values &&
        questionsList
          .filter((item) =>
            values?.some((value: string | null) => value === item.value)
          )
          .map((item) => ({
            label: item.label!,
            value: item.value!,
          }))) ||
      []
    );
  };

  const [isBadRequestModalOpen, setIsBadRequestModalOpen] = useState(false);

  // LOCAL STATES HANDLING => for each question, one state
  const [anneeDeConstruction, setAnneeDeConstruction] = useState<number | null>(
    studyInformation.annee_de_construction ?? null
  );
  const [nombreDOccupants, setNombreDOccupants] = useState<number | null>(
    studyInformation.number_of_occupants || 1 // You can not have 0 occupants
  );
  const [nombreDeNiveaux, setNombreDeNiveaux] = useState<number | null>(
    studyInformation.nombre_de_niveaux ?? 1
  );
  const [nombreDePieces, setNombreDePieces] = useState<number | null>(
    studyInformation.nombre_de_pieces || 1
  );
  const [hauteurSousPlafond, setHauteurSousPlafond] = useState<IOption[]>(
    (studyInformation.hauteur_sous_plafond &&
      findPreselectedOptions(questionData.hauteurSousPlafond.options, [
        studyInformation.hauteur_sous_plafond.toString(),
      ])) ||
      findPreselectedOptions(questionData.hauteurSousPlafond.options, ["250"])
  );
  const [surfaceAuSol, setSurfaceAuSol] = useState<string | null>(
    studyInformation.surface_au_sol?.toString() ?? null
  );
  const [surfaceHabitable, setSurfaceHabitable] = useState<string | null>(
    studyInformation.house_surface?.toString() ?? null
  );

  const [anneeDIsolationMurs, setAnneeDIsolationMurs] = useState<IOption[]>(
    (studyInformation.annee_isolation_murs &&
      findPreselectedOptions(questionData.anneeIsolation.options, [
        studyInformation.annee_isolation_murs,
      ])) ||
      []
  );
  const [anneeDIsolationCombles, setAnneeDIsolationCombles] = useState<
    IOption[]
  >(
    (studyInformation.annee_isolation_combles &&
      findPreselectedOptions(questionData.anneeIsolation.options, [
        studyInformation.annee_isolation_combles,
      ])) ||
      []
  );
  const [anneeDIsolationSousSol, setAnneeDIsolationSousSol] = useState<
    IOption[]
  >(
    (studyInformation.annee_isolation_sous_sol &&
      findPreselectedOptions(questionData.anneeIsolation.options, [
        studyInformation.annee_isolation_sous_sol,
      ])) ||
      []
  );
  const [anneeDIsolationGarage, setAnneeDIsolationGarage] = useState<IOption[]>(
    (studyInformation.annee_isolation_garage &&
      findPreselectedOptions(questionData.anneeIsolation.options, [
        studyInformation.annee_isolation_garage,
      ])) ||
      []
  );
  const [typeDeMurs, setTypeDeMurs] = useState<IOption[]>(
    findPreselectedOptions(
      questionData.typeDeMurs.options,
      studyInformation.type_de_murs
    ) || []
  );

  const [windows, updateWindows] = useImmer<WindowsInputFormData>(
    studyInformation.fenetres || defaultWindowsInputFormData
  );

  const [frenchWindows, updateFrenchWindows] = useImmer<WindowsInputFormData>(
    studyInformation.portes_fenetres || defaultWindowsInputFormData
  );

  const [typeDeCombles, setTypeDeCombles] = useState<IOption[]>(
    findPreselectedOptions(
      questionData.typeDeCombles.options,
      studyInformation.type_de_combles
    ) || []
  );
  const [typeDeVentilation, setTypeDeVentilation] = useState<IOption[]>(
    findPreselectedOptions(
      questionData.typeDeVentilation.options,
      studyInformation.type_de_ventilation
    ) || []
  );
  const [typeDeSousSol, setTypeDeSousSol] = useState<IOption[]>(
    findPreselectedOptions(
      questionData.typeDeSousSol.options,
      studyInformation.type_de_sous_sol
    ) || []
  );
  const [typeDeGarage, setTypeDeGarage] = useState<IOption[]>(
    findPreselectedOptions(
      questionData.typeDeGarage.options,
      studyInformation.type_de_garage
    ) || []
  );
  // variables and functions to manage what happens when the user clicks next
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const onGoNexsubHeader = () => {
    buttonSubmitRef.current?.click();
  };
  const onSubmitFormLogement = async (event: FormEvent) => {
    // it prevents the pare refresh
    event?.preventDefault();

    if (!study_id) {
      return;
    }

    // save the infos in the state
    dispatch(
      setStudyInfoAction({
        studyInformation: {
          annee_de_construction: anneeDeConstruction,
          number_of_occupants: nombreDOccupants,
          nombre_de_niveaux: nombreDeNiveaux,
          nombre_de_pieces: nombreDePieces,
          hauteur_sous_plafond: Number(hauteurSousPlafond?.[0]?.value),
          surface_au_sol: Number(surfaceAuSol),
          house_surface: Number(surfaceHabitable),
          annee_isolation_murs: anneeDIsolationMurs?.[0]?.value,
          annee_isolation_combles: anneeDIsolationCombles?.[0]?.value,
          annee_isolation_sous_sol: anneeDIsolationSousSol?.[0]?.value,
          annee_isolation_garage: anneeDIsolationGarage?.[0]?.value,
          type_de_murs: typeDeMurs?.map((obj) => {
            return obj?.value;
          }),
          type_de_combles: typeDeCombles.map((obj) => {
            return obj?.value;
          }),
          type_de_ventilation: typeDeVentilation.map((obj) => {
            return obj?.value;
          }),
          type_de_sous_sol: typeDeSousSol.map((obj) => {
            return obj?.value;
          }),
          type_de_garage: typeDeGarage.map((obj) => {
            return obj?.value;
          }),
          fenetres: windows,
          portes_fenetres: frenchWindows,
        },
      })
    );
    // build the payload for eero
    const bodyForEero: SaveLogementRequest = {
      annee_de_construction: anneeDeConstruction,
      number_of_occupants: nombreDOccupants,
      nombre_de_niveaux: nombreDeNiveaux,
      nombre_de_pieces: nombreDePieces,
      hauteur_sous_plafond: Number(hauteurSousPlafond?.[0]?.value),
      surface_au_sol: Number(surfaceAuSol),
      house_surface: Number(surfaceHabitable),
      annee_isolation_murs: anneeDIsolationMurs?.[0]?.value,
      annee_isolation_combles: anneeDIsolationCombles?.[0]?.value,
      annee_isolation_sous_sol: anneeDIsolationSousSol?.[0]?.value,
      annee_isolation_garage: anneeDIsolationGarage?.[0]?.value || null,
      type_de_murs: typeDeMurs.map((obj) => {
        return obj?.value;
      }),
      type_de_combles: typeDeCombles.map((obj) => {
        return obj?.value;
      }),
      type_de_ventilation: typeDeVentilation.map((obj) => {
        return obj?.value;
      }),
      type_de_sous_sol: typeDeSousSol.map((obj) => {
        return obj?.value;
      }),
      type_de_garage: typeDeGarage.map((obj) => {
        return obj?.value;
      }),
      fenetres: windows,
      portes_fenetres: frenchWindows,
    };

    // save info in db
    try {
      // throw if failed
      await saveLogement({
        study_id: study_id,
        payload: bodyForEero,
      });

      saveLastPageVisited({
        last_page_visited: "my_housings_form",
        study_id: studyInformation?.study_id || null,
        userToken: userAuthInfo.userToken,
      });

      surfaceHabitable
        ? goNext({ surfaceHabitable: surfaceHabitable })
        : goNext();
    } catch (error) {
      // abort
      return;
    }
  };

  useEffect(() => {
    if (isError) {
      setIsBadRequestModalOpen(true);
    }
    return () => {
      setIsBadRequestModalOpen(false);
    };
  }, [isError]);

  // Select relevant option regarding construction year
  const filteredAnneeIsolationOptions = questionData.anneeIsolation.options
    .filter((option) => option.status !== "old") // Remove old options that are kept in the list for compatibility with old studies
    .filter((option) => {
      if (!anneeDeConstruction) {
        return false;
      }

      const splittedOptionValue = option.value.split("-");

      // Select "Pas d'isolation" option
      if (splittedOptionValue[0] === "None") {
        return true;
      }

      if (anneeDeConstruction < Number(splittedOptionValue[1])) {
        return true;
      }

      return false;
    });

  return (
    <div className="MonLogementRG">
      <Modale isModaleOpen={isBadRequestModalOpen} textAlignMiddle>
        <h2 className="MesConsommationsAndEquipementsRG__TitleTwo">
          Informations incorrectes
        </h2>
        {isError &&
          errorMessage
            .split("\n")
            .map((message: string, index: number) => (
              <p key={index}>{message}</p>
            ))}
        <div className="MesConsommationsAndEquipementsRG__ButtonContainer">
          <CTAButton
            name="D'accord"
            onClick={() => setIsBadRequestModalOpen(false)}
          />
        </div>
      </Modale>
      <SubHeader
        title="Mon logement"
        goNext={onGoNexsubHeader}
        clientInfo={`${
          studyInformation.lastname &&
          studyInformation.lastname?.charAt(0).toUpperCase() +
            studyInformation.lastname?.slice(1)
        } ${
          studyInformation.firstname &&
          studyInformation.firstname?.charAt(0).toUpperCase() +
            studyInformation.firstname?.slice(1)
        }, ${studyInformation.address || ""}`}
      />
      <Layout>
        <div className="MonLogementRG__ErrorMessage">
          {isError && <ErrorMessage errorMessage={errorMessage} />}
        </div>
        <div className="MonLogementRG__Container">
          <div className="MonLogementRG__Content">
            <section className="MonLogementRG__TitleContainer">
              <img src={parametreMaison} alt="Caractéristiques du logement" />
              <h3>Caractéristiques du logement</h3>
            </section>
            <form onSubmit={onSubmitFormLogement}>
              <section
                className="MonLogementRG__FormContent"
                id="caracteristiques_du_logement"
              >
                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDeConstruction">
                    Année de construction :{" "}
                  </label>
                  <TextInput
                    inputLableHidden
                    type="number"
                    inputDescriptionHidden
                    iconHidden
                    placeholder="AAAA"
                    value={anneeDeConstruction?.toString() ?? undefined}
                    onChange={(e) => {
                      setAnneeDeConstruction(e.currentTarget.valueAsNumber);
                    }}
                    required
                  />
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDeConstruction">
                    Nombre d'occupants :{" "}
                  </label>
                  <Quantite
                    value={nombreDOccupants ?? 1}
                    onPlusMinusClick={(e) => setNombreDOccupants(e)}
                  />
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="nombreDeNiveaux">
                    Nombre de niveaux :{" "}
                  </label>
                  <Quantite
                    value={nombreDeNiveaux ?? 1}
                    onPlusMinusClick={(e) => setNombreDeNiveaux(e)}
                    minValue={1}
                  />
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="nombreDePieces">
                    Nombre de pièces :{" "}
                  </label>
                  <Quantite
                    value={nombreDePieces ?? 1}
                    onPlusMinusClick={(e) => setNombreDePieces(e)}
                    minValue={1}
                  />
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="hauteurSousPlafond">
                    Hauteur sous plafond :{" "}
                  </label>
                  <div className="dropdown_container">
                    <Dropdown
                      options={questionData.hauteurSousPlafond.options}
                      tags={false}
                      placeholder={"Hauteur sous plafond"}
                      optionsSelected={hauteurSousPlafond}
                      multipleChoices={false}
                      onSelect={(option) => {
                        manageDropdownSingleSelection(
                          option,
                          setHauteurSousPlafond
                        );
                      }}
                      isRequired={true}
                    />
                  </div>
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDeConstruction">
                    Surface au sol :{" "}
                  </label>
                  <div style={{ maxWidth: "102px" }}>
                    <TextInput
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="squaredMeters"
                      placeholder="XXX"
                      value={surfaceAuSol ?? undefined}
                      onChange={(e) => {
                        setSurfaceAuSol(e.currentTarget.value);
                      }}
                      required={true}
                      iconHidden={false}
                    />
                  </div>
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDeConstruction">
                    Surface habitable :{" "}
                  </label>
                  <div style={{ maxWidth: "102px" }}>
                    <TextInput
                      inputLableHidden
                      type="number"
                      inputDescriptionHidden
                      iconType="squaredMeters"
                      placeholder="XXX"
                      value={surfaceHabitable ?? undefined}
                      onChange={(e) => {
                        setSurfaceHabitable(e.currentTarget.value);
                      }}
                      required={true}
                      iconHidden={false}
                    />
                  </div>
                </div>

                <div className="MonLogementRG__MultiChoiceContainer">
                  <label className="label" htmlFor="typeDeMurs">
                    Type de murs :{" "}
                  </label>
                  <ChoixMultiple
                    options={questionData.typeDeMurs.options}
                    value={typeDeMurs}
                    onClick={(option) => {
                      option &&
                        setTypeDeMurs((state) => {
                          const newState = [...state];
                          const foundIndex = newState.findIndex(
                            (elem) => elem.label === option.label
                          );
                          if (foundIndex !== -1) {
                            newState.splice(foundIndex, 1);
                          } else {
                            return [option];
                          }
                          return newState;
                        });
                    }}
                    isRequired={true}
                  />
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDIsolationMurs">
                    Vos murs sont isolés depuis :{" "}
                  </label>
                  <div className="dropdown_container">
                    <Dropdown
                      placeholder="Année d'isolation"
                      options={filteredAnneeIsolationOptions}
                      tags={false}
                      optionsSelected={anneeDIsolationMurs}
                      multipleChoices={false}
                      onSelect={(option) => {
                        manageDropdownSingleSelection(
                          option,
                          setAnneeDIsolationMurs
                        );
                      }}
                      isRequired={true}
                    />
                  </div>
                </div>

                <InputWindows
                  title="Fenêtres"
                  windows={windows}
                  updateWindows={updateWindows}
                  questionOptions={questionData.typeDeVitrages.options.filter(
                    (option) => option.value !== "none" // = "Pas de fenêtre"
                  )} // For the windows, we don't want the option "Pas de fenêtre" as a house must have at least one window
                />

                <InputWindows
                  title="Portes-Fenêtres"
                  windows={frenchWindows}
                  updateWindows={updateFrenchWindows}
                  questionOptions={questionData.typeDeVitrages.options}
                />

                <div className="MonLogementRG__MultiChoiceContainer">
                  <label className="label" htmlFor="typeDeCombles">
                    Type de combles :{" "}
                  </label>
                  <ChoixMultiple
                    options={questionData.typeDeCombles.options}
                    value={typeDeCombles}
                    onClick={(e) => {
                      e &&
                        setTypeDeCombles((state) => {
                          const newState = [...state];
                          const foundIndex = newState.findIndex(
                            (option) => option.label === e.label
                          );
                          if (foundIndex !== -1) {
                            newState.splice(foundIndex, 1);
                          } else {
                            return [e];
                          }
                          return newState;
                        });
                    }}
                    isRequired={true}
                  />
                </div>

                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDIsolationCombles">
                    Vos combles sont isolés depuis :{" "}
                  </label>
                  <div className="dropdown_container">
                    <Dropdown
                      placeholder="Année d'isolation"
                      options={filteredAnneeIsolationOptions}
                      tags={false}
                      optionsSelected={anneeDIsolationCombles}
                      multipleChoices={false}
                      onSelect={(option) => {
                        manageDropdownSingleSelection(
                          option,
                          setAnneeDIsolationCombles
                        );
                      }}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="MonLogementRG__MultiChoiceContainer">
                  <label className="label" htmlFor="typeDeSousSol">
                    Type de sous-sol :{" "}
                  </label>
                  <ChoixMultiple
                    options={questionData.typeDeSousSol.options}
                    value={typeDeSousSol}
                    onClick={(e) => {
                      e &&
                        setTypeDeSousSol((state) => {
                          const newState = [...state];
                          const foundIndex = newState.findIndex(
                            (option) => option.label === e.label
                          );
                          if (foundIndex !== -1) {
                            newState.splice(foundIndex, 1);
                          } else {
                            return [e];
                          }
                          return newState;
                        });

                      if (e?.value === "garage") {
                        // We preselect the typeDeGarage to 'interieur'
                        // if the user choose type de sous-sol = garage
                        const option = questionData.typeDeGarage.options.find(
                          (option) => option.value === "interieur"
                        );
                        option && setTypeDeGarage([option]);
                      } else {
                        // We reset typeDeGarage if the user change the type of sous-sol
                        setTypeDeGarage([]);
                      }
                    }}
                    isRequired={true}
                  />
                </div>
                <div className="MonLogementRG__QuestionContainer">
                  <label className="label" htmlFor="anneeDIsolationSousSol">
                    Votre plancher est isolé depuis :{" "}
                  </label>
                  <div className="dropdown_container">
                    <Dropdown
                      placeholder="Année d'isolation"
                      options={filteredAnneeIsolationOptions}
                      tags={false}
                      optionsSelected={anneeDIsolationSousSol}
                      multipleChoices={false}
                      onSelect={(option) => {
                        manageDropdownSingleSelection(
                          option,
                          setAnneeDIsolationSousSol
                        );
                      }}
                      isRequired={true}
                    />
                  </div>
                </div>

                <div className="MonLogementRG__MultiChoiceContainer">
                  <label className="label" htmlFor="typeDeGarage">
                    Type de garage :{" "}
                  </label>
                  <ChoixMultiple
                    options={questionData.typeDeGarage.options.filter(
                      (option) =>
                        typeDeSousSol.some(
                          (typeDeSousSol) => typeDeSousSol.value === "garage"
                        )
                          ? option.value !== "pasDeGarage"
                          : true
                    )}
                    value={typeDeGarage}
                    onClick={(e) => {
                      e &&
                        setTypeDeGarage((state) => {
                          const newState = [...state];
                          const foundIndex = newState.findIndex(
                            (option) => option.label === e.label
                          );
                          if (foundIndex !== -1) {
                            newState.splice(foundIndex, 1);
                          } else {
                            return [e];
                          }
                          return newState;
                        });

                      e?.value === "pasDeGarage" &&
                        setAnneeDIsolationGarage([]);
                    }}
                    isRequired={true}
                  />
                </div>

                {!typeDeGarage.some(
                  (typeDeGarage) => typeDeGarage.value === "pasDeGarage"
                ) ? (
                  <div className="MonLogementRG__QuestionContainer">
                    <label className="label" htmlFor="anneeDIsolationGarage">
                      Votre garage est isolé depuis :{" "}
                    </label>
                    <div className="dropdown_container">
                      <Dropdown
                        placeholder="Année d'isolation"
                        options={filteredAnneeIsolationOptions}
                        tags={false}
                        optionsSelected={anneeDIsolationGarage}
                        multipleChoices={false}
                        onSelect={(option) => {
                          manageDropdownSingleSelection(
                            option,
                            setAnneeDIsolationGarage
                          );
                        }}
                        isRequired={
                          !typeDeGarage.some(
                            (typeDeGarage) =>
                              typeDeGarage.value === "pasDeGarage"
                          )
                        }
                      />
                    </div>
                  </div>
                ) : null}
                <div className="MonLogementRG__MultiChoiceContainer">
                  <label className="label" htmlFor="typeDeVentilation">
                    Type de ventilation :{" "}
                  </label>
                  <ChoixMultiple
                    options={questionData.typeDeVentilation.options}
                    value={typeDeVentilation}
                    onClick={(e) => {
                      e &&
                        setTypeDeVentilation((state) => {
                          const newState = [...state];
                          const foundIndex = newState.findIndex(
                            (option) => option.label === e.label
                          );
                          if (foundIndex !== -1) {
                            newState.splice(foundIndex, 1);
                          } else {
                            return [e];
                          }
                          return newState;
                        });
                    }}
                    isRequired={true}
                  />
                </div>
              </section>
              <div className="MonLogementRG__MainButton">
                <CTAButton
                  type="submit"
                  ref={buttonSubmitRef}
                  name="Suivant"
                  isLoading={isPending}
                />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </div>
  );
};

function InputWindows({
  title,
  windows,
  updateWindows,
  questionOptions,
}: {
  title: string;
  windows: WindowsInputFormData;
  updateWindows: Updater<WindowsInputFormData>;
  questionOptions: IOption[];
}) {
  const isVisible = (type: keyof WindowsInputFormData): CSSProperties => {
    return { visibility: windows[type] > 0 ? "visible" : "hidden" };
  };

  const hasWindow =
    windows.simple > 0 || windows.double > 0 || windows.triple > 0;

  return (
    <div className="MonLogementRG__MultiChoiceContainer">
      <label className="label">{title}</label>
      <ChoixMultiple
        options={questionOptions}
        onClick={(option) => {
          if (!option || !option.value) return;

          // Case where the user selects "Pas de porte-fenêtre"
          if (option.value === "none") {
            updateWindows({ simple: 0, double: 0, triple: 0 });
            return;
          }

          // Cases where the user selects a type of window
          const type = option.value as keyof WindowsInputFormData;

          updateWindows((draft) => {
            draft[type] = draft[type] === 0 ? 1 : 0;
          });
        }}
        value={questionOptions.filter((option) => {
          if (option.value === "none" && !hasWindow) return true;

          const type = option.value as keyof WindowsInputFormData;
          return windows ? windows[type] > 0 : false;
        })}
      />

      {windows && hasWindow ? (
        <div className="MonLogementRG__WindowDetailsContainer">
          <div style={isVisible("simple")}>
            <Quantite
              value={windows.simple}
              onPlusMinusClick={(e) => {
                updateWindows((draft) => {
                  draft = draft || defaultWindowsInputFormData;
                  draft.simple = e;
                });
              }}
            />
          </div>

          <div style={isVisible("double")}>
            <Quantite
              value={windows.double}
              onPlusMinusClick={(e) => {
                updateWindows((draft) => {
                  draft = draft || defaultWindowsInputFormData;
                  draft.double = e;
                });
              }}
            />
          </div>

          <div style={isVisible("triple")}>
            <Quantite
              value={windows.triple}
              onPlusMinusClick={(e) => {
                updateWindows((draft) => {
                  draft = draft || defaultWindowsInputFormData;
                  draft.triple = e;
                });
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
