//Styles import
import "./incomeModale.scss";

//Libraries import
import { useState } from "react";

//Component import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { Dualite } from "@web/shared/dist/components/DesignSystem/Boutons/Dualite/Dualite";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";
import { Dropdown } from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

//Services import
import { setStudyInfoAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useSaveCartProducts } from "../../services/RenovationGlobale/MonProjet/useSaveCartProducts";
import { useSendIncomeData } from "../../services/RenovationGlobale/MonProjet/useSendIncomeData";
import { isDepartmentInIDF } from "../../services/isDepartmentInIDF";

//Data import
import {
  MoreThan5OccupantsInIDF,
  MoreThan5OccupantsOutOfIDF,
} from "../../services/MoreThan5Occupants";

//Data import
import { useSaveLogementRq } from "../../api/hooks/useSaveLogementRq";
import { colorsInIDF, colorsOutOfIDF } from "../../data/plafondsDeRessources";

export const IncomeModale = ({
  setIsIncomeModaleOpen,
  fetchProducts,
}: {
  setIsIncomeModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchProducts: () => Promise<void>;
}) => {
  //Redux
  const dispatch = useAppDispatch();
  const { studyInformation, userAuthInfo, cartProducts, RGProjectKPI } =
    useAppSelector((state) => state);

  //Custom Hook
  const { mutateAsync: saveLogementRq, isPending: saveLogementRqIsLoading } =
    useSaveLogementRq();

  const { sendIncomeData, isLoading: sendIncomeIsLoading } =
    useSendIncomeData();
  const { saveCartProducts } = useSaveCartProducts();

  //Form states
  const [constructionYear, setConstructionYear] = useState<number | null>(
    studyInformation?.annee_de_construction || null
  );
  const [occupantNumber, setOccupantNumber] = useState<number>(
    studyInformation.number_of_occupants
      ? studyInformation.number_of_occupants
      : 1
  );
  const [fiscallyFrench, setFiscallyFrench] = useState<boolean>(
    studyInformation?.fiscally_french ?? true
  );

  const ocupationTypeOption = [
    {
      label: "Propriétaire occupant",
      value: "Propriétaire occupant",
    },
    {
      label: "Propriétaire de maison secondaire",
      value: "Propriétaire de maison secondaire",
    },
    {
      label: "Propriétaire bailleur",
      value: "Propriétaire bailleur",
    },
    {
      label: "Locataire",
      value: "Locataire",
    },
  ];

  const [occupationType, setOccupationType] = useState<
    {
      label: string | null;
      value: string | null;
    }[]
  >(
    ocupationTypeOption.filter(
      (occupation) => occupation.value === studyInformation.occupation_type
    )
  );

  const incomeOption = isDepartmentInIDF({
    zipcode: studyInformation.zipcode || null,
  })
    ? occupantNumber > 5
      ? MoreThan5OccupantsInIDF(occupantNumber)
      : colorsInIDF[occupantNumber.toString()]
    : occupantNumber > 5
    ? MoreThan5OccupantsOutOfIDF(occupantNumber)
    : colorsOutOfIDF[occupantNumber.toString()];

  const [income, setIncome] = useState<
    {
      label: string | null;
      value: string | null;
    }[]
  >(
    incomeOption.filter(
      (income) => income.value === studyInformation.income_color
    ) || incomeOption[0]
  );

  const onClickValidateForm = async () => {
    const studyId = studyInformation.study_id;
    if (!studyId) return;

    const bodyToSave = {
      annee_de_construction: constructionYear,
      number_of_occupants: occupantNumber,
      fiscally_french: fiscallyFrench,
      occupation_type: occupationType?.[0]?.value,
      income_color: income[0]?.value,
    };

    dispatch(
      setStudyInfoAction({
        studyInformation: bodyToSave,
      })
    );

    await saveLogementRq({
      study_id: studyId,
      payload: bodyToSave,
    });

    await saveCartProducts({
      cartProducts: cartProducts.filter((product) => product.isSelected),
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
    });

    await sendIncomeData({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      incomeData: {
        domiciled_in_france: fiscallyFrench,
        household_type: income[0]?.value || "",
        owning_type: occupationType?.[0]?.value || "",
      },
    });
    setIsIncomeModaleOpen(false);

    // fetchProducts enables to refresh product helps in case of incomes changes
    fetchProducts();
  };

  return (
    <div className="IncomeModale">
      <div className="IncomeModale__InputContainer">
        <p>Année de construction du logement :</p>
        <TextInput
          value={constructionYear?.toString()}
          iconHidden
          inputDescriptionHidden
          inputLableHidden
          placeholder=""
          onChange={(e) => setConstructionYear(e.target.valueAsNumber)}
          error={constructionYear ? false : true}
          type="number"
        />
      </div>
      <div className="IncomeModale__InputContainer">
        <p>Nombre d'occupants :</p>
        <Quantite
          value={occupantNumber}
          onPlusMinusClick={setOccupantNumber}
          minValue={1}
        />
      </div>
      <div className="IncomeModale__ChoiceButtonContainer">
        <p>Êtes-vous domicilié fiscalement en France ?</p>
        <Dualite
          binaryValue={fiscallyFrench}
          onSelect={(value) => {
            setFiscallyFrench(value);
          }}
        />
      </div>
      <div className="IncomeModale__ChoiceButtonContainer">
        <p>Type d'occupation :</p>
        <ChoixMultiple
          options={ocupationTypeOption}
          value={occupationType}
          onClick={(option) => {
            option ? setOccupationType([option]) : setOccupationType([]);
          }}
          gap={false}
        />
      </div>
      <div className="IncomeModale__InputContainer">
        <p>Catégorie de revenus :</p>
        <div className="IncomeModale__DropdownContainer">
          <Dropdown
            options={incomeOption}
            onSelect={(option) => setIncome([option])}
            optionsSelected={income}
            multipleChoices={false}
            placeholder=""
            tags={false}
            fontSize={16}
          />
        </div>
      </div>
      <div className="IncomeModale__ButtonContainer">
        <CTAButton
          name="Annuler"
          category="secondary"
          onClick={() => {
            setIsIncomeModaleOpen(false);

            if (RGProjectKPI) return; // If there is RGProjectKPI it means there is some product in the cart, so we just want to close the modale
            fetchProducts();
          }}
        />
        <CTAButton
          name="Valider"
          onClick={() => onClickValidateForm()}
          isLoading={sendIncomeIsLoading || saveLogementRqIsLoading}
        />
      </div>
    </div>
  );
};
