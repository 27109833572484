// Initial state
import { IAppStates } from "../interfaces/generalInterfaces";

export const initialState: IAppStates = {
  userAuthInfo: {
    userToken: null,
    userId: null,
    crm_user_id: null,
    companyId: null,
  },
  commercialUserInformation: {
    company_id: null,
    userFirstName: null,
    userLastName: null,
    userEmail: null,
    userPhoneNumber: null,
    userCompany: null,
    userRole: null,
  },
  monInstallation: {
    indexOptionChosen: 0,
    mesScenarios: [],
    dimensionnement: null,
    performance: null,
    profitability: null,
    impactEnvironnemental: null,
  },
  SAGEItemsInfo: [],
  studyInformation: {},
  scenarioChosen: {
    scenario_id: null,
    is_personalized_scenario: false,
    options: null,
    tva_refund: null,
  },
  cartProducts: [],
  solarPotentialStepsStatus: {
    isLoadingScreenDisplayed: true,
    isSolarPotentialKOForm: undefined,
    isCheckSolarPotentialAvailabilityLoading: false,
    isGetSolarPotentialLoading: false,
  },
  RGProjectKPI: null,
  companySettings: {
    uiSettings: [],
  },
};
