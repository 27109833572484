//Styles import
import "swiper/css";
import "./ProductPagesWrapper.scss";

//Libraries import
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/store/hook";

// Components import
import { Layout } from "../../../components/Layout/Layout";

// Services import
import { useGetProductsFromCategory } from "../../../services/RenovationGlobale/MonProjet/useGetProductsFromCategory";
import { productsImagesDictionnary } from "../../../services/RenovationGlobale/productsImagesDictionnary";
import { productsNamesDictionnary } from "../../../services/RenovationGlobale/productsNamesDictionnary";
import { selectProductPageToDisplay } from "../../../services/RenovationGlobale/selectProductPageToDisplay";

// Images import
import chevron from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-right.svg";

// Interfaces import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import {
  IDataProduct,
  TProjectPages,
} from "../../../interfaces/renoglobaleInterface";
import { useGetSAGEArticleInfo } from "../../../services/Recapitulatif/useGetSAGEArticleInfo";

interface IProps {
  setScreenSelected: React.Dispatch<React.SetStateAction<TProjectPages>>;
  productCategorySelected: string;
  setProductCategorySelected: React.Dispatch<React.SetStateAction<string>>;
  previousScreen?: TProjectPages;
  selectedProductIndex?: number | null;
  fetchProjectKPI: ({
    cartProductsToDisplay,
  }: {
    cartProductsToDisplay?: IDataProduct[] | undefined;
  }) => Promise<void>;
  isEligibleRenoGlobal: boolean;
}

export const ProductPagesWrapper = ({
  setScreenSelected,
  productCategorySelected: selectedProductCategory,
  setProductCategorySelected,
  previousScreen,
  selectedProductIndex,
  fetchProjectKPI,
  isEligibleRenoGlobal,
}: IProps) => {
  const { studyInformation, userAuthInfo } = useAppSelector((state) => state);
  const { getProductsFromCategory } = useGetProductsFromCategory();
  const { getSAGEArticleInfo } = useGetSAGEArticleInfo();
  const [
    isFetchProductFromCategoryLoading,
    setIsFetchProductFromCategoryLoading,
  ] = useState<boolean>(false);

  const [dataProductsFromCategory, setDataProductsFromCategory] = useState<
    IDataProduct[]
  >([]);

  const fetchProductsFromCategory = async () => {
    setIsFetchProductFromCategoryLoading(true);
    const productsFromCategoryResponse = await getProductsFromCategory({
      userToken: userAuthInfo.userToken,
      study_id: studyInformation.study_id,
      category: selectedProductCategory,
    });

    if (productsFromCategoryResponse) {
      setDataProductsFromCategory(productsFromCategoryResponse);

      const SAGECharacteristicsPromises = productsFromCategoryResponse.map(
        (product) =>
          getSAGEArticleInfo({
            userToken: userAuthInfo.userToken,
            SAGE_reference: product.sage_reference,
            study_id: studyInformation?.study_id || "",
          })
      );
      await Promise.allSettled([...SAGECharacteristicsPromises]);
    }
    setIsFetchProductFromCategoryLoading(false);
  };

  useEffect(() => {
    selectedProductCategory !== "AUTRE" && fetchProductsFromCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickGoBack = () => {
    setProductCategorySelected("");
    previousScreen === "product_catalogue"
      ? setScreenSelected("product_catalogue")
      : setScreenSelected("project");
  };

  return (
    <Layout>
      <div className="ProductPagesWrapper">
        <section
          className="ProductPagesWrapper__GoBack"
          onClick={onClickGoBack}
        >
          <img src={chevron} alt="" />
          {previousScreen === "product_catalogue" ? (
            <p>Retourner au catalogue</p>
          ) : (
            <p>Mon projet</p>
          )}
        </section>
        <section className="ProductPagesWrapper__ContentContainer">
          <div className="ProductPagesWrapper__Image">
            <img
              src={productsImagesDictionnary({
                product_type: selectedProductCategory,
              })}
              alt="produit"
            />
          </div>
          <div className="ProductPagesWrapper__Content">
            <section className="ProductPagesWrapper__FormulaireContainer">
              <div className="ProductPagesWrapper__FormulaireTitle">
                <h1>
                  {productsNamesDictionnary({
                    product_type: selectedProductCategory,
                  })}
                </h1>
              </div>

              <div className="ProductPagesWrapper__Formulaire">
                {isFetchProductFromCategoryLoading ? (
                  <div className="ProductPagesWrapper__LoaderContainer">
                    <p>Chargement des informations produit ...</p>
                    <LoadingComponent diameter={60} />
                  </div>
                ) : dataProductsFromCategory.length !== 0 ||
                  selectedProductCategory === "AUTRE" ? (
                  selectProductPageToDisplay({
                    selectedProductCategory,
                    dataProductsFromCategory,
                    selectedProductIndex,
                    setScreenSelected,
                    fetchProjectKPI,
                    isEligibleRenoGlobal,
                  }) || "Page produit introuvable"
                ) : null}
              </div>
            </section>
          </div>
        </section>
      </div>
    </Layout>
  );
};
