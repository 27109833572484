//Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

//Interfaces import
import {
  IDocumentFromDB,
  IOreeStudyType,
} from "../../interfaces/generalInterfaces";

//Local interface
interface IProps {
  study_id: string;
  userToken: string;
  fileType: "devis" | "bdc" | "etude";
}

export interface IRequestDocumentInfo extends IDocumentFromDB {
  study_type: IOreeStudyType;
}

export const useGetAllDocumentsFromAzureBlobStorage = () => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getAllDocumentsFromAzureBlobStorage = async ({
    study_id,
    userToken,
    fileType,
  }: IProps): Promise<IRequestDocumentInfo[] | void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IRequestDocumentInfo[]>(
      `${process.env.REACT_APP_EERO_URL}/oree2/azureBlobStorage/getAllDocumentsFromAzureBlobStorage/${study_id}/${fileType}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (!response) {
      setErrorMessage("Impossible de récupérer les données de vos documents.");
      return;
    }

    return response;
  };

  return {
    getAllDocumentsFromAzureBlobStorage,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
