//Style import
import "./productPVRecapitulatif.scss";

//Libraries import
import { useState } from "react";

//Services import
import { formatProductCharacteristicsToDisplay } from "../../../services/Recapitulatif/formatProductCharacteristicsToDisplay";
import { productPrices } from "../../../services/RenovationGlobale/calculateProductPrice";
import { euroLongFormat } from "../../../services/euroFormatter";

//Interface import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import { IDataProduct } from "../../../interfaces/renoglobaleInterface";

//Compoenent import
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Local interface declaration
interface IProps {
  product: IDataProduct;
  sageProduct: ISageProduct[];
  isGetMissingProductFromSageLoading: boolean;
  isEligibleRenoGlobal: boolean;
}

export const ProductPVRecapitulatif = ({
  product,
  sageProduct,
  isGetMissingProductFromSageLoading,
  isEligibleRenoGlobal,
}: IProps) => {
  const [isDetailsDisplay, setIsDetailsDisplay] = useState<boolean>(false);

  return isGetMissingProductFromSageLoading ? (
    <div className="ProductPVRecapitulatif__LoaderContainer">
      <LoadingComponent diameter={60} />
    </div>
  ) : sageProduct ? (
    <div className="ProductPVRecapitulatif">
      <div className="ProductPVRecapitulatif__ProductDetailsContainer">
        <div className="ProductPVRecapitulatif__ProductTitle">
          <p>{product.product_alias || product.product_name}</p>
          <p>
            {euroLongFormat(
              productPrices({
                product,
                isEligibleRenoGlobal,
              }).totalPriceWithTaxWithoutHelpAndExtraCharge
            )}
          </p>
        </div>
        <div className="ProductPVRecapitulatif__ProductDetails">
          {formatProductCharacteristicsToDisplay({
            productInfo: product,
            sageProduct,
          })}

          <div className="ProductPVRecapitulatif__ProductsToAdd">
            {product?.extra_charges_added?.map((productToAdd, index) => (
              <div
                key={index}
                className="ProductPVRecapitulatif__ProductsToAddContent"
              >
                <p>+ {productToAdd.product_name}</p>
                <span>
                  {"+" + euroLongFormat(productToAdd.price_with_tax || 0)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="ProductPVRecapitulatif__ShowDetails">
        <p onClick={() => setIsDetailsDisplay(!isDetailsDisplay)}>
          {isDetailsDisplay ? "Masquer les détails" : "Afficher les détails"}
        </p>
      </div>

      <div
        className={`ProductPVRecapitulatif__RecapProduit ${
          !isDetailsDisplay ? "hide" : ""
        }`}
      >
        {/*  Solar pannels */}
        {sageProduct
          .filter((productCharacteristics) => {
            return (
              productCharacteristics.NO_RefDet === "THOMSON400" ||
              productCharacteristics.NO_RefDet === "THOMSON500"
            );
          })
          .map((productCharacteristics, index) => {
            return (
              <RecapProduitPV
                key={"THOMSON" + index}
                product={product}
                productCharacteristicsFromSage={productCharacteristics}
                url={productCharacteristics["Lien fiche produit"] || undefined}
              />
            );
          })}
        {/*  Batteries */}
        {(product.selected_characteristics?.n_batteries || 0) > 0 &&
          sageProduct
            .filter((productCharacteristics) => {
              return productCharacteristics.FA_CodeFamille === "BATTERIE";
            })
            .map((productCharacteristics, index) => {
              return (
                <RecapProduitPV
                  key={"BATTERIE" + index}
                  product={product}
                  productCharacteristicsFromSage={productCharacteristics}
                  url={
                    productCharacteristics["Lien fiche produit"] || undefined
                  }
                  number_of_batteries={
                    product.selected_characteristics?.n_batteries
                  }
                />
              );
            })}
        {/*  Home management kit */}
        {product.selected_characteristics?.kit_home_management &&
          sageProduct
            .filter((productCharacteristics) => {
              return productCharacteristics.FA_CodeFamille === "DOMOTIQUE";
            })
            .map((productCharacteristics, index) => {
              return (
                <RecapProduitPV
                  key={"DOMOTIQUE" + index}
                  product={product}
                  productCharacteristicsFromSage={productCharacteristics}
                  url={
                    productCharacteristics["Lien fiche produit"] || undefined
                  }
                  kit_home_management={
                    product.selected_characteristics?.kit_home_management
                  }
                  productAlias={
                    product.product_characteristics.kit_home_management?.alias
                  }
                />
              );
            })}

        {/*  Onduleur */}
        {sageProduct
          .filter((productCharacteristics) => {
            return productCharacteristics.FA_CodeFamille === "ONDULEUR";
          })
          .map((productCharacteristics, index) => {
            return (
              <RecapProduitPV
                key={"ONDULEUR" + index}
                product={product}
                productCharacteristicsFromSage={productCharacteristics}
                url={productCharacteristics["Lien fiche produit"] || undefined}
              />
            );
          })}
        {/*  Optimiseur */}
        {sageProduct
          .filter((productCharacteristics) => {
            return productCharacteristics.FA_CodeFamille === "OPTIMISEUR";
          })
          .map((productCharacteristics, index) => {
            return (
              <RecapProduitPV
                key={"OPTIMISEUR" + index}
                product={product}
                productCharacteristicsFromSage={productCharacteristics}
                url={productCharacteristics["Lien fiche produit"] || undefined}
              />
            );
          })}
        {/*  Optimiseur */}
        {sageProduct
          .filter((productCharacteristics) => {
            return productCharacteristics.NO_RefDet === "COFFRETACDCMONO";
          })
          .map((productCharacteristics, index) => {
            return (
              <RecapProduitPV
                product={product}
                productCharacteristicsFromSage={productCharacteristics}
                key={"COFFRETACDCMONO" + index}
                url={productCharacteristics["Lien fiche produit"] || ""}
              />
            );
          })}
      </div>

      {product.help.length > 0 ? (
        <div className="ProductPVRecapitulatif__HelpDetails">
          {product.help
            .filter((help) => help.is_eligible)
            .map((help, index) => (
              <div
                key={index}
                className={"ProductPVRecapitulatif__HelpDetailsContent"}
              >
                <p>{help.name}</p>
                <p>- {euroLongFormat(help.value || 0)}</p>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  ) : (
    <ErrorMessage errorMessage="Une erreur est survenue lors de la récupération des informations produits" />
  );
};

const RecapProduitPV = ({
  productCharacteristicsFromSage,
  url,
  number_of_batteries,
  kit_home_management,
  product,
  productAlias,
}: {
  productCharacteristicsFromSage: ISageProduct;
  url?: string;
  number_of_batteries?: number | null;
  kit_home_management?: boolean | null;
  product: IDataProduct;
  productAlias?: string | null;
}) => {
  return (
    <div className="ProductPVRecapitulatif__ProductDetailsContainer">
      <div className="ProductPVRecapitulatif__ProductTitle">
        <p>
          {productCharacteristicsFromSage?.NO_Qte &&
            +productCharacteristicsFromSage.NO_Qte + " x "}
          {number_of_batteries && number_of_batteries + " x "}
          {kit_home_management ? "1 x " : ""}
          {productAlias || productCharacteristicsFromSage?.AR_Design}
        </p>
      </div>
      <div className="ProductPVRecapitulatif__ProductDetails">
        {productCharacteristicsFromSage?.Marque && (
          <p>
            Marque <strong>{productCharacteristicsFromSage.Marque}</strong>
          </p>
        )}
        {productCharacteristicsFromSage?.Gamme && (
          <p>
            Gamme{" "}
            {(productCharacteristicsFromSage.NO_RefDet || "") +
              " " +
              productCharacteristicsFromSage.Gamme}
          </p>
        )}
        {productCharacteristicsFromSage?.Dimensions && (
          <p>Dimensions : {productCharacteristicsFromSage.Dimensions}</p>
        )}
        {productCharacteristicsFromSage?.Alimentation && (
          <p>Alimentation {productCharacteristicsFromSage.Alimentation}</p>
        )}
        {productCharacteristicsFromSage?.["Norme et Certification"] && (
          <p>
            Norme et Certification :{" "}
            {productCharacteristicsFromSage["Norme et Certification"]}
          </p>
        )}
        {productCharacteristicsFromSage?.["Garantie Fabricant"] && (
          <p>
            Garantie Fabricant :{" "}
            {productCharacteristicsFromSage["Garantie Fabricant"]}
          </p>
        )}
        {productCharacteristicsFromSage?.[
          "Classe energétique (Froid/Chaud"
        ] && (
          <p>
            Classe energétique :{" "}
            {productCharacteristicsFromSage["Classe energétique (Froid/Chaud"]}
          </p>
        )}
        {product.product_characteristics?.scop && (
          <p>SCOP : {product.product_characteristics.scop}</p>
        )}
      </div>
      {url && (
        <div className="ProductPVRecapitulatif__TechnicalSheet">
          <a href={url} target="_blank" rel="noreferrer">
            Fiche technique
          </a>
        </div>
      )}
    </div>
  );
};
